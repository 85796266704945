import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Localize from 'components/Localize';
import Textarea from 'components/Form/Textarea';
import checkIcon from 'shared/lib/styles/images/check.svg';
import { updateInternshipLearningTask } from 'actions/internshipsActions';
import learningTaskApi from 'api/LearningTaskApi';
import { Button, DatePicker, Error, Form, InputField } from 'shared';
import {
  TaskCardSection,
  TaskCardSectionContentWithButton,
  TaskCardSectionTitle,
  TaskCardsWrap,
  TaskCardWrapper,
  TaskFooter,
  ButtonClose
} from './TaskCardStyled';
import DocumentUploadButton from './DocumentUploadButton';

const WriteTask = ({
  pk,
  task,
  due_date,
  teacher_attachment,
  updateInternshipLearningTask,
  submitManageStudents,
  closeManageStudents
}) => {
  const handleSubmit = values => {
    if (submitManageStudents) {
      return submitManageStudents(values);
    }
    return updateInternshipLearningTask(pk, {
      task: values.task,
      teacher_attachment: values.attachment || null,
      due_date: values.due_date
    });
  };
  const deleteTeacherAttachment = pk => {
    learningTaskApi.deleteTeacherAttachment(pk);
  };

  return (
    <Localize>
      {({ translate }) => (
        <Form
          initialValues={{
            task: task || '',
            due_date: due_date || '',
            attachment: teacher_attachment
          }}
          validateSchema={{
            task: { required: true }
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ errors, isSubmitting, dirty }) => (
            <TaskCardWrapper>
              <TaskCardsWrap>
                <TaskCardSection>
                  <TaskCardSectionTitle>
                    {translate('task')}
                  </TaskCardSectionTitle>
                  <TaskCardSectionContentWithButton>
                    <div className="input">
                      <InputField
                        rows={2}
                        name="task"
                        placeholder={translate(
                          'eg-please-work-us-during-your-internship'
                        )}
                        component={Textarea}
                      />
                    </div>
                    <InputField
                      name="attachment"
                      component={DocumentUploadButton}
                      onDeleteTeacherAttachment={deleteTeacherAttachment}
                    />
                  </TaskCardSectionContentWithButton>
                </TaskCardSection>
                <TaskCardSection>
                  <TaskCardSectionTitle>
                    {translate('due-date')}
                  </TaskCardSectionTitle>
                  <div className="date">
                    <InputField
                      name="due_date"
                      yearsInFuture={4}
                      yearsInPrevious={0}
                      component={DatePicker}
                    />
                  </div>
                </TaskCardSection>
              </TaskCardsWrap>
              {errors._error && <Error message={errors._error} />}
              <TaskFooter>
                <Button
                  type="submit"
                  disableBoxShadow
                  icon={checkIcon}
                  size="small"
                  color="secondary"
                  disabled={!dirty}
                  loading={isSubmitting}
                >
                  {translate('edit-learning-task')}
                </Button>
              </TaskFooter>
              {closeManageStudents && (
                <ButtonClose type="button" onClick={closeManageStudents}>
                  <FontAwesomeIcon icon={faTimes} />
                </ButtonClose>
              )}
            </TaskCardWrapper>
          )}
        </Form>
      )}
    </Localize>
  );
};

WriteTask.propTypes = {
  updateInternshipLearningTask: PropTypes.func.isRequired,
  pk: PropTypes.number,
  task: PropTypes.string,
  due_date: PropTypes.string,
  teacher_attachment: PropTypes.shape({}),
  submitManageStudents: PropTypes.func,
  closeManageStudents: PropTypes.func
};

WriteTask.defaultProps = {
  pk: null,
  due_date: null,
  task: undefined,
  teacher_attachment: undefined,
  submitManageStudents: null,
  closeManageStudents: null
};

export default connect(undefined, { updateInternshipLearningTask })(WriteTask);
