import React from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import {
  ChatTabPanelWrapper,
  ChatTabRole,
  Tooltip,
} from './ChatTabPanelStyled';
import ChatTab from './ChatTab';
import {
  AvatarLetters,
  UserAvatar,
  UserAvatarCircle,
  UserAvatarWrapperTab,
} from '../ChatMessagesPanel/Messages/Message/MessageStyled';

export const tabNames = {
  APPLICANT: <Localize id="candidate" />,
  TEACHER: <Localize id="teacher" />,
  COMPANY_EMPLOYEE: <Localize id="company-employee" />,
  EDUCATION_PROVIDER: <Localize id="education-provider" />,
};
export const TABS = {
  APPLICANT: 'APPLICANT',
  TEACHER: 'TEACHER',
  COMPANY_EMPLOYEE: 'COMPANY_EMPLOYEE',
  EDUCATION_PROVIDER: 'EDUCATION_PROVIDER',
  GROUP_CHAT: 'GROUP_CHAT',
  GROUP_CHAT_WITHOUT_STUDENT: 'GROUP_CHAT_WITHOUT_STUDENT',
};

const getGroupChatTooltip = (users) => {
  let tooltip = '';
  users.forEach(({ user, is_owner }, index) => {
    if (is_owner) return;
    if (index === 0) {
      tooltip = user.first_name;
      return;
    }
    tooltip += `, ${user.first_name} ${user.last_name}`;
  });
  return tooltip;
};

const ChatTabPanel = ({
  users,
  handleChangeTab,
  groupChat,
  groupChatWithoutStudent,
  activeTabId,
  messages,
}) => {
  const handleActiveTab = (tabName) => () => handleChangeTab(tabName);
  const renderGroupChat = (
    groupChat,
    users,
    messages,
    withoutStudent = false,
  ) => {
    if (!groupChat) return null;
    const groupChatTooltip = getGroupChatTooltip(users);
    const hasUnreadMessages = messages.find(
      (m) => !m.is_owner && m.room && !m.read_at && m.room === groupChat.api_url,
    );
    return (
      <Tooltip
        key={users.length + 1}
        title={groupChatTooltip}
        theme="light"
        arrow
      >
        <ChatTab
          hasNotification={!!hasUnreadMessages}
          handleActiveTab={handleActiveTab(groupChat.api_url)}
          isTabActive={activeTabId === groupChat.api_url}
          isGroupChatTab
        >
          <ChatTabRole>
            {withoutStudent ? (
              <Localize id="group-chat-without-student" />
            ) : (
              <Localize id="group-chat" />
            )}
          </ChatTabRole>
        </ChatTab>
      </Tooltip>
    );
  };
  return (
    <ChatTabPanelWrapper>
      {users.map((user, idx) => {
        const hasUnreadMessages = messages.find(
          (m) => m.sender && m.sender.pk === user.pk && !m.read_at && !m.room,
        );
        return (
          <Tooltip
            key={idx}
            title={`${user.user.first_name} ${user.user.last_name}`}
            theme="light"
            arrow
          >
            <ChatTab
              hasNotification={!!hasUnreadMessages}
              handleActiveTab={handleActiveTab(user.pk)}
              isTabActive={activeTabId === user.pk}
            >
              <UserAvatarWrapperTab>
                <UserAvatarCircle>
                  {user.avatar ? (
                    <UserAvatar avatar={user.avatar} />
                  ) : (
                    <>
                      <AvatarLetters>
                        {' '}
                        {user.user.first_name && user.user.first_name[0]}
                        {user.user.last_name && user.user.last_name[0]}
                      </AvatarLetters>
                      {!user.user.first_name && !user.user.last_name && (
                        <FontAwesomeIcon
                          className="avatar"
                          size="sm"
                          icon={faUser}
                        />
                      )}
                    </>
                  )}
                </UserAvatarCircle>
              </UserAvatarWrapperTab>
              <ChatTabRole>{tabNames[user.role]}</ChatTabRole>
            </ChatTab>
          </Tooltip>
        );
      })}
      {renderGroupChat(groupChat, users, messages)}
      {renderGroupChat(
        groupChatWithoutStudent,
        groupChatWithoutStudent && groupChatWithoutStudent.participants,
        messages,
        true,
      )}
    </ChatTabPanelWrapper>
  );
};

ChatTabPanel.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      is_owner: PropTypes.bool.isRequired,
      user: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        avatar: PropTypes.string,
      }).isRequired,
    }),
  ).isRequired,
  handleChangeTab: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeTabId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  groupChat: PropTypes.shape({
    hasNotification: PropTypes.bool,
    api_url: PropTypes.string,
    unread_count: PropTypes.number,
  }),
  groupChatWithoutStudent: PropTypes.shape({
    hasNotification: PropTypes.bool,
    api_url: PropTypes.string,
    unread_count: PropTypes.number,
    participants: PropTypes.arrayOf(
      PropTypes.shape({
        is_owner: PropTypes.bool.isRequired,
        user: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          avatar: PropTypes.string,
        }).isRequired,
      }),
    ),
  }),
};

ChatTabPanel.defaultProps = {
  groupChat: null,
  groupChatWithoutStudent: null,
};

export default ChatTabPanel;
