import styled from 'styled-components';
import ReactSVG from 'react-svg';
import { device } from 'styles/device';

export const TaskFormWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TaskFormButtons = styled.div`
  display: flex;
  & button + button {
    margin-left: 10px;
  }
  @media ${device.mobile} {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    flex-direction: column;
    & button + button {
      margin-left: 0px;
      margin-top: 10px;
    }
  }
`;

export const AddTaskWrap = styled.div`
  position: relative;
  margin-top: ${({ hasItems }) => (hasItems ? '20px' : '0')};
`;

export const AddButton = styled.button`
  color: ${({ theme }) => theme.secondary};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  display: flex;
  align-items: center;

  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
`;

export const PlusIcon = styled(ReactSVG)`
  svg path {
    stroke: ${({ theme }) => theme.secondary};
  }
  svg {
    width: 20px;
    height: 20px;
  }
  margin-right: 20px;
`;

export const EditTaskWrap = styled.div`
  margin: 20px 0 10px 0;
`;

export const TaskFormLabelText = styled.div`
  text-transform: uppercase;
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeightLight};
  color: ${({ theme }) => theme.textColor69};
`;

export const TaskFormAttachment = styled.div`
  margin-bottom: 10px;
`;

export const TaskFormFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
`;

export const RemoveIcon = styled(ReactSVG)`
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
  svg path {
    stroke: ${({ theme }) => theme.primary};
  }
`;
