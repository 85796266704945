import React from 'react';
import PropTypes from 'prop-types';

import Localize from 'components/Localize';
import { DatePicker, InputField } from 'shared';
import { PeriodWrapper, DateTitle, To } from './PeriodStyled';

const Period = ({ startName, endName }) => (
  <div>
    <DateTitle>
      <Localize id="date" />
    </DateTitle>
    <PeriodWrapper>
      <InputField component={DatePicker} name={startName} />
      <To>
        <Localize id="to" />
      </To>
      <InputField component={DatePicker} name={endName} />
    </PeriodWrapper>
  </div>
);

Period.propTypes = {
  startName: PropTypes.string.isRequired,
  endName: PropTypes.string.isRequired,
};

export default Period;
