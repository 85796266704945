import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import DateTime from 'components/Form/DateTime';
import Period from 'components/Form/Period';
import CheckBox from 'components/Form/CheckBox';
import { Form, InputField } from 'shared';
import moment from 'moment';
import Localize from 'components/Localize';
import { buildInternshipUrl } from 'api/CalendarApi';
import { EVENT_TYPES, EVENT_VISIBILITY } from 'utils/calendarHelpers';
import Can, { actions } from 'components/Can';
import { section } from 'utils/access-rules/rules/sections';
import AttendeesSelect from '../AttendeesSelect';
import WhoSeeSelect from './WhoSeeSelect';
import {
  getAttendeesFromEvent,
  getStartOfTheDay,
  getEndOfTheDay,
} from './eventFormUtils';
import {
  FormWrapper,
  EventFormWrapper,
  EventFormContent,
  Textarea,
  Button,
  ButtonsWrapper,
  FieldLabelText,
  FieldLabelContent,
  FieldLabel,
  ButtonClose,
  ButtonText,
  ExcuseContainer,
  EventType,
} from './EventFormStyled';

const EventForm = ({
  date,
  event,
  onSendEvent,
  onCancel,
  closeForm,
  internshipId,
  isStudentProfile,
  submitManageStudents,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (values) => {
    setIsLoading(true);
    const data = {
      ...values,
      start:
        event.kind === EVENT_TYPES.ABSENCE
          ? getStartOfTheDay(values.start).format()
          : values.start,
      end:
        event.kind === EVENT_TYPES.ABSENCE
          ? getEndOfTheDay(values.end).format()
          : values.end,
      pk: event.pk,
      kind: event.kind,
      internship: internshipId ? buildInternshipUrl(internshipId) : null,
      visibility: values.visibility || EVENT_VISIBILITY.STUDENT_ONLY,
      attendees: values.attendees
        ? values.attendees.map(({ value }) => ({ pk: value }))
        : [],
    };
    if (submitManageStudents) {
      submitManageStudents(data, () => setIsLoading(false));
      return;
    }
    return onSendEvent(data).then(() => {
      setIsLoading(false);
      closeForm();
    });
  };
  return (
    <FormWrapper isInModal={submitManageStudents}>
      <Form
        initialValues={{
          title: event.title,
          description: event.description,
          place: event.place,
          visibility: event.visibility,
          medical_document_requested: event.medical_document_requested,
          attendees: getAttendeesFromEvent(event),
          start: event.start
            ? moment(event.start).utc()
            : getStartOfTheDay(date)
              .set('hour', 8)
              .set('minute', 0),
          end: event.end
            ? moment(event.end).utc()
            : getEndOfTheDay(date)
              .set('hour', 17)
              .set('minute', 0),
        }}
        validateSchema={{
          title: { required: true },
          start: {
            lessDateThan: (values) => values.end,
          },
        }}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(form) => (
          <Localize>
            {({ translate }) => (
              <EventFormWrapper>
                <EventFormContent>
                  <EventType>
                    {event.kind === EVENT_TYPES.ABSENCE ? (
                      <Localize id="enter-absence" />
                    ) : (
                      <Localize id="create-appointment" />
                    )}
                  </EventType>
                  <FieldLabel>
                    <FieldLabelText>
                      <Localize id="title" />
                    </FieldLabelText>
                    <FieldLabelContent>
                      <InputField
                        name="title"
                        component={Textarea}
                        placeholder={
                          !form.values.title && translate('no-title')
                        }
                        withBottomMargin={false}
                      />
                    </FieldLabelContent>
                  </FieldLabel>
                  {event.kind === EVENT_TYPES.ABSENCE ? (
                    <>
                      <Period startName="start" endName="end" />
                      <ExcuseContainer>
                        <InputField
                          component={CheckBox}
                          name="medical_document_requested"
                        />
                        <div className="label">
                          <Localize id="request-excuse-document" />
                        </div>
                      </ExcuseContainer>
                    </>
                  ) : (
                    <DateTime
                      meta={form}
                      startName="start"
                      endName="end"
                      wholeDayOption
                    />
                  )}
                  <FieldLabel>
                    <FieldLabelText>
                      <Localize id="description" />
                    </FieldLabelText>
                    <FieldLabelContent>
                      <InputField
                        name="description"
                        component={Textarea}
                        placeholder={
                          !form.values.description
                          && translate('no-description')
                        }
                        withBottomMargin={false}
                        lighttext="true"
                      />
                    </FieldLabelContent>
                  </FieldLabel>
                  <FieldLabel>
                    <FieldLabelText>
                      <Localize id="place" />
                    </FieldLabelText>
                    <FieldLabelContent>
                      <InputField
                        name="place"
                        component={Textarea}
                        placeholder={
                          !form.values.place && translate('where-should')
                        }
                        withBottomMargin={false}
                        lighttext="true"
                      />
                    </FieldLabelContent>
                  </FieldLabel>
                  <Can I={actions.edit} a={section.CalendarEventVisibility}>
                    <FieldLabel noBorder>
                      <FieldLabelText>
                        <Localize id="who-can-see-event" />
                      </FieldLabelText>
                      <FieldLabelContent>
                        <WhoSeeSelect
                          name="visibility"
                          meta={form}
                          isStudentProfile={isStudentProfile}
                        />
                      </FieldLabelContent>
                    </FieldLabel>
                  </Can>
                  {form.values.visibility === EVENT_VISIBILITY.ATTENDEES && (
                    <AttendeesSelect
                      name="attendees"
                      internshipId={internshipId}
                      meta={form}
                    />
                  )}
                </EventFormContent>
                <ButtonsWrapper>
                  <Button
                    type="button"
                    onClick={() => onSubmit(form.values)}
                    color="secondary"
                    size="small"
                    loading={isLoading}
                  >
                    <>
                      <FontAwesomeIcon icon={faCheck} />
                      <ButtonText>
                        {event.kind === EVENT_TYPES.ABSENCE ? (
                          <Localize id="save-absence" />
                        ) : (
                          <Localize id="save-appointment" />
                        )}
                      </ButtonText>
                    </>
                  </Button>
                  <ButtonClose type="button" onClick={onCancel}>
                    <FontAwesomeIcon icon={faTimes} />
                  </ButtonClose>
                </ButtonsWrapper>
              </EventFormWrapper>
            )}
          </Localize>
        )}
      </Form>
    </FormWrapper>
  );
};

EventForm.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  event: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  ).isRequired,
  onCancel: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  onSendEvent: PropTypes.func,
  internshipId: PropTypes.number,
  isStudentProfile: PropTypes.bool,
  submitManageStudents: PropTypes.func,
};

EventForm.defaultProps = {
  internshipId: null,
  isStudentProfile: true,
  onSendEvent: null,
  submitManageStudents: null,
};

export default EventForm;
