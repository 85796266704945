import { parseInternshipId } from 'utils/uri';

export const filterByInternship = (internshipId, items) => {
  if (!items || items.length === 0) {
    return [];
  }
  if (!internshipId) {
    return items.filter((i) => !i.internship);
  }
  return items.filter((i) => {
    if (typeof i.internship === 'string') {
      return (
        parseInternshipId(i.internship).toString() === internshipId.toString()
      );
    }
    if (i.internship === undefined) {
      return i;
    }
    return (
      i.internship && i.internship.pk.toString() === internshipId.toString()
    );
  });
};
