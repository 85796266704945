'use strict';

exports.__esModule = true;
var KEY = {
  SELECTED_INTERNSHIP: 'ES_SELECTED_INTERNSHIP'
};

var get = function get(key) {
  return localStorage.getItem(key);
};

var set = function set(key, value) {
  localStorage.setItem(key, value);
};

var remove = function remove(key) {
  localStorage.removeItem(key);
};

exports.get = get;
exports.set = set;
exports.KEY = KEY;
exports.remove = remove;