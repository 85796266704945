import moment from 'moment';

export const START = 'start';
export const END = 'end';

export const setTimeOptions = (time) => {
  const TIME_LIST_OPTIONS = [];

  if (time) {
    const day = moment(time)
      .utc()
      .startOf('day');
    for (let hour = 0; hour < 24; hour += 1) {
      for (let minutes = 0; minutes < 4; minutes += 1) {
        TIME_LIST_OPTIONS.push({
          disabled: false,
          label: `${day
            .set('hour', hour)
            .set('minute', minutes * 15)
            .format('HH:mm')}`,
          value: day
            .set('hour', hour)
            .set('minute', minutes * 15)
            .format(),
        });
        if (hour === 23 && minutes === 3) {
          TIME_LIST_OPTIONS.push({
            disabled: false,
            label: `${day
              .set('hour', hour)
              .set('minute', 59)
              .format('HH:mm')}`,
            value: day
              .set('hour', hour)
              .set('minute', 59)
              .format(),
          });
        }
      }
    }
  }
  return TIME_LIST_OPTIONS;
};

export const getDisabledOptions = (value, type, array) => array.map((option) => {
  const upOption = { ...option };
  if (type === START) {
    if (
      moment(value)
        .add(15, 'minute')
        .isAfter(option.value, 'minute')
    ) {
      upOption.disabled = true;
      return upOption;
    }
    upOption.disabled = false;
    return upOption;
  }
  if (type === END) {
    if (
      moment(value)
        .subtract(15, 'minute')
        .isBefore(option.value, 'minute')
    ) {
      upOption.disabled = true;
      return upOption;
    }
    upOption.disabled = false;
    return upOption;
  }
  return upOption;
});

export const getAttendeesFromEvent = ({ attendees }) => attendees.map(({ pk, user }) => ({
  value: pk,
  label: `${user.first_name} ${user.last_name}`,
}));

export const getStartOfTheDay = (date) => moment(date)
  .utc(true)
  .startOf('day');
export const getEndOfTheDay = (date) => moment(date)
  .utc(true)
  .endOf('day');
