import React from 'react';
import PropTypes from 'prop-types';
import { WhiteAreaWrapper } from './WhiteAreaStyled';

const WhiteArea = ({ children }) => (
  <WhiteAreaWrapper>
    {children}
  </WhiteAreaWrapper>
);

WhiteArea.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WhiteArea;
