'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _freeSolidSvgIcons = require('@fortawesome/free-solid-svg-icons');

var _SuccessStyled = require('./SuccessStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Success = function Success(_ref) {
  var children = _ref.children;
  return _react2.default.createElement(
    _SuccessStyled.SuccessContainer,
    null,
    _react2.default.createElement(_SuccessStyled.SuccessIcon, { icon: _freeSolidSvgIcons.faCheck }),
    _react2.default.createElement(
      _SuccessStyled.SuccessContent,
      null,
      children
    )
  );
};

Success.propTypes = process.env.NODE_ENV !== "production" ? {
  children: _propTypes2.default.node.isRequired
} : {};

exports.default = Success;
module.exports = exports['default'];