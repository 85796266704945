'use strict';

exports.__esModule = true;
exports.Languages = exports.SwitcherTrigger = exports.LanguageSwitcherContainer = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  position: relative;\n'], ['\n  position: relative;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  font-size: 13px;\n  color: ', ';\n  text-decoration: none;\n  padding: 5px;\n  border-bottom: 1px solid ', ';\n  cursor: pointer;\n  position: relative;\n  svg {\n    margin-left: 30px;\n  }\n'], ['\n  font-size: 13px;\n  color: ', ';\n  text-decoration: none;\n  padding: 5px;\n  border-bottom: 1px solid ', ';\n  cursor: pointer;\n  position: relative;\n  svg {\n    margin-left: 30px;\n  }\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  li {\n    color: ', ';\n    padding: 10px;\n  }\n  > li:hover {\n    background-color: ', ';\n  }\n'], ['\n  li {\n    color: ', ';\n    padding: 10px;\n  }\n  > li:hover {\n    background-color: ', ';\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var LanguageSwitcherContainer = exports.LanguageSwitcherContainer = _styledComponents2.default.div(_templateObject);

var SwitcherTrigger = exports.SwitcherTrigger = _styledComponents2.default.a(_templateObject2, function (_ref) {
  var theme = _ref.theme;
  return theme.white;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.white;
});

var Languages = exports.Languages = _styledComponents2.default.ul(_templateObject3, function (_ref3) {
  var theme = _ref3.theme;
  return theme.textColor;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.grayE6;
});