import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import { Prompt, Time } from 'shared';
import { Element } from 'react-scroll';
import Localize from 'components/Localize';
import { DownloadButton } from 'components/DownloadUrl';
import { toBase64 } from 'shared/lib/lib/base64';
import CheckBox from 'components/Form/CheckBox';
import trashIcon from 'shared/lib/styles/images/trash.svg';
import editIcon from 'shared/lib/styles/images/edit.svg';
import documentIcon from 'styles/images/svg/document.svg';
import uploadIcon from 'shared/lib/styles/images/upload-document.svg';
import {
  AttachmentIcon,
  CheckBoxDescription,
  CheckBoxTitle,
  EditIcon,
  RemoveIcon,
  SpecialTaskContent,
  SpecialTaskWrap,
  AttachmentsWrap,
  UploadIconContainer,
  UploadIcon,
} from './SpecialTaskStyled';

const SpecialTask = ({
  task,
  onItemClick,
  onItemRemove,
  onItemEdit,
  isAbsenceRelated,
  patchSpecialTasks,
}) => {
  const [isAttachmentsOpen, setAttachmentsOpen] = useState(false);
  const inputRef = useRef();
  const handleChangeFor = (pk) => (e) => {
    const { checked } = e.target;
    onItemClick(pk, checked);
  };
  const handleUploadButtonClick = () => inputRef.current.click();
  const onMedialDocumentUpload = (e) => {
    const value = e.target.files[0];
    toBase64(value).then((file) => {
      const newFile = {
        image: file.base64,
        file: file.base64.split(',').pop(),
      };
      patchSpecialTasks(task.pk, { medical_documents: [newFile] });
    });
  };
  const {
    pk,
    description,
    deadline,
    attachments,
    author,
    created,
    done,
    editForm,
  } = task;
  return (
    editForm || (
      <SpecialTaskWrap>
        <Element key={pk} name={pk.toString()}>
          <SpecialTaskContent>
            <CheckBox
              value={done}
              onChange={handleChangeFor(pk)}
              disabled={isAbsenceRelated}
            >
              <div>
                <CheckBoxTitle>{description}</CheckBoxTitle>
                <CheckBoxDescription>
                  <Localize
                    id="task-created-by"
                    data={{
                      author: `${author.user.first_name} ${author.user.last_name}`,
                      date: <Time date={created} />,
                    }}
                  />
                  {deadline && (
                    <Localize
                      id="deadline-date"
                      data={{
                        deadline: <Time date={deadline} />,
                      }}
                    />
                  )}
                </CheckBoxDescription>
              </div>
            </CheckBox>
            <div className="icons">
              {attachments.length > 0 && (
                <AttachmentIcon
                  src={documentIcon}
                  onClick={() => setAttachmentsOpen((open) => !open)}
                />
              )}
              {isAbsenceRelated && !task.done && (
                <UploadIconContainer>
                  <UploadIcon
                    onClick={handleUploadButtonClick}
                    src={uploadIcon}
                  />
                  <input
                    onChange={onMedialDocumentUpload}
                    accept="image/*, .pdf, .doc, .docx, .rtf"
                    type="file"
                    ref={inputRef}
                  />
                </UploadIconContainer>
              )}
              {onItemEdit && (
                <>
                  <EditIcon
                    src={editIcon}
                    onClick={() => onItemEdit({
                      pk,
                      description,
                      deadline,
                      attachments,
                    })}
                  />
                </>
              )}
              {onItemRemove && (
                <Prompt
                  confirm={() => onItemRemove(pk)}
                  trigger={<RemoveIcon src={trashIcon} />}
                >
                  <Localize
                    id="are-you-sure-want-delete-task"
                    data={{ name: description }}
                  />
                </Prompt>
              )}
            </div>
          </SpecialTaskContent>
          <Collapse isOpened={isAttachmentsOpen}>
            <AttachmentsWrap>
              {attachments.map(({ file, api_url }) => (
                <DownloadButton key={`task_attachment_${api_url}`} url={file} />
              ))}
            </AttachmentsWrap>
          </Collapse>
        </Element>
      </SpecialTaskWrap>
    )
  );
};

SpecialTask.propTypes = {
  task: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    deadline: PropTypes.string,
    author: PropTypes.object.isRequired,
    created: PropTypes.string.isRequired,
    done: PropTypes.bool.isRequired,
    attachments: PropTypes.array.isRequired,
    editForm: PropTypes.node,
    showAttachments: PropTypes.node,
  }).isRequired,
  isAbsenceRelated: PropTypes.bool,
  onItemClick: PropTypes.func,
  onItemRemove: PropTypes.func,
  onItemEdit: PropTypes.func,
  patchSpecialTasks: PropTypes.func,
};
SpecialTask.defaultProps = {
  onItemClick: () => {},
  onItemRemove: undefined,
  onItemEdit: undefined,
  isAbsenceRelated: false,
  patchSpecialTasks: () => {},
};

export default SpecialTask;
