import React from 'react';
import PropTypes from 'prop-types';
import DocumentItem from './DocumentItem';
import { DocumentList } from './DocumentsListStyled';

function DocumentsList({ documents }) {
  return (
    <DocumentList>
      {documents.map((documentData) => (
        <DocumentItem key={documentData.key} documentData={documentData}>
          {documentData.text}
        </DocumentItem>
      ))}
    </DocumentList>
  );
}

DocumentsList.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object),
};

DocumentsList.defaultProps = {
  documents: [],
};

export default DocumentsList;
