import usersApi from 'api/UsersApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getUsers(internshipId) {
  return createAction({
    type: types.GET_USERS,
    callAPI: () => usersApi.getUsers(internshipId).then((data) => ({ internshipId, data })),
    shouldCallAPI: (state) => internshipId && !state.users.data.byInternship[internshipId],
  });
}
