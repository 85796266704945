'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Button = require('../Button');

var _Button2 = _interopRequireDefault(_Button);

var _phone = require('../../styles/images/phone.svg');

var _phone2 = _interopRequireDefault(_phone);

var _whatsapp = require('../../styles/images/whatsapp.svg');

var _whatsapp2 = _interopRequireDefault(_whatsapp);

var _ContactBoxStyled = require('./ContactBoxStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ContactBox = function ContactBox(_ref) {
  var data = _ref.data;
  return _react2.default.createElement(
    _ContactBoxStyled.ContactBoxContainer,
    null,
    _react2.default.createElement(
      _ContactBoxStyled.ContactBoxTitle,
      null,
      'Wie d\xFCrfen wir dich kontaktieren?'
    ),
    _react2.default.createElement(
      'a',
      { href: data.callme_link },
      _react2.default.createElement(
        _Button2.default,
        { icon: _phone2.default, color: 'primary' },
        'Ruft mich an'
      )
    ),
    _react2.default.createElement(
      'a',
      { href: data.whatsapp_link },
      _react2.default.createElement(
        _Button2.default,
        { icon: _whatsapp2.default, color: 'whatsapp' },
        'Schreib mir bei WhatsApp'
      )
    )
  );
};

ContactBox.propTypes = process.env.NODE_ENV !== "production" ? {
  data: _propTypes2.default.shape({
    callme_link: _propTypes2.default.string.isRequired,
    whatsapp_link: _propTypes2.default.string.isRequired
  }).isRequired
} : {};

exports.default = ContactBox;
module.exports = exports['default'];