'use strict';

exports.__esModule = true;
exports.IconStyled = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  div {\n    background-color: ', ';\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  cursor: pointer;\n  svg path {\n    stroke: white;\n  }\n'], ['\n  div {\n    background-color: ', ';\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  cursor: pointer;\n  svg path {\n    stroke: white;\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactSvg = require('react-svg');

var _reactSvg2 = _interopRequireDefault(_reactSvg);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var IconStyled = exports.IconStyled = (0, _styledComponents2.default)(_reactSvg2.default)(_templateObject, function (_ref) {
  var theme = _ref.theme;
  return theme.secondary;
});