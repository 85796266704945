import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { MediaQuery } from 'shared';
import { Link } from 'react-router-dom';
import { device } from 'shared/src/styles/device';
import doneIcon from 'styles/images/svg/done.svg';
import refreshIcon from 'styles/images/svg/refresh.svg';
import {
  FAIcon,
  ITEM_STATUS,
  SnakeItem,
  SnakeItemIcon,
  SnakeItemLabel,
  SnakeItemStatus,
  SnakeWrapper,
} from './SnakeStyled';

export const ITEM_STATUS_ICON = {
  [ITEM_STATUS.DONE]: doneIcon,
  [ITEM_STATUS.IN_PROGRESS]: refreshIcon,
};

const Snake = ({
  items, onItemClick, scrollTo, selectedInternshipId,
}) => (
  <MediaQuery queries={device}>
    {(matches) => {
      const sizeProps = {
        mobile: matches.mobile.toString(),
      };
      const getAs = (item) => {
        if (item.status !== ITEM_STATUS.INACTIVE) {
          if (item.to) {
            return Link;
          }
          if (item.href) {
            return 'a';
          }
        }
        return undefined; // div
      };
      return (
        <SnakeWrapper {...sizeProps}>
          {items.map((item, index) => (
            <Fragment key={`snake_item_${index}`}>
              <SnakeItem
                {...sizeProps}
                onItemClick={onItemClick && !item.unClickable}
                scrollTo={scrollTo && item.scrollTo}
                status={item.status}
                className={item.className}
                onClick={
                  (item.handleClick && onItemClick && onItemClick)
                  || (scrollTo ? () => scrollTo(item.scrollTo) : undefined)
                }
                to={
                  item.status !== ITEM_STATUS.INACTIVE
                  && !item.handleClick
                  && onItemClick
                  && item.to
                    ? `${item.to}/${selectedInternshipId || ''}`
                    : undefined
                }
                href={
                  item.status !== ITEM_STATUS.INACTIVE
                  && !item.handleClick
                  && onItemClick
                    ? item.href
                    : undefined
                }
                as={getAs(item)}
                target={
                  item.status !== ITEM_STATUS.INACTIVE && item.href
                    ? '_blank'
                    : '_self'
                }
              >
                {item.fontAwesomeIcon ? (
                  <FAIcon
                    icon={item.icon}
                    {...sizeProps}
                    status={item.status}
                  />
                ) : (
                  <SnakeItemIcon
                    src={item.icon}
                    {...sizeProps}
                    status={item.status}
                    className="icon-container"
                  />
                )}
                <SnakeItemLabel
                  {...sizeProps}
                  status={item.status}
                  className="text"
                >
                  {item.label}
                </SnakeItemLabel>
                {item.status !== ITEM_STATUS.INACTIVE && (
                  <SnakeItemStatus
                    src={ITEM_STATUS_ICON[item.status]}
                    status={item.status}
                    {...sizeProps}
                  />
                )}
              </SnakeItem>
            </Fragment>
          ))}
        </SnakeWrapper>
      );
    }}
  </MediaQuery>
);

Snake.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
      status: PropTypes.oneOf(Object.values(ITEM_STATUS)),
      to: PropTypes.string,
      href: PropTypes.string,
    }),
  ).isRequired,
  onItemClick: PropTypes.func,
  scrollTo: PropTypes.func,
  selectedInternshipId: PropTypes.number,
};

Snake.defaultProps = {
  onItemClick: undefined,
  scrollTo: undefined,
  selectedInternshipId: null,
};

export default Snake;
