import React from 'react';
import PropTypes from 'prop-types';
import { faCheck, faSync, faTimes } from '@fortawesome/free-solid-svg-icons';
import { INVITEES_STATUSES } from 'api/CalendarApi';
import { EVENT_VISIBILITY } from 'utils/calendarHelpers';
import Localize from 'components/Localize';
import { roles } from 'utils/access-rules/constants';
import {
  AcceptedIcon,
  RejectedIcon,
  NoRespondedIcon,
  InviteeWrapper,
} from './InviteesListStyled';

const getStatusIcon = (status) => {
  switch (status) {
    case INVITEES_STATUSES.accepted:
      return <AcceptedIcon icon={faCheck} />;
    case INVITEES_STATUSES.rejected:
      return <RejectedIcon icon={faTimes} />;
    default:
      return <NoRespondedIcon icon={faSync} />;
  }
};

const getRole = (role) => {
  switch (role) {
    case roles.Applicant:
      return <Localize id="applicant" />;
    case roles.Teacher:
      return <Localize id="teacher" />;
    case roles.EducationProvider:
      return <Localize id="education-provider" />;
    case roles.CompanyEmployee:
      return <Localize id="company-employee" />;
    default:
      return null;
  }
};

const InviteesList = ({ invitees, eventVisibility }) => (
  <ul>
    {invitees
      .filter(
        (invitee) => invitee.esuser.role === roles.Applicant
          || eventVisibility === EVENT_VISIBILITY.ATTENDEES,
      )
      .map((invitee) => (
        <InviteeWrapper key={invitee.pk}>
          <span className="name">
            {`${invitee.esuser.user.first_name} ${invitee.esuser.user.last_name}`}
          </span>
          <span className="role">{getRole(invitee.esuser.role)}</span>
          <span className="status-icon">{getStatusIcon(invitee.status)}</span>
        </InviteeWrapper>
      ))}
  </ul>
);

InviteesList.propTypes = {
  invitees: PropTypes.arrayOf(
    PropTypes.shape({
      pk: PropTypes.number.isRequired,
      esuser: PropTypes.shape({
        user: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string,
        }).isRequired,
      }).isRequired,
      status: PropTypes.string,
      role: PropTypes.string,
    }),
  ).isRequired,
  eventVisibility: PropTypes.string.isRequired,
};

export default InviteesList;
