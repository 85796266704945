import styled from 'styled-components';
import ReactSVG from 'react-svg';
import { device } from 'styles/device';

export const SpecialTaskWrap = styled.div`
  & + & {
    margin-top: 20px;
  }
`;

export const SpecialTaskContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.mobile} {
    .icons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > div:last-child {
        margin-top: 10px;
      }
      > div {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
`;

export const CheckBoxTitle = styled.div`
  color: ${({ theme }) => theme.secondary};
`;

export const CheckBoxDescription = styled.div`
  color: ${({ theme }) => theme.grayA0};
  font-size: 14px;
`;

export const EditIcon = styled(ReactSVG)`
  display: inline-block;
  cursor: pointer;
  svg path {
    stroke: ${({ theme }) => theme.secondary};
  }
  margin-left: 20px;
  margin-right: 10px;
`;

export const RemoveIcon = styled(ReactSVG)`
  display: inline-block;
  cursor: pointer;
  svg path {
    stroke: ${({ theme }) => theme.primary};
  }
`;

export const UploadIconContainer = styled.div`
  input {
    display: none;
  }
`;

export const UploadIcon = styled(ReactSVG)`
  display: inline-block;
  cursor: pointer;
  svg path {
    stroke: ${({ theme }) => theme.secondary};
  }
`;

export const AttachmentIcon = styled(ReactSVG)`
  display: inline-block;
  cursor: pointer;
  svg path {
    stroke: ${({ theme }) => theme.secondary};
  }
  margin-right: 10px;
`;

export const AttachmentsWrap = styled.div`
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  > * {
    margin: 5px;
  }
`;
