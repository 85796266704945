import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSpecialTasks } from 'actions/specialTaskAction';
import Localize from 'components/Localize';
import plusIcon from 'styles/images/svg/plus.svg';
import { buildInternshipUrl } from 'utils/uri';
import TaskForm from './TaskForm';
import { AddButton, PlusIcon } from './TaskFormStyled';

const AddTask = ({ internshipId, createSpecialTasks, applicant }) => {
  const [addFormVisible, setAddFormVisibility] = useState(false);
  const handleClose = () => {
    setAddFormVisibility(false);
  };
  const handleSubmit = (values) => {
    if (!internshipId && applicant) {
      return createSpecialTasks({
        ...values,
        internship: null,
        assigned_to: applicant.esuser.api_url,
      }).then(handleClose);
    }
    if (!internshipId && !applicant) {
      return createSpecialTasks({
        ...values,
        internship: null,
      }).then(handleClose);
    }
    return createSpecialTasks({
      ...values,
      internship: buildInternshipUrl(internshipId),
    }).then(handleClose);
  };
  return addFormVisible ? (
    <TaskForm onSubmit={handleSubmit} onCancel={handleClose} />
  ) : (
    <AddButton type="button" onClick={() => setAddFormVisibility(true)}>
      <PlusIcon src={plusIcon} /> <Localize id="add-new-task" />
    </AddButton>
  );
};

AddTask.propTypes = {
  internshipId: PropTypes.number,
  createSpecialTasks: PropTypes.func.isRequired,
  applicant: PropTypes.shape({
    esuser: PropTypes.shape({
      api_url: PropTypes.string.isRequired,
    }),
  }),
};

AddTask.defaultProps = {
  internshipId: null,
  applicant: null,
};

export default connect(
  undefined,
  { createSpecialTasks },
)(AddTask);
