'use strict';

exports.__esModule = true;
exports.JobAdvisorContainer = exports.AdvisorName = exports.ContactBoxContainer = exports.AdvisorMessage = exports.Slide = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  position: relative;\n  background-image: url(', ');\n  height: 420px;\n  background-position: center right;\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-color: ', ';\n'], ['\n  position: relative;\n  background-image: url(', ');\n  height: 420px;\n  background-position: center right;\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-color: ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  color: ', ';\n  background-image: url(', ');\n  background-repeat: no-repeat;\n  padding: 40px 0px;\n  font-size: 28px;\n  font-weight: bold;\n  font-style: italic;\n  max-width: 400px;\n  text-align: center;\n  line-height: 40px;\n  margin-bottom: 60px;\n  position: absolute;\n  top: 50px;\n  left: 150px;\n  @media ', ' {\n    left: 60px;\n  }\n  @media ', ' {\n    font-size: 20px;\n    left: 20px;\n    max-width: 190px;\n    line-height: 24px;\n    text-align: left;\n    background-size: 40%;\n    padding-top: 30px;\n  }\n'], ['\n  color: ', ';\n  background-image: url(', ');\n  background-repeat: no-repeat;\n  padding: 40px 0px;\n  font-size: 28px;\n  font-weight: bold;\n  font-style: italic;\n  max-width: 400px;\n  text-align: center;\n  line-height: 40px;\n  margin-bottom: 60px;\n  position: absolute;\n  top: 50px;\n  left: 150px;\n  @media ', ' {\n    left: 60px;\n  }\n  @media ', ' {\n    font-size: 20px;\n    left: 20px;\n    max-width: 190px;\n    line-height: 24px;\n    text-align: left;\n    background-size: 40%;\n    padding-top: 30px;\n  }\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  position: absolute;\n  bottom: -10%;\n  left: 15%;\n  @media ', ' {\n    left: 20%;\n  }\n  @media ', ' {\n    left: 60px;\n  }\n  @media ', ' {\n    width: 280px;\n    margin: 0 auto;\n    left: 0;\n    right: 0;\n    bottom: -30%;\n  }\n'], ['\n  position: absolute;\n  bottom: -10%;\n  left: 15%;\n  @media ', ' {\n    left: 20%;\n  }\n  @media ', ' {\n    left: 60px;\n  }\n  @media ', ' {\n    width: 280px;\n    margin: 0 auto;\n    left: 0;\n    right: 0;\n    bottom: -30%;\n  }\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  color: ', ';\n  position: absolute;\n  bottom: 10px;\n  right: 10%;\n  font-size: 18px;\n  font-weight: 500;\n  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.62);\n  @media ', ' {\n    right: 20px;\n  }\n  @media ', ' {\n    font-size: 14px;\n  }\n  @media ', ' {\n    font-size: 12px;\n    bottom: 25%;\n  }\n'], ['\n  color: ', ';\n  position: absolute;\n  bottom: 10px;\n  right: 10%;\n  font-size: 18px;\n  font-weight: 500;\n  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.62);\n  @media ', ' {\n    right: 20px;\n  }\n  @media ', ' {\n    font-size: 14px;\n  }\n  @media ', ' {\n    font-size: 12px;\n    bottom: 25%;\n  }\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n  position: relative;\n  padding: 0 40px;\n  max-width: 1280px;\n  width: 100%;\n  margin: 0 auto 100px;\n  @media ', ' {\n    margin-bottom: 150px;\n  }\n'], ['\n  position: relative;\n  padding: 0 40px;\n  max-width: 1280px;\n  width: 100%;\n  margin: 0 auto 100px;\n  @media ', ' {\n    margin-bottom: 150px;\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _device = require('../../styles/device');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var Slide = exports.Slide = _styledComponents2.default.div(_templateObject, function (_ref) {
  var bgImage = _ref.bgImage;
  return bgImage;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.white;
});

var AdvisorMessage = exports.AdvisorMessage = _styledComponents2.default.div(_templateObject2, function (_ref3) {
  var theme = _ref3.theme;
  return theme.secondary;
}, function (_ref4) {
  var bgImage = _ref4.bgImage;
  return bgImage;
}, _device.device.tablet, _device.device.mobile);

var ContactBoxContainer = exports.ContactBoxContainer = _styledComponents2.default.div(_templateObject3, _device.device.laptop, _device.device.tablet, _device.device.mobile);

var AdvisorName = exports.AdvisorName = _styledComponents2.default.div(_templateObject4, function (_ref5) {
  var theme = _ref5.theme;
  return theme.secondary;
}, _device.device.laptop, _device.device.tablet, _device.device.mobile);

var JobAdvisorContainer = exports.JobAdvisorContainer = _styledComponents2.default.div(_templateObject5, _device.device.mobile);