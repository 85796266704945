import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  SecondaryButton, FileUploader, Loader, Error,
} from 'shared';
import Localize from 'components/Localize';
import { connect } from 'react-redux';

import { toBase64 } from 'shared/lib/lib/base64';
import arrowRightIcon from 'styles/images/svg/arrow-right.svg';
import {
  addAttachment,
  addAttachmentImage,
  getAttachments,
  getImages,
  removeAttachment,
  removeAttachmentImage,
} from 'actions/attachmentsAction';

import { MessageItem, MessageButtonText } from './MessageStyled';

const UploadAttachments = ({
  selectedVacancy,
  onSubmit,
  getAttachments,
  getImages,
  addAttachment,
  addAttachmentImage,
  removeAttachment,
  removeAttachmentImage,
  attachments,
  loading,
  error,
  uploadLoading,
}) => {
  const stableGetAttachments = useCallback(getAttachments, []);
  const stableGetImages = useCallback(getImages, []);
  useEffect(() => {
    stableGetAttachments();
    stableGetImages();
  }, [stableGetAttachments, stableGetImages]);
  const handleFileAdd = (files) => files.map((file) => toBase64(file).then(({ base64 }) => {
    const data = base64.split(',').pop();
    if (file.type.includes('image')) {
      addAttachmentImage(data);
    } else {
      addAttachment(data);
    }
  }));
  const handleFileDelete = (fileId) => {
    const file = attachments.find((a) => a.pk === fileId);
    if (file.kind === 'DOCUMENT_PHOTO') {
      removeAttachmentImage(fileId);
    } else {
      removeAttachment(fileId);
    }
  };
  return (
    <div>
      <MessageItem>
        {error && <Error />}
        <Loader loading={loading}>
          <FileUploader
            fileListOnTop
            onFileAdd={handleFileAdd}
            onFileDelete={handleFileDelete}
            files={attachments.map(({ pk, preview_image, image }) => ({
              pk,
              image: preview_image || image,
            }))}
          />
        </Loader>
      </MessageItem>
      {selectedVacancy && attachments.length > 0 && (
        <SecondaryButton
          rightIcon={arrowRightIcon}
          size="small"
          onClick={onSubmit}
          disabled={attachments.length === 0}
          width="100%"
          loading={uploadLoading}
        >
          <MessageButtonText>
            <Localize id="submit" />
          </MessageButtonText>
        </SecondaryButton>
      )}
    </div>
  );
};

UploadAttachments.propTypes = {
  selectedVacancy: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  getAttachments: PropTypes.func.isRequired,
  getImages: PropTypes.func.isRequired,
  addAttachment: PropTypes.func.isRequired,
  addAttachmentImage: PropTypes.func.isRequired,
  removeAttachment: PropTypes.func.isRequired,
  removeAttachmentImage: PropTypes.func.isRequired,
  attachments: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  uploadLoading: PropTypes.bool.isRequired,
};

UploadAttachments.defaultProps = {
  selectedVacancy: null,
  error: undefined,
};

const mapStateToProps = ({ attachments: { data, loading, error } }) => ({
  attachments: data || [],
  loading,
  error,
});

export default connect(
  mapStateToProps,
  {
    getAttachments,
    getImages,
    addAttachment,
    removeAttachment,
    removeAttachmentImage,
    addAttachmentImage,
  },
)(UploadAttachments);
