import styled from 'styled-components';
import Select from 'components/Form/Select';
import { device } from 'styles/device';

export const InternshipSelect = styled(Select)`
  min-width: 167px;
  & .select__menu-list {
    max-height: 100%;
  }
  @media ${device.mobile} {
    width: 100%;
  }
`;
