import styled from 'styled-components';
import { device } from 'styles/device';

export const AnswerCheckboxContainer = styled.div`
  position: relative;
  opacity: ${({ disabled, isComparingMode }) => disabled && !isComparingMode ? '0.5' : '1'};
  input[type='checkbox'] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip: rect(0 0 0 0);
    overflow: hidden;
  }
  input[type='checkbox'] + .styled-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    width: 26px;
    height: 26px;
    background-color: ${({ theme }) => theme.grayE6};
    .checkbox-icon {
      display: none;
      color: ${({ theme }) => theme.primary};
      position: absolute;
      font-size: 22px;
      top: 2px;
      bottom: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
    }
    @media ${device.tablet} {
      width: 22px;
      height: 22px;
      .checkbox-icon {
        font-size: 18px;
      }
    }
  }
  input[type='checkbox']:checked + .styled-checkbox {
    display: block;
    .checkbox-icon {
      display: block;
    }
  }
  input[type='checkbox']:checked + .styled-checkbox.overview {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 2px;
    ${({ single }) => single && 'justify-content: center;'};
    .checkbox-icon {
      font-size: 11px;
      position: initial;
      margin: initial;
    }
    .checkbox-icon.teacher {
      color: ${({ theme }) => theme.secondary};
    }
    .checkbox-icon.company {
      color: #f3a6ac;
    }
    .checkbox-icon.provider {
      color: #5da3d9;
    }
  }
`;
