import React, { useState, useContext, useEffect } from 'react';
import { ReactReduxContext } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Prompt } from 'shared';
import Localize from 'components/Localize';
import { INVITEES_STATUSES } from 'api/CalendarApi';
import { EVENT_TYPES, EVENT_VISIBILITY } from 'utils/calendarHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit, faSync } from '@fortawesome/free-solid-svg-icons';
import { roles } from 'utils/access-rules/constants';
import {
  EventMoreInfoWindow,
  TaskMoreInfoWindow,
} from '../../../EventMoreInfoWindow';
import {
  EventTypeSectionEventItem,
  EventTypeSectionEventItemRight,
  EventTypeSectionEventItemGeneralInf,
  EventTypeSectionEventItemSubInf,
  ButtonControllersWrapper,
  EditButton,
  DeleteButton,
  FullDate,
} from '../EventTypeSectionStyled';
import { Loader, NoRespondedIcon } from './EventTypeItemStyled';

const isEventNotResponded = (event, currentUser, invitees) => {
  if (
    event.author.pk === currentUser.pk
    || (currentUser.role !== roles.Applicant
      && event.visibility !== EVENT_VISIBILITY.ATTENDEES)
    || event.kind === EVENT_TYPES.ABSENCE
  ) {
    return false;
  }
  const invitee = invitees.find((u) => u.esuser.pk === currentUser.pk);
  return invitee && invitee.status === null;
};

const getEventAcceptStatus = (event, invitees) => {
  if (event.visibility === EVENT_VISIBILITY.ATTENDEES) {
    if (invitees.some((u) => u.status === null)) {
      return '';
    }
    if (invitees.some((u) => u.status === INVITEES_STATUSES.accepted)) {
      return INVITEES_STATUSES.accepted;
    }
    return INVITEES_STATUSES.rejected;
  }

  const applicant = invitees.find((u) => u.esuser.role === roles.Applicant);
  if (applicant) {
    return applicant.status;
  }
  return '';
};

export function EventTypeItemMeet({
  event,
  onEventEdit,
  onEventDelete,
  markNotificationAsRead,
  notifications,
  deleteNotification,
  onGetEvents,
}) {
  const [invitees, setInvitees] = useState(event.invitees);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNotification, setHasNotification] = useState(false);
  const [isShowMoreInfo, setIsShowMoreInfo] = useState(false);
  const { store } = useContext(ReactReduxContext);
  const currentUser = store.getState().currentUser.data;
  const onUpdateInvitee = (inviteePk, newStatus) => {
    const updatedInvitees = invitees.map((invitee) => {
      if (invitee.pk === inviteePk) {
        return {
          ...invitee,
          status: newStatus,
        };
      }
      return invitee;
    });
    setInvitees(updatedInvitees);
  };
  useEffect(() => {
    if (notifications) {
      const hasNotification = notifications.find(
        (last) => last.instance_id === event.pk,
      );
      setHasNotification(hasNotification);
    }
  }, [notifications, event.pk]);

  function deleteEvent(e) {
    setIsLoading(true);
    return onEventDelete(e, event)
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }
  const handleCloseMoreInfo = (e) => {
    e.stopPropagation();
    setIsShowMoreInfo(false);
  };

  const handleShowMoreInfo = (id) => () => {
    const hasNotification = notifications.find((last) => last.instance_id === id);
    setIsShowMoreInfo(true);
    if (hasNotification) {
      markNotificationAsRead(hasNotification.pk).then(() => {
        deleteNotification(hasNotification.pk);
      });
    }
  };
  const eventAssignedToCurrentUser = event.author.user.pk
    === (currentUser.user ? currentUser.user.pk : currentUser.pk);
  const assignedApplicant = event.assigned_to.find(
    (a) => a.role === roles.Applicant,
  );
  const eventStartDate = moment(event.start)
    .utc()
    .format('DD.MM.YYYY');
  const eventEndDate = moment(event.end)
    .utc()
    .format('DD.MM.YYYY');
  const renderAbsenceDate = (start, end) => {
    if (start === end) {
      return <FullDate>{start}</FullDate>;
    }
    return (
      <FullDate>
        {start} - {end}
      </FullDate>
    );
  };
  return (
    <EventTypeSectionEventItem onClick={handleShowMoreInfo(event.pk)}>
      {isShowMoreInfo && (
        <EventMoreInfoWindow
          event={event}
          handleCloseMoreInfo={handleCloseMoreInfo}
          onGetEvents={onGetEvents}
          invitees={invitees}
          onUpdateInvitee={onUpdateInvitee}
        />
      )}

      <EventTypeSectionEventItemGeneralInf
        status={hasNotification}
        notResponded={isEventNotResponded(event, currentUser, invitees)}
        acceptStatus={getEventAcceptStatus(event, invitees)}
      >
        {isEventNotResponded(event, currentUser, invitees) && (
          <NoRespondedIcon icon={faSync} />
        )}
        {event.kind === EVENT_TYPES.ABSENCE ? (
          renderAbsenceDate(eventStartDate, eventEndDate)
        ) : (
          <>
            <FullDate>{eventStartDate}</FullDate>
            {moment(event.start)
              .utc()
              .format('HH:mm')}{' '}
            -{' '}
            {moment(event.end)
              .utc()
              .format('HH:mm')}{' '}
            <Localize id="hr" />
          </>
        )}
      </EventTypeSectionEventItemGeneralInf>
      <EventTypeSectionEventItemRight>
        <EventTypeSectionEventItemSubInf>
          {event.title}
          {event.kind === EVENT_TYPES.ONSITE_APPOINTMENT && assignedApplicant && (
            <>
              &nbsp;
              <Localize id="with" /> {assignedApplicant.user.first_name}{' '}
              {assignedApplicant.user.last_name}
            </>
          )}
        </EventTypeSectionEventItemSubInf>
        {eventAssignedToCurrentUser && (
          <ButtonControllersWrapper>
            <Loader loading={isLoading}>
              {onEventEdit && (
                <EditButton onClick={(e) => onEventEdit(e, event)}>
                  <FontAwesomeIcon icon={faEdit} />
                </EditButton>
              )}
              {onEventDelete && event.kind !== EVENT_TYPES.ONSITE_APPOINTMENT && (
                <Prompt
                  confirm={deleteEvent}
                  trigger={(
                    <DeleteButton>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </DeleteButton>
)}
                >
                  <Localize
                    id="are-you-sure-want-delete-name-entry"
                    data={{ name: event.title }}
                  />
                </Prompt>
              )}
            </Loader>
          </ButtonControllersWrapper>
        )}
      </EventTypeSectionEventItemRight>
    </EventTypeSectionEventItem>
  );
}

EventTypeItemMeet.propTypes = {
  event: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  onEventEdit: PropTypes.func,
  onEventDelete: PropTypes.func,
  onGetEvents: PropTypes.func,
  markNotificationAsRead: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  deleteNotification: PropTypes.func.isRequired,
};

EventTypeItemMeet.defaultProps = {
  onEventEdit: () => {},
  onEventDelete: async () => {},
  onGetEvents: () => {},
};

export function EventTypeItemTask({
  event,
  onEventEdit,
  onEventDelete,
  markNotificationAsRead,
  notifications,
  deleteNotification,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasNotification, setHasNotification] = useState(false);
  const [isShowMoreInfo, setIsShowMoreInfo] = useState(false);
  const { store } = useContext(ReactReduxContext);
  const { user: currentUser } = store.getState().currentUser.data;

  useEffect(() => {
    if (notifications) {
      const hasNotification = notifications.find(
        (last) => last.instance_id === event.pk,
      );
      setHasNotification(hasNotification);
    }
  }, [notifications, event.pk]);

  function deleteEvent(e) {
    setIsLoading(true);
    return onEventDelete(e, event)
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }
  const handleCloseMoreInfo = (e) => {
    e.stopPropagation();
    setIsShowMoreInfo(false);
  };

  const handleShowMoreInfo = (id) => () => {
    const hasNotification = notifications.find((last) => last.instance_id === id);
    setIsShowMoreInfo(true);
    if (hasNotification) {
      markNotificationAsRead(hasNotification.pk).then(() => {
        deleteNotification(hasNotification.pk);
      });
    }
  };
  const eventAssignedToCurrentUser = event.author.user.pk
    === (currentUser.user ? currentUser.user.pk : currentUser.pk);
  return (
    <EventTypeSectionEventItem onClick={handleShowMoreInfo(event.pk)}>
      {isShowMoreInfo && (
        <TaskMoreInfoWindow
          event={event}
          handleCloseMoreInfo={handleCloseMoreInfo}
        />
      )}

      <EventTypeSectionEventItemGeneralInf status={hasNotification}>
        <FullDate>{moment(event.deadline).format('DD.MM.YYYY')}</FullDate>
      </EventTypeSectionEventItemGeneralInf>
      <EventTypeSectionEventItemRight>
        <EventTypeSectionEventItemSubInf>
          {event.description}
        </EventTypeSectionEventItemSubInf>
        {eventAssignedToCurrentUser && (
          <ButtonControllersWrapper>
            <Loader loading={isLoading}>
              {onEventEdit && (
                <EditButton onClick={(e) => onEventEdit(e, event)}>
                  <FontAwesomeIcon icon={faEdit} />
                </EditButton>
              )}
              {onEventDelete && (
                <Prompt
                  confirm={deleteEvent}
                  trigger={(
                    <DeleteButton>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </DeleteButton>
)}
                >
                  <Localize
                    id="are-you-sure-want-delete-name-entry"
                    data={{ name: event.description }}
                  />
                </Prompt>
              )}
            </Loader>
          </ButtonControllersWrapper>
        )}
      </EventTypeSectionEventItemRight>
    </EventTypeSectionEventItem>
  );
}

EventTypeItemTask.propTypes = {
  event: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  onEventEdit: PropTypes.func,
  onEventDelete: PropTypes.func,
  markNotificationAsRead: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  deleteNotification: PropTypes.func.isRequired,
};

EventTypeItemTask.defaultProps = {
  onEventEdit: () => {},
  onEventDelete: async () => {},
};
