import styled from 'styled-components';
import { device } from 'styles/device';
import { ResizableTextarea } from 'components/Form/Input';

export const Table = styled.table`
  width: 100%;
`;

export const TR = styled.tr`
  width: 100%;
  .clear-button {
    display: block;
    height: 25px;
    font-size: 12px;
    width: 60px;
    text-align: center;
    padding: 0px;
    margin: auto;
  }
  @media ${device.mobile} {
    .clear-button {
      display: block;
      height: 20px;
      margin-top: 15px;
      font-size: 10px;
    }
  }
  // &:hover {
  //   .clear-button {
  //     display: block;
  //   }
  // }
`;

export const TD = styled.td`
  position: relative;
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  width: ${({ width }) => `${width}px`};
  @media ${device.mobile} {
    height: ${({ height }) => (height ? `${height - 10}px` : 'auto')};
    width: ${({ width }) => `${width / 2}px`};
  }
`;

export const TH = styled.td`
  text-align: left;
  width: ${({ width }) => `${width}px`};
  @media ${device.mobile} {
    font-size: 12px;
    width: ${({ width }) => `${width / 2}px`};
  }
`;

export const Input = styled.input`
  width: 90%;
  height: 100%;
  line-height: 24px;
  border: none;
  border-bottom: 1px solid
    ${({ theme, error }) => (error ? theme.errorColor : theme.borderColor)};
  @media ${device.mobile} {
    font-size: 12px;
  }
  :disabled {
    background: none;
  }
`;

export const ResizableInput = styled(ResizableTextarea)`
  margin-bottom: -5px;
  width: 90%;
  height: auto;
  box-sizing: border-box;
  border: none;
  resize: none;
  line-height: 24px;
  overflow: auto;
  border-bottom: 1px solid
    ${({ theme, error }) => (error ? theme.errorColor : theme.borderColor)};
  @media ${device.mobile} {
    font-size: 12px;
  }
  :disabled {
    background: none;
  }
`;

export const Error = styled.div`
  text-align: right;
  padding-top: 30px;
  @media ${device.mobile} {
    font-size: 12px;
    padding-top: 10px;
  }
  color: ${({ theme }) => theme.errorColor};
`;
