import styled from 'styled-components';
import Container from 'components/Container';

export const HeaderWrapper = styled.header`
  background-color: ${({ theme }) => theme.white};
  height: 84px;
  min-height: 84px;
  overflow: visible;
  z-index: ${({ isDropdownOpen }) => (isDropdownOpen ? 10 : 1)};
`;

export const HeaderContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding: 18px 12px;
`;

export const UserPanel = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const HeaderLogo = styled.img`
  height: 38px;
`;
