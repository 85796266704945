import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';

/**
 * Temporary solution till we will have possibility to get all attachments by single request
 */
function getUnique(arr, comp) {
  const unique = arr
    .map((e) => e[comp])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter((e) => arr[e])
    .map((e) => arr[e]);
  return unique;
}

const dataReducer = (state = null, action) => {
  switch (action.type) {
    case successType(types.GET_ATTACHMENTS):
      if (state) {
        return getUnique([...state, ...action.response], 'pk');
      }
      return [...action.response];
    case successType(types.ADD_ATTACHMENT):
      return [...state, action.response];
    case successType(types.REMOVE_ATTACHMENT):
      return state.filter(({ pk }) => pk !== action.response);
    case types.LOGOUT:
      return null;
    default:
      return state;
  }
};

export default createReducer(
  [types.GET_ATTACHMENTS, types.REMOVE_ATTACHMENT, types.ADD_ATTACHMENT],
  dataReducer,
);
