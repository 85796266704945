'use strict';

exports.__esModule = true;
exports.default = undefined;

var _BackButton = require('./BackButton');

var _BackButton2 = _interopRequireDefault(_BackButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _BackButton2.default;
module.exports = exports['default'];