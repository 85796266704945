import styled from 'styled-components';
import { device } from 'styles/device';

export const ReportsContainer = styled.div``;

export const ReportsListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ReportsActions = styled.div`
  display: flex;
  justify-content: flex-end;
  .btn-upload {
    margin-right: 20px;
  }
  @media ${device.mobile} {
    flex-wrap: wrap;
    .btn-upload {
      width: 100%;
      margin: 10px 0px 20px;
    }
    .btn-submit {
      width: 100%;
    }
  }
`;
