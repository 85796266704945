import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { device } from 'styles/device';

export const InviteeWrapper = styled.li`
  .role {
    margin: 0px 20px;
    font-style: italic;
  }
  @media ${device.mobile} {
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.16);
    margin-bottom: 10px;
    padding: 8px;
  }
`;

export const AcceptedIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.green};
`;

export const RejectedIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.primary};
`;

export const NoRespondedIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.warningColor};
`;
