import ServerApi from 'shared/lib/api/ServerApi';

class InternshipCommentApi extends ServerApi {
  getComment(internshipId) {
    return super.get(`/internship/${internshipId}/assessment/comment/`);
  }

  changeComment(internshipId, data) {
    return super.patch(`/internship/${internshipId}/assessment/comment/`, data);
  }
}

export default new InternshipCommentApi();
