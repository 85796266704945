import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Page from 'components/Page';
import ScheduleCalendar from 'pages/ScheduleCalendar';
import Localize from 'components/Localize';
import InformationModal from 'components/InformationModal';
import { ReactComponent as InfoIcon } from 'styles/images/svg/info.svg';

const Calendar = ({ selectedInternshipId }) => {
  const [informationModalOpen, setInformationModalOpen] = useState('');

  return (
    <>
      <InformationModal
        open={informationModalOpen}
        onCloseModal={() => setInformationModalOpen('')}
      />
      <Page
        title={<Localize id="your-calendar" />}
        backTo={`/applicant/${selectedInternshipId || ''}`}
        description={
          <Localize id="here-you-have-overview-all-appointments-and-missin" />
        }
        icon={<InfoIcon />}
        onClick={() => setInformationModalOpen('CALENDAR')}
      >
        <ScheduleCalendar
          shouldFetchEvents
          key={selectedInternshipId}
          internshipId={selectedInternshipId || null}
        />
      </Page>
    </>
  );
};

Calendar.propTypes = {
  selectedInternshipId: PropTypes.number,
};

Calendar.defaultProps = {
  selectedInternshipId: null,
};

const mapStateToProps = ({ selectedInternship }) => ({
  selectedInternshipId: selectedInternship && selectedInternship.pk,
});

export default connect(mapStateToProps)(Calendar);
