import styled from 'styled-components';

export const NotificationCircleBadge = styled.div`
  position: absolute;
  top: 14%;
  right: 1px;
  width: 10px;
  height: 10px;
  background-color: #e95160;
  border-radius: ${({ theme }) => theme.borderRadiusRound};
`;
