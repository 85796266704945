'use strict';

exports.__esModule = true;
exports.format = exports.toUtc = undefined;

var _formatISO = require('date-fns/formatISO');

var _formatISO2 = _interopRequireDefault(_formatISO);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Convert datetime to UTC time zone
 * @param {Date} date
 * @returns {Date}
 */
var toUtc = exports.toUtc = function toUtc(date) {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
};

/**
 * Format datetime to backend format '%Y-%m-%dT%H:%M:%SZ' using utc timezone
 * @param {Date} date
 * @returns {string}
 */
var format = exports.format = function format(date) {
  return (0, _formatISO2.default)(toUtc(date)) + 'Z';
};