import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Error, Loader } from 'shared';
import Localize from 'components/Localize';
import PropTypes from 'prop-types';
import {
  getEntityInstruction,
  setEntityInstructionRating,
} from 'actions/entityInstructionActions';
import Video from './Video';
import {
  InfoModal,
  InformationTitle,
  InformationVideoColumn,
  InformationRow,
  InformationContent,
  InformationFeedback,
  RatingEmojis,
} from './InformationModalStyled';

const InformationModal = ({
  open,
  onCloseModal,
  entityInstruction,
  loading,
  error,
  getEntityInstruction,
  setEntityInstructionRating,
}) => {
  useEffect(() => {
    if (open) {
      getEntityInstruction(open);
    }
  }, [open, getEntityInstruction]);

  const handleRating = (rating) => {
    setEntityInstructionRating({
      identifier: open,
      value: rating,
    });
  };
  return (
    <InfoModal
      className="information-modal"
      open={!!open}
      onCloseModal={onCloseModal}
    >
      {() => (
        <Loader loading={loading}>
          {error ? (
            <Error />
          ) : (
            <>
              <InformationTitle>{entityInstruction.title}</InformationTitle>
              <InformationRow>
                <InformationVideoColumn>
                  <Video src={entityInstruction.video} />
                </InformationVideoColumn>
                <InformationContent
                  dangerouslySetInnerHTML={{
                    __html: entityInstruction.content,
                  }}
                />
              </InformationRow>
              <InformationFeedback>
                <Localize id="was-information-helpful" />
                <RatingEmojis>
                  {[...Array(5)].map((_, i) => (
                    <div
                      className={`emoji${i + 1} ${entityInstruction.rating
                        === i + 1 && 'selected'}`}
                      onClick={() => handleRating(i + 1)}
                    />
                  ))}
                </RatingEmojis>
              </InformationFeedback>
            </>
          )}
        </Loader>
      )}
    </InfoModal>
  );
};

InformationModal.propTypes = {
  open: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
  entityInstruction: PropTypes.shape({
    identifier: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    video: PropTypes.string,
    rating: PropTypes.string,
  }).isRequired,
  getEntityInstruction: PropTypes.func.isRequired,
  setEntityInstructionRating: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

InformationModal.defaultProps = {
  open: '',
  error: undefined,
};

const mapStateToProps = ({ entityInstruction: { data, loading, error } }) => ({
  entityInstruction: data,
  loading,
  error,
});

export default connect(mapStateToProps, {
  getEntityInstruction,
  setEntityInstructionRating,
})(InformationModal);
