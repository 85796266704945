'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _formatDistanceToNow = require('date-fns/formatDistanceToNow');

var _formatDistanceToNow2 = _interopRequireDefault(_formatDistanceToNow);

var _Localize = require('../Localize');

var _Localize2 = _interopRequireDefault(_Localize);

var _languages = require('../../lib/languages');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Duration = function Duration(_ref) {
  var start = _ref.start,
      plusAgo = _ref.plusAgo;
  return _react2.default.createElement(
    _Localize2.default,
    null,
    function (_ref2) {
      var activeLanguage = _ref2.activeLanguage;
      return (0, _formatDistanceToNow2.default)(start, {
        addSuffix: plusAgo,
        locale: _languages.locales[activeLanguage.code]
      });
    }
  );
};

Duration.propTypes = process.env.NODE_ENV !== "production" ? {
  start: _propTypes2.default.instanceOf(Date).isRequired,
  plusAgo: _propTypes2.default.bool
} : {};

Duration.defaultProps = {
  plusAgo: true
};

exports.default = Duration;
module.exports = exports['default'];