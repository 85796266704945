'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _TextInputStyled = require('./TextInputStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var TextInput = _react2.default.forwardRef(function (_ref, ref) {
  var className = _ref.className,
      placeholderClassName = _ref.placeholderClassName,
      placeholder = _ref.placeholder,
      value = _ref.value,
      invalid = _ref.invalid,
      props = _objectWithoutProperties(_ref, ['className', 'placeholderClassName', 'placeholder', 'value', 'invalid']);

  return _react2.default.createElement(
    _TextInputStyled.TextInputWrap,
    null,
    _react2.default.createElement(_TextInputStyled.TextInputControl, _extends({
      type: 'text',
      className: className,
      invalid: invalid,
      ref: ref,
      value: value || ''
    }, props)),
    _react2.default.createElement(
      _TextInputStyled.TextInputPlaceholder,
      { className: placeholderClassName, invalid: invalid, hasValue: !!value, tabIndex: '-1' },
      placeholder
    )
  );
});

TextInput.propTypes = {
  className: _propTypes2.default.string,
  placeholderClassName: _propTypes2.default.string,
  placeholder: _propTypes2.default.string,
  value: _propTypes2.default.string,
  invalid: _propTypes2.default.bool
};

TextInput.defaultProps = {
  className: '',
  placeholderClassName: '',
  placeholder: '',
  value: null,
  invalid: false
};

exports.default = TextInput;
module.exports = exports['default'];