import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EVENT_TYPES, destructEvents } from 'utils/calendarHelpers';
import Localize from 'components/Localize';
import plusIcon from 'styles/images/svg/plus.svg';
import { getAddOnsiteVisitText } from 'pages/ApplicantProfile/applicantProfileUtils';
import {
  getOnSideAppointments,
  deleteEvent,
  editEvent,
  addEvent,
} from 'actions/calendarActions';
import {
  VisitNotesWrapper,
  AddButton,
  PlusIcon,
  AddVisitWrap,
  ShowHideButton,
} from './VisitNotesStyled';
import VisitNote from './VisitNote';
import VisitNotePreview from './VisitNotePreview';

const VisitNotes = ({
  firstName,
  eventObj,
  internship,
  deleteEvent,
  currentUser,
  editEvent,
  addEvent,
}) => {
  const [editableItemPk, setEditableItemPk] = useState(null);
  const [isAddingVisible, setIsAddingVisible] = useState(false);
  const [allVisitsShown, setAllVisitsShown] = useState(false);
  const onShowHideAllVisits = () => setAllVisitsShown(!allVisitsShown);
  const items = useMemo(() => {
    if (eventObj) {
      return destructEvents(eventObj);
    }
    return [];
  }, [eventObj]);
  const onEditableClose = () => setEditableItemPk(null);
  const onAddingClose = () => setIsAddingVisible(false);
  return (
    <VisitNotesWrapper>
      <div className={`show-hide-box ${allVisitsShown && 'expanded'}`}>
        {items.map((event, index) => event.pk === editableItemPk ? (
          <VisitNote
            event={event}
            deleteEvent={deleteEvent}
            editEvent={editEvent}
            internship={internship}
            onEditableClose={onEditableClose}
          />
        ) : (
          (allVisitsShown || index <= 2) && (
          <VisitNotePreview
            key={event.pk}
            firstName={firstName}
            event={event}
            deleteEvent={deleteEvent}
            onItemEdit={
                  event.author.user.pk === currentUser.user.pk
                    ? (event) => setEditableItemPk(event.pk)
                    : undefined
                }
          />
          )
        ))}
      </div>
      <AddVisitWrap>
        {isAddingVisible ? (
          <VisitNote
            onAddNote={addEvent}
            internship={internship}
            onAddingClose={onAddingClose}
          />
        ) : (
          <AddButton type="button" onClick={() => setIsAddingVisible(true)}>
            <PlusIcon src={plusIcon} />{' '}
            <Localize id={getAddOnsiteVisitText(currentUser.role)} />
          </AddButton>
        )}
        {items.length > 3 && (
          <ShowHideButton onClick={onShowHideAllVisits}>
            <div className="text-container">
              {allVisitsShown ? (
                <Localize id="show-less" />
              ) : (
                <Localize id="show-all-onsite-visits" />
              )}
            </div>
          </ShowHideButton>
        )}
      </AddVisitWrap>
    </VisitNotesWrapper>
  );
};

VisitNotes.propTypes = {
  firstName: PropTypes.string.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  editEvent: PropTypes.func.isRequired,
  addEvent: PropTypes.func.isRequired,
  internship: PropTypes.shape({
    pk: PropTypes.number.isRequired,
  }).isRequired,
  eventObj: PropTypes.object,
  currentUser: PropTypes.shape({
    role: PropTypes.string.isRequired,
    user: PropTypes.shape({
      pk: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

VisitNotes.defaultProps = {
  eventObj: null,
};

const mapStateToProps = (
  { calendar: { data }, currentUser },
  { internship },
) => ({
  eventObj:
    data.byInternship[internship.pk]
    && data.byInternship[internship.pk][EVENT_TYPES.ONSITE_APPOINTMENT],
  currentUser: currentUser && currentUser.data,
  editEvent: PropTypes.func.isRequired,
});

export default connect(
  mapStateToProps,
  {
    getOnSideAppointments,
    deleteEvent,
    editEvent,
    addEvent,
  },
)(VisitNotes);
