'use strict';

exports.__esModule = true;
exports.default = undefined;

var _SendIcon = require('./SendIcon');

var _SendIcon2 = _interopRequireDefault(_SendIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _SendIcon2.default;
module.exports = exports['default'];