import styled from 'styled-components';
import AutosizeTextarea from 'react-autosize-textarea';

export const TextAreaWrapper = styled.div`
  flex-grow: 1;
  border-radius: ${({ theme }) => theme.borderRadiusDefault};
  border: 1px solid
    ${({ isValidMessage, theme }) => isValidMessage ? theme.primary : theme.grayF3};
  padding: 12px 40px 12px 18px;
  display: flex;
  position: relative;
`;

export const TextArea = styled(AutosizeTextarea)`
  width: 100%;
  font-style: italic;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  color: ${({ theme }) => theme.textColor69};
  border: none;
  resize: none;

  &::-webkit-scrollbar {
    opacity: 0;
  }
`;
export const TextAreaLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 8px;
  font-size: 28px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  right: 8px;
  color: ${({ theme }) => theme.primary};
  .tippy-tooltip-content {
    color: ${({ theme }) => theme.textColor69};
  }
`;
