'use strict';

exports.__esModule = true;
exports.default = undefined;

var _Spin = require('./Spin');

var _Spin2 = _interopRequireDefault(_Spin);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Spin2.default;
module.exports = exports['default'];