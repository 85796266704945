import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  addMessage,
  addChatRoomMessage,
  markMessageAsRead,
} from 'actions/chatActions';
import { markNotificationAsRead } from 'actions/notificationsActions';
import { Form, SendIcon } from 'shared';
import { Field } from 'formik';
import { roles } from 'utils/access-rules/constants';
import { filterByInternship } from 'reducers/reducersUtils';
import {
  ChatMessagesPanelWrapper,
  ControlPanel,
  SendButton,
} from './ChatMessagesPanelStyled';
import Messages from './Messages';
import TextArea from './TextArea';

function ChatMessagesPanel({
  internshipId,
  messages: _messages,
  activeTab: { activeUser, room },
  addMessage,
  addChatRoomMessage,
  markNotificationAsRead,
  markMessageAsRead,
  notifications,
  currentUser,
}) {
  const [messages, setMessages] = useState([..._messages]);
  const stableMarkNotificationAsRead = useCallback(markNotificationAsRead, [
    messages,
    currentUser.pk,
    notifications,
  ]);
  const stableMarkMessageAsRead = useCallback(markMessageAsRead, [
    messages,
    currentUser.pk,
    notifications,
  ]);
  useEffect(() => {
    if (!notifications || !notifications.length || !messages.length) return;
    const promises = [];
    messages.forEach((message) => {
      const notification = notifications.find(
        (n) => n.instance_id === message.pk,
      );
      if (notification && !notification.read_at) {
        promises.push(stableMarkNotificationAsRead(notification.pk));
      }
      if (message.sender.pk !== currentUser.pk && !message.read_at) {
        promises.push(stableMarkMessageAsRead(message.pk));
      }
    });
    Promise.all(promises);
  }, [
    stableMarkNotificationAsRead,
    stableMarkMessageAsRead,
    messages,
    currentUser.pk,
    notifications,
  ]);

  useEffect(() => {
    setMessages([..._messages]);
  }, [_messages]);

  const handleSubmit = ({ message }, formik) => {
    let messageToAdd = { internship_id: internshipId, message };
    const sender = {
      avatar: currentUser.avatar,
      pk: currentUser.pk,
      role: currentUser.role,
      user: {
        first_name: currentUser.user.first_name,
      },
    };
    if (room) {
      messageToAdd = { ...messageToAdd, room: room.api_url };
      setMessages([
        ...messages,
        {
          ...messageToAdd,
          isLoading: true,
          sender,
        },
      ]);
      addChatRoomMessage(internshipId, messageToAdd);
      formik.resetForm();
    } else {
      messageToAdd = { ...messageToAdd, recepient: activeUser.api_url };
      setMessages([
        ...messages,
        {
          ...messageToAdd,
          isLoading: true,
          sender,
        },
      ]);
      addMessage(internshipId, messageToAdd);
      formik.resetForm();
    }
  };

  const onKeyDown = (event, form) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit(form.values, form);
    }
  };
  return (
    <ChatMessagesPanelWrapper>
      {notifications && (
        <Messages messages={messages} notifications={notifications} />
      )}
      <Form
        initialValues={{
          message: '',
        }}
        validateSchema={{
          message: { required: true },
        }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(form) => (
          <ControlPanel>
            <Field
              name="message"
              onKeyDown={(e) => onKeyDown(e, form)}
              userName={
                activeUser
                && `${activeUser.user.first_name} ${activeUser.user.last_name}`
              }
              isGroupChat={!!room}
              loading={form.isSubmitting}
              error={!!form.errors._error}
              invalid={!!(form.touched.message && form.errors.message)}
              component={TextArea}
            />
            <SendButton type="submit">
              <SendIcon />
            </SendButton>
          </ControlPanel>
        )}
      </Form>
    </ChatMessagesPanelWrapper>
  );
}

ChatMessagesPanel.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      pk: PropTypes.number.isRequired,
    }),
  ),
  internshipId: PropTypes.number,
  activeTab: PropTypes.shape({
    activeUser: PropTypes.shape({
      api_url: PropTypes.string.isRequired,
      user: PropTypes.shape({
        api_url: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
      }),
    }),
    room: PropTypes.shape({
      api_url: PropTypes.string,
    }),
  }).isRequired,
  currentUser: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    avatar: PropTypes.string,
    role: PropTypes.string,
    user: PropTypes.shape({
      first_name: PropTypes.string,
    }),
  }).isRequired,
  addMessage: PropTypes.func.isRequired,
  addChatRoomMessage: PropTypes.func.isRequired,
  markMessageAsRead: PropTypes.func.isRequired,
  markNotificationAsRead: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      instance_id: PropTypes.number.isRequired,
      read_at: PropTypes.string,
    }),
  ),
};

ChatMessagesPanel.defaultProps = {
  internshipId: null,
  messages: [],
  notifications: null,
};

const mapStateToProps = ({
  notifications: { data },
  selectedInternship,
  currentUser,
}) => {
  const state = { currentUser: currentUser && currentUser.data };
  if (currentUser.role === roles.Applicant) {
    return {
      ...state,
      notifications: filterByInternship(
        selectedInternship && selectedInternship.pk,
        data,
      ),
    };
  }
  return {
    ...state,
    notifications: data,
  };
};

export default connect(mapStateToProps, {
  addMessage,
  addChatRoomMessage,
  markNotificationAsRead,
  markMessageAsRead,
})(ChatMessagesPanel);
