import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { toBase64 } from 'shared/lib/lib/base64';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Localize from 'components/Localize';
import {
  AvatarWrapper,
  AvatarLetters,
  Avatar,
  TooltipStyled,
} from './UserAvatarUploaderStyled';

const UserAvatarUploader = ({
  userFirstName,
  userLastName,
  isEditable,
  avatar,
  meta,
  onAvatarUpload,
}) => {
  const fileInputRef = useRef();
  const onOpenUploadWindow = () => {
    if (!isEditable) return;
    fileInputRef.current.click();
  };
  const handleAvatarUpload = (e) => {
    const value = e.target.files[0];
    toBase64(value).then(({ base64 }) => {
      if (onAvatarUpload) {
        onAvatarUpload(base64);
        return;
      }
      meta.setFieldValue('avatar', base64);
    });
  };
  return (
    <AvatarWrapper>
      <Localize>
        {({ translate }) => (
          <TooltipStyled
            title={translate('click-avatar-change-it')}
            theme="light"
            arrow
            open={isEditable}
            position="top-center"
          >
            <Avatar className="avatar-wrap" onClick={onOpenUploadWindow}>
              {avatar ? (
                <img src={avatar} alt="user avatar" />
              ) : (
                <>
                  <AvatarLetters>
                    {' '}
                    {userFirstName && userFirstName[0]}
                    {userLastName && userLastName[0]}
                  </AvatarLetters>
                  {!userFirstName && !userLastName && (
                    <FontAwesomeIcon
                      className="avatar"
                      size="sm"
                      icon={faUser}
                    />
                  )}
                </>
              )}
            </Avatar>
          </TooltipStyled>
        )}
      </Localize>
      <input
        className="avatar-input"
        name="avatar"
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleAvatarUpload}
      />
    </AvatarWrapper>
  );
};

UserAvatarUploader.propTypes = {
  isEditable: PropTypes.bool,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  onAvatarUpload: PropTypes.func,
  avatar: PropTypes.string,
  meta: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }),
};

UserAvatarUploader.defaultProps = {
  isEditable: false,
  userFirstName: null,
  userLastName: null,
  avatar: null,
  meta: null,
  onAvatarUpload: null,
};

export default UserAvatarUploader;
