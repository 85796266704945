import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faCheck,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import { Error, Form } from 'shared';
import DateTime from 'components/Form/DateTime';
import moment from 'moment';
import {
  VisitNoteWrapper,
  VisitNoteTitle,
  VisitNoteBoxLeft,
  VisitNoteButtons,
  VisitNoteSaveButton,
  VisitNoteSaveButtonContent,
  VisitNoteDeleteButton,
  VisitNoteIconWrapper,
  VisitNoteDescription,
  VisitNoteCloseButton,
} from './VisitNoteStyled';
import VisitNoteFileUploader from './VisitNoteFileUploader';

const VisitNote = ({
  firstName,
  event,
  onAddNote,
  internship,
  deleteEvent,
  editEvent,
  onEditableClose,
  onAddingClose,
  submitManageStudents,
  closeManageStudents,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data) => {
    const eventData = {
      ...data,
      visibility: 'EVERYONE',
      kind: 'ONSITE_APPOINTMENT',
      internship: internship ? internship.api_url : null,
    };
    if (event) {
      return editEvent(event.pk, eventData).then(() => onEditableClose());
    }
    return onAddNote(eventData).then(() => onAddingClose());
  };
  const onCancel = () => {
    if (event) {
      return onEditableClose();
    }
    return onAddingClose();
  };
  const onDeleteVisitNote = () => deleteEvent(event);
  const startDay = moment()
    .utc()
    .startOf('day');
  const endDay = moment()
    .utc()
    .startOf('day');
  return (
    <Form
      initialValues={{
        description: event ? event.description : '',
        start: event
          ? moment(event.start).utc()
          : startDay.set('hour', 8).set('minute', 0),
        end: event
          ? moment(event.end).utc()
          : endDay.set('hour', 17).set('minute', 0),
        attachments: event ? event.attachments : [],
      }}
      validateSchema={{
        start: { required: true },
        end: { required: true },
      }}
      onSubmit={onSubmit}
    >
      {(form) => (
        <VisitNoteWrapper submitManageStudents={submitManageStudents}>
          <VisitNoteBoxLeft>
            {form.errors._error && <Error message={form.errors._error} />}
            <VisitNoteTitle>
              <Localize
                id="when-should-appointment"
                data={{ name: firstName }}
              />
            </VisitNoteTitle>
            <DateTime meta={form} startName="start" endName="end" />
            <Localize>
              {({ translate }) => (
                <VisitNoteDescription
                  component="textarea"
                  name="description"
                  value={form.values.description}
                  placeholder={translate('just-enter-your-description-here')}
                />
              )}
            </Localize>
            <VisitNoteFileUploader meta={form} />
            <VisitNoteButtons>
              {deleteEvent && (
                <VisitNoteDeleteButton
                  type="button"
                  onClick={onDeleteVisitNote}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </VisitNoteDeleteButton>
              )}
              <VisitNoteSaveButton
                type="button"
                color="secondary"
                onClick={
                  submitManageStudents
                    ? () => {
                      setIsLoading(true);
                      submitManageStudents(form.values, () => setIsLoading(false));
                    }
                    : form.submitForm
                }
                loading={form.isSubmitting || isLoading}
              >
                <VisitNoteSaveButtonContent>
                  <VisitNoteIconWrapper>
                    <FontAwesomeIcon icon={faCheck} />
                  </VisitNoteIconWrapper>
                  <Localize id="save-appointment" />
                </VisitNoteSaveButtonContent>
              </VisitNoteSaveButton>
              <VisitNoteCloseButton
                type="button"
                onClick={closeManageStudents || onCancel}
              >
                <FontAwesomeIcon icon={faTimes} />
              </VisitNoteCloseButton>
            </VisitNoteButtons>
          </VisitNoteBoxLeft>
        </VisitNoteWrapper>
      )}
    </Form>
  );
};

VisitNote.propTypes = {
  firstName: PropTypes.string,
  event: PropTypes.object,
  onAddNote: PropTypes.func,
  deleteEvent: PropTypes.func,
  editEvent: PropTypes.func,
  onEditableClose: PropTypes.func,
  onAddingClose: PropTypes.func,
  internship: PropTypes.shape({
    api_url: PropTypes.string.isRequired,
    pk: PropTypes.number.isRequired,
    application: PropTypes.shape({
      vacancy: PropTypes.shape({
        company: PropTypes.shape({
          name: PropTypes.string,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }),
  submitManageStudents: PropTypes.func,
  closeManageStudents: PropTypes.func,
};

VisitNote.defaultProps = {
  firstName: '',
  event: null,
  internship: null,
  deleteEvent: null,
  editEvent: null,
  onEditableClose: null,
  onAddingClose: null,
  submitManageStudents: null,
  closeManageStudents: null,
  onAddNote: null,
};

export default VisitNote;
