'use strict';

exports.__esModule = true;

var _redux = require('redux');

var _createTypes = require('./createTypes');

var oneOf = function oneOf(types, fn, actionTyte) {
  return types.some(function (type) {
    return fn(type) === actionTyte;
  });
};

var loadingFor = function loadingFor(types) {
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    var action = arguments[1];

    if (oneOf(types, _createTypes.requestType, action.type)) {
      return true;
    }
    if (oneOf(types, _createTypes.successType, action.type)) {
      return false;
    }
    if (oneOf(types, _createTypes.failureType, action.type)) {
      return false;
    }
    return state;
  };
};

var errorFor = function errorFor(types) {
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var action = arguments[1];

    if (oneOf(types, _createTypes.requestType, action.type)) {
      return null;
    }
    if (oneOf(types, _createTypes.successType, action.type)) {
      return null;
    }
    if (oneOf(types, _createTypes.failureType, action.type)) {
      return action.error;
    }
    return state;
  };
};

exports.default = function (type, dataReducer) {
  var types = Array.isArray(type) ? type : [type];
  return (0, _redux.combineReducers)({
    loading: loadingFor(types),
    data: dataReducer,
    error: errorFor(types)
  });
};

module.exports = exports['default'];