import React from 'react';
import PropTypes from 'prop-types';
import { BackButton } from 'shared';
import Paragraph from 'components/Paragraph';
import JobAdvisor from 'components/JobAdvisor';
import Localize from 'components/Localize';

const showJobAdviser = process.env.REACT_APP_SHOW_JOB_ADVISOR === 'true';
const Page = ({
  title,
  description,
  withBackButton,
  backTo,
  children,
  withJobAdvisor,
  withTopMargin,
  icon,
  onClick,
}) => (
  <>
    {withBackButton && (
      <BackButton backTo={backTo} text={<Localize id="back-profile" />} />
    )}
    {title && (
      <Paragraph
        withTopMargin={withTopMargin || withBackButton}
        title={title}
        icon={icon}
        onClick={onClick}
      >
        {description}
      </Paragraph>
    )}

    {children}
    {showJobAdviser && withJobAdvisor && <JobAdvisor />}
  </>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  description: PropTypes.node,
  withJobAdvisor: PropTypes.bool,
  withBackButton: PropTypes.bool,
  withTopMargin: PropTypes.bool,
  backTo: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
};

Page.defaultProps = {
  description: undefined,
  withBackButton: true,
  withJobAdvisor: true,
  withTopMargin: false,
  backTo: '/',
  icon: undefined,
  title: undefined,
  onClick: undefined,
};

export default Page;
