import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { device } from 'styles/device';

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.primary};
  background-color: ${({ theme }) => theme.white};
  border-radius: ${({ theme }) => theme.borderRadiusDefault};
  color: ${({ theme }) => theme.primary};
  height: ${({ isActive, height }) => (isActive ? height : '38px')};
  overflow: hidden;
  transition: all 0.5s;
  margin-top: 2px;
  cursor: pointer;
  @media ${device.mobile} {
    ${({ isActive }) => isActive
    ? ''
    : css`
            border: none;
          `};
  }
`;

export const Greetings = styled.span`
  @media ${device.mobile} {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 6px 10px 6px 25px;
  @media ${device.mobile} {
    padding: 6px;
  }
`;

const avatarStyles = css`
  width: 25px;
  height: 25px;
  border-radius: ${({ theme }) => theme.borderRadiusRound};
  margin-left: 15px;
  @media ${device.mobile} {
    margin-left: auto;
  }
`;

export const Avatar = styled.img`
  ${avatarStyles}
`;

export const DefaultAvatar = styled(FontAwesomeIcon)`
  ${avatarStyles};
  color: ${({ theme }) => theme.textColor};
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  ${({ isActive }) => isActive
    ? ''
    : css`
          display: none;
        `};
`;

export const ActionItem = styled.div`
  padding: 6px 12px;
  transition: all 0.5s;
  text-align: right;
  color: ${({ theme }) => theme.textColor};
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.grayE6};
  }
  &.disabled {
    opacity: 0.5;
    cursor: initial;
    &:hover {
      background-color: initial;
    }
  }
  .coming-soon {
    color: #475b81;
    font-size: 12px;
    padding-right: 5px;
  }
`;
