import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'shared';
import { Tooltip } from 'react-tippy';
import Localize from 'components/Localize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { TextArea, TextAreaLoader, TextAreaWrapper } from './TextAreaStyled';

const _TextArea = ({
  userName,
  invalid,
  loading,
  error,
  field,
  isGroupChat,
  ...props
}) => (
  <Localize>
    {({ translate }) => {
      const target = isGroupChat ? translate('group-chat') : userName;
      return (
        <TextAreaWrapper isValidMessage={invalid}>
          <TextArea
            rows={1}
            maxRows={8}
            placeholder={translate('message-to-user', { user: target })}
            {...field}
            {...props}
          />
          {loading && (
            <TextAreaLoader>
              <Spin />
            </TextAreaLoader>
          )}
          {error && (
            <TextAreaLoader>
              <Tooltip
                title={translate('something-went-wrong')}
                theme="light"
                arrow
                position="left"
                open
              >
                <FontAwesomeIcon icon={faExclamationCircle} />
              </Tooltip>
            </TextAreaLoader>
          )}
        </TextAreaWrapper>
      );
    }}
  </Localize>
);

_TextArea.propTypes = {
  userName: PropTypes.string,
  field: PropTypes.object.isRequired,
  invalid: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  isGroupChat: PropTypes.bool.isRequired,
};

_TextArea.defaultProps = {
  userName: null,
};

export default _TextArea;
