import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import { Button } from 'shared';

import { actionTypes, actionOptions } from '../ManageStudentsUtils';
import { DropdownContainer, ActionSelect } from './ActionDropdownStyled';

import AddTodo from './AddTodo';
import SetOlt from './SetOlt';
import SetOnSiteMeeting from './SetOnSiteMeeting';
import SetEvent from './SetEvent';
import EnterAbsence from './EnterAbsence';
import SendMessageToStudent from './SendMessageToStudent';
import SendMessageToTeacher from './SendMessageToTeacher';
import SendMessageToEduAdv from './SendMessageToEduAdv';
import SendMessageToCompany from './SendMessageToCompany';
import SendMessageToGroup from './SendMessageToGroup';
import SetPresentationCompleted from './SetPresentationCompleted';

const ActionDropdown = ({
  selectedApplicants,
  selectedInternship,
  submit,
  closeActionModel,
  currentUser,
}) => {
  const [selectedAction, setSelectedAction] = useState(null);

  useEffect(() => {
    setSelectedAction(null);
  }, [selectedApplicants, selectedInternship]);

  const handleAction = () => {
    if (selectedAction.value === actionTypes.ADD_TODO) {
      submit(
        <AddTodo
          selectedApplicants={selectedApplicants}
          selectedInternship={selectedInternship}
          showActionResult={submit}
          closeActionModel={closeActionModel}
        />,
      );
    } else if (selectedAction.value === actionTypes.SET_OLT) {
      submit(
        <SetOlt
          selectedApplicants={selectedApplicants}
          selectedInternship={selectedInternship}
          showActionResult={submit}
          closeActionModel={closeActionModel}
        />,
      );
    } else if (selectedAction.value === actionTypes.SET_ON_SITE_MEETING) {
      submit(
        <SetOnSiteMeeting
          selectedApplicants={selectedApplicants}
          selectedInternship={selectedInternship}
          showActionResult={submit}
          closeActionModel={closeActionModel}
        />,
      );
    } else if (selectedAction.value === actionTypes.SET_EVENT) {
      submit(
        <SetEvent
          selectedApplicants={selectedApplicants}
          selectedInternship={selectedInternship}
          showActionResult={submit}
          closeActionModel={closeActionModel}
        />,
      );
    } else if (selectedAction.value === actionTypes.ENTER_ABSENCE) {
      submit(
        <EnterAbsence
          selectedApplicants={selectedApplicants}
          selectedInternship={selectedInternship}
          showActionResult={submit}
          closeActionModel={closeActionModel}
        />,
      );
    } else if (selectedAction.value === actionTypes.SEND_MESSAGE_TO_STUDENT) {
      submit(
        <SendMessageToStudent
          selectedApplicants={selectedApplicants}
          selectedInternship={selectedInternship}
          showActionResult={submit}
          closeActionModel={closeActionModel}
        />,
      );
    } else if (selectedAction.value === actionTypes.SEND_MESSAGE_TO_TEACHER) {
      submit(
        <SendMessageToTeacher
          selectedApplicants={selectedApplicants}
          selectedInternship={selectedInternship}
          showActionResult={submit}
          closeActionModel={closeActionModel}
        />,
      );
    } else if (selectedAction.value === actionTypes.SEND_MESSAGE_TO_EDU_ADV) {
      submit(
        <SendMessageToEduAdv
          selectedApplicants={selectedApplicants}
          selectedInternship={selectedInternship}
          showActionResult={submit}
          closeActionModel={closeActionModel}
        />,
      );
    } else if (selectedAction.value === actionTypes.SEND_MESSAGE_TO_COMPANY) {
      submit(
        <SendMessageToCompany
          selectedApplicants={selectedApplicants}
          selectedInternship={selectedInternship}
          showActionResult={submit}
          closeActionModel={closeActionModel}
        />,
      );
    } else if (
      selectedAction.value === actionTypes.SEND_MESSAGE_TO_GROUP_CHAT
    ) {
      submit(
        <SendMessageToGroup
          selectedApplicants={selectedApplicants}
          selectedInternship={selectedInternship}
          showActionResult={submit}
          closeActionModel={closeActionModel}
        />,
      );
    } else if (
      selectedAction.value === actionTypes.SET_PRESENTATION_COMPLETED
    ) {
      submit(
        <SetPresentationCompleted
          selectedApplicants={selectedApplicants}
          selectedInternship={selectedInternship}
          showActionResult={submit}
          closeActionModel={closeActionModel}
        />,
      );
    }
  };
  return (
    <DropdownContainer>
      <ActionSelect
        options={actionOptions
          .filter(({ notAllowed }) => notAllowed !== currentUser.role)
          .map(({ value, disability }) => ({
            value,
            label: <Localize id={value} />,
            isDisabled:
              disability
              && (!selectedApplicants.some(
                (applicant) => selectedInternship[applicant.pk]
                  && selectedInternship[applicant.pk].value,
              )
                || (value === actionTypes.SET_PRESENTATION_COMPLETED
                  && !selectedApplicants.some(
                    (applicant) => selectedInternship[applicant.pk]
                      && selectedInternship[applicant.pk].value
                      && !selectedInternship[applicant.pk].value
                        .presentation_completed,
                  ))
                || (value === actionTypes.SET_OLT
                  && !selectedApplicants.some(
                    (applicant) => selectedInternship[applicant.pk]
                      && selectedInternship[applicant.pk].value
                      && !selectedInternship[applicant.pk].value
                        .olt_submitted_at
                      && !selectedInternship[applicant.pk].value
                        .olt_submited_and_company_commented,
                  ))),
          }))}
        value={selectedAction}
        placeholder={<Localize id="select-action" />}
        onChange={setSelectedAction}
        isDisabled={!selectedApplicants.length}
      />
      <Button
        type="button"
        color="secondary"
        size="small"
        onClick={handleAction}
        disabled={!(selectedAction && selectedApplicants.length)}
      >
        <Localize id="go" />
      </Button>
    </DropdownContainer>
  );
};

ActionDropdown.propTypes = {
  selectedApplicants: PropTypes.array.isRequired,
  selectedInternship: PropTypes.shape({}).isRequired,
  submit: PropTypes.func.isRequired,
  closeActionModel: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
};

export default ActionDropdown;
