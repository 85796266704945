import styled from 'styled-components';
import { device } from 'styles/device';

export const EventMoreInfoWrapper = styled.div`
  position: absolute;
  top: 0;
  max-width: 100%;
  padding: 10px 20px;
  right: 0;
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  background-color: #fff;
  z-index: 100;
`;
export const EventMoreInfoType = styled.h3`
  text-transform: uppercase;
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeightLight};
  color: ${({ theme }) => theme.textColor3};
  margin-bottom: 5px;
`;
export const EventMoreInfoTitle = styled.p`
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  color: ${({ theme }) => theme.secondary};
`;
export const EventMoreInfoText = styled.p`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeightLight};
  color: ${({ theme }) => theme.textColor69};
`;
export const EventMoreCloseButton = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 25px;
  top: 20px;
  right: 20px;
  color: ${({ theme }) => theme.secondary};
`;
export const EventMoreInfoBox = styled.div`
  display: flex;
  border-bottom: 1px solid
    ${({ theme, noBorder }) => (noBorder ? 'none' : theme.grayA0)};
  padding: 10px 0 10px;
`;
export const EventMoreInfoBoxInner = styled.div`
  &:nth-child(2) {
    margin-left: 20px;
  }
`;
export const DownloadButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  > * {
    margin: 5px;
  }
`;
export const EventMoreInfoWhoSee = styled.div``;

export const EventMoreInfoButtons = styled.div`
  display: flex;
  align-items: center;
  .reject-button {
    margin-left: 20px;
  }
  @media ${device.mobile} {
    align-items: initial;
    flex-direction: column;
    .reject-button {
      margin-left: 0px;
      margin-top: 10px;
    }
  }
`;
