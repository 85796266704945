import React from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';

import {
  ActionResultsWrapper,
  Title,
  ActionResult,
  CloseButton,
} from './ActionResultsStyled';

const ActionResults = ({ results, onClose }) => (
  <ActionResultsWrapper>
    <Title>
      <Localize id="action-has-been-completed" />
    </Title>
    {results.map(
      (result) => result && (
      <ActionResult>
        {result.user.first_name} {result.user.last_name} {' -> '}
        <Localize id={result.result} />
      </ActionResult>
      ),
    )}
    <CloseButton type="button" color="secondary" size="small" onClick={onClose}>
      <Localize id="done" />
    </CloseButton>
  </ActionResultsWrapper>
);

ActionResults.propTypes = {
  results: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionResults;
