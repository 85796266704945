'use strict';

exports.__esModule = true;
exports.default = undefined;

var _RightNavButton = require('./RightNavButton');

var _RightNavButton2 = _interopRequireDefault(_RightNavButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _RightNavButton2.default;
module.exports = exports['default'];