import styled from 'styled-components';

export const CoverWrap = styled.div`
  position: relative;
  height: ${({ height }) => height};
`;

export const CoverContent = styled.div`
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
