import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loader, Error } from 'shared';
import { getUsers } from 'actions/usersActions';

const AttendeesProvider = ({
  internshipId,
  children,
  loading,
  error,
  getUsers,
  users,
}) => {
  const stableGetUsers = useCallback(getUsers, [internshipId]);
  useEffect(() => {
    stableGetUsers(internshipId);
  }, [internshipId, stableGetUsers]);
  if (error) {
    return <Error />;
  }
  if (loading) {
    return <Loader />;
  }
  return children(users);
};

AttendeesProvider.propTypes = {
  internshipId: PropTypes.number,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  getUsers: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

AttendeesProvider.defaultProps = {
  internshipId: null,
  error: null,
};

const mapStateToProps = (
  { users: { data, loading, error } },
  { internshipId },
) => ({
  users: data.byInternship[internshipId] || [],
  loading,
  error,
});

export default connect(
  mapStateToProps,
  { getUsers },
)(AttendeesProvider);
