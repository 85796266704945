import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Loader, Error } from 'shared';
import {
  getReport,
  editDay,
  editConclusion,
  uploadDayAttachment,
  deleteDayAttachment,
  addDay,
  submitReport,
  resendReport,
  editDayTask,
  addDayTasks,
  deleteDayTask,
} from 'actions/internshipReportActions';
import Report from './Report';
import { InternshipReportContainer } from './InternshipReportStyled';

const InternshipReportContent = ({
  report,
  getReport: _getReport,
  editDay: _editDay,
  editConclusion: _editConclusion,
  uploadDayAttachment: _uploadDayAttachment,
  deleteDayAttachment: _deleteDayAttachment,
  addDay: _addDay,
  addDayTasks: _addDayTasks,
  deleteDayTask: _deleteDayTask,
  editDayTask: _editDayTask,
  submitReport,
  resendReport,
  loading,
  error,
  internshipId,
  viewOnly,
  language,
}) => {
  const stableGetReport = useCallback(_getReport, [internshipId, language]);
  useEffect(() => {
    if (internshipId) stableGetReport(internshipId);
  }, [stableGetReport, internshipId, language]);
  return (
    <InternshipReportContainer>
      {loading && <Loader />}
      {error
        && (Object.values(error) && Object.values(error)[0] ? (
          <Error message={Object.values(error)[0]} />
        ) : (
          <Error />
        ))}
      {report && !loading && (
        <Report
          report={report}
          onDayChange={_editDay}
          onConclusionChange={_editConclusion}
          onUploadAttachment={_uploadDayAttachment}
          onAddDay={_addDay}
          onDeleteDayAttachment={_deleteDayAttachment}
          addDayTasks={_addDayTasks}
          deleteDayTask={_deleteDayTask}
          editDayTask={_editDayTask}
          submitReport={() => submitReport(internshipId)}
          resendReport={() => resendReport(internshipId)}
          viewOnly={viewOnly}
        />
      )}
    </InternshipReportContainer>
  );
};

InternshipReportContent.propTypes = {
  report: PropTypes.shape({
    pk: PropTypes.number,
  }),
  error: PropTypes.shape({
    detail: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  getReport: PropTypes.func.isRequired,
  editDay: PropTypes.func.isRequired,
  editConclusion: PropTypes.func.isRequired,
  uploadDayAttachment: PropTypes.func.isRequired,
  deleteDayAttachment: PropTypes.func.isRequired,
  addDay: PropTypes.func.isRequired,
  internshipId: PropTypes.number.isRequired,
  submitReport: PropTypes.func.isRequired,
  resendReport: PropTypes.func.isRequired,
  addDayTasks: PropTypes.func.isRequired,
  editDayTask: PropTypes.func.isRequired,
  deleteDayTask: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  language: PropTypes.string,
};

InternshipReportContent.defaultProps = {
  report: null,
  error: null,
  viewOnly: false,
  language: null,
};

const mapStateToProps = ({
  currentUser,
  internshipReport: { data, loading, error },
}) => ({
  loading,
  error,
  user: currentUser,
  report: data ? data.report : null,
});

export default connect(
  mapStateToProps,
  {
    getReport,
    editDay,
    editConclusion,
    uploadDayAttachment,
    deleteDayAttachment,
    addDay,
    submitReport,
    resendReport,
    addDayTasks,
    editDayTask,
    deleteDayTask,
  },
)(InternshipReportContent);
