import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Paragraph } from './WrapperWithTitleAndDescStyled';

function WrapperWithTitleAndDesc({
  title,
  description,
  children,
  className,
  ...rest
}) {
  return (
    <Wrapper className={className} {...rest}>
      <Paragraph title={title}>{description}</Paragraph>
      {children}
    </Wrapper>
  );
}

WrapperWithTitleAndDesc.propTypes = {
  title: PropTypes.node,
  description: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.object,
};

WrapperWithTitleAndDesc.defaultProps = {
  title: '',
  description: '',
  className: '',
  children: null,
};

export default WrapperWithTitleAndDesc;
