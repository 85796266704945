import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ReactComponent as ArrowIcon } from 'shared/lib/styles/images/arrow-left.svg';
import Localize from 'components/Localize';

import {
  Applicant,
  ApplicantTop,
  ApplicantBottom,
  ApplicantName,
  ApplicantInfList,
  ApplicantInfItem,
  ArrowGoIcon,
  ApplicantTextWrapper,
  StatusIcon,
} from './ApplicantItemStyled';

export const InternshipItem = ({
  applicantId,
  internship,
  firstName,
  lastName,
  classroom,
  vacancy,
  location,
  status,
}) => {
  let link = `/applicant-profile/${applicantId}`;
  if (internship) link += `/${internship.pk}`;
  const classroomName = `${classroom.school.name} ${classroom.name}`;
  return (
    <Applicant to={link}>
      <StatusIcon status={status} />
      <ApplicantTextWrapper>
        <ApplicantTop>
          <ApplicantName>
            {firstName} {lastName}
          </ApplicantName>
        </ApplicantTop>
        <ApplicantBottom>
          <ApplicantInfList>
            <ApplicantInfItem boldOnMobile>
              <Localize id="class" /> {classroomName}
            </ApplicantInfItem>
            {vacancy && location && (
              <ApplicantInfItem>
                {vacancy} - {location}
              </ApplicantInfItem>
            )}
            {status === 'ACCEPTED' && (
              <ApplicantInfItem>
                <Localize id="get-approved" />
              </ApplicantInfItem>
            )}
          </ApplicantInfList>
        </ApplicantBottom>
        {internship && (
          <ApplicantBottom>
            <ApplicantInfList>
              <ApplicantInfItem>{internship.company_name}</ApplicantInfItem>
              <ApplicantInfItem>
                {moment(internship.starts_at).format('DD.MM.YYYY')} -{' '}
                {moment(internship.ends_at).format('DD.MM.YYYY')}
              </ApplicantInfItem>
            </ApplicantInfList>
          </ApplicantBottom>
        )}
        <ArrowGoIcon>
          <ArrowIcon />
        </ArrowGoIcon>
      </ApplicantTextWrapper>
    </Applicant>
  );
};

InternshipItem.propTypes = {
  applicantId: PropTypes.number.isRequired,
  internship: PropTypes.shape({
    pk: PropTypes.number,
    starts_at: PropTypes.string,
    ends_at: PropTypes.string,
    company_name: PropTypes.string,
  }),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  classroom: PropTypes.shape({
    school: PropTypes.shape({
      name: PropTypes.string,
    }),
    name: PropTypes.string,
  }),
  vacancy: PropTypes.string,
  location: PropTypes.string,
  status: PropTypes.string,
};

InternshipItem.defaultProps = {
  internship: null,
  firstName: '',
  lastName: '',
  classroom: '',
  vacancy: '',
  location: '',
  status: '',
};

export default InternshipItem;
