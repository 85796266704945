'use strict';

exports.__esModule = true;
exports.UploadBoxTip = exports.UploadBoxOr = exports.UploadBoxTitle = exports.UploadBox = exports.StyledFileList = exports.FileUploaderContainer = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  width: 100%;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: ', ';\n  @media ', ' {\n    flex-direction: column-reverse;\n    align-items: center;\n  }\n'], ['\n  width: 100%;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: ', ';\n  @media ', ' {\n    flex-direction: column-reverse;\n    align-items: center;\n  }\n']),
    _templateObject2 = _taggedTemplateLiteralLoose([''], ['']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  width: 100%;\n  max-width: ', ';\n  text-align: center;\n  background: linear-gradient(\n      to right,\n      ', ' 50%,\n      transparent 50%\n    ),\n    linear-gradient(\n      to right,\n      ', ' 50%,\n      transparent 50%\n    ),\n    linear-gradient(\n      to bottom,\n      ', ' 50%,\n      transparent 50%\n    ),\n    linear-gradient(\n      to bottom,\n      ', ' 50%,\n      transparent 50%\n    );\n  background-position: left top, left bottom, left top, right top;\n  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;\n  background-size: 20px 3px, 20px 3px, 3px 20px, 3px 20px;\n  border-radius: ', ';\n  padding: 30px 40px;\n  margin-right: ', ';\n  button {\n    margin: 5px auto;\n    width: 100%;\n  }\n  @media ', ' {\n    padding: 24px 16px;\n    margin-right: ', ';\n    button {\n      font-size: 16px;\n    }\n  }\n  @media ', ' {\n    padding: 24px 16px;\n    margin: auto;\n    button {\n      font-size: 16px;\n    }\n  }\n'], ['\n  width: 100%;\n  max-width: ', ';\n  text-align: center;\n  background: linear-gradient(\n      to right,\n      ', ' 50%,\n      transparent 50%\n    ),\n    linear-gradient(\n      to right,\n      ', ' 50%,\n      transparent 50%\n    ),\n    linear-gradient(\n      to bottom,\n      ', ' 50%,\n      transparent 50%\n    ),\n    linear-gradient(\n      to bottom,\n      ', ' 50%,\n      transparent 50%\n    );\n  background-position: left top, left bottom, left top, right top;\n  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;\n  background-size: 20px 3px, 20px 3px, 3px 20px, 3px 20px;\n  border-radius: ', ';\n  padding: 30px 40px;\n  margin-right: ', ';\n  button {\n    margin: 5px auto;\n    width: 100%;\n  }\n  @media ', ' {\n    padding: 24px 16px;\n    margin-right: ', ';\n    button {\n      font-size: 16px;\n    }\n  }\n  @media ', ' {\n    padding: 24px 16px;\n    margin: auto;\n    button {\n      font-size: 16px;\n    }\n  }\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  font-size: 16px;\n  font-weight: ', ';\n  color: ', ';\n'], ['\n  font-size: 16px;\n  font-weight: ', ';\n  color: ', ';\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n  position: relative;\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  hr {\n    width: 100%;\n    z-index: 1;\n    border: none;\n    height: 1px;\n    background-color: ', ';\n    margin: 0px;\n    left: 0px;\n  }\n  span {\n    z-index: 2;\n    padding: 20px 15px;\n    color: ', ';\n    word-break: initial;\n  }\n'], ['\n  position: relative;\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  hr {\n    width: 100%;\n    z-index: 1;\n    border: none;\n    height: 1px;\n    background-color: ', ';\n    margin: 0px;\n    left: 0px;\n  }\n  span {\n    z-index: 2;\n    padding: 20px 15px;\n    color: ', ';\n    word-break: initial;\n  }\n']),
    _templateObject6 = _taggedTemplateLiteralLoose(['\n  font-size: 14px;\n  font-weight: ', ';\n  color: ', ';\n  margin-top: 20px;\n'], ['\n  font-size: 14px;\n  font-weight: ', ';\n  color: ', ';\n  margin-top: 20px;\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _device = require('../../styles/device');

var _FilesList = require('./FilesList');

var _FilesList2 = _interopRequireDefault(_FilesList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var FileUploaderContainer = exports.FileUploaderContainer = _styledComponents2.default.div(_templateObject, function (_ref) {
  var fileListOnTop = _ref.fileListOnTop;
  return fileListOnTop ? 'column-reverse' : 'row';
}, _device.device.mobile);
var StyledFileList = exports.StyledFileList = (0, _styledComponents2.default)(_FilesList2.default)(_templateObject2);
var UploadBox = exports.UploadBox = _styledComponents2.default.div(_templateObject3, function (_ref2) {
  var fileListOnTop = _ref2.fileListOnTop;
  return fileListOnTop ? '100%' : '450px';
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.primary;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.primary;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.primary;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.primary;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.borderRadiusDefault;
}, function (_ref8) {
  var fileListOnTop = _ref8.fileListOnTop;
  return fileListOnTop ? '0' : '100px';
}, _device.device.tablet, function (_ref9) {
  var fileListOnTop = _ref9.fileListOnTop;
  return fileListOnTop ? '0' : '40px';
}, _device.device.mobile);

var UploadBoxTitle = exports.UploadBoxTitle = _styledComponents2.default.h3(_templateObject4, function (_ref10) {
  var theme = _ref10.theme;
  return theme.fontWeightMedium;
}, function (_ref11) {
  var theme = _ref11.theme;
  return theme.textColor;
});

var UploadBoxOr = exports.UploadBoxOr = _styledComponents2.default.div(_templateObject5, function (_ref12) {
  var theme = _ref12.theme;
  return theme.grayE6;
}, function (_ref13) {
  var theme = _ref13.theme;
  return theme.grayA0;
});

var UploadBoxTip = exports.UploadBoxTip = _styledComponents2.default.div(_templateObject6, function (_ref14) {
  var theme = _ref14.theme;
  return theme.fontWeightMedium;
}, function (_ref15) {
  var theme = _ref15.theme;
  return theme.grayA0;
});