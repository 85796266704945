import assessmentApi from 'api/AssessmentApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getAssessment(internshipId) {
  return createAction({
    type: types.GET_ASSESSMENT,
    callAPI: () => assessmentApi.getAssessment(internshipId),
  });
}

export function getAssessmentAnswerChoices() {
  return createAction({
    type: types.GET_ASSESSMENT_ANSWER_CHOICES,
    callAPI: () => assessmentApi.getAssessmentAnswerChoices(),
  });
}

export function changeAssessmentItems(assessmentId, data) {
  return createAction({
    type: types.CHANGE_ASSESSMENT_ITEMS,
    callAPI: () => assessmentApi.changeAssessmentItems(assessmentId, data),
  });
}

export function changeAssessment(assessmentId, data) {
  return createAction({
    type: types.CHANGE_ASSESSMENT,
    callAPI: () => assessmentApi.changeAssessment(assessmentId, data),
  });
}

export function resendAssessment(assessmentId, data) {
  return createAction({
    type: types.CHANGE_ASSESSMENT,
    callAPI: () => assessmentApi.resendAssessment(assessmentId, data),
  });
}
