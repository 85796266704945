import styled from 'styled-components';

export const StyledButton = styled.button`
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  border-radius: ${({ theme }) => theme.borderRadiusDefault};
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.secondary};
  height: 66px;
  border: none;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  .text {
    padding-left: 20px;
    text-align: left;
  }
`;
