'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactFontawesome = require('@fortawesome/react-fontawesome');

var _freeSolidSvgIcons = require('@fortawesome/free-solid-svg-icons');

var _SelectStyled = require('./SelectStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var Select = function Select(_ref) {
  var className = _ref.className,
      invalid = _ref.invalid,
      props = _objectWithoutProperties(_ref, ['className', 'invalid']);

  return _react2.default.createElement(_SelectStyled.StyledDropDown, _extends({
    className: className,
    invalid: invalid,
    arrowClosed: _react2.default.createElement(
      'span',
      null,
      _react2.default.createElement(_reactFontawesome.FontAwesomeIcon, { icon: _freeSolidSvgIcons.faAngleDown })
    ),
    arrowOpen: _react2.default.createElement(
      'span',
      null,
      _react2.default.createElement(_reactFontawesome.FontAwesomeIcon, { icon: _freeSolidSvgIcons.faAngleUp })
    )
  }, props));
};

Select.propTypes = process.env.NODE_ENV !== "production" ? {
  className: _propTypes2.default.string,
  invalid: _propTypes2.default.bool,
  placeholder: _propTypes2.default.string,
  options: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]).isRequired,
    label: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]).isRequired
  })).isRequired,
  value: _propTypes2.default.shape({
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]).isRequired,
    label: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]).isRequired
  }),
  onChange: _propTypes2.default.func.isRequired
} : {};

Select.defaultProps = {
  className: undefined,
  placeholder: undefined,
  invalid: false,
  value: null
};

exports.default = Select;
module.exports = exports['default'];