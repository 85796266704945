import kindApi from 'api/KindApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getKindList() {
  return createAction({
    type: types.GET_KIND_LIST,
    callAPI: () => kindApi.getKindList(),
    shouldCallAPI: (state) => !state.kind.data && !state.kind.loading,
  });
}
