import internshipsApi from 'api/InternshipsApi';
import { createAction } from 'shared/lib/lib/callAPI';
import learningTaskApi from 'api/LearningTaskApi';
import * as types from './types';

export function getInternship(pk) {
  return createAction({
    type: types.GET_INTERNSHIP,
    callAPI: () => internshipsApi.getInternshipById(pk),
    // shouldCallAPI: (state) => !state.internships.data.byId[pk],
  });
}

export function getApplicants(params) {
  return createAction({
    type: types.GET_APPLICANTS,
    callAPI: () => internshipsApi.getApplicants(params),
    // shouldCallAPI: (state) => !state.applicants.data,
  });
}

export function getApplicant(pk) {
  return createAction({
    type: types.GET_APPLICANT,
    callAPI: () => internshipsApi.getApplicant(pk),
    // shouldCallAPI: (state) => !state.applicants.data,
  });
}

export function completePresentation(internshipId, data) {
  return createAction({
    type: types.COMPLETE_PRESENTATION,
    callAPI: () => internshipsApi.completePresentation(internshipId, data),
  });
}

export function updateInternshipLearningTask(pk, task) {
  return (dispatch) => learningTaskApi.updateTask(pk, task).then((data) => {
    dispatch({
      type: types.UPDATE_INTERNSHIP_LEARNING_TASK,
      response: data,
    });
  });
}

export function resendInternshipLearningTask(pk) {
  return (dispatch) => learningTaskApi.resendTask(pk).then((data) => {
    dispatch({
      type: types.UPDATE_INTERNSHIP_LEARNING_TASK,
      response: data,
    });
  });
}
