import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import {
  ColumnFilter,
  ColumnFilterCheckbox,
  ColumnFilterOption,
  ResetFilter,
} from './TableColumnFilterStyled';

function TableColumnFilter({
  options,
  setFilters,
  filters,
  columnName,
  dataLength,
  closeFilter,
}) {
  const itemsRef = useRef(null);
  const handleFilter = (i, option) => {
    setFilters((filters) => ({
      ...filters,
      [columnName]: {
        ...filters[columnName],
        [option]:
          itemsRef.current.childNodes[i].childNodes[0].childNodes[0].checked,
      },
    }));
  };
  const handleClick = (e) => {
    if (itemsRef.current.contains(e.target)) {
      return;
    }
    closeFilter();
  };
  const stableHandleClick = useCallback(handleClick, []);
  useEffect(() => {
    document.addEventListener('mousedown', stableHandleClick);
    return () => {
      document.removeEventListener('mousedown', stableHandleClick);
    };
  }, [stableHandleClick]);
  const resetFilter = (columnName) => {
    setFilters((filters) => ({
      ...filters,
      [columnName]: {
        ...Object.keys(filters[columnName] || {}).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {}),
      },
    }));
  };
  return (
    <ColumnFilter
      ref={itemsRef}
      dataLength={dataLength}
      optionsLength={options.length}
    >
      {options.map((option, i) => (
        <ColumnFilterOption>
          <ColumnFilterCheckbox
            checked={
              (filters[columnName] && filters[columnName][option]) || false
            }
            onClick={() => handleFilter(i, option)}
          >
            <Localize id={option} />
          </ColumnFilterCheckbox>
        </ColumnFilterOption>
      ))}
      <ResetFilter onClick={() => resetFilter(columnName)}>
        <Localize id="reset-filter" />
      </ResetFilter>
    </ColumnFilter>
  );
}

TableColumnFilter.propTypes = {
  options: PropTypes.array.isRequired,
  columnName: PropTypes.string.isRequired,
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape({}).isRequired,
  dataLength: PropTypes.number.isRequired,
  closeFilter: PropTypes.func.isRequired,
};

export default TableColumnFilter;
