import React from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import WhiteArea from 'components/WhiteArea';
import moment from 'moment';
import {
  Error,
  Form,
  InputField,
  SecondaryButton,
  PrimaryButton,
  DatePicker,
  FileList,
} from 'shared';
import checkIcon from 'shared/lib/styles/images/check.svg';
import closeIcom from 'shared/lib/styles/images/close.svg';
import {
  TaskFormWrap,
  TaskFormButtons,
  TaskFormLabelText,
} from './TaskFormStyled';

const TaskForm = ({ task, onSubmit, onCancel }) => {
  const handleSubmit = (values) => onSubmit({
    ...values,
    author: undefined,
    deadline: values.deadline
      ? moment.utc(values.deadline).format()
      : undefined,
    attachments: values.attachments.map(({ pk, file }) => ({
      pk,
      file: file.split(',').pop(),
    })),
  });
  return (
    <WhiteArea>
      <Form
        initialValues={{
          description: task ? task.description : '',
          deadline: task ? task.deadline : undefined,
          attachments: task ? task.attachments : [],
        }}
        validateSchema={{
          description: { required: true },
        }}
        onSubmit={handleSubmit}
      >
        {(form) => (
          <TaskFormWrap>
            <InputField
              name="description"
              placeholder={<Localize id="description" />}
            />
            <TaskFormLabelText>
              <Localize id="deadline" />
            </TaskFormLabelText>
            <InputField
              name="deadline"
              yearsInFuture={4}
              yearsInPrevious={0}
              component={DatePicker}
            />
            <InputField name="attachments" component={FileList} />
            {form.errors._error && <Error message={form.errors._error} />}
            <TaskFormButtons>
              <SecondaryButton
                type="submit"
                size="small"
                icon={checkIcon}
                disableBoxShadow
                disabled={!form.dirty}
                loading={form.isSubmitting}
              >
                <Localize id="save" />
              </SecondaryButton>
              <PrimaryButton
                type="button"
                size="small"
                variant="outlined"
                icon={closeIcom}
                onClick={onCancel}
                disableBoxShadow
              >
                <Localize id="cancel" />
              </PrimaryButton>
            </TaskFormButtons>
          </TaskFormWrap>
        )}
      </Form>
    </WhiteArea>
  );
};

TaskForm.propTypes = {
  task: PropTypes.shape({
    description: PropTypes.string.isRequired,
    deadline: PropTypes.string,
    attachments: PropTypes.array,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

TaskForm.defaultProps = {
  task: undefined,
};

export default TaskForm;
