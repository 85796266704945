import styled from 'styled-components';
import _Paragraph from 'components/Paragraph/Paragraph';
import { device } from 'styles/device';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _UserInfoField from './UserInfoField';

export const PrimaryUserDataWrap = styled.div`
  margin-top: 42px;
`;

export const Paragraph = styled(_Paragraph)`
  @media ${device.mobile} {
    text-align: center;
  }
`;

export const UserInfo = styled.div`
  form {
    display: flex;
    align-items: center;
    @media ${device.mobile} {
      flex-direction: column;
    }
    @media ${device.tablet} {
      flex-direction: column;
    }
  }
`;

export const InfoList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;

  @media ${device.mobile} {
    width: 100%;
    flex-direction: column;
  }
`;

export const UserInfoField = styled(_UserInfoField)`
  width: 49.65%;
  margin-top: 9px;

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const CheckIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.secondary};
`;

export const HiddenButton = styled.button`
  display: none;
`;
