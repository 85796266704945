'use strict';

exports.__esModule = true;
exports.InfoContent = exports.InfoIcon = exports.InfoWrap = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  width: 100%;\n  padding: 0.75rem 1.25rem;\n  margin: 1rem auto;\n  border: 1px solid ', ';\n  display: flex;\n  align-items: center;\n'], ['\n  width: 100%;\n  padding: 0.75rem 1.25rem;\n  margin: 1rem auto;\n  border: 1px solid ', ';\n  display: flex;\n  align-items: center;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  color: ', ';\n  font-size: 40px;\n  margin-right: 25px;\n'], ['\n  color: ', ';\n  font-size: 40px;\n  margin-right: 25px;\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  flex: 1;\n  font-weight: ', ';\n  //color: ', ';\n'], ['\n  flex: 1;\n  font-weight: ', ';\n  //color: ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactFontawesome = require('@fortawesome/react-fontawesome');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var InfoWrap = exports.InfoWrap = _styledComponents2.default.div(_templateObject, function (_ref) {
  var theme = _ref.theme;
  return theme.warningColor;
});

var InfoIcon = exports.InfoIcon = (0, _styledComponents2.default)(_reactFontawesome.FontAwesomeIcon)(_templateObject2, function (_ref2) {
  var theme = _ref2.theme;
  return theme.warningColor;
});

var InfoContent = exports.InfoContent = _styledComponents2.default.div(_templateObject3, function (_ref3) {
  var theme = _ref3.theme;
  return theme.fontWeightBold;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.warningColor;
});