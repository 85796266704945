import styled from 'styled-components';
import { SecondaryButton } from 'shared';
import { Link } from 'react-router-dom';

export const LoginWrap = styled.div`
  max-width: 400px;
  margin: auto;
  width: 100%;
  padding: 80px 0;
`;

export const MoodleButton = styled(SecondaryButton)`
  margin-top: 10px;
  background: #f17c11;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.secondary};
`;
