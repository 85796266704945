import styled from 'styled-components';
import Checkbox from 'components/Form/CheckBox';

export const ColumnFilter = styled.div`
  position: absolute;
  background: #fff;
  border: 1px solid ${({ theme }) => theme.secondary};
  border-radius: 3px;
  padding: 5px;
  margin-top: ${({ dataLength, optionsLength }) => optionsLength > 2 * dataLength ? '0px' : '40px'};
  white-space: ${({ dataLength, optionsLength }) => optionsLength > 2 * dataLength && 'nowrap'};
  z-index: 1;
`;
export const ColumnFilterCheckbox = styled(Checkbox)`
  z-index: 999;
  svg {
    margin-right: 10px;
    height: 15px;
    width: 15px;
  }
`;
export const ColumnFilterOption = styled.div`
  padding: 5px;
`;
export const ResetFilter = styled.a`
  cursor: pointer;
  z-index: 1;
  position: relative;
`;
