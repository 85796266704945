import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Thumbnail, Success, Info, Prompt } from 'shared';
import uploadIcon from 'shared/lib/styles/images/upload.svg';
import Localize from 'components/Localize';
import Can, { actions } from 'components/Can';
import { section } from 'utils/access-rules/rules/internshipTask';
import { resendInternshipLearningTask } from 'actions/internshipsActions';
import refreshIcon from 'styles/images/svg/refresh.svg';
import {
  ImageList,
  TaskCardSection,
  TaskCardSectionContent,
  TaskCardSectionContentWithButton,
  TaskCardSectionTitle,
  TaskCardsWrap,
  TaskCardWrapper,
  TaskFooter
} from './TaskCardStyled';
import DownloadButton from '../DownloadUrl/DownloadButton';
import WriteTask from './WriteTask';
import WriteRating from './WriteRating';

const TaskCard = ({
  data: {
    pk,
    task,
    due_date,
    report,
    company_rating,
    applicant_attachment_list,
    submitted,
    teacher_attachment,
    report_url
  },
  resendInternshipLearningTask
}) => (
  <Can I={actions.see} a={section.unSubmittedLearningTask} passThrough>
    {can =>
      !submitted && !can ? (
        <Info>
          <Localize id="you-are-not-able-see-task-until-it-submitted" />
        </Info>
      ) : (
        <TaskCardWrapper>
          <TaskCardsWrap>
            {submitted && (
              <Success>
                <Localize id="operational-learning-task-submitted" />
              </Success>
            )}
            <Can
              I={actions.edit}
              a={section.learningTaskDescription}
              passThrough
            >
              {can =>
                can && !submitted ? (
                  <WriteTask
                    pk={pk}
                    task={task}
                    due_date={due_date}
                    teacher_attachment={teacher_attachment}
                  />
                ) : (
                  <TaskCardSection>
                    <TaskCardSectionTitle>
                      <Localize id="task" />
                    </TaskCardSectionTitle>
                    <TaskCardSectionContentWithButton isItalic={!task}>
                      <div className="text">
                        {task || <Localize id="no-text" />}
                      </div>
                      {teacher_attachment && (
                        <DownloadButton url={teacher_attachment.file} />
                      )}
                    </TaskCardSectionContentWithButton>
                  </TaskCardSection>
                )
              }
            </Can>
            <TaskCardSection>
              <TaskCardSectionTitle>
                <Localize id="message" />
              </TaskCardSectionTitle>
              <TaskCardSectionContent isItalic={!report}>
                {report || <Localize id="no-text" />}
              </TaskCardSectionContent>
              <ImageList>
                {applicant_attachment_list &&
                  applicant_attachment_list.map(attachment => (
                    <Thumbnail key={attachment.pk} data={attachment} disabled />
                  ))}
              </ImageList>
            </TaskCardSection>
            {submitted && (
              <Can I={actions.edit} a={section.learningTaskRating} passThrough>
                {can =>
                  can ? (
                    <WriteRating pk={pk} rating={company_rating} />
                  ) : (
                    <TaskCardSection>
                      <TaskCardSectionTitle>
                        <Localize id="rating-company" />
                      </TaskCardSectionTitle>
                      <TaskCardSectionContent isItalic={!company_rating}>
                        {company_rating || <Localize id="no-text" />}
                      </TaskCardSectionContent>
                    </TaskCardSection>
                  )
                }
              </Can>
            )}
          </TaskCardsWrap>
          <TaskFooter>
            <Button
              as="a"
              className="btn-upload"
              icon={uploadIcon}
              disableBoxShadow
              size="small"
              color="secondary"
              href={report_url}
              target="_blank"
            >
              <Localize id="download-pdf" />
            </Button>
            <Can I={actions.see} a={section.resendLearningTask} passThrough>
              <Prompt
                confirm={() => resendInternshipLearningTask(pk)}
                trigger={
                  <Button
                    className="btn-submit"
                    icon={refreshIcon}
                    disableBoxShadow
                    color="secondary"
                    variant="outlined"
                    size="small"
                    disabled={!submitted}
                  >
                    <Localize id="resend" />
                  </Button>
                }
              >
                <Localize id="are-you-sure-want-resend-form" />
              </Prompt>
            </Can>
          </TaskFooter>
        </TaskCardWrapper>
      )
    }
  </Can>
);

TaskCard.propTypes = {
  data: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    task: PropTypes.string,
    report: PropTypes.string,
    report_url: PropTypes.string,
    due_date: PropTypes.string,
    company_rating: PropTypes.string,
    applicant_attachment_list: PropTypes.arrayOf(PropTypes.object),
    submitted: PropTypes.bool,
    teacher_attachment: PropTypes.object
  }).isRequired,
  resendInternshipLearningTask: PropTypes.func.isRequired
};

export default connect(undefined, { resendInternshipLearningTask })(TaskCard);
