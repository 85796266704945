import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import { LocalizedDate } from 'pages/LocalizeProvider';
import { Element } from 'react-scroll';
import {
  EventTypeItemMeet,
  EventTypeItemTask,
} from './EventTypeItem/EventTypeItem';
import {
  Paragraph,
  ParagraphText,
  EventTypeSection,
  EventTypeSectionHeader,
  CurrentMissesList,
  EventTypeSectionEventItemSubInf,
  EventTypeSectionDouble,
  CurrentMissesListText,
  CircleBadge,
  EditMissList,
  EditButton,
  TYPE_SECTION,
} from './EventTypeSectionStyled';

function EventTypeSectionMeet({
  navDate,
  typeSection,
  title,
  events,
  onEventEdit,
  onEventDelete,
  markNotificationAsRead,
  lastNotification,
  notifications,
  deleteNotification,
  onGetEvents,
}) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  useEffect(() => {
    if (events && events.length <= 3) {
      setMenuIsOpen(true);
    } else {
      setMenuIsOpen(false);
    }
  }, [events]);
  function toggleMenu() {
    setMenuIsOpen(!menuIsOpen);
  }
  const eventsNotification = events
    .map(
      (item) => notifications && notifications.find((not) => not.instance_id === item.pk),
    )
    .find((not) => not);

  const hasEvents = events && events.length > 0;
  let sortedEventsDays = events;
  let sortedEventDaysDaysListStrings = sortedEventsDays;

  if (hasEvents) {
    sortedEventsDays = events.sort((a, b) => {
      if (moment(a.start).isBefore(moment(b.start), 'day')) {
        return -1;
      }

      if (moment(a.start).isAfter(moment(b.start), 'day')) {
        return 1;
      }

      return 0;
    });
    sortedEventDaysDaysListStrings = sortedEventsDays.reduce(
      (currentDay, nextDay, currentIndex) => {
        if (
          moment(nextDay.start)
            .utc()
            .format('DD-MM-YYYY')
          === moment(nextDay.end)
            .utc()
            .format('DD-MM-YYYY')
        ) {
          return `${currentDay}${currentIndex > 0 ? '.,' : ''}  ${moment(
            nextDay.start,
          )
            .utc()
            .format('D')}`;
        }
        return `${currentDay}${currentIndex > 0 ? '.,' : ''}  ${moment(
          nextDay.start,
        )
          .utc()
          .format('D')} - ${moment(nextDay.end)
          .utc()
          .format('D')}`;
      },
      ' ',
    );
  }

  return (
    <EventTypeSection>
      <EventTypeSectionHeader>
        <Paragraph
          title={(
            <ParagraphText>
              <CircleBadge typeSection={typeSection} />
              {title}
              {hasEvents && (
                <>
                  {menuIsOpen ? (
                    <EditButton onClick={toggleMenu}>
                      <FontAwesomeIcon icon={faEyeSlash} />
                    </EditButton>
                  ) : (
                    <EditButton onClick={toggleMenu}>
                      <FontAwesomeIcon icon={faEye} />
                    </EditButton>
                  )}
                </>
              )}
            </ParagraphText>
)}
        />
      </EventTypeSectionHeader>
      {hasEvents && (
        <EventTypeSectionDouble>
          {menuIsOpen && (
            <EditMissList>
              {sortedEventsDays.map((event) => (
                <Element key={event.pk} name={event.pk.toString()}>
                  <EventTypeItemMeet
                    markNotificationAsRead={markNotificationAsRead}
                    key={event.pk}
                    event={event}
                    onEventEdit={onEventEdit}
                    onGetEvents={onGetEvents}
                    onEventDelete={onEventDelete}
                    lastNotification={lastNotification}
                    notifications={notifications}
                    deleteNotification={deleteNotification}
                  />
                </Element>
              ))}
            </EditMissList>
          )}
          {!menuIsOpen && (
            <EventTypeSectionEventItemSubInf>
              <CurrentMissesList
                status={eventsNotification}
                onClick={toggleMenu}
              >
                <CurrentMissesListText>{events.length}</CurrentMissesListText>
              </CurrentMissesList>
              {sortedEventDaysDaysListStrings}.&nbsp;
              <LocalizedDate date={navDate}>
                {(LocalizedDate) => LocalizedDate.format('MMM YYYY')}
              </LocalizedDate>
            </EventTypeSectionEventItemSubInf>
          )}
        </EventTypeSectionDouble>
      )}
    </EventTypeSection>
  );
}

EventTypeSectionMeet.propTypes = {
  navDate: PropTypes.instanceOf(moment),
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  events: PropTypes.arrayOf(PropTypes.object),
  onEventEdit: PropTypes.func,
  onEventDelete: PropTypes.func,
  onGetEvents: PropTypes.func,
  markNotificationAsRead: PropTypes.func.isRequired,
  lastNotification: PropTypes.array,
  notifications: PropTypes.array,
  deleteNotification: PropTypes.func.isRequired,
  typeSection: PropTypes.string.isRequired,
};

EventTypeSectionMeet.defaultProps = {
  navDate: moment(),
  events: null,
  notifications: [],
  lastNotification: [],
  onEventDelete: () => {},
  onEventEdit: () => {},
  onGetEvents: () => {},
};

function EventTypeSectionMissed({
  typeSection,
  navDate,
  title,
  currentMissesList,
  onEventEdit,
  onEventDelete,
  markNotificationAsRead,
  lastNotification,
  notifications,
  deleteNotification,
}) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  useEffect(() => {
    if (currentMissesList && currentMissesList.length <= 3) {
      setMenuIsOpen(true);
    } else {
      setMenuIsOpen(false);
    }
  }, [currentMissesList]);
  function toggleMenu() {
    setMenuIsOpen(!menuIsOpen);
  }
  const missesNotification = currentMissesList
    .map(
      (item) => notifications && notifications.find((not) => not.instance_id === item.pk),
    )
    .find((not) => not);

  const hasMissedEvents = currentMissesList && currentMissesList.length > 0;
  let sortedMissedDays = currentMissesList;
  let sortedMissedDaysDaysListStrings = sortedMissedDays;

  if (hasMissedEvents) {
    sortedMissedDays = currentMissesList.sort((a, b) => {
      if (moment(a.start).isBefore(moment(b.start), 'day')) {
        return -1;
      }

      if (moment(a.start).isAfter(moment(b.start), 'day')) {
        return 1;
      }

      return 0;
    });
    sortedMissedDaysDaysListStrings = sortedMissedDays.reduce(
      (currentDay, nextDay, currentIndex) => {
        if (
          moment(nextDay.start)
            .utc()
            .format('DD-MM-YYYY')
          === moment(nextDay.end)
            .utc()
            .format('DD-MM-YYYY')
        ) {
          return `${currentDay}${currentIndex > 0 ? '.,' : ''}  ${moment(
            nextDay.start,
          )
            .utc()
            .format('D')}`;
        }
        return `${currentDay}${currentIndex > 0 ? '.,' : ''}  ${moment(
          nextDay.start,
        )
          .utc()
          .format('D')} - ${moment(nextDay.end)
          .utc()
          .format('D')}`;
      },
      ' ',
    );
  }

  return (
    <EventTypeSection missesList menuIsOpen={menuIsOpen}>
      <EventTypeSectionHeader>
        <Paragraph
          title={(
            <ParagraphText>
              <CircleBadge typeSection={typeSection} />
              {title}
              {hasMissedEvents && (
                <>
                  {menuIsOpen ? (
                    <EditButton onClick={toggleMenu}>
                      <FontAwesomeIcon icon={faEyeSlash} />
                    </EditButton>
                  ) : (
                    <EditButton onClick={toggleMenu}>
                      <FontAwesomeIcon icon={faEye} />
                    </EditButton>
                  )}
                </>
              )}
            </ParagraphText>
)}
        />
      </EventTypeSectionHeader>
      {hasMissedEvents && (
        <EventTypeSectionDouble>
          {menuIsOpen && (
            <EditMissList>
              {sortedMissedDays.map((event) => (
                <EventTypeItemMeet
                  markNotificationAsRead={markNotificationAsRead}
                  key={event.pk}
                  event={event}
                  onEventEdit={onEventEdit}
                  onEventDelete={onEventDelete}
                  lastNotification={lastNotification}
                  notifications={notifications}
                  deleteNotification={deleteNotification}
                />
              ))}
            </EditMissList>
          )}
          {!menuIsOpen && (
            <EventTypeSectionEventItemSubInf>
              <CurrentMissesList
                status={missesNotification}
                onClick={toggleMenu}
              >
                <CurrentMissesListText>
                  {currentMissesList.length}
                </CurrentMissesListText>
              </CurrentMissesList>
              {sortedMissedDaysDaysListStrings}.&nbsp;
              <LocalizedDate date={navDate}>
                {(LocalizedDate) => LocalizedDate.format('MMM YYYY')}
              </LocalizedDate>
            </EventTypeSectionEventItemSubInf>
          )}
        </EventTypeSectionDouble>
      )}
    </EventTypeSection>
  );
}

EventTypeSectionMissed.propTypes = {
  navDate: PropTypes.instanceOf(moment),
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  currentMissesList: PropTypes.arrayOf(PropTypes.object),
  onEventEdit: PropTypes.func,
  onEventDelete: PropTypes.func,
  markNotificationAsRead: PropTypes.func.isRequired,
  lastNotification: PropTypes.array,
  notifications: PropTypes.array,
  deleteNotification: PropTypes.func.isRequired,
  typeSection: PropTypes.string.isRequired,
};

EventTypeSectionMissed.defaultProps = {
  navDate: moment(),
  currentMissesList: null,
  notifications: [],
  lastNotification: [],
  onEventDelete: () => {},
  onEventEdit: () => {},
};

function EventTypeSectionTasks({
  navDate,
  typeSection,
  title,
  currentTaskList,
  markNotificationAsRead,
  lastNotification,
  notifications,
  deleteNotification,
}) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  useEffect(() => {
    if (currentTaskList && currentTaskList.length <= 3) {
      setMenuIsOpen(true);
    } else {
      setMenuIsOpen(false);
    }
  }, [currentTaskList]);
  function toggleMenu() {
    setMenuIsOpen(!menuIsOpen);
  }
  const tasksNotification = currentTaskList
    .map(
      (item) => notifications && notifications.find((not) => not.instance_id === item.pk),
    )
    .find((not) => not);

  const hasTaskEvents = currentTaskList && currentTaskList.length > 0;
  let sortedTaskDays = currentTaskList;
  let sortedTaskDaysDaysListStrings = sortedTaskDays;

  if (hasTaskEvents) {
    sortedTaskDays = currentTaskList.sort((a, b) => {
      if (moment(a.deadline).isBefore(moment(b.deadline), 'day')) {
        return -1;
      }

      if (moment(a.deadline).isAfter(moment(b.deadline), 'day')) {
        return 1;
      }

      return 0;
    });
    sortedTaskDaysDaysListStrings = sortedTaskDays.reduce(
      (currentDay, nextDay, currentIndex) => `${currentDay}${currentIndex > 0 ? '.,' : ''}  ${moment(
        nextDay.deadline,
      )
        .utc()
        .format('D')}`,
      ' ',
    );
  }

  return (
    <EventTypeSection>
      <EventTypeSectionHeader>
        <Paragraph
          title={(
            <ParagraphText>
              <CircleBadge typeSection={typeSection} />
              {title}
              {hasTaskEvents && (
                <>
                  {menuIsOpen ? (
                    <EditButton onClick={toggleMenu}>
                      <FontAwesomeIcon icon={faEyeSlash} />
                    </EditButton>
                  ) : (
                    <EditButton onClick={toggleMenu}>
                      <FontAwesomeIcon icon={faEye} />
                    </EditButton>
                  )}
                </>
              )}
            </ParagraphText>
)}
        />
      </EventTypeSectionHeader>
      {hasTaskEvents && (
        <EventTypeSectionDouble>
          {menuIsOpen && (
            <EditMissList>
              {sortedTaskDays.map((event) => (
                <EventTypeItemTask
                  markNotificationAsRead={markNotificationAsRead}
                  key={event.pk}
                  event={event}
                  lastNotification={lastNotification}
                  notifications={notifications}
                  deleteNotification={deleteNotification}
                />
              ))}
            </EditMissList>
          )}
          {!menuIsOpen && (
            <EventTypeSectionEventItemSubInf>
              <CurrentMissesList
                status={tasksNotification}
                onClick={toggleMenu}
              >
                <CurrentMissesListText>
                  {currentTaskList.length}
                </CurrentMissesListText>
              </CurrentMissesList>
              {sortedTaskDaysDaysListStrings}.&nbsp;
              <LocalizedDate date={navDate}>
                {(LocalizedDate) => LocalizedDate.format('MMM YYYY')}
              </LocalizedDate>
            </EventTypeSectionEventItemSubInf>
          )}
        </EventTypeSectionDouble>
      )}
    </EventTypeSection>
  );
}

EventTypeSectionTasks.propTypes = {
  navDate: PropTypes.instanceOf(moment),
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  currentTaskList: PropTypes.arrayOf(PropTypes.object),
  markNotificationAsRead: PropTypes.func.isRequired,
  lastNotification: PropTypes.array,
  notifications: PropTypes.array,
  deleteNotification: PropTypes.func.isRequired,
  typeSection: PropTypes.string.isRequired,
};

EventTypeSectionTasks.defaultProps = {
  navDate: moment(),
  currentTaskList: null,
  notifications: [],
  lastNotification: [],
};

function EventTypeSectionFactory(props) {
  const section = {
    [TYPE_SECTION.meet]: <EventTypeSectionMeet {...props} />,
    [TYPE_SECTION.missed]: <EventTypeSectionMissed {...props} />,
    [TYPE_SECTION.tasks]: <EventTypeSectionTasks {...props} />,
    [TYPE_SECTION.onsite]: <EventTypeSectionMeet {...props} />,
  };

  return section[props.typeSection];
}

EventTypeSectionFactory.propTypes = {
  typeSection: PropTypes.string.isRequired,
};

export default EventTypeSectionFactory;
