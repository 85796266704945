import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import {
  BackButton, Error, Form, InputField, SecondaryButton,
} from 'shared';
import { login } from 'actions/authActions';
import AuthApi from 'api/AuthApi';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect, withRouter } from 'react-router-dom';
import { parse } from 'qs';
import { Container, Tip } from './ForgotPasswordStyled';

const ForgotPassword = ({ location, isAuthenticated }) => {
  const [sent, setSent] = useState(false);
  const handleSubmit = async ({ email }, formik) => {
    await AuthApi.forgotPassword(email);
    setSent(true);
    formik.resetForm();
  };

  if (isAuthenticated) {
    const { next } = parse(location.search.slice(1));
    return <Redirect to={next} />;
  }

  if (sent) {
    return (
      <>
        <BackButton backTo="/login" />
        <Container>
          <Localize id="we-have-sent-you-e-mail-link-reset-password-please" />
        </Container>
      </>
    );
  }

  return (
    <>
      <BackButton backTo="/login" />
      <Form
        initialValues={{
          email: '',
        }}
        validateSchema={{
          email: {
            required: true,
          },
        }}
        onSubmit={handleSubmit}
      >
        {(form) => (
          <Localize>
            {({ translate }) => (
              <Container>
                {form.errors._error && <Error message={form.errors._error} />}
                <Tip>
                  <Localize id="forgotten-your-password-enter-your-e-mail-address" />
                </Tip>
                <InputField
                  name="email"
                  autoComplete="username"
                  rounded
                  placeholder={translate('e-mail')}
                />
                <SecondaryButton
                  id="reset"
                  type="submit"
                  disabled={!form.dirty}
                  loading={form.isSubmitting}
                >
                  {translate('reset-my-password')}
                </SecondaryButton>
              </Container>
            )}
          </Localize>
        )}
      </Form>
    </>
  );
};

ForgotPassword.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = ({ currentUser: { data } }) => ({
  isAuthenticated: !!data,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { login }),
)(ForgotPassword);
