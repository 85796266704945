'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactDropzone = require('react-dropzone');

var _reactDropzone2 = _interopRequireDefault(_reactDropzone);

var _Button = require('../Button');

var _Button2 = _interopRequireDefault(_Button);

var _camera = require('../../styles/images/camera.svg');

var _camera2 = _interopRequireDefault(_camera);

var _uploadDocument = require('../../styles/images/upload-document.svg');

var _uploadDocument2 = _interopRequireDefault(_uploadDocument);

var _Localize = require('../Localize');

var _Localize2 = _interopRequireDefault(_Localize);

var _FileUploaderStyled = require('./FileUploaderStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FileUploader = function FileUploader(_ref) {
  var files = _ref.files,
      onFileAdd = _ref.onFileAdd,
      onFileDelete = _ref.onFileDelete,
      fileListOnTop = _ref.fileListOnTop,
      disabled = _ref.disabled;

  var dropzoneRef = (0, _react.createRef)();
  var onOpenDialog = function onOpenDialog(e) {
    if (e) {
      e.preventDefault();
    }
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };
  var onDrop = function onDrop(files) {
    return onFileAdd(files);
  };
  return _react2.default.createElement(
    _FileUploaderStyled.FileUploaderContainer,
    { fileListOnTop: fileListOnTop },
    !disabled && _react2.default.createElement(
      _reactDropzone2.default,
      {
        noClick: true,
        noKeyboard: true,
        accept: 'image/*, .pdf, .doc, .docx, .rtf, .odt, .ppt, .pptx, .rtf, .xls, .xlsx',
        ref: dropzoneRef,
        onDrop: onDrop
      },
      function (_ref2) {
        var getInputProps = _ref2.getInputProps,
            getRootProps = _ref2.getRootProps;
        return _react2.default.createElement(
          _FileUploaderStyled.UploadBox,
          _extends({}, getRootProps(), { fileListOnTop: fileListOnTop }),
          _react2.default.createElement('input', getInputProps()),
          _react2.default.createElement(
            _FileUploaderStyled.UploadBoxTitle,
            null,
            _react2.default.createElement(_Localize2.default, { id: 'drag-files-here-upload' })
          ),
          _react2.default.createElement(
            _FileUploaderStyled.UploadBoxOr,
            null,
            _react2.default.createElement('hr', null),
            _react2.default.createElement(
              'span',
              null,
              _react2.default.createElement(_Localize2.default, { id: 'or' })
            ),
            _react2.default.createElement('hr', null)
          ),
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              _Button2.default,
              { icon: _camera2.default, onClick: onOpenDialog },
              _react2.default.createElement(_Localize2.default, { id: 'take-picture' })
            ),
            _react2.default.createElement(
              _Button2.default,
              { icon: _uploadDocument2.default, onClick: onOpenDialog },
              _react2.default.createElement(_Localize2.default, { id: 'choose-file' })
            )
          ),
          _react2.default.createElement(
            _FileUploaderStyled.UploadBoxTip,
            null,
            _react2.default.createElement(_Localize2.default, { id: 'maximum-file-size' }),
            ': 10 MB'
          )
        );
      }
    ),
    _react2.default.createElement(_FileUploaderStyled.StyledFileList, {
      files: files,
      onFileDelete: onFileDelete,
      disabled: disabled
    })
  );
};

FileUploader.propTypes = process.env.NODE_ENV !== "production" ? {
  files: _propTypes2.default.arrayOf(_propTypes2.default.object).isRequired,
  onFileAdd: _propTypes2.default.func.isRequired,
  onFileDelete: _propTypes2.default.func.isRequired,
  fileListOnTop: _propTypes2.default.bool,
  disabled: _propTypes2.default.bool
} : {};

FileUploader.defaultProps = {
  fileListOnTop: false,
  disabled: false
};

exports.default = FileUploader;
module.exports = exports['default'];