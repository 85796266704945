import React, {
  useEffect, useMemo, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import { NoNotificationsText } from 'components/BellNotification/BellNotificationStyled';
import NotificationList from 'components/BellNotification/NotificationList/NotificationList';
import { connect } from 'react-redux';
import {
  getUserNotifications,
  markNotificationAsRead,
} from 'actions/notificationsActions';
import { KIND } from 'api/NotificationApi';
import { isSpecialTaskEvent } from 'reducers/notificationsReducer';
import Localize from 'components/Localize';
import { roles, actions } from 'utils/access-rules/constants';
import AbilitiesService from 'utils/access-rules/abilities';
import { section } from 'utils/access-rules/rules/notifications';
import { filterByInternship } from 'reducers/reducersUtils';
import { StyledBellNotification } from './NotificationStyled';
// import {
//   getChatLink,
//   getSpecialTaskLink,
//   getOLTLink,
//   getInternshipReportLink,
//   getAssessmentLink,
// } from './generateSourceLink';

const Notification = ({
  getUserNotifications,
  markNotificationAsRead,
  notifications,
  role,
  // applicants,
  isUserDropdownOpen,
  isDropdownOpen,
  onVisibilityChange,
  selectedInternshipId,
  notificationsLoading,
}) => {
  const [limit, setLimit] = useState(10);
  const stableGetUserNotifications = useCallback(
    (limit) => getUserNotifications(limit),
    [],
  );
  useEffect(() => {
    if (notificationsLoading) return;
    stableGetUserNotifications(limit);
  }, [stableGetUserNotifications]);

  const onLoadMore = () => {
    const newLimit = limit + 10;
    stableGetUserNotifications(newLimit);
    setLimit(newLimit);
  };

  const getLink = (notification) => {
    if (role === roles.Applicant) {
      if (notification.kind === KIND.CALENDAR_EVENT) {
        return selectedInternshipId
          ? `/calendar/${selectedInternshipId}`
          : '/calendar';
      }
      if (
        notification.kind === KIND.CHAT_MESSAGE
        || notification.kind === KIND.CHAT_MESSAGE_HAS_BEEN_READ
      ) {
        return selectedInternshipId ? `/chat/${selectedInternshipId}` : '/chat';
      }
      if (notification.kind === KIND.CONTRACT_UPLOADED) {
        return '/applicant-documents';
      }
      if (isSpecialTaskEvent(notification.kind)) {
        return selectedInternshipId
          ? `/applicant/${selectedInternshipId}`
          : '/applicant';
      }
      if (notification.kind === KIND.INTERNSHIP_REPORT_UNSUBMITTED) {
        return selectedInternshipId
          ? `/internship-report/${selectedInternshipId}`
          : '/internship-report';
      }
      if (notification.kind === KIND.TEACHER_ASSESSMENT_UNSUBMITTED) {
        return selectedInternshipId
          ? `/your-assessment/${selectedInternshipId}`
          : '/your-assessment';
      }
      if (
        notification.kind === KIND.TEACHER_ADDED_OPERATIONAL_LEARNING_TASK
        || notification.kind === KIND.TEACHER_UNSUBMITTED_OLT_REPORT
      ) {
        return selectedInternshipId
          ? `/learning-task/${selectedInternshipId}`
          : '/learning-task';
      }
    } else if (
      notification.kind === KIND.CHAT_MESSAGE
      || notification.kind === KIND.CHAT_MESSAGE_HAS_BEEN_READ
    ) {
      if (notification.applicant) {
        return notification.internship
          ? `/es/applicant-profile/${notification.applicant.pk}/${notification.internship.pk}?chat-id=${notification.sender.pk}#chat`
          : `/es/applicant-profile/${notification.applicant.pk}?chat-id=${notification.sender.pk}#chat`;
      }
      return undefined;
    }
    // if (notification.kind === KIND.CALENDAR_EVENT) {
    //   if (
    //     notification.instance
    //     && notification.instance.visibility === 'ONLY_ME'
    //   ) {
    //     return '/applicants#calendar';
    //   }
    //   return '/calendar';
    // }
    // if (isSpecialTaskEvent(notification.kind)) {
    //   return getSpecialTaskLink(notification.sender, notification.internship);
    // }
    // if (notification.kind === KIND.CHAT_MESSAGE || notification.kind === KIND.CHAT_MESSAGE_HAS_BEEN_READ) {
    //   return getChatLink(
    //     notification.sender,
    //     notification.internship,
    //     applicants,
    //   );
    // }
    // if (
    //   notification.kind === KIND.APPLICANT_SUBMITTED_OLT_REPORT
    //   || notification.kind === KIND.TEACHER_ADDED_OPERATIONAL_LEARNING_TASK
    // ) {
    //   return getOLTLink(notification.sender, notification.internship);
    // }
    // if (
    //   notification.kind === KIND.APPLICANT_SUBMITTED_INTERNSHIP_REPORT
    //   || notification.kind === KIND.INTERNSHIP_REPORT_UNSUBMITTED
    // ) {
    //   return getInternshipReportLink(
    //     notification.sender,
    //     notification.internship,
    //   );
    // }
    // if (notification.kind === KIND.APPLICANT_SUBMITTED_ASSESSMENT) {
    //   return getAssessmentLink(notification.sender, notification.internship);
    // }
    return undefined;
  };
  const unread = notifications.filter(({ read_at }) => read_at == null);

  const handleBellClose = () => {
    // mark as read on close to be able user see which one in unread during opens.
    Promise.all(unread.map(({ pk }) => markNotificationAsRead(pk)));
  };

  const stableGetLink = useCallback(getLink, [notifications]);
  const notificationsWithLink = useMemo(
    () => notifications.map((notification) => ({
      ...notification,
      to: stableGetLink(notification),
    })),
    [stableGetLink, notifications],
  );

  return (
    <StyledBellNotification
      hasUnread={unread.length > 0}
      onClose={handleBellClose}
      isUserDropdownOpen={isUserDropdownOpen}
      isDropdownOpen={isDropdownOpen}
      onVisibilityChange={onVisibilityChange}
    >
      {(close) => notifications.length > 0 ? (
        <NotificationList
          loading={notificationsLoading}
          userRole={role}
          notifications={notificationsWithLink}
          onClick={close}
          markNotificationAsRead={markNotificationAsRead}
          onLoadMore={onLoadMore}
        />
      ) : (
        <NoNotificationsText>
          <Localize id="you-dont-have-any-notifications" />
        </NoNotificationsText>
      )}
    </StyledBellNotification>
  );
};

Notification.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
  getUserNotifications: PropTypes.func.isRequired,
  markNotificationAsRead: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  notificationsLoading: PropTypes.bool.isRequired,
  // applicants: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     pk: PropTypes.number.isRequired,
  //     esuser: PropTypes.shape({
  //       internships: PropTypes.arrayOf(
  //         PropTypes.shape({
  //           pk: PropTypes.number,
  //         }),
  //       ),
  //     }),
  //   }),
  // ),
  isUserDropdownOpen: PropTypes.bool.isRequired,
  isDropdownOpen: PropTypes.bool.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  selectedInternshipId: PropTypes.number,
};

Notification.defaultProps = {
  notifications: [],
  // applicants: [],
  selectedInternshipId: null,
};

const mapStateToProps = ({
  notifications: _notifications,
  currentUser: { data },
  selectedInternship,
}) => {
  const ability = new AbilitiesService();
  let notifications;
  if (ability.can(actions.see, section.allNotifications)) {
    notifications = _notifications.data;
  } else {
    notifications = filterByInternship(
      selectedInternship && selectedInternship.pk,
      _notifications.data,
    );
  }
  return {
    notifications,
    notificationsLoading: _notifications.loading,
    role: data.role,
    selectedInternshipId: selectedInternship && selectedInternship.pk,
  };
};

export default connect(mapStateToProps, {
  getUserNotifications,
  markNotificationAsRead,
})(Notification);
