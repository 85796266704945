import React from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import { ReactComponent as FilterIcon } from 'shared/lib/styles/images/filter.svg';
import {
  FilterPanel,
  FilterPanelBody,
  SearchInputStyled,
  SelectStyled,
  SelectStyledContainer,
} from './YourApplicationsStyled';
import StatusesProvider from './Application/Status/StatusesProvider';

const Filter = ({ filter, onTextChanged, onStatusChanged }) => (
  <FilterPanel>
    <FilterIcon />
    <FilterPanelBody>
      <StatusesProvider>
        {(statuses) => {
          const options = statuses.map(({ key, value }) => ({
            value: key,
            label: value,
          }));
          return (
            <SelectStyledContainer>
              <SelectStyled
                clearIndicator
                onChange={onStatusChanged}
                value={options.find(({ value }) => value === filter.status)}
                options={options}
                placeholder={<Localize id="status" />}
              />
            </SelectStyledContainer>
          );
        }}
      </StatusesProvider>
      <SearchInputStyled value={filter.text} onChange={onTextChanged} />
    </FilterPanelBody>
  </FilterPanel>
);

Filter.propTypes = {
  onTextChanged: PropTypes.func.isRequired,
  onStatusChanged: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    status: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

export default Filter;
