import React, { useEffect } from 'react';
import { setSelectedInternship } from 'actions/selectedInternshipActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InternshipSwitcherContainer from 'pages/Applicant/InternshipSwitcherContainer';
import { refreshIframe } from 'actions/iframeActions';
import { Main, FooterWrapper } from './LayoutStyled';
import Header from './Header';

const Layout = ({
  children,
  refreshIframe,
  setSelectedInternship,
  history,
}) => {
  const pathArray = history.location.pathname.split('/');
  const selectedInternshipId = pathArray[2] ? +pathArray[2] : null;
  useEffect(() => {
    setSelectedInternship({ pk: selectedInternshipId });
  }, [setSelectedInternship, selectedInternshipId]);
  return (
    <>
      <Header />
      <Main>
        <>
          <InternshipSwitcherContainer />
          {children}
        </>
      </Main>
      <FooterWrapper updateIframe={() => refreshIframe()} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  refreshIframe: PropTypes.func.isRequired,
  setSelectedInternship: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default connect(
  null,
  { refreshIframe, setSelectedInternship },
)(withRouter(Layout));
