import React, { useState } from 'react';

import Page from 'components/Page';
import Localize from 'components/Localize';
import InternshipSelectionProvider from 'components/InternshipSwitcher/InternshipSelectionProvider';
import { ActiveLanguageProvider } from 'pages/LocalizeProvider';
import InformationModal from 'components/InformationModal';
import { ReactComponent as InfoIcon } from 'styles/images/svg/info.svg';
import InternshipReportContent from './InternshipReportContent';

const InternshipReport = () => {
  const [informationModalOpen, setInformationModalOpen] = useState('');

  return (
    <>
      <InformationModal
        open={informationModalOpen}
        onCloseModal={() => setInformationModalOpen('')}
      />
      <InternshipSelectionProvider>
        {({ selectedInternshipId }) => (
          <Page
            title={<Localize id="your-internship-report" />}
            backTo={`/applicant/${selectedInternshipId || ''}`}
            description={
              <Localize id="here-you-can-create-report-each-day-internship" />
            }
            icon={<InfoIcon />}
            onClick={() => setInformationModalOpen('INTERNSHIP_REPORT')}
          >
            <ActiveLanguageProvider>
              {({ code }) => (
                <InternshipReportContent
                  internshipId={selectedInternshipId}
                  language={code}
                />
              )}
            </ActiveLanguageProvider>
          </Page>
        )}
      </InternshipSelectionProvider>
    </>
  );
};

export default InternshipReport;
