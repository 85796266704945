'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _SpinStyled = require('./SpinStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Spin = function Spin(_ref) {
  var className = _ref.className;
  return _react2.default.createElement(
    _SpinStyled.SpinContainer,
    null,
    _react2.default.createElement(_SpinStyled.SpinIndicator, { className: className })
  );
};

Spin.propTypes = process.env.NODE_ENV !== "production" ? {
  className: _propTypes2.default.string
} : {};

Spin.defaultProps = {
  className: undefined
};

exports.default = Spin;
module.exports = exports['default'];