import styled from 'styled-components';
import { Loader as _Loader } from 'shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Loader = styled(_Loader)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`;

export const LoaderWrap = styled.li`
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }

  & .spin {
    margin-top: -36px;
  }
`;

export const NoRespondedIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.white};
  position: absolute;
  right: 6px;
  top: 6px;
  font-size: 12px;
`;
