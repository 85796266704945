import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Page from 'components/Page';
import PrimaryUserData from 'components/UserPrimaryData';
import { updateUser } from 'actions/userActions';

const Settings = ({ user, updateUser }) => (
  <Page>
    <PrimaryUserData
      pk={user.pk}
      avatar={user.avatar}
      userFirstName={user.first_name}
      userLastName={user.last_name}
      birthday={user.birthday}
      phone={user.phone && user.phone.number}
      email={user.email}
      location={user.location}
      onClickEdit={() => {}}
      updateUser={updateUser}
    />
  </Page>
);

Settings.propTypes = {
  user: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    birthday: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.object,
    location: PropTypes.string,
    avatar: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
  updateUser: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  currentUser: { data },
  application: { advisorsData },
}) => ({
  user: {
    pk: data && data.pk,
    first_name: data && data.user && data.user.first_name,
    last_name: data && data.user && data.user.last_name,
    avatar: data && data.avatar,
    email: data && data.user && data.user.email,
    birthday: data && data.birthday,
    phone: data && data.phone,
    location: data && data.location,
  },
  advisorsData,
});

export default connect(
  mapStateToProps,
  { updateUser },
)(Settings);
