'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _formik = require('formik');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Localize = require('../Localize');

var _Localize2 = _interopRequireDefault(_Localize);

var _reactRouterDom = require('react-router-dom');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }
// import in phone component
// import 'libphonenumber-js-utils';


var isTasksInValid = function isTasksInValid(tasks) {
  var isInValid = false;
  tasks.map(function (t) {
    if (!t.description && !t.toDelete) {
      isInValid = true;
    }
  });
  return isInValid;
};

var validateFor = function validateFor(schema, translate) {
  return function (values) {
    var errors = {};
    Object.keys(schema).forEach(function (field) {
      if (!values[field]) {
        var required = schema[field].required;

        if (typeof required === 'function') {
          required = required(values);
        }
        if (required) {
          errors[field] = translate('this-field-is-required');
        }
      } else {
        if (schema[field].email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9]{2,}$/i.test(values[field])) {
          errors[field] = translate('invalid-email-address');
        }
        if (schema[field].same && values[field] !== values[schema[field].same]) {
          errors[field] = translate('passwords-must-match');
        }
        if (schema[field].lessDateThan && schema[field].lessDateThan(values) && new Date(values[field]) > new Date(schema[field].lessDateThan(values))) {
          errors[field] = translate('should-be-less-than-end-date');
        }
        if (schema[field].taskValidation && isTasksInValid(values.tasks)) {
          errors[field] = translate('this-field-is-required');
        }
        if (schema[field].minLength && values[field].length < schema[field].minLength) {
          errors[field] = translate('field-should-have-least-n-characters', {
            n: schema[field].minLength
          });
        }
        if (schema[field].maxLength && values[field].length > schema[field].maxLength) {
          errors[field] = translate('field-should-be-less-n-characters', {
            n: schema[field].maxLength
          });
        }
        if (schema[field].phone) {
          if (!/^\+?\d+$/.test(values[field])) {
            errors[field] = translate('only-digits-are-allowed');
          }
        }
        if (schema[field].requiredAttachments && values[field].length === 0) {
          errors[field] = translate('least-one-image-should-be-uploaded');
        }
      }
    });
    return errors;
  };
};

var handleSubmitFor = function handleSubmitFor(onSubmitCustom, translate) {
  return function (values, formik) {
    if (onSubmitCustom) {
      onSubmitCustom(values, formik).then(function () {
        formik.setSubmitting(false);
        formik.resetForm();
      }).catch(function (error) {
        formik.setSubmitting(false);
        var errors = {};
        if (error.message) {
          errors._error = Array.isArray(error.message) ? error.message[0] : error.message;
          formik.setErrors(errors);
          return;
        }
        if (Object.values(error) && Object.values(error)[0]) {
          errors = {
            _error: Object.values(error)[0]
          };
          formik.setErrors(errors);
          return;
        }
        errors._error = translate('request-failed');
        formik.setErrors(errors);
      });
    }
  };
};

var Form = function Form(_ref) {
  var validateSchema = _ref.validateSchema,
      onSubmit = _ref.onSubmit,
      children = _ref.children,
      noPrompt = _ref.noPrompt,
      props = _objectWithoutProperties(_ref, ['validateSchema', 'onSubmit', 'children', 'noPrompt']);

  return _react2.default.createElement(
    _Localize2.default,
    null,
    function (_ref2) {
      var translate = _ref2.translate;
      return _react2.default.createElement(
        _formik.Formik,
        _extends({
          validate: validateFor(validateSchema, translate),
          onSubmit: handleSubmitFor(onSubmit, translate)
        }, props),
        function (_ref3) {
          var handleSubmit = _ref3.handleSubmit,
              rest = _objectWithoutProperties(_ref3, ['handleSubmit']);

          return _react2.default.createElement(
            'form',
            { onSubmit: handleSubmit },
            !noPrompt && _react2.default.createElement(_reactRouterDom.Prompt, {
              when: rest.dirty,
              message: translate('you-have-unsaved-changes')
            }),
            children(_extends({}, rest))
          );
        }
      );
    }
  );
};

Form.propTypes = process.env.NODE_ENV !== "production" ? {
  children: _propTypes2.default.func.isRequired,
  noPrompt: _propTypes2.default.bool,
  onSubmit: _propTypes2.default.func,
  validateSchema: _propTypes2.default.object,
  initialValues: _propTypes2.default.object,
  enableReinitialize: _propTypes2.default.bool
} : {};

Form.defaultProps = {
  validateSchema: {},
  initialValues: {},
  onSubmit: null,
  enableReinitialize: false,
  noPrompt: false
};

exports.default = Form;
module.exports = exports['default'];