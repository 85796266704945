'use strict';

exports.__esModule = true;
exports.default = undefined;

var _LanguageSwitcher = require('./LanguageSwitcher');

var _LanguageSwitcher2 = _interopRequireDefault(_LanguageSwitcher);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _LanguageSwitcher2.default;
module.exports = exports['default'];