'use strict';

exports.__esModule = true;
exports.default = undefined;

var _LeftNavButton = require('./LeftNavButton');

var _LeftNavButton2 = _interopRequireDefault(_LeftNavButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _LeftNavButton2.default;
module.exports = exports['default'];