import styled from 'styled-components';
import { device, size } from 'styles/device';

export const ContainerWrap = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  z-index: 2;
  /*@media ${device.laptop} {
    max-width: ${size.laptop};
  }
  @media ${device.tablet} {
    max-width: ${size.tablet};
  }
  @media ${device.mobile} {
    max-width: ${size.mobile};
  }*/
`;
