'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _formik = require('formik');

var _GroupInput = require('./GroupInput');

var _GroupInput2 = _interopRequireDefault(_GroupInput);

var _TextInput = require('../TextInput');

var _TextInput2 = _interopRequireDefault(_TextInput);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var InputField = function InputField(_ref) {
  var component = _ref.component,
      withBottomMargin = _ref.withBottomMargin,
      props = _objectWithoutProperties(_ref, ['component', 'withBottomMargin']);

  return _react2.default.createElement(_formik.Field, _extends({}, props, {
    component: _GroupInput2.default,
    innerComponent: component,
    withBottomMargin: withBottomMargin
  }));
};

InputField.propTypes = process.env.NODE_ENV !== "production" ? {
  name: _propTypes2.default.string.isRequired,
  component: _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.object]),
  withBottomMargin: _propTypes2.default.bool
} : {};

InputField.defaultProps = {
  component: _TextInput2.default,
  withBottomMargin: true
};

exports.default = InputField;
module.exports = exports['default'];