import React, { useEffect, useCallback } from 'react';
import checkIcon from 'shared/lib/styles/images/check.svg';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Localize from 'components/Localize';
import {
  Form, InputField, Button, Error,
} from 'shared';
import Textarea from 'components/Form/Textarea';
import InternshipSelectionProvider from 'components/InternshipSwitcher/InternshipSelectionProvider';
import {
  getComment,
  changeComment,
} from '../../actions/internshipCommentActions';
import {
  ButtonsContainer,
  CommentTitle,
  CommentWrapper,
} from './InternshipCommentStyled';

const InternshipComment = ({
  loading,
  error,
  comment,
  getComment: _getComment,
  changeComment: _changeComment,
  user,
  history,
  internshipId,
}) => {
  const stableGetComment = useCallback(_getComment, [internshipId]);
  useEffect(() => {
    stableGetComment(internshipId);
  }, [stableGetComment, internshipId]);
  const onSubmit = (data) => _changeComment(internshipId, data).then(() => {
    if (user.role === 'APPLICANT') {
      history.push(`/applicant/${internshipId || ''}`);
    }
  });
  return (
    <>
      <InternshipSelectionProvider>
        {() => (
          <Localize>
            {({ translate }) => (
              <Form
                initialValues={{
                  comment,
                }}
                enableReinitialize
              >
                {({ values, isSubmitting, dirty }) => {
                  const submit = () => onSubmit(values);
                  return (
                    <div>
                      <CommentWrapper>
                        <CommentTitle>
                          <Localize id="write-your-comment-here" />
                        </CommentTitle>
                        <InputField
                          rows={2}
                          name="comment"
                          placeholder={translate('comment')}
                          component={Textarea}
                        />
                      </CommentWrapper>
                      <ButtonsContainer>
                        {error && <Error />}
                        <Button
                          icon={checkIcon}
                          loading={isSubmitting || loading}
                          disabled={!dirty}
                          onClick={submit}
                          type="button"
                          className="btn-submit"
                          disableBoxShadow
                          color="secondary"
                          size="small"
                        >
                          <Localize id="save" />
                        </Button>
                      </ButtonsContainer>
                    </div>
                  );
                }}
              </Form>
            )}
          </Localize>
        )}
      </InternshipSelectionProvider>
    </>
  );
};

InternshipComment.propTypes = {
  error: PropTypes.shape({
    detail: PropTypes.string,
  }),
  user: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  comment: PropTypes.string,
  answerChoices: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  getComment: PropTypes.func.isRequired,
  changeComment: PropTypes.func.isRequired,
  internshipId: PropTypes.number.isRequired,
};

InternshipComment.defaultProps = {
  error: null,
  comment: null,
  answerChoices: null,
};

const mapStateToProps = ({
  comment: { data, loading, error },
  currentUser,
  selectedInternship,
}) => ({
  user: currentUser && currentUser.data,
  loading,
  error,
  comment: data && data.comment,
  selectedInternship,
});

export default connect(mapStateToProps, {
  getComment,
  changeComment,
})(InternshipComment);
