"use strict";

exports.__esModule = true;
var toBase64 = exports.toBase64 = function toBase64(file) {
  return new Promise(function (resolve, reject) {
    if (!file) {
      reject();
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      return resolve({
        name: file.name,
        type: file.type,
        base64: reader.result
      });
    };
    reader.onerror = function (error) {
      return reject(error);
    };
  });
};

var toBlob = exports.toBlob = function toBlob(base64) {
  return fetch(base64).then(function (res) {
    return res.blob();
  });
};