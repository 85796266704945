import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from 'pages/Layout';
import Todo from 'pages/Applicant/Todo';
import Calendar from 'pages/CalendarPage';
import ThemeProvider from 'pages/ThemeProvider';
import Settings from 'pages/Settings';
import ManageStudents from 'pages/ManageStudents';
import Container from 'components/Container';
import Chat from 'pages/Chat';
import InternshipReport from 'pages/InternshipReport';
import Login from 'pages/Login';
import ForgotPassword from 'pages/ForgotPassword';
import NoMatch from 'pages/NoMatch';
import Authenticate from 'pages/Authenticate';
import AuthenticateRoute from 'pages/Login/AuthenticateRoute';
import UserManagerPage from 'pages/UserManagerPage';
import ApplicantProfile from 'pages/ApplicantProfile/ApplicantProfileContainer';
import Can, { actions, CanRoute } from 'components/Can';
import { page } from 'utils/access-rules/rules/routes';
import YourApplications from 'pages/Applicant/YourApplications';
import Assessment from 'pages/Assessment';
import LearningTask from 'pages/Applicant/LearningTask';
import InternshipComment from 'pages/InternshipComment';
import Instructions from 'pages/Instructions';

function App() {
  return (
    <ThemeProvider>
      <Authenticate>
        <Layout>
          <Container>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <AuthenticateRoute>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => (
                      <>
                        <Can I={actions.see} a={page.Todo}>
                          <Redirect to="/applicant/:internshipId?" />
                        </Can>
                        <Can I={actions.see} a={page.Applicants}>
                          <Redirect to="/applicants" />
                        </Can>
                      </>
                    )}
                  />
                  <CanRoute
                    to={page.ManageStudents}
                    path="/manage-students"
                    component={ManageStudents}
                  />
                  <CanRoute
                    to={page.Instructions}
                    path="/instructions"
                    component={Instructions}
                  />
                  {/* Applicant routes */}
                  <CanRoute
                    to={page.Todo}
                    path="/applicant/:internshipId?"
                    component={Todo}
                  />
                  <CanRoute
                    to={page.Settings}
                    path="/settings/:internshipId?"
                    component={Settings}
                  />
                  <CanRoute
                    to={page.Calendar}
                    path="/calendar/:internshipId?"
                    component={Calendar}
                  />
                  <CanRoute
                    to={page.Chat}
                    path="/chat/:internshipId?"
                    component={Chat}
                  />
                  <CanRoute
                    to={page.InternshipReport}
                    path="/internship-report/:internshipId?"
                    component={InternshipReport}
                  />
                  <CanRoute
                    to={page.InternshipComment}
                    path="/internship-comment/:internshipId?"
                    component={InternshipComment}
                  />
                  <CanRoute
                    to={page.YourApplications}
                    path="/your-applications/:internshipId?"
                    component={YourApplications}
                  />
                  <CanRoute
                    to={page.Assessment}
                    path="/your-assessment/:internshipId?"
                    component={Assessment}
                  />
                  <CanRoute
                    to={page.LearningTask}
                    path="/learning-task/:internshipId?"
                    component={LearningTask}
                  />
                  {/* Teacher, Company, Education Provider routes */}
                  <CanRoute
                    to={page.Applicants}
                    path="/applicants"
                    component={UserManagerPage}
                  />
                  <CanRoute
                    to={page.ApplicantProfile}
                    path="/applicant-profile/:applicantId/:internshipId?"
                    component={ApplicantProfile}
                  />
                  <Route component={NoMatch} />
                </Switch>
              </AuthenticateRoute>
            </Switch>
          </Container>
        </Layout>
      </Authenticate>
    </ThemeProvider>
  );
}

export default hot(App);
