import React from 'react';
import PropTypes from 'prop-types';
import Container from 'components/Container';
import Localize from 'components/Localize';
import { roles } from 'utils/access-rules/constants';
import { getChooseInternshipText } from 'pages/ApplicantProfile/applicantProfileUtils';
import {
  InternshipSwitcherWrap,
  SelectStyled,
} from './InternshipSwitcherStyled';

const InternshipSwitcher = ({
  options,
  onChange,
  selectedInternshipId,
  userRole,
}) => (
  <Container>
    <InternshipSwitcherWrap className="internship-switcher">
      {userRole === roles.Applicant ? (
        <Localize id="internship-you-are-currently-work" />
      ) : (
        <Localize id={getChooseInternshipText(userRole)} />
      )}
      <SelectStyled
        options={options}
        onChange={onChange}
        value={options.find(({ value }) => value === selectedInternshipId)}
        defaultValue={options.length > 1 ? options[1] : options[0]}
      />
    </InternshipSwitcherWrap>
  </Container>
);

InternshipSwitcher.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedInternshipId: PropTypes.number,
  userRole: PropTypes.string,
};

InternshipSwitcher.defaultProps = {
  selectedInternshipId: null,
  userRole: null,
};

export default InternshipSwitcher;
