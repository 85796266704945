import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from './ActionModalStyled';

const ActionModal = ({ children, isOpen, onClose }) => (
  <Modal isOpen={isOpen} onClick={onClose}>
    {children}
  </Modal>
);

ActionModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

ActionModal.defaultProps = {
  children: null,
};

export default ActionModal;
