import React from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import arrows from 'styles/images/svg/arrows.svg';
import { ApplyButtonWrapper } from './ApplyButtonStyled';

const ApplyButton = ({ onClick }) => (
  <ApplyButtonWrapper
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
    rightIcon={arrows}
  >
    <Localize id="apply" />
  </ApplyButtonWrapper>
);

ApplyButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ApplyButton;
