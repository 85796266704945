import { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pusher from 'pusher-js';
import { EVENT, KIND } from 'api/NotificationApi';
import { addNotification } from 'actions/notificationsActions';
import {
  addMessageFromNotification,
  markMessageAsReadFromNotification,
} from 'actions/chatActions';

const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
const PUSHER_SSL = process.env.REACT_APP_PUSHER_SSL;

Pusher.logToConsole = process.env.NODE_ENV !== 'production';

const Subscriber = ({
  user,
  addNotification,
  addMessageFromNotification,
  markMessageAsReadFromNotification,
}) => {
  const stableMarkMessageAsReadFromNotification = useCallback(
    markMessageAsReadFromNotification,
    [user.uuid],
  );
  const stableAddNotification = useCallback(addNotification, [user.uuid]);
  const stableAddMessageFromNotification = useCallback(
    addMessageFromNotification,
    [user.uuid],
  );
  useEffect(() => {
    const pusher = new Pusher(PUSHER_KEY, {
      cluster: PUSHER_CLUSTER,
      forceTLS: PUSHER_SSL,
    });

    const channel = pusher.subscribe(user.uuid);
    channel.bind(EVENT.CREATE, (data) => {
      if (data.kind === KIND.CHAT_MESSAGE_HAS_BEEN_READ) {
        stableMarkMessageAsReadFromNotification(data.instance_id);
        return;
      }
      stableAddNotification(data);
      if (data.kind === KIND.CHAT_MESSAGE) {
        stableAddMessageFromNotification(data.instance);
      }
    });
    return () => {
      pusher.unsubscribe(user.uuid);
    };
  }, [
    stableMarkMessageAsReadFromNotification,
    stableAddNotification,
    stableAddMessageFromNotification,
    user.uuid,
  ]);
  return null;
};

Subscriber.propTypes = {
  addMessageFromNotification: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  user: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ currentUser: { data } }) => ({
  user: data,
});

export default connect(
  mapStateToProps,
  {
    addNotification,
    addMessageFromNotification,
    markMessageAsReadFromNotification,
  },
)(Subscriber);
