import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import Localize from 'components/Localize';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import TableColumnFilter from './TableColumnFilter';
import {
  TableColumn,
  TableColumnText,
  TableColumnName,
  TableColumnFilterIcon,
} from './TableStyled';

function Table(props) {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState({});
  const handleColumnClick = (name) => {
    setIsFilterModalOpen((isFilterModalOpen) => ({
      [name]: !isFilterModalOpen[name],
    }));
  };
  const {
    columns, columnFilters, setColumnFilters, data,
  } = props;
  const tableColumns = columns.map((column) => ({
    ...column,
    name: (
      <TableColumn>
        <TableColumnText
          onClick={
            column.filter ? () => handleColumnClick(column.name) : undefined
          }
        >
          <TableColumnName>
            <Localize id={column.name} />
          </TableColumnName>
          {column.filter && <TableColumnFilterIcon icon={faFilter} />}
        </TableColumnText>
        {column.filter && isFilterModalOpen[column.name] && (
          <TableColumnFilter
            options={column.filter}
            columnName={column.name}
            filters={columnFilters}
            setFilters={setColumnFilters}
            dataLength={data.length}
            closeFilter={() => setIsFilterModalOpen({})}
          />
        )}
      </TableColumn>
    ),
  }));
  return <DataTable {...props} columns={tableColumns} />;
}

Table.propTypes = {
  columnFilters: PropTypes.shape({}).isRequired,
  setColumnFilters: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default Table;
