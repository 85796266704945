import React, { useState, useRef } from 'react';

import documentIcon from 'styles/images/svg/document.svg';
import check from 'styles/images/svg/check.svg';
import moment from 'moment';
import {
  Button, Form, PlusIcon, DatePicker, InputField,
} from 'shared';
import Localize from 'components/Localize';
import { toBase64 } from 'shared/lib/lib/base64';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import CheckBox from 'components/Form/CheckBox';
import refreshIcon from 'styles/images/svg/refresh.svg';
import {
  ReportItemContainer,
  ReportContent,
  ReportEditableHeader,
  NoInternshipCheckBox,
  DataStatus,
  ItemStatus,
  ReportItemHeader,
  ChevronIcon,
  EditReportItemContainer,
  ButtonsContainer,
  Description,
} from './ReportDayStyled';
import { REPORT_ITEM_TYPES } from '../Report';
import ReportDayAttachments from '../ReportDayAttachments';
import ReportDayTasks from '../ReportDayTasks';

const initialState = {
  isEditable: false,
};

export const ReportItem = ({
  data, text, type, onChange, isSubmitted,
}) => {
  const [state, setState] = useState(initialState);
  const onShowHideEditableForm = (e) => {
    if (state.isEditable && e.target.closest('.editable-date')) {
      return;
    }
    setState({ ...state, isEditable: !state.isEditable });
  };
  const getHash = () => Math.random()
    .toString(36)
    .substring(7);

  const fileInputRef = useRef();
  const onSubmit = (day) => {
    if (type === REPORT_ITEM_TYPES.ADD_DAY) {
      const attachmentsToUpload = day.attachment_list
        .filter((p) => p.isNew && !p.toDelete)
        .map((attachment) => ({ file: attachment.file }));
      setState({ ...state, isEditable: false });
      return onChange(day, attachmentsToUpload);
    }
    const reducedDay = day;
    reducedDay.attachment_list = reducedDay.attachment_list.map((attachment) => ({
      file: attachment.file,
    }));
    if (type === REPORT_ITEM_TYPES.CONCLUSION) {
      setState({ ...state, isEditable: false });
      return onChange(data.pk, reducedDay);
    }
    setState({ ...state, isEditable: false });
    return onChange(data.pk, reducedDay);
  };
  const onUploadImageButtonClick = () => fileInputRef.current.click();
  const getStatusIcon = (completed) => {
    if (completed) {
      return (
        <ItemStatus className="icon" isActive>
          <FontAwesomeIcon icon={faCheck} />
        </ItemStatus>
      );
    }
    return <ReactSVG className="icon" src={refreshIcon} />;
  };
  return (
    <ReportItemContainer isEditable={state.isEditable}>
      <Form
        initialValues={{
          report:
            type === REPORT_ITEM_TYPES.CONCLUSION
              ? data.conclusion
              : data.report || '',
          date: data ? data.date : null,
          attachment_list: data.attachment_list || [],
          internship: data ? data.internship : null,
          tasks: data.tasks || [],
          no_internship_day: data.no_internship_day,
        }}
        onSubmit={onSubmit}
        validateSchema={{
          tasks: {
            taskValidation: true,
          },
        }}
      >
        {(form) => {
          const onFileUpload = (e) => toBase64(e.target.files[0]).then((file) => {
            const newFile = {
              pk: getHash(),
              image: file.base64,
              file: file.base64.split(',').pop(),
              isNew: true,
            };
            form.setFieldValue('attachment_list', [
              ...form.values.attachment_list,
              newFile,
            ]);
          });
          const onDeleteAttachment = (pk) => {
            const newFiles = form.values.attachment_list.filter(
              (attachment) => attachment.pk !== pk,
            );
            form.setFieldValue('attachment_list', newFiles);
          };
          return (
            <div>
              <ReportItemHeader
                isEditable={state.isEditable && !isSubmitted}
                onClick={onShowHideEditableForm}
                type={type}
              >
                <DataStatus>
                  <ReportContent
                    isEditable={state.isEditable && !isSubmitted}
                    className="editable-date"
                    type={type}
                  >
                    <div className="name">{text}</div>
                    {type !== REPORT_ITEM_TYPES.CONCLUSION
                      && (state.isEditable && !isSubmitted ? (
                        <ReportEditableHeader>
                          <InputField
                            name="date"
                            component={DatePicker}
                            withBottomMargin={false}
                          />
                          <NoInternshipCheckBox>
                            <InputField
                              component={CheckBox}
                              name="no_internship_day"
                            />
                            <div className="label">
                              <Localize id="today-was-no-internship-day" />
                            </div>
                          </NoInternshipCheckBox>
                        </ReportEditableHeader>
                      ) : (
                        <div className="date">
                          {data.date && moment(data.date).format('DD.MM.YYYY')}
                        </div>
                      ))}
                  </ReportContent>
                  {type === REPORT_ITEM_TYPES.DAY
                    && (!state.isEditable || isSubmitted)
                    && getStatusIcon(data.completed)}
                  {type === REPORT_ITEM_TYPES.CONCLUSION && (
                    <ItemStatus className="icon" isActive={data.conclusion}>
                      <FontAwesomeIcon icon={faCheck} />
                    </ItemStatus>
                  )}
                </DataStatus>
                {type === REPORT_ITEM_TYPES.ADD_DAY && !state.isEditable ? (
                  <PlusIcon />
                ) : (
                  <ChevronIcon
                    icon={faChevronDown}
                    iseditable={state.isEditable}
                  />
                )}
              </ReportItemHeader>
              {state.isEditable && (
                <EditReportItemContainer>
                  <div>
                    {type === REPORT_ITEM_TYPES.CONCLUSION ? (
                      <Localize>
                        {({ translate }) => (
                          <Description
                            component="textarea"
                            name="report"
                            value={form.values.report}
                            placeholder={
                              !isSubmitted
                              && translate(
                                'just-enter-your-report-here-and-add-pictures',
                              )
                            }
                            disabled={isSubmitted}
                          />
                        )}
                      </Localize>
                    ) : (
                      <ReportDayTasks
                        tasks={form.values.tasks}
                        disabled={isSubmitted}
                        meta={form}
                      />
                    )}
                    <div>
                      {form.values.attachment_list
                        && type !== REPORT_ITEM_TYPES.CONCLUSION && (
                          <ReportDayAttachments
                            attachments={form.values.attachment_list}
                            onDeleteAttachment={onDeleteAttachment}
                            disabled={isSubmitted}
                          />
                      )}
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={onFileUpload}
                      />
                      {!isSubmitted && (
                        <ButtonsContainer>
                          {type !== REPORT_ITEM_TYPES.CONCLUSION && (
                            <Button
                              type="button"
                              className="btn-add"
                              icon={documentIcon}
                              disableBoxShadow
                              size="small"
                              variant="outlined"
                              color="secondary"
                              onClick={onUploadImageButtonClick}
                            >
                              <Localize id="add-education-entry" />
                            </Button>
                          )}
                          <Button
                            type="summit"
                            className="btn-save"
                            icon={check}
                            disableBoxShadow
                            color="secondary"
                            size="small"
                            disabled={!form.dirty}
                          >
                            <Localize id="save" />
                          </Button>
                        </ButtonsContainer>
                      )}
                    </div>
                  </div>
                </EditReportItemContainer>
              )}
            </div>
          );
        }}
      </Form>
    </ReportItemContainer>
  );
};

ReportItem.propTypes = {
  data: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    text: PropTypes.string,
    date: PropTypes.string,
    report: PropTypes.string,
    completed: PropTypes.bool,
    api_url: PropTypes.string,
    conclusion: PropTypes.string,
    attachment_list: PropTypes.arrayOf(PropTypes.object),
    internship: PropTypes.string,
    tasks: PropTypes.array,
    no_internship_day: PropTypes.bool,
  }).isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['day', 'conclusion', 'add-day']).isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
};

export default ReportItem;
