'use strict';

exports.__esModule = true;
exports.FileInputLabel = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  display: inline-block;\n  position: relative;\n  //it is important to hide input[type=file] within this container.\n  overflow: hidden;\n\n  input[type=\'file\'] {\n    cursor: pointer;\n    font-size: 999px;\n    position: absolute;\n    right: 0;\n    top: 0;\n    opacity: 0;\n  }\n'], ['\n  display: inline-block;\n  position: relative;\n  //it is important to hide input[type=file] within this container.\n  overflow: hidden;\n\n  input[type=\'file\'] {\n    cursor: pointer;\n    font-size: 999px;\n    position: absolute;\n    right: 0;\n    top: 0;\n    opacity: 0;\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var FileInputLabel = exports.FileInputLabel = _styledComponents2.default.label(_templateObject);