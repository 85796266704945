import styled from 'styled-components';
import { Tooltip as _Tooltip } from 'react-tippy';

export const ChatTabPanelWrapper = styled.div`
  background-color: ${({ theme }) => theme.grayF3};
  display: flex;
  min-height: 73px;
  flex-wrap: wrap;
`;
export const ChatTabRole = styled.p`
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-left: 5px;
`;

export const Tooltip = styled(_Tooltip)`
  height: 73px;
`;
