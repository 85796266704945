'use strict';

exports.__esModule = true;

var _MediaQuery = require('./MediaQuery');

Object.defineProperty(exports, 'default', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_MediaQuery).default;
  }
});
Object.defineProperty(exports, 'Tablet', {
  enumerable: true,
  get: function get() {
    return _MediaQuery.Tablet;
  }
});
Object.defineProperty(exports, 'Mobile', {
  enumerable: true,
  get: function get() {
    return _MediaQuery.Mobile;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }