import mediaApi from 'api/MediaApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getAttachments() {
  return createAction({
    type: types.GET_ATTACHMENTS,
    callAPI: () => mediaApi.getAttachments(),
    shouldCallAPI: (state) => !state.attachments.data,
  });
}

export function getImages() {
  return createAction({
    type: types.GET_ATTACHMENTS,
    callAPI: () => mediaApi.getImages(),
    shouldCallAPI: (state) => !state.attachments.data,
  });
}

export function addAttachment(file) {
  return createAction({
    type: types.ADD_ATTACHMENT,
    callAPI: () => mediaApi.addAttachment(file),
  });
}

export function addAttachmentImage(file) {
  return createAction({
    type: types.ADD_ATTACHMENT,
    callAPI: () => mediaApi.addAttachmentImage(file),
  });
}

export function removeAttachment(pk) {
  return createAction({
    type: types.REMOVE_ATTACHMENT,
    callAPI: () => mediaApi.removeAttachment(pk).then(() => pk),
  });
}

export function removeAttachmentImage(pk) {
  return createAction({
    type: types.REMOVE_ATTACHMENT,
    callAPI: () => mediaApi.removeAttachmentImage(pk).then(() => pk),
  });
}
