import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Error, Loader } from 'shared';
import { compose } from 'redux';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { getApplicationStatuses } from 'actions/applicationActions';

const StatusesProvider = ({
  activeLanguageCode,
  loading,
  error,
  statuses,
  children,
  getApplicationStatuses,
}) => {
  const stableGetApplicationStatuses = useCallback(getApplicationStatuses, [
    activeLanguageCode,
  ]);
  useEffect(() => {
    stableGetApplicationStatuses(activeLanguageCode);
  }, [stableGetApplicationStatuses, activeLanguageCode]);
  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <Error />;
  }
  return children(statuses);
};

StatusesProvider.propTypes = {
  children: PropTypes.func.isRequired,
  activeLanguageCode: PropTypes.string.isRequired,
  getApplicationStatuses: PropTypes.func.isRequired,
  statuses: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

StatusesProvider.defaultProps = {
  error: undefined,
};

const mapStateToProps = (
  { applicationStatuses: { data, loading, error } },
  { activeLanguage },
) => ({
  loading,
  error,
  statuses: (data && data[activeLanguage.code]) || [],
  activeLanguageCode: activeLanguage.code,
});

export default compose(
  withLocalize,
  connect(
    mapStateToProps,
    { getApplicationStatuses },
  ),
)(StatusesProvider);
