import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'components/Format';
import Localize from 'components/Localize';
import {
  KindCompanyWrapper,
  CompanyWrapper,
  KindWrapper,
} from './KindCompanyStyled';

const Kind = ({ vacancy }) => {
  if (vacancy.industry) {
    return (
      <KindWrapper>
        <Tag slug={vacancy.industry.slug} />
      </KindWrapper>
    );
  }
  return null;
};

const Company = ({ vacancy }) => (
  <CompanyWrapper>
    <Localize id="at" /> {vacancy.company.name}
  </CompanyWrapper>
);

const KindCompany = ({ vacancy }) => (
  <KindCompanyWrapper>
    {vacancy.industry && (
      <li>
        <Kind vacancy={vacancy} />
      </li>
    )}
    <li>
      <Company vacancy={vacancy} />
    </li>
  </KindCompanyWrapper>
);

KindCompany.propTypes = {
  vacancy: PropTypes.object.isRequired,
};

Kind.propTypes = {
  vacancy: PropTypes.object.isRequired,
};

Company.propTypes = {
  vacancy: PropTypes.object.isRequired,
};

export default KindCompany;
export { Kind, Company };
