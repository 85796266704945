'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactFontawesome = require('@fortawesome/react-fontawesome');

var _faArrowLeft = require('@fortawesome/free-solid-svg-icons/faArrowLeft');

var _BackButtonStyled = require('./BackButtonStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BackButton = function BackButton(_ref) {
  var backTo = _ref.backTo,
      text = _ref.text;
  return _react2.default.createElement(
    _BackButtonStyled.BackButtonContainer,
    null,
    _react2.default.createElement(
      _BackButtonStyled.ButtonLink,
      { to: backTo },
      _react2.default.createElement(_reactFontawesome.FontAwesomeIcon, { icon: _faArrowLeft.faArrowLeft })
    ),
    _react2.default.createElement(
      _BackButtonStyled.ButtonText,
      null,
      text
    )
  );
};

BackButton.propTypes = process.env.NODE_ENV !== "production" ? {
  backTo: _propTypes2.default.string.isRequired,
  text: _propTypes2.default.node.isRequired
} : {};

exports.default = BackButton;
module.exports = exports['default'];