import styled from 'styled-components';

export const DateDividerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.grayE6};
    border-radius: ${({ theme }) => theme.borderRadiusDefault};
    transform: translateY(-50%);
  }
`;

export const Date = styled.div`
  position: relative;
  padding: 0 14px;
  color: ${({ theme }) => theme.grayE6};
  font-weight: ${({ theme }) => theme.fontWeightUpperBold};
  background-color: white;
  z-index: 1;
`;
