import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Localize from 'components/Localize';

const PresentationCompletedSelector = ({ row, selectedInternship }) => {
  let link = `/es/applicant-profile/${row.pk}`;
  if (row.esuser.internships[0]) link += `/${row.esuser.internships[0].pk}#presentation`;
  if (
    selectedInternship[row.esuser.pk]
    && selectedInternship[row.esuser.pk].value
  ) {
    link = `/es/applicant-profile/${row.pk}/${
      selectedInternship[row.esuser.pk].value.pk
    }#presentation`;
    if (selectedInternship[row.esuser.pk].value.presentation_completed) {
      return (
        <a href={link}>
          <Localize
            id="yes-on"
            data={{
              date: moment(
                selectedInternship[row.esuser.pk].value
                  .presentation_completed_changed,
              ).format('DD.MM.YYYY'),
            }}
          />
        </a>
      );
    }
    return (
      <a href={link}>
        <Localize id="not-yet" />
      </a>
    );
  }
  return null;
};

PresentationCompletedSelector.propTypes = {
  row: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    esuser: PropTypes.shape({
      pk: PropTypes.number.isRequired,
      internships: PropTypes.array,
    }).isRequired,
  }).isRequired,
  selectedInternship: PropTypes.shape({}).isRequired,
};

export default PresentationCompletedSelector;
