'use strict';

exports.__esModule = true;
exports.default = undefined;

var _EditIcon = require('./EditIcon');

var _EditIcon2 = _interopRequireDefault(_EditIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _EditIcon2.default;
module.exports = exports['default'];