import ServerApi from 'shared/lib/api/ServerApi';

class MediaApi extends ServerApi {
  getAttachments() {
    return super.get('/media/uploadedcv/');
  }

  getImages() {
    return super.get('/media/image/?kind=DOCUMENT_PHOTO');
  }

  addAttachment(file) {
    return super.post('/media/uploadedcv/', { cv: file });
  }

  addAttachmentImage(file) {
    return super.post('/media/image/', { image: file, kind: 'DOCUMENT_PHOTO' });
  }

  removeAttachment(pk) {
    return super.delete(`/media/uploadedcv/${pk}/`);
  }

  removeAttachmentImage(pk) {
    return super.delete(`/media/image/${pk}/`);
  }
}

export default new MediaApi();
