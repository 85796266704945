'use strict';

exports.__esModule = true;
exports.setDefaultLanguage = exports.getDefaultLanguage = exports.languages = exports.locales = undefined;

var _english = require('../styles/images/english.png');

var _english2 = _interopRequireDefault(_english);

var _germany = require('../styles/images/germany.png');

var _germany2 = _interopRequireDefault(_germany);

var _enGB = require('date-fns/locale/en-GB');

var _enGB2 = _interopRequireDefault(_enGB);

var _de = require('date-fns/locale/de');

var _de2 = _interopRequireDefault(_de);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var locales = exports.locales = {
  en: _enGB2.default,
  de: _de2.default
};

// The order matters! The order MUST follow the order of server return translation.
var languages = exports.languages = [{ name: 'English', code: 'en', flag: _english2.default }, { name: 'Deutsch', code: 'de', flag: _germany2.default }];

var getDefaultBrowserLanguage = function getDefaultBrowserLanguage() {
  var lang = window.navigator.languages ? window.navigator.languages[0] : null;
  lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;
  // eslint-disable-next-line prefer-destructuring
  if (lang.indexOf('-') !== -1) lang = lang.split('-')[0];

  // eslint-disable-next-line prefer-destructuring
  if (lang.indexOf('_') !== -1) lang = lang.split('_')[0];
  return lang;
};

var getDefaultLanguage = exports.getDefaultLanguage = function getDefaultLanguage() {
  var browserLanguage = getDefaultBrowserLanguage();
  return localStorage.getItem('languageCode') || (languages.map(function (lang) {
    return lang.code;
  }).includes(browserLanguage) ? browserLanguage : 'de');
};

var setDefaultLanguage = exports.setDefaultLanguage = function setDefaultLanguage(code) {
  localStorage.setItem('languageCode', code);
};