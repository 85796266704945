'use strict';

exports.__esModule = true;
exports.IconContainer = exports.ButtonContent = exports.Spinner = exports.StyledButton = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n      font-size: 16px;\n      height: 45px;\n      padding: 0px 20px;\n    '], ['\n      font-size: 16px;\n      height: 45px;\n      padding: 0px 20px;\n    ']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n    font-size: 18px;\n    height: 55px;\n    padding: 0px 26px;\n  '], ['\n    font-size: 18px;\n    height: 55px;\n    padding: 0px 26px;\n  ']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n      ', '\n      svg {\n        width: 28px;\n      }\n    '], ['\n      ', '\n      svg {\n        width: 28px;\n      }\n    ']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n              margin-left: ', ';\n            '], ['\n              margin-left: ', ';\n            ']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n              margin-right: ', ';\n            '], ['\n              margin-right: ', ';\n            ']),
    _templateObject6 = _taggedTemplateLiteralLoose(['\n    ', '\n    svg {\n      width: 34px;\n    }\n  '], ['\n    ', '\n    svg {\n      width: 34px;\n    }\n  ']),
    _templateObject7 = _taggedTemplateLiteralLoose(['\n            margin-left: ', ';\n          '], ['\n            margin-left: ', ';\n          ']),
    _templateObject8 = _taggedTemplateLiteralLoose(['\n            margin-right: ', ';\n          '], ['\n            margin-right: ', ';\n          ']),
    _templateObject9 = _taggedTemplateLiteralLoose(['\n  box-shadow: ', ';\n  border-radius: ', ';\n  ', ';\n  font-weight: ', ';\n  color: ', ';\n  background-color: ', ';\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  border: ', ';\n  transition: 0.3s;\n  text-align: left;\n  &:hover,\n  &:focus {\n    opacity: 0.9;\n  }\n  &:focus {\n    outline: none;\n  }\n  &:disabled,\n  &.disabled {\n    opacity: 0.5;\n    cursor: unset;\n  }\n  position: relative;\n'], ['\n  box-shadow: ', ';\n  border-radius: ', ';\n  ', ';\n  font-weight: ', ';\n  color: ', ';\n  background-color: ', ';\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  border: ', ';\n  transition: 0.3s;\n  text-align: left;\n  &:hover,\n  &:focus {\n    opacity: 0.9;\n  }\n  &:focus {\n    outline: none;\n  }\n  &:disabled,\n  &.disabled {\n    opacity: 0.5;\n    cursor: unset;\n  }\n  position: relative;\n']),
    _templateObject10 = _taggedTemplateLiteralLoose(['\n  border-top-color: ', ';\n  border-right-color: ', ';\n'], ['\n  border-top-color: ', ';\n  border-right-color: ', ';\n']),
    _templateObject11 = _taggedTemplateLiteralLoose(['\n  opacity: ', ';\n  width: ', ';\n'], ['\n  opacity: ', ';\n  width: ', ';\n']),
    _templateObject12 = _taggedTemplateLiteralLoose(['\n  ', ';\n  display: flex;\n  justify-content: center;\n  path,\n  line {\n    stroke: ', ';\n  }\n'], ['\n  ', ';\n  display: flex;\n  justify-content: center;\n  path,\n  line {\n    stroke: ', ';\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Loader = require('../Loader');

var _Button = require('./Button');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var getColorByColorType = function getColorByColorType(color, theme) {
  switch (color) {
    case _Button.BUTTON_COLORS.PRIMARY:
      return theme.primary;
    case _Button.BUTTON_COLORS.SECONDARY:
      return theme.secondary;
    case _Button.BUTTON_COLORS.WHATSAPP:
      return '#25d366';
    case _Button.BUTTON_COLORS.APPROVE:
      return 'green';
    default:
      break;
  }
};

var getButtonBgColor = function getButtonBgColor(color, theme, variant) {
  if (color === _Button.BUTTON_COLORS.DEFAULT || variant === _Button.BUTTON_VARIANTS.OUTLINED) {
    return theme.white;
  }
  return getColorByColorType(color, theme);
};

var getTextColor = function getTextColor(color, theme, variant) {
  if (variant === _Button.BUTTON_VARIANTS.OUTLINED) {
    return getColorByColorType(color, theme);
  }
  if (color === _Button.BUTTON_COLORS.DEFAULT) {
    return theme.textColor3;
  }
  return theme.white;
};

var getButtonSizeStyle = function getButtonSizeStyle(size) {
  if (size === _Button.BUTTON_SIZES.SMALL) {
    return (0, _styledComponents.css)(_templateObject);
  }
  return (0, _styledComponents.css)(_templateObject2);
};

var getBorderStyle = function getBorderStyle(color, theme, variant) {
  if (variant === _Button.BUTTON_VARIANTS.OUTLINED) {
    return '1px solid ' + getColorByColorType(color, theme);
  }
  return 'none';
};

var getIconStyle = function getIconStyle(size) {
  if (size === _Button.BUTTON_SIZES.SMALL) {
    return (0, _styledComponents.css)(_templateObject3, function (_ref) {
      var rightIcon = _ref.rightIcon;
      return rightIcon ? (0, _styledComponents.css)(_templateObject4, function (_ref2) {
        var withText = _ref2.withText;
        return withText ? '10px' : 0;
      }) : (0, _styledComponents.css)(_templateObject5, function (_ref3) {
        var withText = _ref3.withText;
        return withText ? '10px' : 0;
      });
    });
  }
  return (0, _styledComponents.css)(_templateObject6, function (_ref4) {
    var rightIcon = _ref4.rightIcon;
    return rightIcon ? (0, _styledComponents.css)(_templateObject7, function (_ref5) {
      var withText = _ref5.withText;
      return withText ? '20px' : 0;
    }) : (0, _styledComponents.css)(_templateObject8, function (_ref6) {
      var withText = _ref6.withText;
      return withText ? '20px' : 0;
    });
  });
};

var StyledButton = exports.StyledButton = _styledComponents2.default.button(_templateObject9, function (_ref7) {
  var disableBoxShadow = _ref7.disableBoxShadow;
  return disableBoxShadow ? 'none' : '0 3px 6px rgba(0, 0, 0, 0.16)';
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.borderRadiusDefault;
}, function (_ref9) {
  var size = _ref9.size;
  return getButtonSizeStyle(size);
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.fontWeightBold;
}, function (_ref11) {
  var color = _ref11.color,
      theme = _ref11.theme,
      variant = _ref11.variant;
  return getTextColor(color, theme, variant);
}, function (_ref12) {
  var color = _ref12.color,
      theme = _ref12.theme,
      variant = _ref12.variant;
  return getButtonBgColor(color, theme, variant);
}, function (_ref13) {
  var color = _ref13.color,
      theme = _ref13.theme,
      variant = _ref13.variant;
  return getBorderStyle(color, theme, variant);
});

var Spinner = exports.Spinner = (0, _styledComponents2.default)(_Loader.Spin)(_templateObject10, function (_ref14) {
  var color = _ref14.color,
      theme = _ref14.theme,
      variant = _ref14.variant;
  return getTextColor(color, theme, variant);
}, function (_ref15) {
  var color = _ref15.color,
      theme = _ref15.theme,
      variant = _ref15.variant;
  return getTextColor(color, theme, variant);
});

var ButtonContent = exports.ButtonContent = _styledComponents2.default.span(_templateObject11, function (_ref16) {
  var isLoading = _ref16.isLoading;
  return isLoading ? '0.1' : '1';
}, function (_ref17) {
  var width = _ref17.width;
  return width;
});

var IconContainer = exports.IconContainer = _styledComponents2.default.div(_templateObject12, function (_ref18) {
  var size = _ref18.size;
  return getIconStyle(size);
}, function (_ref19) {
  var color = _ref19.color,
      theme = _ref19.theme,
      variant = _ref19.variant;
  return getTextColor(color, theme, variant);
});