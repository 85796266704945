'use strict';

exports.__esModule = true;
// Define what props.theme will look like
exports.default = {
  primary: '#e95160',
  secondary: '#183a64',
  secondaryLight: '#35557b',
  white: '#ffffff',
  grayA0: '#A0A0A0',
  grayF3: '#F3F3F1',
  grayF7: '#F7F7F7',
  grayE6: '#E6E6E6',
  textColor: '#505050',
  textColor69: '#696969',
  textColor3: '#333333',
  textColorD8: '#d8d8d8',
  textColor9D: '#9d9d9c',
  green: '#7ac943',
  fontWeightLight: 100,
  fontWeightRegular: 200,
  fontWeightMedium: 300,
  fontWeightBold: 400,
  fontWeightSemiBold: 500,
  fontWeightUpperBold: 600,
  fontWeightHeavyBold: 700,
  borderRadiusDefault: '3px',
  borderRadiusRound: '50%',
  borderColor: '#e6e6e6',
  errorColor: '#e95160',
  warningColor: '#ffa834'
};
module.exports = exports['default'];