import ServerApi from 'shared/lib/api/ServerApi';
import { parse } from 'qs';
import { getDefaultLanguage } from 'shared/lib/lib/languages';

const INTERNSHIP_KIND = 'SCHOOL_INTERNSHIP';
const PAGE_SIZE = 10;

class VacancyApi extends ServerApi {
  getApplyLink(company, vacancy) {
    const { referrer } = parse(window.location.search.slice(1));
    const referrerPart = referrer ? `?referrer=${referrer}` : '';
    const applyPath = process.env.REACT_APP_JOB_APPLY_PATH;
    const companyPart = company ? `/${company}` : '';
    return `${applyPath}/c${companyPart}/vacancy/${vacancy}${referrerPart}`;
  }

  getVacancyList(pageIndex) {
    const offset = pageIndex * PAGE_SIZE;
    return super
      .get(
        `/recruiting/vacancy/?language=${getDefaultLanguage()}&limit=${PAGE_SIZE}&offset=${offset}&kind=${INTERNSHIP_KIND}`,
        true,
      )
      .then((data) => data);
  }

  addApplication(vacancy, video, audio) {
    return super.post('/recruiting/application/', {
      vacancy,
      // in order not to pass null.
      video: video || undefined,
      // until rules validator on backend is off, send kind param.
      kind: (video && 'VIDEO') || (audio && 'AUDIO') || 'CV',
    });
  }
}

export default new VacancyApi();
