import React from 'react';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  LocalizeProvider as LocalizeProviderBase,
  withLocalize,
} from 'react-localize-redux';
import localizationApi from 'api/LocalizationApi';
import { getDefaultLanguage, languages } from 'shared/lib/lib/languages';
import AccessTokenStorage from 'shared/lib/lib/accessTokenStorage';
import moment from 'moment';

class LocalizeProviderInner extends React.Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
      error: false,
      tries: 0,
    };
  }

  componentDidMount() {
    this.getLocalization();
  }

  async getLocalization() {
    const { initialize } = this.props;

    await this.setState(({ tries }) => ({ tries: tries + 1 }));
    return localizationApi
      .getLocalization()
      .then((json) => {
        const defaultLanguage = getDefaultLanguage();
        initialize({
          languages,
          translation: json,
          options: {
            defaultLanguage,
            renderToStaticMarkup,
          },
        });
        this.setState({ loaded: true });
      })
      .catch(() => {
        const { tries } = this.state;

        if (tries >= 2) {
          this.setState({ error: true });
        } else {
          // if token is not valid, localization endpoint also failed.
          AccessTokenStorage.remove();
          return this.getLocalization();
        }
      });
  }

  render() {
    const { loaded, error } = this.state;
    const { children } = this.props;
    if (error) {
      return 'Failed to load localization';
    }
    if (loaded) {
      return children;
    }
    // show loading indicator
    return null;
  }
}

LocalizeProviderInner.propTypes = {
  children: PropTypes.node.isRequired,
  initialize: PropTypes.func.isRequired,
};

const ActiveLanguageProviderHOC = ({ children, activeLanguage }) => children(activeLanguage);
export const ActiveLanguageProvider = withLocalize(ActiveLanguageProviderHOC);

export const LocalizedDate = ({ date, children }) => (
  <ActiveLanguageProvider>
    {(activeLang) => children(moment(date).locale(activeLang.code))}
  </ActiveLanguageProvider>
);

LocalizedDate.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

const LocalizeProviderInnerWithHOC = withLocalize(LocalizeProviderInner);

const LocalizeProvider = ({ children }) => (
  <LocalizeProviderBase>
    <LocalizeProviderInnerWithHOC>{children}</LocalizeProviderInnerWithHOC>
  </LocalizeProviderBase>
);

LocalizeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocalizeProvider;
