import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Localize from 'components/Localize';

const LastLoginSelector = ({ row }) => {
  if (row.esuser.user.last_login) {
    return moment(row.esuser.user.last_login).format('DD.MM.YYYY HH:mm');
  }
  return <Localize id="never" />;
};

LastLoginSelector.propTypes = {
  row: PropTypes.shape({
    esuser: PropTypes.shape({
      user: PropTypes.shape({
        last_login: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
};

export default LastLoginSelector;
