import styled from 'styled-components';
import { device } from 'styles/device';

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 1280px;
  margin: 0 auto;
`;
export const InternshipInfoWrapper = styled.div`
  text-align: right;
  color: ${({ theme }) => theme.secondary};
  font-size: 18px;
  margin-top: -15px;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  @media ${device.mobile} {
    font-size: 14px;
  }
`;
