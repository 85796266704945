'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactSvg = require('react-svg');

var _reactSvg2 = _interopRequireDefault(_reactSvg);

var _trash = require('../../styles/images/trash.svg');

var _trash2 = _interopRequireDefault(_trash);

var _Modal = require('../Modal');

var _Modal2 = _interopRequireDefault(_Modal);

var _DownloadUrl = require('../DownloadUrl');

var _ThumbnailStyled = require('./ThumbnailStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var Thumbnail = function Thumbnail(_ref) {
  var data = _ref.data,
      _onDelete = _ref.onDelete,
      deleteButtonClass = _ref.deleteButtonClass,
      disabled = _ref.disabled,
      prompt = _ref.prompt,
      promptNode = _ref.promptNode,
      rest = _objectWithoutProperties(_ref, ['data', 'onDelete', 'deleteButtonClass', 'disabled', 'prompt', 'promptNode']);

  var _useState = (0, _react.useState)(false),
      isModalOpen = _useState[0],
      onModalOpen = _useState[1];

  var onModalShow = function onModalShow() {
    return onModalOpen(true);
  };
  var onModalClose = function onModalClose() {
    return onModalOpen(false);
  };
  var onDelete = function onDelete(e) {
    if (e) {
      e.stopPropagation();
    }
    if (disabled) return;
    _onDelete(data.pk);
  };
  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      _ThumbnailStyled.ThumbnailContainer,
      _extends({
        bgImage: data.image || data.preview,
        onClick: onModalShow
      }, rest),
      !disabled && !prompt && _react2.default.createElement(
        _ThumbnailStyled.DeleteButton,
        {
          onClick: onDelete,
          className: deleteButtonClass,
          type: 'button',
          disabled: disabled
        },
        _react2.default.createElement(_reactSvg2.default, { src: _trash2.default })
      ),
      !disabled && prompt && _react2.default.createElement(
        _Modal.Prompt,
        {
          confirm: onDelete,
          trigger: _react2.default.createElement(
            _ThumbnailStyled.DeleteButton,
            {
              className: deleteButtonClass,
              type: 'button',
              disabled: disabled
            },
            _react2.default.createElement(_reactSvg2.default, { src: _trash2.default })
          ),
          onOpen: function onOpen(e) {
            return e.stopPropagation();
          },
          onClose: function onClose(e) {
            return e.stopPropagation();
          }
        },
        promptNode
      )
    ),
    _react2.default.createElement(
      _Modal2.default,
      { open: isModalOpen, onClose: onModalClose },
      _react2.default.createElement(_DownloadUrl.DownloadButton, { url: data.file || data.image || data.preview }),
      _react2.default.createElement(
        _ThumbnailStyled.ModalContent,
        null,
        _react2.default.createElement('img', { className: 'full-width', src: data.image || data.preview, alt: '' })
      )
    )
  );
};

Thumbnail.propTypes = process.env.NODE_ENV !== "production" ? {
  data: _propTypes2.default.shape({
    api_url: _propTypes2.default.string,
    file: _propTypes2.default.string,
    image: _propTypes2.default.string,
    preview: _propTypes2.default.string,
    kind: _propTypes2.default.string,
    pk: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.string])
  }).isRequired,
  deleteButtonClass: _propTypes2.default.string,
  onDelete: _propTypes2.default.func,
  disabled: _propTypes2.default.bool,
  prompt: _propTypes2.default.bool,
  promptNode: _propTypes2.default.node
} : {};

Thumbnail.defaultProps = {
  deleteButtonClass: '',
  disabled: false,
  onDelete: null,
  prompt: false,
  promptNode: undefined
};

exports.default = Thumbnail;
module.exports = exports['default'];