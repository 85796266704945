import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Page from 'components/Page';
import Localize from 'components/Localize';
import InformationModal from 'components/InformationModal';
import { ReactComponent as InfoIcon } from 'styles/images/svg/info.svg';
import { Loader } from 'shared';
import InternshipSelectionProvider from 'components/InternshipSwitcher/InternshipSelectionProvider';
import {
  getAssessment,
  changeAssessment,
  getAssessmentAnswerChoices,
  changeAssessmentItems,
} from 'actions/assessmentActions';
import Questionnaire from './Questionnaire';
import {
  getSubmittedProperty,
  getSubmittedStatusByRole,
} from './assessmentUtils';

const Assessment = ({
  loading,
  error,
  assessment,
  answerChoices,
  getAssessment: _getAssessment,
  changeAssessment: _changeAssessment,
  getAssessmentAnswerChoices: _getAssessmentAnswerChoices,
  changeAssessmentItems: _changeAssessmentItems,
  user,
  history,
  selectedInternship,
}) => {
  const stableGetAssessment = useCallback(_getAssessment, [
    answerChoices,
    selectedInternship,
  ]);
  const stableGetAssessmentAnswerChoices = useCallback(
    _getAssessmentAnswerChoices,
    [answerChoices, selectedInternship],
  );
  useEffect(() => {
    if (selectedInternship) stableGetAssessment(selectedInternship.pk);
    if (!answerChoices) stableGetAssessmentAnswerChoices();
  }, [
    stableGetAssessment,
    stableGetAssessmentAnswerChoices,
    answerChoices,
    selectedInternship,
  ]);
  const [informationModalOpen, setInformationModalOpen] = useState('');

  const onSubmit = (items, assessmentData) => _changeAssessment(selectedInternship.pk, {
    ...assessmentData,
    ...getSubmittedProperty(assessment, user.role),
  }).then(() => _changeAssessmentItems(items).then(
    () => user.role === 'APPLICANT'
          && history.push(`/applicant/${selectedInternship.pk || ''}`),
  ));
  const onSave = (items, assessmentData) => _changeAssessment(selectedInternship.pk, assessmentData).then(() => _changeAssessmentItems(items));
  return (
    <>
      <InformationModal
        open={informationModalOpen}
        onCloseModal={() => setInformationModalOpen('')}
      />
      <Page
        title={<Localize id="your-self-assessment" />}
        backTo={`/applicant/${(selectedInternship && selectedInternship.pk)
          || ''}`}
        description={(
          <div>
            <Localize id="during-last-internship-week-you-should-complete" />
            &nbsp;
            <Localize id="company-your-teacher-and-your-mentor-will-fill" />
            &nbsp;
            <Localize id="then-you-can-compare-all-information-each-other-se" />
          </div>
        )}
        icon={<InfoIcon />}
        onClick={() => setInformationModalOpen('SELF_ASSESSMENT')}
      >
        {loading && <Loader />}
        <InternshipSelectionProvider>
          {() => {
            if (assessment && answerChoices) {
              return (
                <Questionnaire
                  key={assessment.pk}
                  isCompleted={assessment.completed}
                  isEditable={!getSubmittedStatusByRole(assessment, user.role)}
                  answers={answerChoices}
                  data={assessment.group_list}
                  assessment={assessment}
                  onSubmit={onSubmit}
                  onSave={onSave}
                  userRole={user.role.toLowerCase()}
                  hasButtons
                  error={error}
                />
              );
            }
            return null;
          }}
        </InternshipSelectionProvider>
      </Page>
    </>
  );
};

Assessment.propTypes = {
  error: PropTypes.shape({
    detail: PropTypes.string,
  }),
  user: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  assessment: PropTypes.shape({
    group_list: PropTypes.arrayOf(PropTypes.object),
    pk: PropTypes.number.isRequired,
    completed: PropTypes.bool.isRequired,
  }),
  answerChoices: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  getAssessment: PropTypes.func.isRequired,
  changeAssessment: PropTypes.func.isRequired,
  getAssessmentAnswerChoices: PropTypes.func.isRequired,
  changeAssessmentItems: PropTypes.func.isRequired,
  selectedInternship: PropTypes.shape({
    pk: PropTypes.number.isRequired,
  }).isRequired,
};

Assessment.defaultProps = {
  error: null,
  assessment: null,
  answerChoices: null,
};

const mapStateToProps = ({
  assessment: { data, loading, error },
  currentUser,
  selectedInternship,
}) => ({
  user: currentUser && currentUser.data,
  loading,
  error,
  assessment: data && data.questions,
  answerChoices: data && data.answerChoices,
  selectedInternship,
});

export default connect(mapStateToProps, {
  getAssessment,
  getAssessmentAnswerChoices,
  changeAssessment,
  changeAssessmentItems,
})(Assessment);
