import React from 'react';
import Localize from 'components/Localize';
import { ReactComponent as SearchIcon } from 'shared/lib/styles/images/search.svg';
import { InputStyled } from './SearchInputStyled';

const SearchInput = (props) => (
  <Localize>
    {({ translate }) => (
      <InputStyled
        placeholder={translate('search-input-placeholder')}
        icon={<SearchIcon />}
        {...props}
      />
    )}
  </Localize>
);

export default SearchInput;
