import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Error, Loader } from 'shared';
import Page from 'components/Page';
import InformationModal from 'components/InformationModal';
import Localize from 'components/Localize';
import WhiteArea from 'components/WhiteArea';
import { getApplicantState } from 'actions/applicantAction';
import { getInternshipsRequestsList } from 'actions/internshipRequestActions';
import { getVacancyList, addApplication } from 'actions/vacancyActions';
import ApplicantState from 'components/ApplicantState';
import { ITEM_STATUS } from 'components/ApplicantState/Snake';
import { ReactComponent as InfoIcon } from 'styles/images/svg/info.svg';
import SpecialTasks from './SpecialTasks';
import FillRequest from './FillRequest';
import BottomLinks from './BottomLinks';

const Todo = ({
  applicantState,
  loading,
  error,
  getApplicantState,
  getInternshipsRequestsList,
  selectedInternshipId,
  getVacancyList: _getVacancyList,
  addApplication: _addApplication,
}) => {
  const stableGetApplicantState = useCallback(getApplicantState, [
    selectedInternshipId,
  ]);
  const stableGetInternshipsRequestsList = useCallback(
    getInternshipsRequestsList,
    [],
  );
  useEffect(() => {
    stableGetApplicantState(selectedInternshipId);
  }, [stableGetApplicantState, selectedInternshipId]);
  useEffect(() => {
    stableGetInternshipsRequestsList();
  }, [stableGetInternshipsRequestsList]);
  const [fillRequestVisibility, setFillRequestVisibility] = useState(false);
  const [informationModalOpen, setInformationModalOpen] = useState('');

  const switchFillRequestVisibility = () => {
    setFillRequestVisibility(!fillRequestVisibility);
  };
  const getVacancyList = () => {
    _getVacancyList({
      kind: ['SCHOOL_INTERNSHIP'],
    });
  };
  const addApplication = (vacancyUrl, videoUrl, audioUrl) => {
    _addApplication(vacancyUrl, videoUrl, audioUrl);
  };
  const renderSnake = () => {
    if (error) {
      return <Error />;
    }
    // if there are no internship exists show at least Bewerbang button.
    const states = selectedInternshipId
      ? applicantState
      : [
        {
          kind: 'INTERNSHIP',
          status: applicantState.find(({ kind }) => kind === 'INTERNSHIP')
            ? applicantState.find(({ kind }) => kind === 'INTERNSHIP').status
            : ITEM_STATUS.IN_PROGRESS,
        },
      ];
    return (
      <Loader loading={loading}>
        <FillRequest
          visible={fillRequestVisibility}
          onClose={switchFillRequestVisibility}
          getVacancyList={getVacancyList}
          addApplication={addApplication}
        >
          <ApplicantState
            states={states}
            onItemClick={switchFillRequestVisibility}
            selectedInternshipId={selectedInternshipId}
          />
        </FillRequest>
      </Loader>
    );
  };
  return (
    <>
      <InformationModal
        open={informationModalOpen}
        onCloseModal={() => setInformationModalOpen('')}
      />
      <Page
        title={<Localize id="your-special-tasks" />}
        description={
          <Localize id="here-are-individual-tasks-you-to-complete-quickly" />
        }
        withBackButton={false}
        withJobAdvisor={false}
        icon={<InfoIcon />}
        onClick={() => setInformationModalOpen('TASKS')}
      >
        <WhiteArea>
          <SpecialTasks />
        </WhiteArea>
      </Page>
      <Page
        title={<Localize id="these-are-your-todos" />}
        description={
          <Localize id="here-you-can-see-what-you-have-do-until-end-intern" />
        }
        withBackButton={false}
        withTopMargin
        icon={<InfoIcon />}
        onClick={() => setInformationModalOpen('INTERNSHIP_OVERVIEW')}
      >
        {renderSnake()}
        <BottomLinks />
      </Page>
    </>
  );
};

Todo.propTypes = {
  applicantState: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  getApplicantState: PropTypes.func.isRequired,
  getInternshipsRequestsList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  selectedInternshipId: PropTypes.number,
  getVacancyList: PropTypes.func.isRequired,
  addApplication: PropTypes.func.isRequired,
};

Todo.defaultProps = {
  error: undefined,
  selectedInternshipId: null,
};

const mapStateToProps = ({
  applicantState: { data, loading, error },
  selectedInternship,
}) => ({
  applicantState: data || [],
  selectedInternshipId: selectedInternship && selectedInternship.pk,
  loading,
  error,
});

export default connect(mapStateToProps, {
  getApplicantState,
  getInternshipsRequestsList,
  getVacancyList,
  addApplication,
})(Todo);
