import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Page from 'components/Page';
import { Button } from 'shared';
import Can from 'components/Can';
import { section } from 'utils/access-rules/rules/instructions';
import Localize from 'components/Localize';
import { actions, roles } from 'utils/access-rules/constants';
import {
  Container,
  HelpImage,
  InstructionsImage,
  ContentBox,
  TextBox,
  ImageContainer,
  Line,
  NoInternships,
} from './InstructionsStyled';
import help from '../../styles/images/help.svg';
import instructions from '../../styles/images/instructions.svg';

const Instructions = ({ user, selectedInternshipPk }) => {
  const selectedInternship = selectedInternshipPk
    ? user.internships.find((i) => i.pk === selectedInternshipPk)
    : user.internships[0];

  const getFileSrc = () => {
    if (user.role === roles.Applicant) {
      return '/pdf/Manual_IBA-digital_SuS_kurz - Student.pdf';
    }
    if (user.role === roles.EducationProvider) {
      return '/pdf/Manual_IBA-digital_BuB_kurz - Educational Provider.pdf';
    }
    return '/pdf/Manual_IBA-digital_LuL_kurz - Teacher.pdf';
  };

  if (!selectedInternship) {
    return (
      <Page>
        <Container>
          <NoInternships>
            <Localize id="none-internships-are-assigned-user" />
          </NoInternships>
        </Container>
      </Page>
    );
  }
  return (
    <Page>
      <Container>
        <ContentBox>
          <ImageContainer>
            <HelpImage src={help} alt="help" />
          </ImageContainer>
          <TextBox>
            <h3 className="title">
              <Localize id="need-help" />
            </h3>
            <p className="description">
              <Localize id="write-your-school-administrator-email-following-em" />
            </p>
            <div className="link-container">
              <a href={`mailto:${selectedInternship.school_admin_email}`}>
                {selectedInternship.school_admin_email}
              </a>
            </div>
          </TextBox>
        </ContentBox>
        <Can I={actions.see} a={section.freeGuide}>
          <Line />
          <ContentBox>
            <ImageContainer>
              <InstructionsImage src={instructions} alt="instructions" />
            </ImageContainer>
            <TextBox>
              <h3 className="title">
                <Localize id="free-guide" />
              </h3>
              <p className="description">
                <Localize id="pdf-summarizes-functionality-iba-digital-simple-un" />
              </p>
              <div className="button-container">
                <Button
                  color="secondary"
                  as="a"
                  size="small"
                  href={getFileSrc()}
                  download
                >
                  <Localize id="download-tutorial" />
                </Button>
              </div>
            </TextBox>
          </ContentBox>
        </Can>
      </Container>
    </Page>
  );
};

Instructions.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
    internships: PropTypes.arrayOf(
      PropTypes.shape({
        school_admin_email: PropTypes.string,
        pk: PropTypes.number,
      }),
    ),
  }).isRequired,
  selectedInternshipPk: PropTypes.number,
};

Instructions.defaultProps = {
  selectedInternshipPk: null,
};

const mapStateToProps = ({ currentUser: { data }, selectedInternship }) => ({
  user: data,
  selectedInternshipPk: selectedInternship ? selectedInternship.pk : null,
});

export default connect(mapStateToProps)(Instructions);
