import React from 'react';
import PropTypes from 'prop-types';
import ReactSvg from 'react-svg';
import moment from 'moment';

import {
  Container,
  Content,
  Text,
  StyledInputField,
} from './UserInfoFieldStyled';

const UserInfoField = ({
  className,
  icon,
  isEditable,
  name,
  value,
  ...rest
}) => (
  <Container className={className}>
    <Content>
      <ReactSvg className="icon" src={icon} />
      {isEditable ? (
        <StyledInputField
          name={name}
          value={value}
          placeholder={name}
          placeholderClassName="label"
          withBottomMargin={false}
          {...rest}
        />
      ) : (
        <Text>
          {name === 'birthday' && value
            ? moment(value).format('DD.MM.YYYY')
            : value}
        </Text>
      )}
    </Content>
  </Container>
);

UserInfoField.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  isEditable: PropTypes.bool.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
};

UserInfoField.defaultProps = {
  className: undefined,
  value: '',
};

export default UserInfoField;
