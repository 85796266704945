import internshipCommentApi from 'api/InternshipCommentApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getComment(internshipId) {
  return createAction({
    type: types.GET_INTERNSHIP_COMMENT,
    callAPI: () => internshipCommentApi.getComment(internshipId),
  });
}

export function changeComment(internshipId, data) {
  return createAction({
    type: types.CHANGE_INTERNSHIP_COMMENT,
    callAPI: () => internshipCommentApi.changeComment(internshipId, data),
  });
}
