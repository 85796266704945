import styled, { css } from 'styled-components';
import { device } from 'styles/device';

export const ParagraphContainer = styled.div`
  margin-bottom: 30px;
  margin-top: ${({ withTopMargin }) => (withTopMargin ? '42px' : '0')};

  &.applicant-profile-main-paragraph {
    @media ${device.mobile} {
      .title {
        flex-direction: column;
        align-items: center;
        .internship-switcher {
          justify-content: center;
          padding-top: 30px;
        }
      }
    }
  }
`;

export const withRightTextStyles = css`
  justify-content: space-between;
  align-items: flex-end;
`;

export const ParagraphTitle = styled.div`
  display: flex;
  position: relative;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  color: ${({ theme }) => theme.secondary};
  border-bottom: 2px solid ${({ theme }) => theme.secondary};
  padding-bottom: 6px;
  ${({ withRightText }) => withRightText && withRightTextStyles};
  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const ParagraphIcon = styled.div`
  margin-right: 20px;
  margin-left: 10px;
  cursor: pointer;
`;

export const ParagraphText = styled.div`
  padding-top: 0;
`;

export const ParagraphRightTextContainer = styled.div`
  text-transform: none;
`;
