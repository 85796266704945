import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WhiteArea from 'components/WhiteArea';
import { removeSpecialTasks, getSpecialTasks } from 'actions/specialTaskAction';
import { sortTasks } from 'api/SpecialTaskApi';
import SpecialTask from 'components/SpecialTask';
import Localize from 'components/Localize';
import { filterByInternship } from 'reducers/reducersUtils';
import AddTask from './AddTask';
import EditTask from './EditTask';
import { AddTaskWrap } from './TaskFormStyled';
import { SpecialTasksWrapper, ShowHideButton } from './SpecialTasksStyled';

const SpecialTasks = ({
  internshipId,
  items,
  removeSpecialTasks,
  currentUser,
  applicant,
  getSpecialTasks,
}) => {
  const [taskIdToEdit, setTaskToEdit] = useState(undefined);
  const [allTasksShown, setAllTasksShown] = useState(false);
  const onShowHideAllTasks = () => setAllTasksShown(!allTasksShown);
  const stableGetSpecialTasks = useCallback(getSpecialTasks, [internshipId]);
  useEffect(() => {
    if (!internshipId) stableGetSpecialTasks(null);
  }, [stableGetSpecialTasks, internshipId]);
  const handleClose = () => {
    setTaskToEdit(undefined);
  };
  return (
    <WhiteArea>
      <SpecialTasksWrapper>
        <div className={`show-hide-box ${allTasksShown && 'expanded'}`}>
          {sortTasks(items)
            .map((task) => ({
              ...task,
              editForm: taskIdToEdit && task.pk === taskIdToEdit && (
                <EditTask
                  internshipId={internshipId}
                  task={items.find((task) => task.pk === taskIdToEdit)}
                  onClose={handleClose}
                />
              ),
            }))
            .map((task, index) => {
              if (!allTasksShown && index > 2) return null;
              return (
                <SpecialTask
                  key={`special_task_${task.pk}`}
                  task={task}
                  onItemRemove={
                    task.author.user.email === currentUser.email
                      ? (pk) => removeSpecialTasks(pk, internshipId)
                      : undefined
                  }
                  onItemEdit={
                    task.author.user.email === currentUser.email
                      ? (task) => setTaskToEdit(task.pk)
                      : undefined
                  }
                />
              );
            })}
        </div>
      </SpecialTasksWrapper>
      <AddTaskWrap hasItems={items.length > 0}>
        <AddTask internshipId={internshipId} applicant={applicant} />
        {items.length > 3 && (
          <ShowHideButton onClick={onShowHideAllTasks}>
            <div className="text-container">
              {allTasksShown ? (
                <Localize id="show-less" />
              ) : (
                <Localize id="show-all-tasks" />
              )}
            </div>
          </ShowHideButton>
        )}
      </AddTaskWrap>
    </WhiteArea>
  );
};

SpecialTasks.propTypes = {
  internshipId: PropTypes.number,
  items: PropTypes.array.isRequired,
  removeSpecialTasks: PropTypes.func.isRequired,
  getSpecialTasks: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  applicant: PropTypes.shape({
    api_url: PropTypes.string.isRequired,
  }),
};

SpecialTasks.defaultProps = {
  internshipId: null,
  applicant: null,
};

const mapStateToProps = (
  { specialTasks: { data }, currentUser },
  { internshipId },
) => ({
  items: internshipId ? data : filterByInternship(internshipId, data),
  currentUser: currentUser && currentUser.data && currentUser.data.user,
});

export default connect(mapStateToProps, {
  removeSpecialTasks,
  getSpecialTasks,
})(SpecialTasks);
