import React, { useEffect, useCallback } from 'react';
import query from 'query-string';
import PropTypes from 'prop-types';
import { Error, Loader } from 'shared';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Chat from 'components/Chat';
import {
  getChatUsers,
  getChatMessages,
  getChatRooms,
} from 'actions/chatActions';
import { filterByInternship } from 'reducers/reducersUtils';

const ChatContainer = ({
  getChatUsers,
  getChatMessages,
  getChatRooms,
  internshipId,
  users,
  loading,
  error,
  messages,
  globalRooms,
  internshipRooms,
  applicantId,
  currentUser,
  history,
}) => {
  const stableGetChatUsers = useCallback(getChatUsers, [
    applicantId,
    internshipId,
  ]);
  const stableGetChatRooms = useCallback(getChatRooms, [internshipId]);
  const stableGetChatMessages = useCallback(getChatMessages, [
    applicantId,
    internshipId,
  ]);
  useEffect(() => {
    if (internshipId) {
      stableGetChatUsers(internshipId);
      stableGetChatRooms(internshipId);
    } else {
      stableGetChatUsers(null, applicantId);
      stableGetChatRooms(null);
    }
    stableGetChatMessages(internshipId);
  }, [
    stableGetChatUsers,
    stableGetChatRooms,
    stableGetChatMessages,
    applicantId,
    internshipId,
  ]);
  if (error) {
    return <Error />;
  }
  const parsedString = query.parse(history.location.search);
  const chatTabId = parsedString['chat-id'];
  return (
    <Loader loading={loading}>
      {users.length > 0 && messages && globalRooms && internshipRooms && (
        <Chat
          messages={messages}
          users={users}
          globalRooms={globalRooms}
          internshipRooms={internshipRooms}
          internshipId={internshipId}
          chatOwner={currentUser}
          selectedTab={parseInt(chatTabId, 0)}
        />
      )}
    </Loader>
  );
};

ChatContainer.propTypes = {
  internshipId: PropTypes.number,
  users: PropTypes.array.isRequired,
  messages: PropTypes.array.isRequired,
  globalRooms: PropTypes.array.isRequired,
  internshipRooms: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  applicantId: PropTypes.number,
  getChatUsers: PropTypes.func.isRequired,
  getChatMessages: PropTypes.func.isRequired,
  getChatRooms: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    pk: PropTypes.number.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }).isRequired,
};

ChatContainer.defaultProps = {
  internshipId: null,
  applicantId: null,
  error: null,
};

const mapStateToProps = (
  { chat: { data, loading, error }, currentUser, messages },
  { internshipId, applicant },
) => ({
  users:
    (data
      && data[internshipId || 'global']
      && data[internshipId || 'global'].users)
    || [],
  messages: filterByInternship(internshipId, messages.data),
  globalRooms: (data && data.global && data.global.rooms) || [],
  internshipRooms:
    (data && data[internshipId] && data[internshipId].rooms) || [],
  applicantId: applicant && applicant.pk,
  currentUser: currentUser && currentUser.data,
  loading,
  error,
});

export default connect(
  mapStateToProps,
  {
    getChatUsers,
    getChatMessages,
    getChatRooms,
  },
)(withRouter(ChatContainer));
