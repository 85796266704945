import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { scroller } from 'react-scroll';
import { Collapse, Error } from 'shared';
import Can from 'components/Can/Can';
import { section } from 'utils/access-rules/rules/internshipTask';
import { actions } from 'components/Can';
import Page from 'components/Page/Page';
import { getInternship } from 'actions/internshipsActions';
import ApplicantState from 'components/ApplicantState';
import ScheduleCalendar from 'pages/ScheduleCalendar';
import Localize from 'components/Localize';
import Chat from 'pages/Chat/ChatContainer';
import InternshipsRequestsList from 'components/InternshipsRequestsList/InternshipListContainer';
import { withRouter } from 'react-router-dom';
import DocumentsList from 'components/DocumentsList/DocumentsList';
import VisitNotes from './VisitNotes';
import SpecialTasks from './SpecialTasks';
import LoadingInternshipPage from './LoadingInternshipPage';
import InternshipRelatedBoxes from './InternshipRelatedBoxes';
import {
  getOnsiteVisitText,
  getAddOnsiteVisitSubText,
  getOltTasksText,
  getOltTasksSubText,
  getStateText,
  getStateSubText,
  getChatText,
  getChatSubText,
  getCalendarText,
  getCalendarSubText,
  getRequestText,
  getRequestSubText,
  getDocumentsText,
  getDocumentsSubText,
} from './applicantProfileUtils';

const ApplicantProfile = ({
  internship,
  getInternship,
  loading,
  assessmentLoading,
  error,
  history,
  user,
  selectedInternshipId,
  applicant,
  getApplicants,
  language,
}) => {
  const stableGetInternship = useCallback(getInternship, [
    internship,
    selectedInternshipId,
    language,
  ]);

  useEffect(() => {
    if (!selectedInternshipId) return;
    if (!internship) stableGetInternship(selectedInternshipId);
  }, [stableGetInternship, internship, selectedInternshipId]);

  useEffect(() => {
    if (loading || assessmentLoading) return;
    setTimeout(() => {
      scroller.scrollTo(history.location.hash.replace('#', ''));
    }, 1000);
  }, [loading, assessmentLoading, history.location.hash]);
  const scrollTo = (to) => {
    if (to) {
      scroller.scrollTo(to, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  };
  if (loading) return <LoadingInternshipPage withoutHeader userRole={user.role} />;
  if (error) {
    return (
      <Page withJobAdvisor={false}>
        <Error />
      </Page>
    );
  }
  const { esuser } = applicant;
  const { first_name } = esuser.user;
  return (
    <Localize>
      {({ translate }) => (
        <div>
          {internship && (
            <Collapse
              collapseInOpen
              headerText={translate(getStateText(user.role))}
              previewText={(
                <Can I={actions.see} a={section.previewText}>
                  {translate(getStateSubText(user.role))}
                </Can>
              )}
            >
              <ApplicantState states={internship.states} scrollTo={scrollTo} />
            </Collapse>
          )}
          {internship && (
            <Can I={actions.see} a={section.onSiteAppointment}>
              <Collapse
                onlyOpen
                collapseInOpen
                headerText={translate(getOnsiteVisitText(user.role), {
                  name: first_name,
                })}
                previewText={(
                  <Can I={actions.see} a={section.previewText}>
                    {translate(getAddOnsiteVisitSubText(user.role), {
                      name: first_name,
                    })}
                  </Can>
                )}
              >
                <VisitNotes firstName={first_name} internship={internship} />
              </Collapse>
            </Can>
          )}
          <Can I={actions.see} a={section.specialTasks}>
            <Collapse
              id="special-tasks"
              collapseInOpen
              onlyOpen
              headerText={translate(getOltTasksText(user.role), {
                name: first_name,
              })}
              previewText={(
                <Can I={actions.see} a={section.previewText}>
                  {translate(getOltTasksSubText(user.role), {
                    name: first_name,
                  })}
                </Can>
              )}
            >
              <SpecialTasks
                internshipId={internship ? internship.pk : null}
                applicant={applicant}
              />
            </Collapse>
          </Can>
          <Collapse
            id="chat"
            collapseInOpen
            headerText={translate(getChatText(user.role))}
            previewText={(
              <Can I={actions.see} a={section.previewText}>
                {translate(getChatSubText(user.role))}
              </Can>
            )}
          >
            <Chat
              internshipId={internship && internship.pk}
              applicant={applicant}
            />
          </Collapse>
          <Collapse
            id="calendar"
            collapseInOpen
            headerText={translate(getCalendarText(user.role), {
              name: first_name,
            })}
            previewText={(
              <Can I={actions.see} a={section.previewText}>
                <>{translate(getCalendarSubText(user.role))}</>
              </Can>
            )}
          >
            <ScheduleCalendar
              internshipId={internship && internship.pk}
              shouldFetchEvents={false}
              shouldFetchSpecialTasks={false}
            />
          </Collapse>
          {!internship && (
            <Collapse
              collapseInOpen
              headerText={translate(getDocumentsText(user.role))}
              previewText={(
                <Can I={actions.see} a={section.previewText}>
                  {translate(getDocumentsSubText(user.role))}
                </Can>
              )}
            >
              <DocumentsList
                documents={[
                  ...applicant.uploaded_documents.map((doc) => ({
                    key: `document${doc.pk}`,
                    text: translate('application-document'),
                    link: doc.image || doc.cv,
                    nameFile: `${translate('application-document')}_of_${
                      user.user.first_name
                    }_${user.user.last_name}`,
                  })),
                ].filter((doc) => doc)}
              />
            </Collapse>
          )}
          {internship && (
            <InternshipRelatedBoxes
              translate={translate}
              user={user}
              esuser={esuser}
              internship={internship}
              {...internship}
            />
          )}
          <Can I={actions.see} a={section.internshipRequest}>
            <Collapse
              id="your-practices"
              collapseInOpen
              headerText={translate(getRequestText(user.role))}
              previewText={(
                <Can I={actions.see} a={section.previewText}>
                  {translate(getRequestSubText(user.role), {
                    name: first_name,
                  })}
                </Can>
              )}
            >
              <InternshipsRequestsList
                applicantId={applicant.pk}
                getApplicants={getApplicants}
              />
            </Collapse>
          </Can>
        </div>
      )}
    </Localize>
  );
};

ApplicantProfile.propTypes = {
  applicant: PropTypes.shape({
    esuser: PropTypes.shape({
      user: PropTypes.shape({
        first_name: PropTypes.string,
      }),
    }),
    pk: PropTypes.number.isRequired,
    uploaded_documents: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  internship: PropTypes.shape({
    pk: PropTypes.number,
    states: PropTypes.array,
  }),
  getInternship: PropTypes.func.isRequired,
  getApplicants: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  assessmentLoading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      hash: PropTypes.string,
    }),
  }).isRequired,
  selectedInternshipId: PropTypes.number.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string,
    user: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  language: PropTypes.string.isRequired,
};

ApplicantProfile.defaultProps = {
  error: null,
  internship: null,
};

const mapStateToProps = (
  { internships: { data, loading, error }, currentUser, assessment },
  { selectedInternshipId },
) => ({
  internship: data.byId[selectedInternshipId],
  loading,
  // we need this to know when assessment content is loaded
  assessmentLoading: assessment.loading,
  error,
  user: currentUser && currentUser.data,
});

export default connect(mapStateToProps, { getInternship })(
  withRouter(ApplicantProfile),
);
