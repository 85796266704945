import React from 'react';
import PropTypes from 'prop-types';
import Textarea from 'components/Form/Textarea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Form, SecondaryButton, InputField } from 'shared';
import Localize from 'components/Localize';

import {
  ChatWrapper,
  ChatTitle,
  InputFieldWrapper,
  ButtonClose,
} from './ActionDropdownStyled';

const SendMessage = ({ handleSubmit, submitButtonText, handleClose }) => {
  const onKeyDown = (event, form) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit(form.values, form);
    }
  };
  return (
    <Form
      initialValues={{
        message: '',
      }}
      validateSchema={{
        message: { required: true },
      }}
      onSubmit={handleSubmit}
    >
      {(form) => (
        <ChatWrapper>
          <ChatTitle>
            <Localize id={submitButtonText} />
          </ChatTitle>
          <InputFieldWrapper>
            <Localize>
              {({ translate }) => (
                <InputField
                  name="message"
                  onKeyDown={(e) => onKeyDown(e, form)}
                  component={Textarea}
                  placeholder={translate('type-message')}
                  rows={5}
                />
              )}
            </Localize>
          </InputFieldWrapper>
          <SecondaryButton
            type="submit"
            size="small"
            disableBoxShadow
            loading={form.isSubmitting}
          >
            <Localize id={submitButtonText} />
          </SecondaryButton>
          <ButtonClose type="button" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </ButtonClose>
        </ChatWrapper>
      )}
    </Form>
  );
};

SendMessage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SendMessage;
