import authApi from 'api/AuthApi';
import accessTokenStorage from 'shared/lib/lib/accessTokenStorage';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getAuthUser() {
  return createAction({
    type: types.GET_CURRENT_USER,
    callAPI: () => authApi.getAuthUser(),
    shouldCallAPI: (state) => !state.currentUser.data,
  });
}

export function login(email, password) {
  return (dispatch) => authApi.login(email, password).then((data) => {
    accessTokenStorage.set(data.key);
    return dispatch(getAuthUser());
  });
}

export function logout() {
  return (dispatch) => authApi.logout().then(() => {
    accessTokenStorage.remove();
    dispatch({ type: types.LOGOUT });
  });
}
