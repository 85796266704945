import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { device } from 'styles/device';

export const Blocks = styled.div`
  display: flex;
  align-items: center;
  @media ${device.mobile} {
    flex-direction: column;
  }
  margin-top: 20px;
  @media ${device.mobile} {
    margin-top: 10px;
  }
`;

export const Block = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  flex: 1;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  color: ${({ theme }) => theme.textColor};
  & + & {
    margin-left: 20px;
  }
  @media ${device.mobile} {
    width: 100%;
    & + & {
      margin-left: 0;
      margin-top: 10px;
    }
  }
  @media ${device.tablet} {
    font-size: 14px;
  }
`;

export const BlockIcon = styled(ReactSVG)`
  margin-right: 30px;
  height: 70px;
  @media ${device.tablet} {
    svg {
      width: 50px;
    }
  }
`;
