import React from 'react';
import PropTypes from 'prop-types';
import { DateDividerWrapper, Date } from './DateDividerStyled';

function DateDivider({ children }) {
  return (
    <DateDividerWrapper>
      <Date>{children}</Date>
    </DateDividerWrapper>
  );
}

DateDivider.propTypes = {
  children: PropTypes.array.isRequired,
};

export default DateDivider;
