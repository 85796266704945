'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _GeoApi = require('../../../api/GeoApi');

var _GeoApi2 = _interopRequireDefault(_GeoApi);

var _ = require('./..');

var _reactAutosuggest = require('react-autosuggest');

var _reactAutosuggest2 = _interopRequireDefault(_reactAutosuggest);

var _CitySelectStyled = require('./CitySelectStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CitySelect = function (_React$Component) {
  _inherits(CitySelect, _React$Component);

  function CitySelect() {
    _classCallCheck(this, CitySelect);

    var _this = _possibleConstructorReturn(this, _React$Component.call(this));

    _this.handleSuggestionsFetchRequested = function (_ref) {
      var value = _ref.value;

      var inputValue = value.trim();
      if (inputValue.length <= 1) return;
      _GeoApi2.default.getCities(inputValue, 10).then(function (items) {
        _this.setState({
          suggestions: items.map(function (item) {
            return item.name;
          })
        });
      });
    };

    _this.handleSuggestionsClearRequested = function () {
      _this.setState({
        suggestions: []
      });
    };

    _this.handleChange = function (event, _ref2) {
      var newValue = _ref2.newValue;
      var _this$props = _this.props,
          onChange = _this$props.onChange,
          name = _this$props.name;

      onChange({
        target: {
          name: name,
          value: newValue
        },
        persist: event.persist
      });
    };

    _this.state = {
      suggestions: []
    };
    return _this;
  }

  CitySelect.prototype.render = function render() {
    var suggestions = this.state.suggestions;
    var value = this.props.value;

    var inputProps = _extends({}, this.props, {
      value: value || '',
      onChange: this.handleChange
    });
    return _react2.default.createElement(
      _CitySelectStyled.CitySelectWrap,
      null,
      _react2.default.createElement(_reactAutosuggest2.default, {
        suggestions: suggestions,
        onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
        onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
        getSuggestionValue: function getSuggestionValue(value) {
          return value;
        },
        renderSuggestion: function renderSuggestion(value) {
          return value;
        },
        renderInputComponent: function renderInputComponent(inputProperty) {
          return _react2.default.createElement(_.TextInput, inputProperty);
        },
        inputProps: inputProps
      })
    );
  };

  return CitySelect;
}(_react2.default.Component);

CitySelect.propTypes = process.env.NODE_ENV !== "production" ? {
  value: _propTypes2.default.string,
  onChange: _propTypes2.default.func.isRequired,
  name: _propTypes2.default.string.isRequired
} : {};

CitySelect.defaultProps = {
  value: null
};

exports.default = CitySelect;
module.exports = exports['default'];