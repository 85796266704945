import React from 'react';
import PropTypes from 'prop-types';

import Localize from 'components/Localize';
import { roles } from 'utils/access-rules/constants';
import Page from 'components/Page/Page';
import { PageWrapper } from '../ApplicantProfileStyled';
import DefaultInternshipPage from './DefaultInternshipPage';

const LoadingInternshipPage = ({ withoutHeader, userRole }) => {
  if (withoutHeader) {
    return (
      <Localize>
        {({ translate }) => (
          <PageWrapper>
            <DefaultInternshipPage
              userRole={userRole}
              translate={translate}
              withoutHeader={withoutHeader}
            />
          </PageWrapper>
        )}
      </Localize>
    );
  }
  return (
    <Localize>
      {({ translate }) => (
        <PageWrapper>
          <Page withJobAdvisor={false} pageIsLoading>
            <DefaultInternshipPage translate={translate} userRole={userRole} />
          </Page>
        </PageWrapper>
      )}
    </Localize>
  );
};

LoadingInternshipPage.propTypes = {
  withoutHeader: PropTypes.bool,
  userRole: PropTypes.string,
};

LoadingInternshipPage.defaultProps = {
  withoutHeader: false,
  userRole: roles.Teacher,
};

export default LoadingInternshipPage;
