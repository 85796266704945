'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _noMessages = require('../../styles/images/no-messages.svg');

var _noMessages2 = _interopRequireDefault(_noMessages);

var _NoMessagesIconStyled = require('./NoMessagesIconStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NoMessagesIcon = function NoMessagesIcon() {
  return _react2.default.createElement(_NoMessagesIconStyled.IconStyled, { src: _noMessages2.default });
};

exports.default = NoMessagesIcon;
module.exports = exports['default'];