import styled from 'styled-components';
import { device } from 'styles/device';

export const FilterPanelWrap = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 45px;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeightUpperBold};
  color: ${({ theme }) => theme.secondary};

  @media ${device.mobile} {
    justify-content: space-between;
    padding-left: 0;
  }
`;

export const FilterIconWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);

  & svg {
    width: 100%;
  }

  @media ${device.mobile} {
    display: none;
  }
`;
