import calendarApi from 'api/CalendarApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getEvents(internshipId, date) {
  const _internshipId = internshipId || 'global';
  return createAction({
    type: types.GET_CALENDAR_EVENTS,
    callAPI: () => calendarApi.getEvents(internshipId, date).then((events) => ({
      internshipId: _internshipId,
      data: events,
    })),
  });
}

export function getOnSideAppointments(internshipId) {
  return createAction({
    type: types.GET_CALENDAR_EVENTS,
    callAPI: () => calendarApi.getOnSideAppointments(internshipId).then((events) => ({
      internshipId,
      data: events,
    })),
  });
}

export function editInviteeStatus(id, data) {
  return createAction({
    type: types.EDIT_INVITEE_STATUS,
    callAPI: () => calendarApi.editInviteeStatus(id, data),
  });
}

export function deleteEvent(event) {
  return (dispatch) => calendarApi.deleteEvent(event.pk).then(() => dispatch({
    type: types.DELETE_CALENDAR_EVENT,
    data: event,
  }));
}

export function addEvent(event) {
  return (dispatch) => calendarApi.addEvent(event).then((newEvent) => dispatch({
    type: types.ADD_CALENDAR_EVENT,
    data: newEvent,
  }));
}

export function editEvent(eventId, event) {
  return (dispatch) => calendarApi.editEvent(eventId, event).then((newEvent) => dispatch({
    type: types.EDIT_CALENDAR_EVENT,
    data: newEvent,
  }));
}

/*
export function addNotificationEvent(newEvent, events) {
   const updatedEvents = addEventHelper(newEvent, events);
  return {
    type: types.ADD_CALENDAR_EVENT,
    data: updatedEvents,
  };
}
*/
/*
export function deleteNotificationEvent(newEvent, events) {
   const updatedEvents = deleteEventHelper(newEvent, events);
  return {
    type: types.ADD_CALENDAR_EVENT,
    data: updatedEvents,
  };
}
*/
