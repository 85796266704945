import ServerApi from 'shared/lib/api/ServerApi';
import { getDefaultLanguage } from 'shared/lib/lib/languages';

class InternshipApi extends ServerApi {
  getApplicants(params) {
    if (params) {
      let parameters = '';
      Object.keys(params).forEach((key) => {
        if (
          Object.prototype.hasOwnProperty.call(params, key)
          && params[key]
          && Object.prototype.hasOwnProperty.call(params[key], 'value')
        ) {
          parameters = `${parameters + key}=${params[key].value}&`;
        }
      });
      return super.get(`/internship/applicant/?${parameters}`);
    }
    return super.get('/internship/applicant/');
  }

  getApplicant(applicantId) {
    return super.get(`/internship/applicant/${applicantId}/`);
  }

  async getInternshipById(pk) {
    let internship = await super.get(
      `/internship/internship/${pk}/?language=${getDefaultLanguage()}`,
    );
    const teachers = await super.get(
      `/internship/internship/${pk}/teachers/?language=${getDefaultLanguage()}`,
    );
    const uploaded_documents = await super.get(
      `/internship/internship/${pk}/uploaded_documents/?language=${getDefaultLanguage()}`,
    );
    const calendar_events = await super.get(
      `/internship/internship/${pk}/calendar_events/?language=${getDefaultLanguage()}`,
    );
    internship = {
      ...internship,
      teachers,
      uploaded_documents,
      calendar_events,
    };
    return internship;
  }

  completePresentation(internshipId, data) {
    return super.patch(`/internship/internship/${internshipId}/`, {
      presentation_completed: data,
    });
  }
}

export default new InternshipApi();
