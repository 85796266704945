import ServerApi from 'shared/lib/api/ServerApi';
import { getDefaultLanguage } from 'shared/lib/lib/languages';

class ReportsApi extends ServerApi {
  getReport(internshipId) {
    return super.get(
      `/internship/internship-update/${internshipId}/?language=${getDefaultLanguage()}`,
    );
  }

  editDay(dayId, dayData) {
    return super.put(`/internship/day/${dayId}/`, dayData);
  }

  editConclusion(internshipId, data) {
    return super.patch(`/internship/internship-update/${internshipId}/`, {
      conclusion: data.report,
    });
  }

  uploadDayAttachment(dayId, file) {
    return super.patch(`/internship/day-attachment/${dayId}/`, { file });
  }

  addDay(internship) {
    return super.post('/internship/day/', internship);
  }

  deleteDayAttachment(dayPk) {
    return super.delete(`/internship/day-attachment/${dayPk}/`);
  }

  submitReport(internshipId) {
    return super.patch(`/internship/internship-update/${internshipId}/`, {
      submitted: true,
    });
  }

  resendReport(internshipId) {
    return super.patch(`/internship/internship-update/${internshipId}/`, {
      submitted: false,
    });
  }

  addDayTasks(dayId, tasks) {
    return super.patch(`/internship/day/${dayId}/`, { tasks });
  }

  editDayTask(taskId, task) {
    return super.patch(`/internship/day-task/${taskId}/`, task);
  }

  deleteDayTask(taskId) {
    return super.delete(`/internship/day-task/${taskId}/`);
  }
}

export default new ReportsApi();
