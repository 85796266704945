'use strict';

exports.__esModule = true;
exports.ContactBoxTitle = exports.ContactBoxContainer = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  background-color: ', ';\n  width: 440px;\n  height: 245px;\n  border-radius: ', ';\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding-top: 40px;\n  button {\n    width: 375px;\n    margin: 5px;\n  }\n  @media ', ' {\n    width: 280px;\n    height: 220px;\n    padding-top: 30px;\n    button {\n      width: 260px;\n    }\n  }\n'], ['\n  background-color: ', ';\n  width: 440px;\n  height: 245px;\n  border-radius: ', ';\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding-top: 40px;\n  button {\n    width: 375px;\n    margin: 5px;\n  }\n  @media ', ' {\n    width: 280px;\n    height: 220px;\n    padding-top: 30px;\n    button {\n      width: 260px;\n    }\n  }\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  color: ', ';\n  font-size: 18px;\n  font-weight: ', ';\n  text-align: center;\n  margin-bottom: 30px;\n  @media ', ' {\n    margin-bottom: 10px;\n  }\n'], ['\n  color: ', ';\n  font-size: 18px;\n  font-weight: ', ';\n  text-align: center;\n  margin-bottom: 30px;\n  @media ', ' {\n    margin-bottom: 10px;\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _device = require('../../styles/device');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var ContactBoxContainer = exports.ContactBoxContainer = _styledComponents2.default.div(_templateObject, function (_ref) {
  var theme = _ref.theme;
  return theme.secondary;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.borderRadiusDefault;
}, _device.device.mobile);

var ContactBoxTitle = exports.ContactBoxTitle = _styledComponents2.default.h3(_templateObject2, function (_ref3) {
  var theme = _ref3.theme;
  return theme.white;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.fontWeightBold;
}, _device.device.mobile);