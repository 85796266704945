import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Can from 'components/Can/Can';
import { actions } from 'components/Can';
import { section } from 'utils/access-rules/rules/sections';
import { Info, Loader, Error } from 'shared';
import { getApplicants } from 'actions/internshipsActions';
import { setFilters, clearFilters } from 'actions/filterActions';
import { PERSONAL_INTERNSHIP_ID } from 'api/CalendarApi';
import Page from 'components/Page';
import ScheduleCalendar from 'pages/ScheduleCalendar';
import Localize from 'components/Localize';
import 'react-tippy/dist/tippy.css';
import ApplicantsList from 'components/Classroom/ApplicantsList';
import FilterPanel from 'components/Form/FilterPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WrapperWithTitleAndDesc from 'components/WrapperWithTitleAndDesc/WrapperWithTitleAndDesc';
import {
  faSortAlphaUp,
  faSortAlphaDown,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import FilteredInternshipList from './FilteredInternshipList';
import {
  CalendarWrap,
  ClassroomNameSelect,
  ClassroomSelect,
  InternshipListWrap,
  Search,
  ToolTip,
  SortApplicantButton,
  ClearButton,
  ClassroomAndSort,
} from './UserManagerPageStyled';
import {
  getMainDescriptionSlug,
  getMainTitleSlug,
  getCalendarSlug,
  getCalendarDescriptionSlug,
} from './userManagerPageUtils';

class UserManagerPage extends PureComponent {
  componentDidMount() {
    const { getApplicants } = this.props;
    getApplicants();
  }

  changeFilter = (name, value) => {
    const { filter, setFilters } = this.props;
    setFilters({ ...filter, [name]: value });
  };

  onTextChange = (e) => {
    this.changeFilter(e.target.name, e.target.value);
  };

  onSelectChange = (data, e) => {
    this.changeFilter(e.name, data ? data.value : '');
  };

  onSortClicked = () => {
    const { filter } = this.props;
    if (filter.sort === 0) {
      this.changeFilter('sort', 1);
    } else if (filter.sort === 1) {
      this.changeFilter('sort', 0);
    }
  };

  render() {
    const {
      applicants,
      loading,
      error,
      currentUser,
      filter,
      clearFilters,
    } = this.props;
    const {
      classroom, schoolName, sort, search, clearButtonVisible,
    } = filter;
    const classroomOptions = applicants
      .map(({ classroom }) => ({
        label: classroom.name,
        value: classroom.name,
      }))
      .filter((c, i, a) => a.map((q) => q.value).indexOf(c.value) === i);
    const schoolNameOptions = applicants
      .reduce((a, b) => {
        if (!a.find((element) => element.value === b.classroom.school.name)) {
          a.push({
            label: b.classroom.school.name,
            value: b.classroom.school.name,
          });
        }
        return a;
      }, [])
      .filter((c, i, a) => a.map((q) => q.value).indexOf(c.value) === i);

    return (
      <Page
        withBackButton={false}
        withJobAdvisor={false}
        title={<Localize id={getMainTitleSlug(currentUser.role)} />}
        description={<Localize id={getMainDescriptionSlug(currentUser.role)} />}
      >
        <FilterPanel>
          <Can I={actions.see} a={section.schoolFiltering}>
            <ToolTip
              title={schoolName}
              disabled={!schoolName.length}
              theme="light"
              arrow
              mobileWidth="100%"
            >
              <ClassroomNameSelect
                clearIndicator
                value={
                  schoolName
                    ? schoolNameOptions.find((o) => o.value === schoolName)
                    : ''
                }
                isActive={schoolName}
                options={schoolNameOptions}
                onChange={this.onSelectChange}
                name="schoolName"
                placeholder={<Localize id="school" />}
              />
            </ToolTip>
          </Can>
          <ClassroomAndSort>
            <Can I={actions.see} a={section.classRoomFiltering}>
              <ClassroomSelect
                clearIndicator
                value={
                  classroom
                    ? classroomOptions.find((o) => o.value === classroom)
                    : ''
                }
                isActive={classroom}
                onChange={this.onSelectChange}
                options={classroomOptions}
                name="classroom"
                placeholder={<Localize id="class" />}
              />
            </Can>
            <Can I={actions.see} a={section.sortApplicantByName}>
              <SortApplicantButton
                type="button"
                disableBoxShadow
                onClick={this.onSortClicked}
              >
                <FontAwesomeIcon
                  icon={sort === 0 ? faSortAlphaDown : faSortAlphaUp}
                />
              </SortApplicantButton>
            </Can>
          </ClassroomAndSort>
          {clearButtonVisible && (
            <ClearButton type="button" disableBoxShadow onClick={clearFilters}>
              <span className="text">
                <Localize id="reset-filter" />
              </span>
              <FontAwesomeIcon icon={faTimes} />
            </ClearButton>
          )}
          <Search name="search" onChange={this.onTextChange} value={search} />
        </FilterPanel>

        <FilteredInternshipList filterBy={filter} list={applicants}>
          {(filteredList) => (
            <InternshipListWrap>
              <ApplicantsList applicants={filteredList} />
              {loading && <Loader />}
              {error && <Error />}
              {filteredList.length <= 0 && !loading && !error && (
                <Info>
                  <Localize id="internships-not-found" />
                </Info>
              )}
            </InternshipListWrap>
          )}
        </FilteredInternshipList>
        <WrapperWithTitleAndDesc
          id="calendar"
          title={<Localize id={getCalendarSlug(currentUser.role)} />}
          description={
            <Localize id={getCalendarDescriptionSlug(currentUser.role)} />
          }
        >
          <CalendarWrap>
            <ScheduleCalendar
              internshipId={PERSONAL_INTERNSHIP_ID}
              shouldFetchSpecialTasks={false}
              shouldAddAbsence={false}
              isStudentProfile={false}
            />
          </CalendarWrap>
        </WrapperWithTitleAndDesc>
      </Page>
    );
  }
}

UserManagerPage.propTypes = {
  applicants: PropTypes.array.isRequired,
  getApplicants: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  filter: PropTypes.object.isRequired,
  currentUser: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }).isRequired,
};

UserManagerPage.defaultProps = {
  error: undefined,
};

const mapStateToProps = ({
  applicants: { data, loading, error },
  currentUser,
  filter,
}) => ({
  applicants: data && data.list ? data.list : [],
  loading,
  error,
  currentUser: currentUser.data,
  filter: filter.data,
});

export default connect(mapStateToProps, {
  getApplicants,
  setFilters,
  clearFilters,
})(UserManagerPage);
