import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Localize from 'components/Localize';
import Textarea from 'components/Form/Textarea';
import checkIcon from 'shared/lib/styles/images/check.svg';
import { updateInternshipLearningTask } from 'actions/internshipsActions';
import {
  Button, Error, Form, InputField,
} from 'shared';
import {
  TaskCardSection,
  TaskCardSectionTitle,
  TaskCardSectionContent,
  TaskCardsWrap,
  TaskCardWrapper,
  TaskFooter,
} from './TaskCardStyled';

const WriteRating = ({
  pk, rating, updateInternshipLearningTask,
}) => {
  const handleSubmit = (values) => updateInternshipLearningTask(pk, {
    company_rating: values.rating,
  });

  return (
    <Localize>
      {({ translate }) => (
        <Form
          initialValues={{
            rating: rating || '',
          }}
          validateSchema={{
            rating: { required: true },
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ errors, isSubmitting, dirty }) => (
            <TaskCardWrapper>
              <TaskCardsWrap>
                <TaskCardSection>
                  <TaskCardSectionTitle>
                    {translate('rating-company')}
                  </TaskCardSectionTitle>
                  <TaskCardSectionContent>
                    <InputField
                      rows={2}
                      name="rating"
                      placeholder={translate('no-text')}
                      component={Textarea}
                    />
                  </TaskCardSectionContent>
                </TaskCardSection>
              </TaskCardsWrap>
              {errors._error && <Error message={errors._error} />}
              <TaskFooter>
                <Button
                  type="submit"
                  disableBoxShadow
                  icon={checkIcon}
                  size="small"
                  color="secondary"
                  disabled={!dirty}
                  loading={isSubmitting}
                >
                  {translate('save')}
                </Button>
              </TaskFooter>
            </TaskCardWrapper>
          )}
        </Form>
      )}
    </Localize>
  );
};

WriteRating.propTypes = {
  updateInternshipLearningTask: PropTypes.func.isRequired,
  pk: PropTypes.number.isRequired,
  rating: PropTypes.string,
};

WriteRating.defaultProps = {
  rating: undefined,
};

export default connect(undefined, { updateInternshipLearningTask })(WriteRating);
