'use strict';

exports.__esModule = true;
exports.ButtonText = exports.ButtonLink = exports.BackButtonContainer = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  display: flex;\n  align-items: center;\n'], ['\n  display: flex;\n  align-items: center;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  background-color: ', ';\n  padding: 16px 30px;\n  border-radius: ', ';\n  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);\n  transition: all 0.5s;\n  svg {\n    font-size: 22px;\n    color: ', ';\n  }\n  &:hover {\n    svg {\n      color: ', ';\n    }\n  }\n  @media ', ' {\n    padding: 10px 24px;\n  }\n  @media ', ' {\n    padding: 4px 15px;\n    svg {\n      font-size: 16px;\n    }\n  }\n'], ['\n  background-color: ', ';\n  padding: 16px 30px;\n  border-radius: ', ';\n  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);\n  transition: all 0.5s;\n  svg {\n    font-size: 22px;\n    color: ', ';\n  }\n  &:hover {\n    svg {\n      color: ', ';\n    }\n  }\n  @media ', ' {\n    padding: 10px 24px;\n  }\n  @media ', ' {\n    padding: 4px 15px;\n    svg {\n      font-size: 16px;\n    }\n  }\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  color: ', ';\n  font-size: 24px;\n  font-weight: 500;\n  margin-left: 24px;\n  @media ', ' {\n    font-size: 18px;\n  }\n  @media ', ' {\n    font-size: 16px;\n    margin-left: 12px;\n  }\n'], ['\n  color: ', ';\n  font-size: 24px;\n  font-weight: 500;\n  margin-left: 24px;\n  @media ', ' {\n    font-size: 18px;\n  }\n  @media ', ' {\n    font-size: 16px;\n    margin-left: 12px;\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactRouterDom = require('react-router-dom');

var _device = require('../../styles/device');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var BackButtonContainer = exports.BackButtonContainer = _styledComponents2.default.div(_templateObject);

var ButtonLink = exports.ButtonLink = (0, _styledComponents2.default)(_reactRouterDom.Link)(_templateObject2, function (_ref) {
  var theme = _ref.theme;
  return theme.white;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.borderRadiusDefault;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.grayA0;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.textColor;
}, _device.device.tablet, _device.device.mobile);

var ButtonText = exports.ButtonText = _styledComponents2.default.div(_templateObject3, function (_ref5) {
  var theme = _ref5.theme;
  return theme.secondary;
}, _device.device.tablet, _device.device.mobile);