'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Spin = require('./Spin');

var _Spin2 = _interopRequireDefault(_Spin);

var _LoaderStyled = require('./LoaderStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Loader = function Loader(_ref) {
  var loading = _ref.loading,
      className = _ref.className,
      children = _ref.children,
      fullScreen = _ref.fullScreen;
  return _react2.default.createElement(
    _LoaderStyled.Loading,
    { fullScreen: fullScreen },
    loading && _react2.default.createElement(_Spin2.default, { className: 'spin' }),
    _react2.default.createElement(
      _LoaderStyled.LoadingContent,
      { className: className, isLoading: loading },
      children
    )
  );
};

Loader.propTypes = process.env.NODE_ENV !== "production" ? {
  loading: _propTypes2.default.bool,
  fullScreen: _propTypes2.default.bool,
  className: _propTypes2.default.string,
  children: _propTypes2.default.node
} : {};

Loader.defaultProps = {
  loading: true,
  fullScreen: false,
  className: undefined,
  children: undefined
};

exports.default = Loader;
module.exports = exports['default'];