import styled from 'styled-components';
import { Button } from 'shared';

export const ActionResultsWrapper = styled.div`
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 3px;
`;
export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0px;
`;
export const ActionResult = styled.div`
  margin: 10px 0px;
`;
export const CloseButton = styled(Button)`
  margin-top: 10px;
`;
