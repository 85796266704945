import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  button {
    width: 50%;
  }
  .btn-save {
    margin-right: 10px;
  }
  .btn-cancel {
    margin-left: 10px;
    display: flex;
    justify-content: center;
  }
`;

export const PeriodLabel = styled.div`
  color: ${({ theme }) => theme.textColor9D};
  padding-bottom: 10px;
`;

export const To = styled.div`
  color: ${({ theme }) => theme.textColor9D};
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
`;

export const StyledDateSelect = styled.div`
  .month {
    width: 100%;
  }
`;
