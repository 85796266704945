'use strict';

exports.__esModule = true;
exports.backendUrl = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _accessTokenStorage = require('../lib/accessTokenStorage');

var _accessTokenStorage2 = _interopRequireDefault(_accessTokenStorage);

var _languages = require('../lib/languages');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var backendUrl = exports.backendUrl = process.env.REACT_APP_BACKEND_URL;

function status(response) {
  if (response.ok) {
    return response;
  }
  if (response.status === 401) {
    // TODO: go to login page
  }
  var contentType = response.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return response.json()
    // eslint-disable-next-line prefer-promise-reject-errors
    .then(function (body) {
      return Promise.reject(_extends({}, body, { status: response.status }));
    });
  }
  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({ message: response.statusText });
}

function json(response) {
  if (response.status !== 204) {
    var contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    }
    return response;
  }
}

var ServerApi = function () {
  function ServerApi() {
    _classCallCheck(this, ServerApi);

    this.opts = function (bodyExist) {
      return {
        headers: _extends({}, _accessTokenStorage2.default.isExist() ? { Authorization: 'Token ' + _accessTokenStorage2.default.get() } : {}, {
          'Content-Language': (0, _languages.getDefaultLanguage)(),
          Accept: 'application/json'
        }, bodyExist ? { 'Content-type': 'application/json' } : {})
      };
    };
    this.bodyConverter = function (body) {
      return JSON.stringify(body);
    };
  }

  ServerApi.prototype.buildUrl = function buildUrl(path) {
    var useV1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    return '' + backendUrl + (useV1 ? '/v1' : '') + path;
  };

  /**
   * Make GET ajax request.
   * @param url - requested url
   * @param useV1
   * @param useRecruiting
   * @returns {Promise.<TResult>}
   */


  ServerApi.prototype.get = function get(url) {
    var useV1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    return fetch(this.buildUrl(url, useV1), _extends({
      method: 'get'
    }, this.opts(false))).then(status).then(json);
  };

  ServerApi.prototype.post = function post(url, body) {
    var useV1 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

    return fetch(this.buildUrl(url, useV1), _extends({
      method: 'post'
    }, this.opts(body), {
      body: this.bodyConverter(body)
    })).then(status).then(json);
  };

  ServerApi.prototype.postWithCustomUrl = function postWithCustomUrl(url, body) {
    return fetch(url, _extends({
      method: 'post'
    }, this.opts(body), {
      body: this.bodyConverter(body)
    })).then(status).then(json);
  };

  ServerApi.prototype.postFormData = function postFormData(url, body) {
    return fetch(url, {
      method: 'post',
      body: body
    }).then(status).then(json);
  };

  ServerApi.prototype.upload = function upload(url, body, onProgress) {
    var _this = this;

    var useV1 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

    var formData = new FormData();

    Object.keys(body).forEach(function (name) {
      // issue in firefox to pass 3 param undefined
      if (name === 'video') {
        formData.append(name, body[name], body[name].name);
      } else {
        formData.append(name, body[name]);
      }
    });

    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', _this.buildUrl(url, useV1), true);

      var _opts = _this.opts(false),
          headers = _opts.headers;
      // eslint-disable-next-line guard-for-in


      for (var name in headers) {
        xhr.setRequestHeader(name, headers[name]);
      }
      xhr.upload.onprogress = function (e) {
        if (e.lengthComputable) {
          var percentComplete = e.loaded / e.total * 100;
          onProgress(Math.round(percentComplete));
        }
      };
      xhr.onload = function onLoad() {
        var contentType = this.getResponseHeader('content-type');
        if (contentType && contentType.includes('application/json')) {
          var _body = JSON.parse(this.response);
          if (this.status >= 300) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(_extends({ status: this.status }, _body));
          } else {
            resolve(_body);
          }
        } else {
          // 500
          reject(this.response);
        }
      };
      xhr.onerror = function () {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ status: _this.status });
      };
      xhr.send(formData);
    });
  };

  ServerApi.prototype.patch = function patch(url, body) {
    var useV1 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

    return fetch(this.buildUrl(url, useV1), _extends({
      method: 'PATCH'
    }, this.opts(body), {
      body: this.bodyConverter(body)
    })).then(status).then(json);
  };

  ServerApi.prototype.patchXHR = function patchXHR(url, body, onProgress) {
    var _this2 = this;

    var useV1 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('PATCH', _this2.buildUrl(url, useV1), true);

      var _opts2 = _this2.opts(true),
          headers = _opts2.headers;
      // eslint-disable-next-line guard-for-in


      for (var name in headers) {
        xhr.setRequestHeader(name, headers[name]);
      }
      xhr.upload.onprogress = function (e) {
        if (e.lengthComputable) {
          var percentComplete = e.loaded / e.total * 100;
          onProgress(Math.round(percentComplete));
        }
      };
      xhr.onload = function onLoad() {
        var contentType = this.getResponseHeader('content-type');
        if (xhr.status === 400) {
          reject(this.response);
        }
        if (xhr.status === 405) {
          reject(JSON.parse(this.response));
        }
        if (contentType && contentType.includes('application/json')) {
          resolve(JSON.parse(this.response));
        } else {
          // 500
          reject(this.response);
        }
      };
      xhr.onerror = function () {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ status: _this2.status });
      };
      xhr.send(_this2.bodyConverter(body));
    });
  };

  /**
   * Make PUT ajax request.
   * @param url - requested url
   * @param body - object {key: value}
   * @returns {Promise.<TResult>}
   */


  ServerApi.prototype.put = function put(url, body) {
    return fetch(this.buildUrl(url), _extends({
      method: 'put'
    }, this.opts(body), {
      body: this.bodyConverter(body)
    })).then(status).then(json);
  };

  /**
   * Make DELETE ajax request.
   * @param url - requested url
   * @param body - object {key: value}
   * @returns {Promise.<TResult>}
   */


  ServerApi.prototype.delete = function _delete(url, body) {
    return fetch(this.buildUrl(url), _extends({
      method: 'delete'
    }, this.opts(body), {
      body: this.bodyConverter(body)
    })).then(status).then(json);
  };

  return ServerApi;
}();

exports.default = ServerApi;