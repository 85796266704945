import ServerApi from 'shared/lib/api/ServerApi';
import { getDefaultLanguage } from 'shared/lib/lib/languages';

class ApplicationApi extends ServerApi {
  getAssessment(internshipId) {
    return super.get(
      `/internship/assessment/${internshipId}/?language=${getDefaultLanguage()}`,
    );
  }

  getAssessmentAnswerChoices() {
    return super.get(
      `/internship/assessment-answers/?language=${getDefaultLanguage()}`,
    );
  }

  changeAssessmentItems(data) {
    return super.post('/internship/assessment-update/', data);
  }

  changeAssessment(pk, data) {
    return super.patch(`/internship/assessment/${pk}/`, data);
  }

  resendAssessment(pk, data) {
    return super.patch(`/internship/assessment/${pk}/`, data);
  }
}

export default new ApplicationApi();
