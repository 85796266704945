import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';
import initialState from 'store/initialState';

const dataReducer = (state = initialState.entityInstruction, action) => {
  switch (action.type) {
    case successType(types.GET_ENTITY_INSTRUCTION):
      if (action.response) {
        return action.response?.[0];
      }
      return state;
    case successType(types.SET_ENTITY_INSTRUCTION_RATING):
      if (action.response) {
        return {
          ...state,
          rating: action.response.value,
        };
      }
      return state;
    default:
      return state;
  }
};

export default createReducer([types.GET_ENTITY_INSTRUCTION], dataReducer);
