'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Button = require('../../Button');

var _Localize = require('../../Localize');

var _Localize2 = _interopRequireDefault(_Localize);

var _Alert = require('../../Alert');

var _ = require('./..');

var _2 = _interopRequireDefault(_);

var _PromptStyled = require('./PromptStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var Prompt = function Prompt(_ref) {
  var trigger = _ref.trigger,
      confirm = _ref.confirm,
      children = _ref.children,
      rest = _objectWithoutProperties(_ref, ['trigger', 'confirm', 'children']);

  var _useState = (0, _react.useState)(false),
      removing = _useState[0],
      setRemoving = _useState[1];

  var _useState2 = (0, _react.useState)(false),
      failed = _useState2[0],
      setFailed = _useState2[1];

  var handleClickWith = function handleClickWith(close) {
    return function () {
      setRemoving(true);
      setFailed(false);
      Promise.resolve(confirm()).then(function () {
        setRemoving(false);
        close();
      }).catch(function () {
        setFailed(true);
        setRemoving(false);
      });
    };
  };
  return _react2.default.createElement(
    _2.default,
    _extends({ trigger: trigger }, rest),
    function (close) {
      return _react2.default.createElement(
        _PromptStyled.PromptWrap,
        null,
        _react2.default.createElement(
          _PromptStyled.PromptBody,
          null,
          children || _react2.default.createElement(_Localize2.default, { id: 'are-you-sure' })
        ),
        failed && _react2.default.createElement(_Alert.Error, { general: true }),
        _react2.default.createElement(
          _PromptStyled.PromptButtons,
          null,
          _react2.default.createElement(
            _Button.PrimaryButton,
            {
              className: 'prompt-button',
              size: 'small',
              loading: removing,
              onClick: handleClickWith(close)
            },
            _react2.default.createElement(_Localize2.default, { id: 'yes' })
          ),
          _react2.default.createElement(
            _Button.SecondaryButton,
            {
              className: 'prompt-button',
              size: 'small',
              onClick: close
            },
            _react2.default.createElement(_Localize2.default, { id: 'no' })
          )
        )
      );
    }
  );
};

Prompt.propTypes = process.env.NODE_ENV !== "production" ? {
  trigger: _propTypes2.default.node,
  confirm: _propTypes2.default.func.isRequired,
  children: _propTypes2.default.node
} : {};

Prompt.defaultProps = {
  children: undefined,
  trigger: undefined
};

exports.default = Prompt;
module.exports = exports['default'];