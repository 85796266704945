import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';

export const isSpecialTaskEvent = (kind) => {
  if (
    kind === 'APPLICANT_ENTRY_CREATED'
    || kind === 'APPLICANT_3_DAYS_DEADLINE'
    || kind === 'APPLICANT_1_WEEK_DEADLINE'
    || kind === 'APPLICANT_1_WEEK_DEADLINE'
    || kind === 'TEACHER_DEADLINE_EXPIRED'
    || kind === 'TEACHER_ENTRY_COMPLETED'
  ) return true;
  return false;
};

const dataReducer = (state = [], action) => {
  switch (action.type) {
    case successType(types.GET_NOTIFICATIONS):
      if (action.response) {
        return [...action.response];
      }
      return state;
    case types.ADD_NOTIFICATION:
      if (action.data) {
        return [action.data, ...state];
      }
      return state;
    case types.DELETE_NOTIFICATION:
      if (action.data) {
        return state.filter((n) => n.pk !== action.data.pk);
      }
      return state;
    case successType(types.MARK_NOTIFICATION_AS_READ):
      if (action.response) {
        return state.map((notification) => notification.pk === action.response.pk
          ? action.response
          : notification);
      }
      return state;
    case types.LOGOUT:
      return [];
    default:
      return state;
  }
};

export default createReducer([types.GET_NOTIFICATIONS], dataReducer);
