import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  markNotificationAsRead,
  deleteNotification,
} from 'actions/notificationsActions';
import plusIcon from 'styles/images/svg/plus.svg';
import Localize from 'components/Localize';
import Can from 'components/Can/Can';
import { actions } from 'components/Can';
import { section } from 'utils/access-rules/rules/internshipTask';
import {
  EVENT_TYPES,
  getEventListByDate,
  addEvent as addEventHelper,
} from 'utils/calendarHelpers';
import {
  EventInfoContainer,
  EventInfoTop,
  EventInfoTopLeft,
  EventInfoTopRight,
  EventInfoBottom,
  EventInfoHeroDate,
  EventInfoHeroDayNumber,
  EventInfoHeroDayMonth,
  Button,
} from './EventInfoStyled';

import { TYPE_SECTION } from './EventTypeSection/EventTypeSectionStyled';
import EventTypeSection from './EventTypeSection/EventTypeSection';

function EventInfo({
  language,
  date,
  navDate,
  meetList,
  missesList,
  taskList,
  onsiteAppointments,
  onEventAdd,
  onEventEdit,
  onEventDelete,
  notifications,
  calendar,
  lastNotification,
  deleteNotification,
  markNotificationAsRead,
  shouldAddAbsence,
  children,
  onGetEvents,
}) {
  const parsedDay = moment(date);
  parsedDay.locale(language);
  const dayNumber = parsedDay.date();
  const day = parsedDay.format('dddd');

  const currentYear = moment(navDate).year();
  const currentMonth = moment(navDate).format('MM');
  const currentDay = date.format('DD');

  const onSiteEvents = useMemo(
    () => getEventListByDate(onsiteAppointments, currentYear, currentMonth) || [],
    [onsiteAppointments, currentYear, currentMonth],
  );
  const meet = useMemo(
    () => getEventListByDate(meetList, currentYear, currentMonth, currentDay) || [],
    [meetList, currentYear, currentMonth, currentDay],
  );
  const misses = useMemo(
    () => getEventListByDate(missesList, currentYear, currentMonth) || [],
    [missesList, currentYear, currentMonth],
  );
  const tasks = useMemo(
    () => getEventListByDate(taskList, currentYear, currentMonth) || [],
    [taskList, currentYear, currentMonth],
  );
  useEffect(() => {
    /*    ///////here delete and add////////// */
    if (
      lastNotification
      && lastNotification.length
      && lastNotification[0].instance
    ) {
      // const updatedEvents =
      addEventHelper(lastNotification[0].instance, calendar);
      // addNotificationEvent(lastNotification[0].instance, calendar);
    }
  }, [lastNotification, calendar]);

  return (
    <EventInfoContainer>
      <EventInfoTop>
        <EventInfoTopLeft>
          <EventInfoHeroDate>
            <EventInfoHeroDayNumber>{dayNumber}</EventInfoHeroDayNumber>
            <EventInfoHeroDayMonth>{day}</EventInfoHeroDayMonth>
          </EventInfoHeroDate>
        </EventInfoTopLeft>
        <EventInfoTopRight>
          <Button
            type="button"
            icon={plusIcon}
            color="secondary"
            size="small"
            onClick={() => onEventAdd(EVENT_TYPES.APPOINTMENT)}
          >
            <Localize id="add-apointment" />
          </Button>
          {shouldAddAbsence && (
            <Can I={actions.see} a={section.absenceEntry}>
              <Button
                type="button"
                icon={plusIcon}
                color="secondary"
                size="small"
                onClick={() => onEventAdd(EVENT_TYPES.ABSENCE)}
              >
                <Localize id="add-absence" />
              </Button>
            </Can>
          )}
        </EventInfoTopRight>
        {children}
      </EventInfoTop>
      <EventInfoBottom>
        <EventTypeSection
          title={<Localize id="onsite-appointment" />}
          typeSection={TYPE_SECTION.onsite}
          events={onSiteEvents}
          date={date}
          navDate={navDate}
          markNotificationAsRead={markNotificationAsRead}
          language={language}
          lastNotification={lastNotification}
          notifications={notifications}
          onEventDelete={onEventDelete}
          deleteNotification={deleteNotification}
        />
        <EventTypeSection
          title={<Localize id="apointments" />}
          typeSection={TYPE_SECTION.meet}
          events={meet}
          date={date}
          onEventEdit={onEventEdit}
          onEventDelete={onEventDelete}
          navDate={navDate}
          markNotificationAsRead={markNotificationAsRead}
          notifications={notifications}
          lastNotification={lastNotification}
          deleteNotification={deleteNotification}
          onGetEvents={onGetEvents}
        />
        <EventTypeSection
          title={<Localize id="absences" />}
          typeSection={TYPE_SECTION.missed}
          currentMissesList={misses}
          date={date}
          onEventEdit={onEventEdit}
          onEventDelete={onEventDelete}
          navDate={navDate}
          markNotificationAsRead={markNotificationAsRead}
          language={language}
          lastNotification={lastNotification}
          notifications={notifications}
          deleteNotification={deleteNotification}
        />
        <EventTypeSection
          title={<Localize id="tasks" />}
          typeSection={TYPE_SECTION.tasks}
          currentTaskList={tasks}
          date={date}
          navDate={navDate}
          markNotificationAsRead={markNotificationAsRead}
          language={language}
          lastNotification={lastNotification}
          notifications={notifications}
          deleteNotification={deleteNotification}
        />
      </EventInfoBottom>
    </EventInfoContainer>
  );
}

EventInfo.propTypes = {
  language: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  navDate: PropTypes.instanceOf(moment).isRequired,
  meetList: PropTypes.objectOf(PropTypes.object),
  missesList: PropTypes.objectOf(PropTypes.object),
  taskList: PropTypes.objectOf(PropTypes.object),
  onsiteAppointments: PropTypes.objectOf(PropTypes.object),
  onEventEdit: PropTypes.func.isRequired,
  onEventDelete: PropTypes.func.isRequired,
  onEventAdd: PropTypes.func.isRequired,
  notifications: PropTypes.array,
  markNotificationAsRead: PropTypes.func.isRequired,
  calendar: PropTypes.object.isRequired,
  lastNotification: PropTypes.array,
  deleteNotification: PropTypes.func.isRequired,
  onGetEvents: PropTypes.func.isRequired,
  children: PropTypes.node,
  shouldAddAbsence: PropTypes.bool,
};

EventInfo.defaultProps = {
  date: moment(),
  meetList: null,
  missesList: null,
  taskList: null,
  onsiteAppointments: null,
  children: null,
  shouldAddAbsence: true,
  lastNotification: [],
  notifications: [],
};
const mapStateToProps = ({ notifications: { data }, selectedInternship }) => ({
  lastNotification:
    data
    && selectedInternship
    && data[selectedInternship.pk]
    && data[selectedInternship.pk].lastNotification,
  notifications: data,
});
export default connect(
  mapStateToProps,
  {
    markNotificationAsRead,
    deleteNotification,
  },
)(EventInfo);
