import * as types from 'actions/types';
import moment from 'moment';
import { createReducer, successType } from 'shared/lib/lib/callAPI';

const replaceDay = (days, dayToReplace) => days.map((day) => {
  if (day.pk === dayToReplace.pk) {
    return dayToReplace;
  }
  return day;
});

const dataReducer = (state = null, action) => {
  switch (action.type) {
    case successType(types.SET_REPORT):
      return {
        report: {
          ...action.response,
          day_list: [
            ...action.response.day_list.map((day) => {
              day.tasks.sort((a, b) => a.pk - b.pk);
              return day;
            }),
          ],
        },
      };
    case successType(types.EDIT_REPORT_DAY):
      return {
        ...state,
        report: {
          ...state.report,
          day_list: [
            ...replaceDay(state.report.day_list, action.response).map((day) => {
              day.tasks.sort((a, b) => a.pk - b.pk);
              return day;
            }),
          ],
        },
      };
    case successType(types.EDIT_REPORT_CONCLUSION):
      return {
        report: action.response,
      };
    case types.SET_CURRENT_PHOTO_LIST:
      return {
        ...state,
      };
    case successType(types.UPLOAD_DAY_ATTACHMENT):
      return {
        ...state,
      };
    case successType(types.DELETE_DAY_ATTACHMENT):
      return {
        ...state,
      };
    case successType(types.ADD_DAY):
      return {
        ...state,
        report: {
          ...state.report,
          day_list: [...state.report.day_list, action.response].sort((a, b) => moment.utc(a.date).diff(moment.utc(b.date))),
        },
      };
    case successType(types.ADD_DAY_TASKS):
      return {
        ...state,
        report: {
          ...state.report,
          day_list: replaceDay(state.report.day_list, action.response),
        },
      };
    case types.LOGOUT:
      return null;
    default:
      return state;
  }
};

export default createReducer(
  [
    types.SET_REPORT,
    types.EDIT_REPORT_DAY,
    types.UPLOAD_DAY_ATTACHMENT,
    types.DELETE_DAY_ATTACHMENT,
    types.ADD_DAY,
    types.ADD_DAY_TASKS,
    types.EDIT_DAY_TASK,
    types.DELETE_DAY_TASK,
  ],
  dataReducer,
);
