import styled from 'styled-components';
import { device } from 'styles/device';
import SearchInput from 'components/Form/SearchInput';
import Select from 'components/Form/Select';

export const FilterPanel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const FilterPanelBody = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 30px;
  @media ${device.mobile} {
    margin-left: 10px;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const SearchInputStyled = styled(SearchInput)`
  margin-left: 10px;
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const SelectStyled = styled(Select)`
  & .select__menu {
    width: 300px;
    @media ${device.mobile} {
      left: 0;
      transform: none;

      &::after,
      &::before {
        left: 30%;
      }
    }
  }
`;

export const SelectStyledContainer = styled.div`
  position: relative;
  width: 300px;
`;
