import styled from 'styled-components';
import { device } from 'styles/device';

export const KindCompanyWrapper = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 17px;
  @media ${device.tablet} {
    margin-bottom: 4px;
  }

  > li {
  }
`;

export const CompanyWrapper = styled.span`
  //color: #696969;
  padding: 4px 0;
  display: block;
`;

export const KindWrapper = styled.span`
  display: inline-block;
  color: #183a64;
  font-size: 12px;
  border: 1px solid #183a64;
  border-radius: 3px;
  padding: 5px 10px;
  font-weight: bold;
  line-height: 1;
  margin-right: 10px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  text-transform: uppercase;
`;
