import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { ReactComponent as ArrowIcon } from 'shared/lib/styles/images/slider-arrow-left.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { StyledSelect, ArrowIconWrap, ClearIconWrap } from './SelectStyled';

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <ArrowIconWrap>
      <ArrowIcon />
    </ArrowIconWrap>
  </components.DropdownIndicator>
);

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <ClearIconWrap>
      <FontAwesomeIcon icon={faTimes} />
    </ClearIconWrap>
  </components.ClearIndicator>
);

function Select({
  hideIndicator,
  clearIndicator,
  isSearchable,
  value,
  ...props
}) {
  return (
    <StyledSelect
      {...props}
      hasValue={!!value}
      value={value}
      classNamePrefix="select"
      isSearchable={isSearchable}
      components={{
        ClearIndicator: clearIndicator ? ClearIndicator : undefined,
        DropdownIndicator: hideIndicator ? undefined : DropdownIndicator,
      }}
      hideIndicator={hideIndicator}
      isClearable={clearIndicator}
    />
  );
}

Select.propTypes = {
  value: PropTypes.object,
  hideIndicator: PropTypes.bool,
  clearIndicator: PropTypes.bool,
  isSearchable: PropTypes.bool,
};

Select.defaultProps = {
  value: undefined,
  hideIndicator: false,
  clearIndicator: false,
  isSearchable: false,
};

export default Select;
