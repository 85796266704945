import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';

const dataReducer = (state = null, action) => {
  switch (action.type) {
    case successType(types.GET_APPLICATION_STATUSES):
      const { language, items } = action.response;
      return {
        ...(state || {}),
        [language]: items,
      };
    default:
      return state;
  }
};

export default createReducer(types.GET_APPLICATION_STATUSES, dataReducer);
