import styled from 'styled-components';
import { Button } from 'shared';
import ReactSVG from 'react-svg';

export const MessageWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MessageItem = styled.div`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeightRegular};
  margin-bottom: 20px;
  max-width: 700px;
`;

export const MessageTitle = styled(MessageItem)`
  color: ${({ theme }) => theme.secondary};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  font-size: 27px;
`;

export const MessageButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MessageButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const MessageButtonText = styled.div`
  text-align: center;
`;

export const BackButton = styled(Button)``;

export const ApplicationAssistant = styled.iframe`
  height: 100%;
  width: 100%;
`;

export const SuccessText = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const CheckIcon = styled(ReactSVG)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: green;
  border-radius: 100%;
  margin-right: 20px;
  height: 40px;
  width: 40px;
`;
