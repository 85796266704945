import styled from 'styled-components';
import { device } from 'styles/device';

export const DocumentItemWrap = styled.div`
  position: relative;
  padding: 18px 22px;
  padding-right: 266px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeightUpperBold};
  color: ${({ theme }) => theme.secondary};
  word-break: break-word;
  background-color: ${({ theme }) => theme.white};
  border-radius: ${({ theme }) => theme.borderRadiusDefault};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  &:first-child {
    margin-top: 0;
  }

  @media ${device.mobile} {
    padding-bottom: 62px;
    padding-right: 22px;
  }
`;

export const ControlsWrap = styled.div`
  position: absolute;
  top: 50%;
  right: 18px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  max-width: 210px;
  transform: translateY(-50%);

  @media ${device.mobile} {
    left: 0;
    right: 0;
    top: auto;
    bottom: 0;
    padding: 9px 22px;
    justify-content: space-between;
    max-width: 100%;
    transform: none;
  }
`;

export const Control = styled.button`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  max-width: 38px;
  padding: 8px;
  margin-left: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  & input {
    display: none;
  }

  & svg {
    width: 100%;
    height: auto;
  }

  & path {
    stroke: ${({ theme }) => theme.secondary};
  }
`;

export const EyeControl = styled(Control)`
  max-width: 44px;
`;
