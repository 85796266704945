import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';

const dataReducer = (state = null, action) => {
  switch (action.type) {
    case successType(types.GET_VACANCY_LIST):
      return [...action.response];
    default:
      return state;
  }
};
export default createReducer([types.GET_VACANCY_LIST], dataReducer);
