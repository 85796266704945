'use strict';

exports.__esModule = true;
exports.ErrorContent = exports.ErrorIcon = exports.ErrorWrap = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  padding: 0.75rem 1.25rem;\n  margin: 1rem auto;\n  border: 1px solid ', ';\n  display: flex;\n  align-items: center;\n'], ['\n  padding: 0.75rem 1.25rem;\n  margin: 1rem auto;\n  border: 1px solid ', ';\n  display: flex;\n  align-items: center;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  color: ', ';\n  font-size: 40px;\n  margin-right: 25px;\n'], ['\n  color: ', ';\n  font-size: 40px;\n  margin-right: 25px;\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  flex: 1;\n  color: ', ';\n'], ['\n  flex: 1;\n  color: ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactFontawesome = require('@fortawesome/react-fontawesome');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var ErrorWrap = exports.ErrorWrap = _styledComponents2.default.div(_templateObject, function (_ref) {
  var theme = _ref.theme;
  return theme.errorColor;
});

var ErrorIcon = exports.ErrorIcon = (0, _styledComponents2.default)(_reactFontawesome.FontAwesomeIcon)(_templateObject2, function (_ref2) {
  var theme = _ref2.theme;
  return theme.errorColor;
});

var ErrorContent = exports.ErrorContent = _styledComponents2.default.div(_templateObject3, function (_ref3) {
  var theme = _ref3.theme;
  return theme.errorColor;
});