import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getTagList } from 'actions/tagActions';
import { connect } from 'react-redux';

const Tag = ({
  getTagList, slug, loading, error, data,
}) => {
  const stableGetTagList = useCallback(getTagList, []);
  useEffect(() => {
    stableGetTagList();
  }, [stableGetTagList]);

  if (loading || error || !data) {
    return null;
  }

  const field = data.find((field) => field.slug === slug);
  if (!field) {
    return slug;
  }
  return field.name;
};

Tag.propTypes = {
  slug: PropTypes.string.isRequired,
};

const mapStateToProps = ({ tag: { data, error, loading } }) => ({
  loading,
  error,
  data,
});

export default connect(
  mapStateToProps,
  { getTagList },
)(Tag);
