import React from 'react';
import PropTypes from 'prop-types';
import { Loader, Error } from 'shared';
import Localize from 'components/Localize';
import Vacancy from '../Vacancy';
import { VacancyListWrapper } from './VacancyListStyled';

const VacancyList = ({
  vacancyList, loading, error, handleSubmit,
}) => (
  <VacancyListWrapper>
    {error && <Error />}
    <Loader loading={loading}>
      {vacancyList
        .filter((vacancy) => !vacancy.applied)
        .map((vacancy) => (
          <Vacancy vacancy={vacancy} onClick={() => handleSubmit(vacancy)} />
        ))}
      {vacancyList.filter((vacancy) => !vacancy.applied).length === 0 && (
        <Localize>
          {({ translate }) => (
            <Error message={translate('there-no-vacancy-apply')} />
          )}
        </Localize>
      )}
    </Loader>
  </VacancyListWrapper>
);

VacancyList.propTypes = {
  vacancyList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

VacancyList.defaultProps = {
  error: undefined,
};

export default VacancyList;
