import React, { useState } from 'react';
import Localize from 'components/Localize';
import Page from 'components/Page';
import InformationModal from 'components/InformationModal';
import { ReactComponent as InfoIcon } from 'styles/images/svg/info.svg';
import InternshipSelectionProvider from 'components/InternshipSwitcher/InternshipSelectionProvider';
import ApplicantTask from './ApplicantTask';

const LearningTask = () => {
  const [informationModalOpen, setInformationModalOpen] = useState('');

  return (
    <>
      <InformationModal
        open={informationModalOpen}
        onCloseModal={() => setInformationModalOpen('')}
      />
      <InternshipSelectionProvider>
        {({ selectedInternshipId }) => (
          <Page
            title={<Localize id="operational-learning-task" />}
            description={
              <Localize id="your-internship-has-given-you-learning-task" />
            }
            backTo={`/applicant/${selectedInternshipId || ''}`}
            icon={<InfoIcon />}
            onClick={() => setInformationModalOpen('OPERATIONAL_LEARNING_TASK')}
          >
            <ApplicantTask internshipId={selectedInternshipId} />
          </Page>
        )}
      </InternshipSelectionProvider>
    </>
  );
};
export default LearningTask;
