import styled from 'styled-components';
import _PrimaryUserData from 'components/UserPrimaryData';
import { Loader } from 'shared';

export const PrimaryUserData = styled(_PrimaryUserData)`
  margin-top: 0;
`;

export const PageWrapper = styled(Loader)`
  opacity: 0.2;
  overflow: hidden;
  @keyframes phAnimation {
    0% {
      transform: translate3d(-30%, 0, 0);
    }
    100% {
      transform: translate3d(30%, 0, 0);
    }
  }
`;
