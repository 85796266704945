import React from 'react';
import PropTypes from 'prop-types';
import { ChatTabWrapper, NotificationIcon } from './ChatTabStyled';

function ChatTab({
  children,
  hasNotification,
  isTabActive,
  handleActiveTab,
  ...rest
}) {
  return (
    <ChatTabWrapper
      onClick={handleActiveTab}
      isTabActive={isTabActive}
      {...rest}
    >
      {children}
      {hasNotification && <NotificationIcon />}
    </ChatTabWrapper>
  );
}

ChatTab.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  hasNotification: PropTypes.bool,
  isTabActive: PropTypes.bool,
  handleActiveTab: PropTypes.func.isRequired,
};

ChatTab.defaultProps = {
  hasNotification: false,
  isTabActive: false,
};

export default ChatTab;
