import React from 'react';
import PropTypes from 'prop-types';

import { toBase64 } from 'shared/lib/lib/base64';
import { FileUploader } from 'shared';
import { VisitNoteImageWrapper } from './VisitNoteFileUploaderStyled';

export const VisitNoteFileUploader = ({ meta }) => {
  const onFileUpload = (filesToAdd) => {
    const promises = [];
    filesToAdd.map((file) => promises.push(toBase64(file)));
    Promise.all(promises).then((base64Files) => {
      const newFiles = base64Files.map(({ base64 }) => ({
        image: base64,
        file: base64.split(',').pop(),
      }));
      meta.setFieldValue('attachments', [
        ...meta.values.attachments,
        ...newFiles,
      ]);
    });
  };
  const onDeleteAttachment = (pk) => {
    const newFiles = meta.values.attachments.filter((a) => a.pk !== pk);
    meta.setFieldValue('attachments', newFiles);
  };
  return (
    <VisitNoteImageWrapper>
      <FileUploader
        onFileAdd={onFileUpload}
        onFileDelete={onDeleteAttachment}
        files={meta.values.attachments.map(({ pk, image, file }) => ({
          pk,
          image,
          file,
        }))}
      />
    </VisitNoteImageWrapper>
  );
};

VisitNoteFileUploader.propTypes = {
  meta: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.shape({
      attachments: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  }).isRequired,
};

export default VisitNoteFileUploader;
