import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Can from 'components/Can/Can';
import { actions } from 'components/Can';
import { page } from 'utils/access-rules/rules/routes';
import AbilitiesService from 'utils/access-rules/abilities';
import { logout } from 'actions/authActions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Localize from 'components/Localize';
import {
  Actions,
  ActionItem,
  Avatar,
  DefaultAvatar,
  Container,
  Content,
  Greetings,
} from './UserActionsStyled';

const UserActions = ({
  user,
  logout,
  isDropdownOpen,
  onVisibilityChange,
  onModalOpen,
}) => {
  const userActionsRef = useRef(null);
  const ability = new AbilitiesService();
  const onClose = (e) => {
    if (isDropdownOpen && !userActionsRef.current.contains(e.target)) {
      onVisibilityChange(false);
    }
  };
  const stableOnClose = useCallback(onClose, [userActionsRef, isDropdownOpen]);
  useEffect(() => {
    if (userActionsRef.current) {
      if (isDropdownOpen) {
        document.body.addEventListener('click', stableOnClose);
      } else {
        document.body.removeEventListener('click', stableOnClose);
      }
    }

    return () => {
      document.body.removeEventListener('click', stableOnClose);
    };
  }, [stableOnClose, userActionsRef, isDropdownOpen]);
  if (!user) {
    return null;
  }
  return (
    <>
      <Container
        isActive={isDropdownOpen}
        onClick={() => onVisibilityChange(!isDropdownOpen)}
        ref={userActionsRef}
      >
        <Content>
          <Greetings>Hi {user.first_name}!</Greetings>
          {user.avatar ? (
            <Avatar src={user.avatar} alt="user avatar" />
          ) : (
            <DefaultAvatar className="avatar" icon={faUser} />
          )}
        </Content>
        <Actions
          isActive={isDropdownOpen}
          height={user.role === 'APPLICANT' ? '122px' : '88px'}
        >
          <Can I={actions.see} a={page.ManageStudents}>
            <ActionItem as={Link} to="/manage-students">
              <Localize id="manage-students" />
            </ActionItem>
          </Can>
          <ActionItem as={Link} to="/instructions">
            <Localize id="instruction-help" />
          </ActionItem>
          <ActionItem onClick={onModalOpen}>
            <Localize id="avatar" />
          </ActionItem>
          {ability.can(actions.see, page.Settings) ? (
            <ActionItem as={Link} to="/settings">
              <Localize id="settings" />
            </ActionItem>
          ) : (
            <ActionItem className="disabled">
              <span className="coming-soon">*Coming soon!*</span>
              <Localize id="settings" />
            </ActionItem>
          )}

          <ActionItem onClick={logout}>
            <Localize id="logout" />
          </ActionItem>
        </Actions>
      </Container>
    </>
  );
};

UserActions.propTypes = {
  logout: PropTypes.func.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    avatar: PropTypes.string,
    role: PropTypes.string.isRequired,
  }),
  isDropdownOpen: PropTypes.bool.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
};

UserActions.defaultProps = {
  user: null,
};

const mapStateToProps = ({ currentUser: { data } }) => ({
  user: {
    first_name: data.user.first_name,
    avatar: data.ufouser && data.ufouser.avatar,
    role: data.role,
  },
});

export default connect(mapStateToProps, { logout })(UserActions);
