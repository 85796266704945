'use strict';

exports.__esModule = true;
exports.default = undefined;

var _FileUploader = require('./FileUploader');

var _FileUploader2 = _interopRequireDefault(_FileUploader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _FileUploader2.default;
module.exports = exports['default'];