import styled from 'styled-components';
import { device } from 'styles/device';

export const StyledList = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
  }
  @media ${device.tablet} {
    justify-content: center;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 50px;
`;
