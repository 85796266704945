import styled from 'styled-components';
import Select from 'components/Form/Select';
import Table from 'components/Table';
import { device } from 'styles/device';

export const FilterSelect = styled(Select)`
  margin-right: 8px;
  width: 100%;

  ${({ withoutIndicator }) => withoutIndicator
    && `
    .select__control {
      padding-right: 10px;

    }
    .select__indicators {
      display: none;
    }
  `}

  & .select__control {
    min-height: 42px;
  }

  & .select__menu {
    font-size: 14px;
  }

  & path {
    stroke: ${({ isActive, theme }) => !isActive && theme.secondary};
  }

  @media ${device.mobile} {
    max-width: 100%;
    flex-grow: 1;
    margin-right: 0;
    margin-top: 8px;
  }
`;

export const ClassroomSelect = styled(FilterSelect)`
  max-width: 120px;

  @media ${device.mobile} {
    width: 100%;
    min-width: auto;
    flex-grow: 0;
  }
`;

export const ClassroomNameSelect = styled(FilterSelect)`
  max-width: 247px;
  width: 100vw;
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const FilterPanel = styled.div`
  z-index: 2;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;

  @media ${device.mobile} {
    justify-content: space-between;
  }
`;

export const FilterButtons = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResetFilter = styled.a`
  cursor: pointer;
`;

export const LinkButton = styled.a`
  cursor: pointer;
  color: blue;
`;

export const StudentTable = styled(Table)`
  .rdt_TableCol {
    // padding-left: 2px !important;
    // padding-right: 2px !important;
  }
  .rdt_TableCol div:first-child {
    font-size: 9px !important;
    display: flex;
    flex-direction: row;
    word-break: normal !important;

    .svg-inline--fa.fa-w-16 {
      width: auto !important;
    }
  }
  .rdt_TableCell {
    // padding-left: 2px !important;
    // padding-right: 2px !important;
  }
  .rdt_TableCell div:first-child {
    word-break: normal !important;
    white-space: normal !important;
    overflow: unset !important;
    font-size: 9px !important;
  }
  .rdt_TableHeadRow {
    pointer-events: unset !important;
  }
`;

export const UnaffectedUsers = styled.div`
  background: #fff;
  padding: 10px;
  margin-top: 10px;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`;
