import { device } from 'shared/src/styles/device';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  max-width: 1440px;
  padding: 100px 50px;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  @media ${device.tablet} {
    flex-direction: column;
  }
  @media ${device.mobile} {
    flex-direction: column;
    padding: 20px;
  }
`;

export const HelpImage = styled.img`
  width: 312px;
  height: 256px;
  @media ${device.mobile} {
    width: 100%;
    max-width: 300px;
    height: auto;
  }
`;

export const InstructionsImage = styled.img`
  width: 236px;
  height: 279px;
  @media ${device.mobile} {
    width: 100%;
    max-width: 300px;
    height: auto;
  }
`;

export const ContentBox = styled.div`
  width: 100%;
  text-align: center;
`;

export const TextBox = styled.div`
  .title {
    font-size: 25px;
    color: #193a64;
    font-weight: 600;
    @media ${device.mobile} {
      font-size: 20px;
    }
  }
  .description {
    font-size: 21px;
    line-height: 30px;
    color: #193a64;
    max-width: 540px;
    padding: 0px 40px;
    margin: 20px auto 0px;
    @media ${device.mobile} {
      padding: 0px 10px;
      font-size: 18px;
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }
  .link-container {
    padding-top: 50px;
    a {
      font-size: 21px;
      color: #193a64;
      font-weight: 600;
      @media ${device.mobile} {
        font-size: 18px;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  height: 300px;
  @media ${device.mobile} {
    height: auto;
    padding-bottom: 20px;
  }
`;

export const Line = styled.div`
  background-color: #707070;
  width: 1px;
  opacity: 0.4;
  @media ${device.tablet} {
    flex-direction: column;
    width: 100%;
    height: 1px;
    margin: 50px 0px;
  }
  @media ${device.mobile} {
    flex-direction: column;
    width: 100%;
    height: 1px;
    margin: 50px 0px;
  }
`;

export const NoInternships = styled.div`
  font-size: 21px;
  color: #193a64;
  font-weight: 600;
  text-align: center;
`;
