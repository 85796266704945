import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';
import initialState from 'store/initialState';

const dataReducer = (state = initialState.applicant, action) => {
  switch (action.type) {
    case successType(types.GET_APPLICANTS):
      return {
        ...state,
        list: action.response,
      };
    case successType(types.GET_APPLICANT):
      return {
        ...state,
        applicant: action.response,
      };
    case types.LOGOUT:
      return null;
    default:
      return state;
  }
};

export default createReducer(types.GET_APPLICANTS, dataReducer);
