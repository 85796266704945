import React from 'react';
import PropTypes from 'prop-types';

import Can from 'components/Can/Can';
import { actions } from 'components/Can';
import { section } from 'utils/access-rules/rules/assessment';
import check from 'styles/images/svg/check-blue.svg';
import uploadIcon from 'shared/lib/styles/images/upload.svg';
import arrowRight from 'styles/images/svg/arrow-right.svg';
import {
  Form, Button, Prompt, Error, Success,
} from 'shared';
import Localize from 'components/Localize';
import refreshIcon from 'styles/images/svg/refresh.svg';
import { getSubmittedStatusByRole } from '../../assessmentUtils';
import { ButtonsContainer } from './CommentsFormsStyled';

const roles = {
  ALL_ASSESSMENTS: 'all_assessments',
  APPLICANT: 'applicant',
  TEACHER: 'teacher',
  COMPANY_EMPLOYEE: 'company_employee',
  EDUCATION_PROVIDER: 'education_provider',
};

const getReportUrl = (isComparingMode, role, reportUrl) => {
  if (isComparingMode) return reportUrl;
  if (role === roles.TEACHER) return `${reportUrl}?role=teacher`;
  if (role === roles.COMPANY_EMPLOYEE) return `${reportUrl}?role=company_employee`;
};

const CommentsForms = ({
  comments,
  changedItems,
  isValid,
  isCompleted,
  isEditable,
  onSave,
  onSubmit,
  userRole,
  isComparingMode,
  error,
  onResend,
  touched,
  saveError,
  saveSuccess,
}) => (
  <Form
    initialValues={{
      applicant_comments: comments.applicant_comments,
      company_employee_comments: comments.company_employee_comments,
      educational_provider_comments: comments.educational_provider_comments,
      teacher_comments: comments.teacher_comments,
    }}
    enableReinitialize
  >
    {({ values, dirty }) => {
      const save = () => onSave(values);
      const submit = () => onSubmit(values);
      return (
        <div>
          {/* {(userRole === roles.APPLICANT || isComparingMode) && (
            <CommentWrapper>
              <CommentTitle>
                {isEditable && !isCompleted ? (
                  <Localize id="write-your-comment-here" />
                ) : (
                  <Localize id="student-comment" />
                )}
              </CommentTitle>
              {isEditable && !isCompleted ? (
                <InputField
                  rows={2}
                  name="applicant_comments"
                  placeholder={translate('comment')}
                  component={Textarea}
                />
              ) : (
                <CommentBox>
                  {comments.applicant_comments || (
                    <Localize id="no-comment-yet" />
                  )}
                </CommentBox>
              )}
            </CommentWrapper>
          )} */}
          <ButtonsContainer>
            {error && <Error />}
            {!touched && saveError && <Error />}
            {isEditable && !isCompleted && (
              <>
                <Button
                  type="button"
                  className="btn-save"
                  icon={check}
                  disableBoxShadow
                  size="small"
                  variant="outlined"
                  color="secondary"
                  onClick={save}
                  disabled={
                    (changedItems.length === 0 || isCompleted) && !dirty
                  }
                >
                  <Localize id="save" />
                </Button>
                <Can I={actions.see} a={section.submitButton}>
                  <Prompt
                    confirm={submit}
                    trigger={(
                      <Button
                        type="button"
                        className="btn-submit"
                        icon={arrowRight}
                        disableBoxShadow
                        color="secondary"
                        size="small"
                        disabled={(!isValid || isCompleted) && !dirty}
                      >
                        <Localize id="submit-report" />
                      </Button>
                    )}
                  >
                    <Localize id="are-you-sure-want-submit-form" />
                  </Prompt>
                </Can>
              </>
            )}
            {(isComparingMode
              || userRole === roles.TEACHER
              || userRole === roles.COMPANY_EMPLOYEE) && (
              <Button
                as="a"
                className="btn-upload"
                icon={uploadIcon}
                disableBoxShadow
                size="small"
                color="secondary"
                href={getReportUrl(
                  isComparingMode,
                  userRole,
                  comments.report_url,
                )}
                target="_blank"
              >
                <Localize id="download-pdf" />
              </Button>
            )}
            {userRole !== roles.ALL_ASSESSMENTS && userRole !== roles.TEACHER && (
              <Can I={actions.see} a={section.resendButton}>
                <Prompt
                  confirm={onResend}
                  trigger={(
                    <Button
                      className="btn-submit"
                      icon={refreshIcon}
                      disableBoxShadow
                      color="secondary"
                      variant="outlined"
                      size="small"
                      disabled={
                        !getSubmittedStatusByRole(
                          comments,
                          userRole.toUpperCase(),
                        )
                      }
                    >
                      <Localize id="resend" />
                    </Button>
                  )}
                >
                  <Localize id="are-you-sure-want-resend-form" />
                </Prompt>
              </Can>
            )}
          </ButtonsContainer>
          {!touched && saveSuccess && (
            <Success>
              <Localize id="successful" />
            </Success>
          )}
        </div>
      );
    }}
  </Form>
);

CommentsForms.propTypes = {
  comments: PropTypes.shape({
    applicant_comments: PropTypes.string,
    company_employee_comments: PropTypes.string,
    educational_provider_comments: PropTypes.string,
    teacher_comments: PropTypes.string,
    report_url: PropTypes.string,
  }).isRequired,
  error: PropTypes.shape({
    detail: PropTypes.string,
  }),
  changedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  isComparingMode: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  touched: PropTypes.bool.isRequired,
  saveError: PropTypes.bool.isRequired,
  saveSuccess: PropTypes.bool.isRequired,
};

CommentsForms.defaultProps = {
  error: null,
};

export default CommentsForms;
