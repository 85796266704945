import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const NameSurnameSelector = ({ row, selectedInternship }) => {
  let link = `/applicant-profile/${row.pk}`;
  if (row.esuser.internships[0]) link += `/${row.esuser.internships[0].pk}`;
  if (
    selectedInternship[row.esuser.pk]
    && selectedInternship[row.esuser.pk].value
  ) {
    link = `/applicant-profile/${row.pk}/${
      selectedInternship[row.esuser.pk].value.pk
    }`;
  }
  return (
    <NavLink to={link}>
      {row.esuser.user.first_name} {row.esuser.user.last_name}
    </NavLink>
  );
};

NameSurnameSelector.propTypes = {
  row: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    esuser: PropTypes.shape({
      pk: PropTypes.number.isRequired,
      user: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
      }),
      internships: PropTypes.array,
    }).isRequired,
  }).isRequired,
  selectedInternship: PropTypes.shape({}).isRequired,
};

export default NameSurnameSelector;
