import ServerApi from 'shared/lib/api/ServerApi';

class LocalizationApi extends ServerApi {
  getLocalization() {
    return super.get(
      '/developer/app-localization/?page=education-system&polite_form=sie',
      false,
    );
  }
}

export default new LocalizationApi();
