import styled from 'styled-components';

export const BellNotificationContainer = styled.div`
  position: relative;
  display: inline-block;
  background-color: white;
`;

export const BellNotificationButton = styled.div`
  display: inline-block;
  padding: 8px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transition: box-shadow 0.3s ease;
  }

  ${({ isMenuOpen }) => isMenuOpen && 'display: block; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);'}
`;

export const BellNotificationList = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  min-width: 250px;
  padding: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: white;
  cursor: default;
  z-index: 10;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0;
    height: 1px;
    width: calc(100% - 40px);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
    z-index: 1;
  }

  ${({ isMenuOpen }) => isMenuOpen && 'display: block; box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);'}
`;

export const NoNotificationsText = styled.div`
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  font-weight: 200;
`;
