import { match, compile } from 'path-to-regexp';

/**
 * Parse id from https://dev.jobufo.com/api/v1/internship/internship/36991/
 */
export function parseInternshipId(path) {
  const matchBind = match('(.*)/internship/:id', { decode: decodeURIComponent });
  const result = matchBind(path);
  if (!result) {
    throw new Error(`Failed to parse ${path}`);
  }
  return result.params.id;
}

/**
 * Build url https://dev.jobufo.com/api/v1/internship/internship/36991/ with given id.
 */
export function buildInternshipUrl(id) {
  const toPath = compile('/v1/internship/internship/:id/', { encode: encodeURIComponent });
  return `${process.env.REACT_APP_BACKEND_URL}${toPath({ id })}`;
}
