import styled from 'styled-components';
import _FooterWrapper from './FooterWrapper';

export const Main = styled.main`
  background-color: ${({ theme }) => theme.mainBackgroundColor};
  padding: 30px 0 60px;
  position: relative;
  z-index: 1;
  flex-grow: 1;
`;

export const FooterWrapper = styled(_FooterWrapper)`
  height: 155px;
`;
