import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';
import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case successType(types.GET_INTERNSHIP):
    case successType(types.COMPLETE_PRESENTATION):
      return {
        ...state,
        [action.response.pk]: { ...action.response },
      };
    case types.UPDATE_INTERNSHIP_LEARNING_TASK:
      // eslint-disable-next-line no-case-declarations
      const internshipId = action.response.pk;
      return {
        ...state,
        [internshipId]: {
          ...(state[internshipId] || {}),
          operational_learning: action.response,
        },
      };
    case types.LOGOUT:
      return {};
    default:
      return state;
  }
};

const dataReducer = combineReducers({
  byId,
});

export default createReducer(types.GET_INTERNSHIP, dataReducer);
