import styled from 'styled-components';
import { ReactComponent as BellSvgIcon } from 'styles/images/svg/bell.svg';

export const BellIcon = styled(BellSvgIcon)`
  width: 100%;
  height: auto;
`;

export const BellBadge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 9px;
  height: 9px;
  background-color: #e95160;
  border-radius: ${({ theme }) => theme.borderRadiusRound};
`;

export const BellContainer = styled.div`
  position: relative;
  display: flex;
  max-width: 24px;
  max-height: 24px;
  width: 24px;
  height: 24px;
`;
