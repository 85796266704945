import styled from 'styled-components';
import _Paragraph from 'components/Paragraph';
import { ParagraphTitle } from 'components/Paragraph/ParagraphStyled';
import _CircleBadge from 'components/NotificationGeneralComponents/CircleBadge';
import { device } from 'styles/device';
import { INVITEES_STATUSES } from 'api/CalendarApi';

export const TYPE_SECTION = {
  meet: 'meet',
  missed: 'missed',
  tasks: 'tasks',
  onsite: 'onsite',
};

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: transform 0.3s ease;

  & svg {
    width: 100%;
  }
`;

export const DeleteButton = styled(Button)`
  &:hover {
    opacity: 0.8;
  }
  color: ${({ theme }) => theme.primary};
  font-size: 24px;
  opacity: 1;
  transition: 0.3s;
  @media ${device.mobile} {
    width: 20px;
    height: 20px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const EditButton = styled(Button)`
  &:hover {
    opacity: 0.8;
  }
  width: auto;
  height: auto;
  color: ${({ theme }) => theme.secondary};
  font-size: 26px;
  opacity: 1;
  transition: 0.3s;
  margin-left: auto;
  @media ${device.mobile} {
    width: 40px;
    height: 40px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const ParagraphText = styled.div`
  position: relative;
  padding-left: 30px;
  padding-right: 10px;
  display: flex;
  align-items: center;
`;

export const Paragraph = styled(_Paragraph)`
  margin-bottom: 0;

  ${ParagraphTitle} {
    padding-bottom: 9px;
    font-size: 18px;
    font-weight: ${({ theme }) => theme.fontWeightBold};
    text-transform: initial;
    border-bottom-color: white;

    @media ${device.tablet} {
      font-size: 16px;
    }

    @media ${device.mobile} {
      font-size: 14px;
      padding-bottom: 0px;
    }
  }

  @media ${device.mobile} {
    margin-bottom: 13px;
  }
`;
export const CurrentMissesListText = styled.p`
  position: relative;
  z-index: 1;
`;
export const CurrentMissesList = styled(Button)`
  position: relative;
  padding: 8px 12px;
  min-width: 40px;
  flex-shrink: 0;
  font-size: 18px;
  text-align: center;
  overflow: hidden;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  color: white;
  background-color: ${({ theme }) => theme.secondary};
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ status }) => status
    && `
        &:before{
            position: absolute;
            content: '';
            width: 200px;
            height: 200px;
            border-radius: 50%;
            margin-left: -30px;
            top: 50%;
            left:0;
            transform: translate(-100%, -50%);
            background-color: rgba(255,255,255,0.5);
            animation: zoomCircleSmall 3s infinite;
        }
    &:after{
            position: absolute;
            content: '';
            width: 200px;
            height: 200px;
            border-radius: 50%;
            top: 50%;
            left:0;
            transition: 1s;
            transform: translate(-100%, -50%);
            background-color: rgba(255,255,255,0.3);
            animation: zoomCircle 3s infinite;}`}
`;
export const EventTypeSectionEventItemGeneralInf = styled.div`
  position: relative;
  padding: 8px 12px;
  min-width: 45px ;
  flex-shrink: 0;
  font-size: 18px;
  text-align: center;
  overflow: hidden ;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  color: white;
  background-color: ${({ theme, acceptStatus }) => (acceptStatus === INVITEES_STATUSES.accepted && 'green')
    || (acceptStatus === INVITEES_STATUSES.rejected && theme.primary)
    || theme.secondary};
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: ${({ notResponded }) => (notResponded ? 0.5 : 1)};

  @media ${device.mobile} {
    width: 130px;
    font-size: 14px;
    padding: 6px 4px;
    text-align: left;
    margin-right: 5px;
  }
  ${({ status }) => status
    && `
        &:before{
            position: absolute;
            content: '';
            width: 200px;
            height: 200px;
            border-radius: 50%;
            margin-left: -30px;
            top: 50%;
            left:0;
            transform: translate(-100%, -50%);
            background-color: rgba(255,255,255,0.5);
            animation: zoomCircleSmall 3s infinite;
        }
    &:after{
            position: absolute;
            content: '';
            width: 200px;
            height: 200px;
            border-radius: 50%;
            top: 50%;
            left:0;
            transition: 1s;
            transform: translate(-100%, -50%);
            background-color: rgba(255,255,255,0.3);
            animation: zoomCircle 3s infinite;}`}
};

@keyframes zoomCircle{
 from{
 transform: translate(-100%, -50%);
background-color: rgba(255,255,255,1);
 }
 85%{
  transform: translate(0%, -50%);
background-color: rgba(255,255,255,0);
 }
 to{
 transform: translate(0%, -50%);
background-color: rgba(255,255,255,0);
 }
}
@keyframes zoomCircleSmall{
 from{
 transform: translate(-100%, -50%);
background-color: rgba(255,255,255,1);
 }

 to{
 transform: translate(0%, -50%);
background-color: rgba(255,255,255,0);
 }
}
`;

export const EventTypeSectionEventItemLeft = styled.div`
  margin-right: 19px;
`;

export const ButtonControllersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EventTypeSection = styled.div`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  color: ${({ theme }) => theme.textColor3};
  margin-bottom: 10px;
`;

export const EventTypeSectionHeader = styled.div`
  position: relative;
`;

export const EventTypeSectionEventList = styled.ul``;
export const EventTypeSectionDouble = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
`;
export const EventTypeSectionEventItem = styled.li`
  position: relative;
  display: flex;
  margin: 15px 0 30px;
  transition: 0.3s;
  cursor: pointer;
  padding-right: 10px;
  &:before {
    position: absolute;
    content: '';
    height: 0;
    width: 100%;
    border-bottom: 2px solid white;
    bottom: -15px;
    left: 0;
  }
  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  }

  &:last-child {
    &:before {
      border-bottom: none;
    }
  }
`;

export const EventTypeSectionEventItemRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
`;

export const EventTypeSectionEventItemSubInf = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px 0;
  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const FullDate = styled.div`
  margin-bottom: 4px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  text-align: left;
`;

export const CircleBadge = styled(_CircleBadge)`
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${({ typeSection, theme }) => {
    if (typeSection === TYPE_SECTION.missed) {
      return theme.primary;
    }
    if (typeSection === TYPE_SECTION.tasks) {
      return 'green';
    }
    if (typeSection === TYPE_SECTION.onsite) {
      return 'orange';
    }
    return theme.secondary;
  }};
`;

export const EditMissList = styled.ul`
  width: 100%;
`;
