import React from 'react';
import Localize from 'components/Localize';
import path4Icon from 'styles/images/svg/path4.svg';
import path5Icon from 'styles/images/svg/path5.svg';
import path7Icon from 'styles/images/svg/path7.svg';
import path8Icon from 'styles/images/svg/path8.svg';
import path9Icon from 'styles/images/svg/path9.svg';
import path10Icon from 'styles/images/svg/path10.svg';
import path11Icon from 'styles/images/svg/path11.svg';
import path12Icon from 'styles/images/svg/path12.svg';

export default [
  {
    key: 'INTERNSHIP',
    icon: path4Icon,
    handleClick: true,
    scrollTo: 'documents',
    label: <Localize id="lets-start" />,
  },
  {
    key: 'ACCEPTED',
    icon: path5Icon,
    to: '/your-applications',
    scrollTo: 'documents',
    label: <Localize id="get-accepted" />,
  },
  {
    key: 'OPERATIONAL_LEARNING_TASK',
    icon: path7Icon,
    to: '/learning-task',
    scrollTo: 'operationalLearning',
    label: <Localize id="operational-learning-task" />,
  },
  {
    key: 'INTERNSHIP_REPORT',
    icon: path8Icon,
    to: '/internship-report',
    scrollTo: 'internshipReport',
    label: <Localize id="write-internship-report" />,
  },
  {
    key: 'SELF_ASSESSMENT',
    icon: path9Icon,
    to: '/your-assessment',
    scrollTo: 'assessment',
    label: <Localize id="fill-self-assessment" />,
  },
  {
    key: 'INTERNSHIP_COMMENT',
    icon: path11Icon,
    to: '/internship-comment',
    scrollTo: 'internshipComment',
    label: <Localize id="internship-comment" />,
  },
  {
    key: 'PRESENTATION',
    icon: path10Icon,
    className: 'presentation',
    scrollTo: 'presentation',
    label: <Localize id="presentation-learning-task" />,
  },
  {
    key: 'DONE',
    icon: path12Icon,
    unClickable: true,
    label: <Localize id="all-done" />,
  },
];
