'use strict';

exports.__esModule = true;
exports.default = undefined;

var _Localize = require('./Localize');

var _Localize2 = _interopRequireDefault(_Localize);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Localize2.default;
module.exports = exports['default'];