import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';

const dataReducer = (state = null, action) => {
  switch (action.type) {
    case successType(types.GET_QUALIFICATION):
      return [...action.response];
    case types.ADD_QUALIFICATION:
      return [...state, action.data];
    case types.EDIT_QUALIFICATION:
      return [...state.filter((item) => item.pk !== action.data.pk), action.data];
    case types.REMOVE_QUALIFICATION:
      return state.filter((item) => item.pk.toString() !== action.id);
    case types.LOGOUT:
      return null;
    default:
      return state;
  }
};

export default createReducer([types.GET_QUALIFICATION], dataReducer);
