'use strict';

exports.__esModule = true;
exports.DatePickerSelect = exports.DatePickerWrap = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  display: flex;\n  align-items: center;\n  .date-select {\n    width: calc(50% - 5px);\n    + .date-select {\n      margin-left: 10px;\n    }\n  }\n'], ['\n  display: flex;\n  align-items: center;\n  .date-select {\n    width: calc(50% - 5px);\n    + .date-select {\n      margin-left: 10px;\n    }\n  }\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  & + & {\n    margin-left: 10px;\n  }\n'], ['\n  & + & {\n    margin-left: 10px;\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ = require('./..');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var DatePickerWrap = exports.DatePickerWrap = _styledComponents2.default.div(_templateObject);

var DatePickerSelect = exports.DatePickerSelect = (0, _styledComponents2.default)(_.Select)(_templateObject2);