'use strict';

exports.__esModule = true;
var parse = exports.parse = function parse(value) {
  var date = new Date(value);
  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear()
  };
};

var pad = function pad(num) {
  var norm = Math.floor(Math.abs(num));
  return (norm < 10 ? '0' : '') + norm;
};

var format = exports.format = function format(_ref) {
  var year = _ref.year,
      month = _ref.month,
      day = _ref.day;
  return year + '-' + pad(month) + '-' + pad(day);
};