'use strict';

exports.__esModule = true;
exports.RemoveButton = exports.ButtonLinkBack = exports.SecondaryButton = exports.PrimaryButton = exports.default = undefined;

var _Types = require('./Types');

Object.defineProperty(exports, 'PrimaryButton', {
  enumerable: true,
  get: function get() {
    return _Types.PrimaryButton;
  }
});
Object.defineProperty(exports, 'SecondaryButton', {
  enumerable: true,
  get: function get() {
    return _Types.SecondaryButton;
  }
});
Object.defineProperty(exports, 'ButtonLinkBack', {
  enumerable: true,
  get: function get() {
    return _Types.ButtonLinkBack;
  }
});
Object.defineProperty(exports, 'RemoveButton', {
  enumerable: true,
  get: function get() {
    return _Types.RemoveButton;
  }
});

var _Button = require('./Button');

var _Button2 = _interopRequireDefault(_Button);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Button2.default;