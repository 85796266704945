'use strict';

exports.__esModule = true;
exports.InputFeedbackWrap = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  width: 100%;\n  margin-top: 5px;\n  font-size: 80%;\n  color: ', ';\n'], ['\n  width: 100%;\n  margin-top: 5px;\n  font-size: 80%;\n  color: ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var InputFeedbackWrap = exports.InputFeedbackWrap = _styledComponents2.default.div(_templateObject, function (_ref) {
  var theme = _ref.theme;
  return theme.errorColor;
});