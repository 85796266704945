'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _FileUploader = require('../../FileUploader');

var _FileUploader2 = _interopRequireDefault(_FileUploader);

var _base = require('../../../lib/base64');

var _guid = require('../../../lib/guid');

var _guid2 = _interopRequireDefault(_guid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Component to be used with Formik.
 * <InputField name="attachments" component={FileList} />
 */
var FileList = function FileList(_ref) {
  var fileListOnTop = _ref.fileListOnTop,
      value = _ref.value,
      meta = _ref.meta,
      name = _ref.name,
      disabled = _ref.disabled,
      onFileAdd = _ref.onFileAdd,
      onFileDelete = _ref.onFileDelete;

  var files = value.map(function (_ref2) {
    var pk = _ref2.pk,
        file = _ref2.file,
        preview = _ref2.preview,
        kind = _ref2.kind;
    return {
      pk: pk,
      file: file,
      preview: preview,
      kind: kind,
      pkForDelete: pk ? pk.toString() : (0, _guid2.default)()
    };
  });
  var setValues = function setValues(files) {
    meta.setFieldValue(name, files.map(function (_ref3) {
      var pk = _ref3.pk,
          file = _ref3.file,
          preview = _ref3.preview,
          type = _ref3.type,
          kind = _ref3.kind;
      return {
        pk: pk,
        file: file,
        preview: preview,
        type: type,
        kind: kind
      };
    }));
  };

  var handleFileAdd = function handleFileAdd(filesToAdd) {
    var promises = [];
    filesToAdd.map(function (file) {
      return promises.push((0, _base.toBase64)(file));
    });
    Promise.all(promises).then(function (base64Files) {
      var newFiles = base64Files.map(function (_ref4) {
        var base64 = _ref4.base64,
            type = _ref4.type;
        return {
          file: base64,
          preview: type.includes('image') ? base64 : null,
          type: type,
          isNew: true
        };
      });
      if (onFileAdd) {
        onFileAdd(newFiles).then(function (data) {
          var updatedFiles = data.map(function (_ref5) {
            var response = _ref5.response;
            return response;
          });
          setValues([].concat(files, updatedFiles));
        });
        return;
      }
      setValues([].concat(files, newFiles));
    });
  };
  var handleFileDelete = function handleFileDelete(fileId) {
    if (onFileDelete) {
      onFileDelete(fileId).then(function () {
        return setValues(files.filter(function (file) {
          return file.pkForDelete !== fileId;
        }));
      });
      return;
    }
    setValues(files.filter(function (file) {
      return file.pkForDelete !== fileId;
    }));
  };

  return _react2.default.createElement(_FileUploader2.default, {
    fileListOnTop: fileListOnTop,
    onFileAdd: handleFileAdd,
    onFileDelete: handleFileDelete,
    files: files.map(function (_ref6) {
      var pkForDelete = _ref6.pkForDelete,
          preview = _ref6.preview,
          file = _ref6.file;
      return {
        pk: pkForDelete,
        image: preview,
        file: file
      };
    }),
    disabled: disabled
  });
};

FileList.propTypes = process.env.NODE_ENV !== "production" ? {
  value: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    pk: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]).isRequired,
    file: _propTypes2.default.string.isRequired,
    preview: _propTypes2.default.string.isRequired
  })).isRequired,
  meta: _propTypes2.default.shape({
    setFieldValue: _propTypes2.default.func.isRequired
  }).isRequired,
  name: _propTypes2.default.string.isRequired,
  fileListOnTop: _propTypes2.default.bool,
  disabled: _propTypes2.default.bool,
  onFileAdd: _propTypes2.default.func,
  onFileDelete: _propTypes2.default.func
} : {};

FileList.defaultProps = {
  fileListOnTop: false,
  disabled: false,
  onFileAdd: null,
  onFileDelete: null
};

exports.default = FileList;
module.exports = exports['default'];