import { roles } from 'utils/access-rules/constants';

export const getChooseInternshipText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-choose-internship-text';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-choose-internship-text';
  }
  if (role === roles.EducationProvider) {
    return 'provider-choose-internship-text';
  }
};

export const getOnsiteVisitText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-onsite-visit-text';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-onsite-visit-text';
  }
  if (role === roles.EducationProvider) {
    return 'provider-onsite-visit-text';
  }
};

export const getAddOnsiteVisitSubText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-onsite-visit-subtext';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-onsite-visit-subtext';
  }
  if (role === roles.EducationProvider) {
    return 'provider-onsite-visit-subtext';
  }
};

export const getAddOnsiteVisitText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-add-onsite-visit-text';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-add-onsite-visit-text';
  }
  if (role === roles.EducationProvider) {
    return 'provider-add-onsite-visit-text';
  }
};

export const getOltTasksText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-olt-tasks-text';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-olt-tasks-text';
  }
  if (role === roles.EducationProvider) {
    return 'provider-olt-tasks-text';
  }
};

export const getOltTasksSubText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-olt-tasks-subtext';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-olt-tasks-subtext';
  }
  if (role === roles.EducationProvider) {
    return 'provider-olt-tasks-subtext';
  }
};

export const getStateText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-state-text';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-state-text';
  }
  if (role === roles.EducationProvider) {
    return 'provider-state-text';
  }
};

export const getStateSubText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-state-subtext';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-state-subtext';
  }
  if (role === roles.EducationProvider) {
    return 'provider-state-subtext';
  }
};

export const getChatText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-chat-text';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-chat-text';
  }
  if (role === roles.EducationProvider) {
    return 'provider-chat-text';
  }
};

export const getChatSubText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-chat-subtext';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-chat-subtext';
  }
  if (role === roles.EducationProvider) {
    return 'provider-chat-subtext';
  }
};

export const getCalendarText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-calendar-on-applicant-profile-text';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-calendar-text';
  }
  if (role === roles.EducationProvider) {
    return 'provider-calendar-text';
  }
};

export const getCalendarSubText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-calendar-on-applicant-profile-subtext';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-calendar-subtext';
  }
  if (role === roles.EducationProvider) {
    return 'provider-calendar-subtext';
  }
};

export const getDocumentsText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-documents-text';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-documents-text';
  }
  if (role === roles.EducationProvider) {
    return 'provider-documents-text';
  }
};

export const getDocumentsSubText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-documents-subtext';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-documents-subtext';
  }
  if (role === roles.EducationProvider) {
    return 'provider-documents-subtext';
  }
};

export const getAssessmentText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-assessment-text';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-assessment-text';
  }
  if (role === roles.EducationProvider) {
    return 'provider-assessment-text';
  }
};

export const getAssessmentSubText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-assessment-subtext';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-assessment-subtext';
  }
  if (role === roles.EducationProvider) {
    return 'provider-assessment-subtext';
  }
};

export const getOltText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-olt-text';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-olt-text';
  }
  if (role === roles.EducationProvider) {
    return 'provider-olt-text';
  }
};

export const getOltSubText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-olt-subtext';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-olt-subtext';
  }
  if (role === roles.EducationProvider) {
    return 'provider-olt-subtext';
  }
};

export const getReportText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-report-text';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-report-text';
  }
  if (role === roles.EducationProvider) {
    return 'provider-report-text';
  }
};

export const getReportSubText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-report-subtext';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-report-subtext';
  }
  if (role === roles.EducationProvider) {
    return 'provider-report-subtext';
  }
};

export const getPresentationText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-presentation-text';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-presentation-text';
  }
  if (role === roles.EducationProvider) {
    return 'provider-presentation-text';
  }
};

export const getPresentationSubText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-presentation-subtext';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-presentation-subtext';
  }
  if (role === roles.EducationProvider) {
    return 'provider-presentation-subtext';
  }
};

export const getRequestText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-request-text';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-request-text';
  }
  if (role === roles.EducationProvider) {
    return 'provider-request-text';
  }
};

export const getRequestSubText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-request-subtext';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-request-subtext';
  }
  if (role === roles.EducationProvider) {
    return 'provider-request-subtext';
  }
};
