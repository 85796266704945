import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getAuthUser } from 'actions/authActions';
import { connect } from 'react-redux';

const Authenticate = ({
  getAuthUser, loading, error, children, userId,
}) => {
  const stableGetAuthUser = useCallback(getAuthUser, [userId]);
  useEffect(() => {
    stableGetAuthUser();
  }, [stableGetAuthUser, userId]);

  if (loading) {
    // loading indicator will be displayed (#root:empty)
    return null;
  }
  // handle "not authorized" and "access restricted"
  if (error && ![401, 403].includes(error.status)) {
    return `Failed to get auth user:${JSON.stringify(error)}`;
  }

  return children;
};

Authenticate.propTypes = {
  children: PropTypes.node.isRequired,
};
const mapStateToProps = ({ currentUser: { loading, error, data } }) => ({
  // initially, before componentDidMount data is null and no errors
  loading: loading || (!data && !error),
  userId: data && data.pk,
  error,
});
export default connect(
  mapStateToProps,
  { getAuthUser },
)(Authenticate);
