import 'react-dates/initialize';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import CalendarDay from 'components/SchedulerCalendar/Calendar/CalendarDay';
import ArrowController from 'components/SchedulerCalendar/Calendar/ArrowController';
import { ActiveLanguageProvider } from 'pages/LocalizeProvider';
import WeekDay from './WeekDay';
import {
  DayPickerSingleDateControllerWrapper,
  DateNavPanel,
  DateSelectYear,
  DateSelectMonth,
} from './CalendarStyled';
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/de';
import 'moment/locale/pl';

const yearOptions = [
  {
    value: moment().year() - 1,
    label: moment().year() - 1,
  },
  {
    value: moment().year(),
    label: moment().year(),
  },
  {
    value: moment().year() + 1,
    label: moment().year() + 1,
  },
  {
    value: moment().year() + 2,
    label: moment().year() + 2,
  },
  {
    value: moment().year() + 3,
    label: moment().year() + 3,
  },
  {
    value: moment().year() + 4,
    label: moment().year() + 4,
  },
];

const getMonthOptions = (lang = 'en') => moment
  .localeData(lang)
  .monthsShort()
  .map((label, value) => ({
    value,
    label,
  }));

const Calendar = ({
  onChangeNavDate,
  date,
  meetList,
  missesList,
  taskList,
  onsiteAppointments,
  onChangeDate,
}) => {
  const [state, setState] = useState({
    isDisabledNavDateNext: false,
    isDisabledNavDatePrev: false,
  });
  const onNextMonthClick = (date) => {
    const { isDisabledNavDatePrev } = state;

    const nextYear = moment(date)
      .add(1, 'M')
      .year();
    const maxYear = yearOptions[yearOptions.length - 1].value;
    const isMaxDate = nextYear > maxYear;

    if (isMaxDate) setState({ ...state, isDisabledNavDateNext: true });
    if (isDisabledNavDatePrev) setState({ ...state, isDisabledNavDatePrev: false });
    onChangeNavDate(date);
  };

  const onPrevMonthClick = (date) => {
    const { isDisabledNavDateNext } = state;
    const nextYear = moment(date)
      .subtract(1, 'M')
      .year();
    const minYear = yearOptions[0].value;
    const isMinDate = nextYear < minYear;

    if (isMinDate) setState({ ...state, isDisabledNavDatePrev: true });
    if (isDisabledNavDateNext) setState({ ...state, isDisabledNavDateNext: false });
    onChangeNavDate(date);
  };

  const selectMonth = (selectedOption, month, onMonthSelect) => {
    onMonthSelect(month, selectedOption.value);
    onChangeNavDate(moment().month(selectedOption.value));
  };

  const selectYear = (selectedOption, month, onYearSelect) => {
    onYearSelect(month, selectedOption.value);
    onChangeNavDate(moment().year(selectedOption.value));
  };

  const onDateChange = (selectedDate) => {
    if (moment(selectedDate).isBefore(date, 'month')) {
      onPrevMonthClick(selectedDate);
    }
    if (moment(selectedDate).isAfter(date, 'month')) {
      onNextMonthClick(selectedDate);
    }
    onChangeDate(selectedDate);
  };

  return (
    <ActiveLanguageProvider>
      {(activeLang) => (
        <DayPickerSingleDateControllerWrapper>
          <DayPickerSingleDateController
            date={date}
            firstDayOfWeek={1}
            numberOfMonths={1}
            enableOutsideDays
            transitionDuration={0}
            hideKeyboardShortcutsPanel
            noBorder
            onDateChange={onDateChange}
            onNextMonthClick={onNextMonthClick}
            onPrevMonthClick={onPrevMonthClick}
            navPrev={
              <ArrowController isDisabled={state.isDisabledNavDatePrev} />
            }
            navNext={(
              <ArrowController
                isNext
                isDisabled={state.isDisabledNavDateNext}
              />
)}
            renderDayContents={(day) => (
              <CalendarDay
                day={day}
                meetList={meetList}
                missesList={missesList}
                taskList={taskList}
                onsiteAppointments={onsiteAppointments}
              />
            )}
            renderWeekHeaderElement={(weekNumber) => (
              <WeekDay date={date} weekNumber={parseInt(weekNumber, 0) + 1} />
            )}
            weekDayFormat="e"
            renderMonthElement={({ month, onYearSelect, onMonthSelect }) => (
              <DateNavPanel>
                <DateSelectMonth
                  options={getMonthOptions(activeLang.code)}
                  value={getMonthOptions(activeLang.code).find(
                    (_month) => _month.value === month.month(),
                  )}
                  onChange={(selectedOption) => {
                    selectMonth(selectedOption, month, onMonthSelect);
                  }}
                  hideIndicator
                />
                <DateSelectYear
                  options={yearOptions}
                  value={yearOptions.find(
                    (_year) => _year.value === month.year(),
                  )}
                  onChange={(selectedOption) => {
                    selectYear(selectedOption, month, onYearSelect);
                  }}
                  hideIndicator
                />
              </DateNavPanel>
            )}
          />
        </DayPickerSingleDateControllerWrapper>
      )}
    </ActiveLanguageProvider>
  );
};

Calendar.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  meetList: PropTypes.objectOf(PropTypes.object),
  missesList: PropTypes.objectOf(PropTypes.object),
  taskList: PropTypes.objectOf(PropTypes.object),
  onsiteAppointments: PropTypes.objectOf(PropTypes.object),
  onChangeDate: PropTypes.func.isRequired,
  onChangeNavDate: PropTypes.func,
};

Calendar.defaultProps = {
  date: moment(),
  meetList: null,
  missesList: null,
  taskList: null,
  onsiteAppointments: null,
  onChangeNavDate: () => {},
};

export default Calendar;
