import styled from 'styled-components';
import _Select from 'components/Form/Select';
import { device } from 'styles/device';

export const Select = styled(_Select)`
  width: 100px;
  border: none;
  ${({ isActive, theme }) => isActive && `background-color: ${theme.secondary}`};
  background-color: transparent;
  width: 54px;
  & .select__indicator {
    width: 20px;
    & path {
      stroke: ${({ theme }) => theme.secondary};
    }
  }
  & .select__indicators {
  }

  & .select__single-value {
    font-size: 18px;
    font-weight: ${({ theme }) => theme.fontWeightMedium};
    color: ${({ isActive, theme }) => isActive ? theme.secondary : theme.textColor69};
    text-transform: none;
  }

  & .select__control {
    min-height: 50px;
  }

  & .select__control--is-focused {
    .select__placeholder {
      display: none;
    }
  }

  & .select__value-container {
    height: auto;
    flex-direction: row;
    justify-content: flex-start;
  }

  & .select__value-container::after {
    left: auto;
    right: 10px;
    top: 50%;
    border-color: ${({ theme }) => theme.textColor69} transparent transparent
      transparent;
    transform: translateY(-50%);
  }

  & .select__menu {
    width: 100%;
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fontWeightMedium};
    color: ${({ theme }) => theme.textColor69};
    text-transform: none;
    background-color: white;
  }

  & .select__option--is-selected.select__option--is-focused {
    color: white;
  }
  & .select__option:active {
    color: white;
  }
  & .select__placeholder {
    color: ${({ theme, hasErrors }) => hasErrors ? theme.primary : theme.grayA0};
    font-weight: ${({ theme }) => theme.fontWeightBold};
    font-size: 18px;
    height: 100%;
    margin: 0 !important;
    text-align: left;
    width: 100%;
  }
`;

export const DateSelect = styled(Select)`
  & .select__option:first-child {
    display: none;
  }
  .select__value-container{
    overflow: visible !important;
  }
  & .select__placeholder{
    color: ${({ theme, hasErrors }) => hasErrors ? theme.primary : theme.grayA0};
    font-weight: ${({ theme }) => theme.fontWeightBold};
    font-size: 18px;
    height: 100%;
    margin: 0 !important;
  }
  & .select__single-value{
    font-weight: ${({ theme }) => theme.fontWeightBold};
    color: ${({ theme, hasErrors }) => hasErrors ? theme.primary : theme.grayA0};
  }
  .select__single-value{
    font-weight: ${({ theme }) => theme.fontWeightBold};
    color: ${({ theme }) => theme.secondary};
  }
    & .select__control {
    min-height: 50px;
    border: none!important;
    background-color: transparent !important;
  }
    & .select__indicators {
    width: 20px;
    & path {
      stroke:  ${({ theme, hasErrors }) => hasErrors ? theme.primary : theme.grayA0};
    }
    }
    & .select__indicators {
      position: absolute;
      top: 81%;
      left: 66%;
      transform: translateX(-50%);
      width: 16px;
  }
    & .select__control{
     padding: 0;
  }
    & .select__menu{
    padding: 10px 0;
    width: 110px;
    }
    & .select__menu-list{
    padding:0 10px;

    }
    & .select__option{
         // font-weight: ${({ theme }) => theme.fontWeightBold};
        // font-size: 18px;
        // color: ${({ theme }) => theme.grayA0};
    }
`;

export const DateTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  @media ${device.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Separator = styled.span`
  font-size: 18px;
  margin-right: 5px;
  margin-left: 5px;
  color: ${({ theme }) => theme.grayA0};
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

export const SelectWrapper = styled.div`
  display: flex;
`;

export const TimeWrapper = styled.div`
  padding-left: 20px;
  @media ${device.mobile} {
    padding-left: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export const Label = styled.div`
  text-transform: uppercase;
  padding-bottom: 5px;
  @media ${device.mobile} {
    font-size: 14px;
    padding-bottom: 0px;
  }
`;

export const TimeContent = styled.div`
  display: flex;
  align-items: center;
  @media ${device.mobile} {
    margin-top: -10px;
  }
`;

export const WholeDayContainer = styled.div`
  padding-left: 20px;
`;
