import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import ApplyButton from './ApplyButton';
import Applied from './Applied';
import CityTagDuration from './CityTagDuration';
import KindCompany from './KindCompany';
import Title from './Title';
import {
  VacancyWrap,
  VacancyLogo,
  VacancyContent,
  ApplyWrapper,
  VacancyLogoLetters,
} from './VacancyStyled';

const Vacancy = ({ vacancy, onClick }) => {
  const [isDescriptionsOpen, setDescriptionsOpen] = useState(false);

  return (
    <VacancyWrap onClick={() => setDescriptionsOpen((open) => !open)}>
      <VacancyLogo>
        {vacancy.company_logo || (vacancy.company && vacancy.company.logo) ? (
          <img
            src={
              vacancy.company_logo ? vacancy.company_logo : vacancy.company.logo
            }
            alt="logo"
          />
        ) : (
          <VacancyLogoLetters>
            {' '}
            {vacancy.company && vacancy.company.name[0]}
          </VacancyLogoLetters>
        )}
      </VacancyLogo>
      <VacancyContent>
        <Title vacancy={vacancy} />
        <KindCompany vacancy={vacancy} />
        <Collapse isOpened={isDescriptionsOpen}>{vacancy.description}</Collapse>
        <CityTagDuration vacancy={vacancy} />
      </VacancyContent>
      <ApplyWrapper>
        <Applied vacancy={vacancy}>
          <ApplyButton onClick={onClick} />
        </Applied>
      </ApplyWrapper>
    </VacancyWrap>
  );
};

Vacancy.propTypes = {
  vacancy: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Vacancy;
