import React from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import { withRouter } from 'react-router-dom';
import { roles } from 'utils/access-rules/constants';
import { KIND } from 'api/NotificationApi';
import { scroller } from 'react-scroll';
import { Tooltip } from 'react-tippy';
import { Loader } from 'shared';
import {
  NotificationList,
  NotificationContent,
  NotificationTime,
} from './NotificationListStyled';
import NotificationItem from './NotificationItem/NotificationItem';
import NotificationTimeAgo from './NotificationTimeAgo/NotificationTimeAgo';

export const Notifications = ({
  notifications,
  onClick,
  history,
  markNotificationAsRead,
  userRole,
  onLoadMore,
  loading,
}) => {
  const handleClick = (notification) => {
    if (notification.read_at) {
      onClick();
      if (
        (notification.kind === KIND.INVITEE_CHANGED_STATUS
          || notification.kind === KIND.CALENDAR_EVENT
          || notification.kind === KIND.APPLICANT_ENTRY_CREATED)
        && notification.instance_id
      ) {
        scroller.scrollTo(notification.instance_id.toString(), {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
      }
      if (notification.kind === KIND.APPLICANT_SUBMITTED_ASSESSMENT) {
        scroller.scrollTo('assessment', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
        return;
      }
      if (
        notification.kind === KIND.CHAT_MESSAGE
        || notification.kind === KIND.CHAT_MESSAGE_HAS_BEEN_READ
      ) {
        if (userRole !== roles.Applicant) {
          if (notification.to) {
            window.location.href = notification.to;
          }
          return;
        }
      }
      if (notification.kind === KIND.INTERNSHIP_REQUEST_READY) {
        scroller.scrollTo(notification.instance_id.toString(), {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
        return;
      }
      if (userRole === roles.Applicant) {
        history.push(notification.to);
        return;
      }
      window.location.href = notification.to;
    } else {
      markNotificationAsRead(notification.pk);
      onClick();
      if (
        (notification.kind === KIND.INVITEE_CHANGED_STATUS
          || notification.kind === KIND.CALENDAR_EVENT
          || notification.kind === KIND.APPLICANT_ENTRY_CREATED)
        && notification.instance_id
      ) {
        scroller.scrollTo(notification.instance_id, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
      }
      if (notification.kind === KIND.APPLICANT_SUBMITTED_ASSESSMENT) {
        scroller.scrollTo('assessment', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
        return;
      }
      if (
        notification.kind === KIND.CHAT_MESSAGE
        || notification.kind === KIND.CHAT_MESSAGE_HAS_BEEN_READ
      ) {
        if (userRole !== roles.Applicant) {
          if (notification.to) {
            window.location.href = notification.to;
          }
          return;
        }
      }
      if (notification.kind === KIND.INTERNSHIP_REQUEST_READY) {
        scroller.scrollTo(notification.instance_id.toString(), {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
        return;
      }
      history.push(notification.to);
    }
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      onLoadMore();
    }
  };

  return (
    <NotificationList onScroll={handleScroll}>
      {notifications.map((notification) => {
        if (notification.applicant) {
          return (
            <Localize>
              {({ translate }) => (
                <Tooltip
                  title={`${translate('process')} ${
                    notification.applicant.first_name
                  } ${notification.applicant.last_name}`}
                  theme="light"
                  arrow
                >
                  <NotificationItem
                    unread={notification.read_at === null}
                    key={notification.pk}
                    onClick={() => handleClick(notification)}
                  >
                    <NotificationContent>
                      {notification.message}
                    </NotificationContent>
                    <NotificationTime>
                      <NotificationTimeAgo>
                        {notification.created}
                      </NotificationTimeAgo>
                    </NotificationTime>
                  </NotificationItem>
                </Tooltip>
              )}
            </Localize>
          );
        }
        return (
          <NotificationItem
            unread={notification.read_at === null}
            key={notification.pk}
            onClick={() => handleClick(notification)}
          >
            <NotificationContent>{notification.message}</NotificationContent>
            <NotificationTime>
              <NotificationTimeAgo>{notification.created}</NotificationTimeAgo>
            </NotificationTime>
          </NotificationItem>
        );
      })}
      {loading && <Loader />}
    </NotificationList>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      pk: PropTypes.number.isRequired,
      message: PropTypes.string.isRequired,
      to: PropTypes.string,
      created: PropTypes.string,
      applicant: PropTypes.object,
    }),
  ),
  onLoadMore: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  markNotificationAsRead: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

Notifications.defaultProps = {
  notifications: [],
};

export default withRouter(Notifications);
