import * as types from 'actions/types';
import { createReducer } from 'shared/lib/lib/callAPI';
import initialState from 'store/initialState';

const dataReducer = (state = initialState.filter, action) => {
  switch (action.type) {
    case types.CLEAR_FILTERS:
      return {
        ...initialState.filter,
      };
    case types.SET_FILTERS:
      return {
        schoolName: action.schoolName,
        classroom: action.classroom,
        search: action.search,
        sort: action.sort,
        clearButtonVisible:
          action.schoolName !== ''
          || action.classroom !== ''
          || action.search !== ''
          || action.sort !== 0,
      };
    default:
      return state;
  }
};
export default createReducer(
  [types.CLEAR_FILTERS, types.SET_FILTERS],
  dataReducer,
);
