'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactLocalizeRedux = require('react-localize-redux');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Localize = function Localize(props) {
  return _react2.default.createElement(_reactLocalizeRedux.Translate, props);
};

exports.default = Localize;
module.exports = exports['default'];