'use strict';

exports.__esModule = true;
exports.init = undefined;

var _browser = require('@sentry/browser');

var Sentry = _interopRequireWildcard(_browser);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var sentryDSN = process.env.REACT_APP_SENTRY_DSN;

var init = exports.init = function init() {
  Sentry.init({ dsn: sentryDSN });
};