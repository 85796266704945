import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';

const dataReducer = (state = [], action) => {
  switch (action.type) {
    case successType(types.GET_CHAT_MESSAGES):
      if (action.response) {
        return [...action.response];
      }
      return state;
    case successType(types.ADD_CHAT_MESSAGE):
      if (action.response) {
        return [action.response, ...state];
      }
      return state;
    case successType(types.ADD_CHAT_ROOM_MESSAGE):
      if (action.response) {
        return [...action.response, ...state];
      }
      return state;
    case types.ADD_CHAT_MESSAGE_FROM_NOTIFICATION:
      if (action.data) {
        return [action.data, ...state];
      }
      return state;
    case successType(types.MARK_MESSAGE_AS_READ):
      if (action.response) {
        return [
          action.response,
          ...state.filter((m) => m.pk !== action.response.pk),
        ];
      }
      return state;
    case types.MARK_MESSAGE_AS_READ_FROM_NOTIFICATION:
      if (action.messageId) {
        const messageToBeMarked = state.find((m) => m.pk === action.messageId);
        return [
          ...state.filter((m) => m.pk !== action.messageId),
          { ...messageToBeMarked, read_at: true },
        ];
      }
      return state;
    default:
      return state;
  }
};

export default createReducer(
  [types.GET_CHAT_MESSAGES, types.ADD_CHAT_MESSAGE, types.MARK_MESSAGE_AS_READ],
  dataReducer,
);
