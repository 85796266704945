import styled from 'styled-components';
import Select from 'react-select';

export const SelectWrap = styled.div`
  position: relative;
`;

export const StyledSelect = styled(Select)`
  width: auto;
  border: 1px solid ${({ theme }) => theme.secondary};
  border-radius: 3px;
  height: auto;

  .select__control {
    //min-height: auto;
    min-height: 42px;
    padding: 10px 34px 10px 10px;
    ${({ hideIndicator }) => hideIndicator && 'padding-right: 10px;'};
    background-color: ${({ theme, hasValue }) => hasValue ? theme.secondary : 'transparent'};
    border: none;
    box-shadow: none;
    cursor: pointer;
    border-radius: 3px;
    &--menu-is-open {
      & .select__dropdown-indicator {
        & svg {
          transform: rotateY(180deg);
        }
      }
    }
    &:hover {
      border-color: inherit;
      box-shadow: none;
    }
  }

  .select__option {
    padding: 6px 12px;
    border-bottom: 1px solid ${({ theme }) => theme.textColorD8};
    cursor: pointer;
    transition: 0.2s;
    &:last-child {
      border: none;
    }

    &--is-focused {
      color: inherit;
      background-color: inherit;
      border-color: ${({ theme }) => theme.textColorD8};
    }
    &:active {
    }
    &:hover {
      color: white;
      background-color: ${({ theme }) => theme.secondaryLight};
      border-color: ${({ theme }) => theme.secondary};
    }

    &--is-selected {
      color: #fff;
      border-color: ${({ theme }) => theme.secondary};
      background-color: ${({ theme }) => theme.secondary} !important;
    }
  }

  .select__menu {
    left: 50%;
    margin-top: 14px;
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fontWeightUpperBold};
    color: ${({ theme }) => theme.secondary};
    text-align: center;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.secondary};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transform: translateX(-50%);
    background-color: #fff;

    &::after,
    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      transform: translateX(-50%);
    }
    &:after {
      border-width: 0 11px 7px 11px;
      border-color: transparent transparent #fff transparent;
    }

    &:before {
      border-width: 0 12px 8px 12px;
      border-color: transparent transparent rgba(0, 0, 0, 0.13) transparent;
    }
  }

  .select__menu-list {
    max-height: 210px;
    padding: 12px 7px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.primary};
    }
  }

  .select__placeholder {
    color: ${({ theme }) => theme.secondary};
    position: relative;
    transform: none;
  }

  .select__dropdown-indicator {
    padding: 0;

    & path {
      stroke: ${({ hasValue, theme }) => (hasValue ? '#fff' : theme.secondary)};
    }
  }

  .select__clear-indicator {
    padding: 0;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__single-value {
    position: static;
    display: block;
    width: 100%;
    height: auto;
    max-width: 100%;
    margin: 0;
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fontWeightBold};
    color: ${({ hasValue, theme }) => (hasValue ? '#fff' : theme.secondary)};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transform: none;
  }

  .select__value-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    height: auto;
    padding: 0;

    & input {
      height: ${({ isSearchable }) => (isSearchable ? 'auto' : 0)};
    }
  }
`;

export const ArrowIconWrap = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  max-width: 8px;
  transform: translateY(-50%) rotate(-90deg);

  & svg {
    max-width: 100%;
    transition: transform 0.3s ease;
  }

  & path {
    stroke: ${({ theme }) => theme.secondary};
  }
`;

export const ClearIconWrap = styled.div`
  svg {
    color: ${({ theme }) => theme.white};
    margin-left: 20px;
  }
`;
