import React from 'react';
import PropTypes from 'prop-types';
import { BellContainer, BellIcon, BellBadge } from './BellIconStyled';

function Bell({ hasUnread }) {
  return (
    <BellContainer>
      <BellIcon />
      {
        hasUnread && <BellBadge />
      }
    </BellContainer>
  );
}

Bell.propTypes = {
  hasUnread: PropTypes.bool,
};

Bell.defaultProps = {
  hasUnread: false,
};

export default Bell;
