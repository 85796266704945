'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _format = require('date-fns/format');

var _format2 = _interopRequireDefault(_format);

var _languages = require('../../lib/languages');

var _Localize = require('../Localize');

var _Localize2 = _interopRequireDefault(_Localize);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var formats = {
  dateTime: 'dd.MM.yyyy, hh:mm a',
  date: 'dd.MM.yyyy'
};

var Time = function Time(_ref) {
  var date = _ref.date,
      format = _ref.format;

  var dateTime = new Date(date);
  return _react2.default.createElement(
    _Localize2.default,
    null,
    function (_ref2) {
      var activeLanguage = _ref2.activeLanguage;
      return _react2.default.createElement(
        'time',
        { dateTime: dateTime.toISOString() },
        (0, _format2.default)(dateTime, formats[format], {
          locale: _languages.locales[activeLanguage.code]
        })
      );
    }
  );
};

Time.propTypes = process.env.NODE_ENV !== "production" ? {
  date: _propTypes2.default.string.isRequired,
  format: _propTypes2.default.oneOf(Object.keys(formats))
} : {};

Time.defaultProps = {
  format: 'date'
};

exports.default = Time;
module.exports = exports['default'];