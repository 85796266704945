import { combineReducers } from 'redux';
import currentUser from './currentUserReducer';
import notifications from './notificationsReducer';
import application from './applicationReducer';
import contract from './contractReducer';
import internshipReport from './internshipReportReducer';
import calendar from './calendarReducer';
import qualifications from './qualificationsReducer';
import skills from './skillsReducer';
import userSkills from './userSkillsReducer';
import applicants from './applicantsReducer';
import applicantState from './applicantStateReducer';
import attachments from './attachmentsReducer';
import myApplications from './myApplicationsReducer';
import assessment from './assessmentReducer';
import kind from './kindReducer';
import applicationStatuses from './applicationStatusesReducer';
import learningTask from './learningTaskReducer';
import internships from './internshipsReducer';
import specialTasks from './specialTasksReducer';
import internshipRequest from './internshipRequestReducer';
import users from './usersReducer';
import selectedInternship from './selectedInternshipReducer';
import chat from './chatReducer';
import messages from './messagesReducer';
import vacancy from './vacancyReducer';
import tag from './tagReducer';
import iframe from './iframeReducer';
import comment from './internshipCommentReducer';
import entityInstruction from './entityInstructionReducer';
import filter from './filterReducer';

export default combineReducers({
  currentUser,
  notifications,
  application,
  calendar,
  contract,
  internshipReport,
  qualifications,
  skills,
  userSkills,
  applicants,
  applicantState,
  attachments,
  myApplications,
  assessment,
  kind,
  applicationStatuses,
  learningTask,
  internships,
  specialTasks,
  internshipRequest,
  users,
  selectedInternship,
  chat,
  messages,
  vacancy,
  tag,
  iframe,
  entityInstruction,
  filter,
  comment,
});
