import chatApi from 'api/ChatApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getChatUsers(internshipId, applicantId) {
  return createAction({
    type: types.GET_CHAT_USERS,
    callAPI: () => chatApi.getUsers(internshipId, applicantId),
    payload: { internshipId },
  });
}

export function addChatRoomMessage(internshipId, data) {
  return createAction({
    type: types.ADD_CHAT_ROOM_MESSAGE,
    callAPI: () => chatApi.addRoomMessage(data),
    payload: { internshipId },
  });
}

export function addMessage(internshipId, data) {
  const _internshipId = internshipId || 'global';
  return createAction({
    type: types.ADD_CHAT_MESSAGE,
    callAPI: () => chatApi.addMessage(data),
    payload: { internshipId: _internshipId },
  });
}

export function addMessageFromNotification(message) {
  return (dispatch) => {
    dispatch({
      type: types.ADD_CHAT_MESSAGE_FROM_NOTIFICATION,
      data: message,
    });
  };
}

export function getChatMessages(internshipId) {
  return createAction({
    type: types.GET_CHAT_MESSAGES,
    callAPI: () => chatApi.getMessages(),
    payload: { internshipId },
  });
}

export function getChatRooms(internshipId) {
  return createAction({
    type: types.GET_CHAT_ROOMS,
    callAPI: () => chatApi.getRooms(),
    payload: { internshipId },
  });
}

export function markMessageAsRead(messageId) {
  return createAction({
    type: types.MARK_MESSAGE_AS_READ,
    callAPI: () => chatApi.markMessageAsRead(messageId),
  });
}

export function markMessageAsReadFromNotification(messageId) {
  return (dispatch) => {
    dispatch({
      type: types.MARK_MESSAGE_AS_READ_FROM_NOTIFICATION,
      messageId,
    });
  };
}
