'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactFontawesome = require('@fortawesome/react-fontawesome');

var _freeSolidSvgIcons = require('@fortawesome/free-solid-svg-icons');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

/* eslint-disable */
var RightNavButton = function RightNavButton(_ref) {
  var currentSlide = _ref.currentSlide,
      slideCount = _ref.slideCount,
      props = _objectWithoutProperties(_ref, ['currentSlide', 'slideCount']);

  return _react2.default.createElement(
    'div',
    props,
    _react2.default.createElement(_reactFontawesome.FontAwesomeIcon, { icon: _freeSolidSvgIcons.faChevronRight })
  );
};

exports.default = RightNavButton;
module.exports = exports['default'];