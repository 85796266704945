import ServerApi from 'shared/lib/api/ServerApi';
import {
  buildInternshipUrl as build,
  parseInternshipId as parse,
} from 'utils/uri';

export const PERSONAL_INTERNSHIP_ID = 'personal';

export const buildInternshipUrl = (internshipId) => internshipId === PERSONAL_INTERNSHIP_ID ? null : build(internshipId);

export const parseInternshipId = (url) => url == null ? PERSONAL_INTERNSHIP_ID : parse(url);

export const INVITEES_STATUSES = {
  accepted: 'ACCEPTED',
  rejected: 'REJECTED',
};

class CalendarApi extends ServerApi {
  getEvents(internshipId, date) {
    let params = '';
    if (internshipId === PERSONAL_INTERNSHIP_ID) {
      params = '&internship_exists=false';
    }
    if (date) {
      const year = date.get('year');
      const month = date.get('month');
      params = `${params}&year=${year}&month=${month}`;
    }
    if (params) {
      return super.get(
        `/internship/calendar-event/?internship=${internshipId}${params}`,
      );
    }
    return super.get(`/internship/calendar-event/?internship=${internshipId}`);
  }

  getOnSideAppointments() {
    return super.get('/internship/calendar-event/?kind=ONSITE_APPOINTMENT');
  }

  deleteEvent(id) {
    return super.delete(`/internship/calendar-event/${id}/`);
  }

  addEvent(event) {
    return super.post('/internship/calendar-event/', event);
  }

  editEvent(eventId, event) {
    return super.put(`/internship/calendar-event/${eventId}/`, event);
  }

  editInviteeStatus(id, data) {
    return super.patch(`/internship/calendar-event-invitee/${id}/`, data);
  }
}

export default new CalendarApi();
