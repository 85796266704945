import ServerApi from 'shared/lib/api/ServerApi';

class LearningTaskApi extends ServerApi {
  getTask(internshipId) {
    return super.get(
      `/internship/operational-learning/?internship=${internshipId}`,
    );
  }

  updateTask(pk, task) {
    return super.patch(`/internship/operational-learning/${pk}/`, task);
  }

  resendTask(pk) {
    return super.patch(`/internship/operational-learning/${pk}/`, {
      submitted: false,
    });
  }

  deleteTaskAttachments(pk) {
    return super.delete(`/internship/operational-learning-attachment/${pk}/`);
  }

  uploadTeacherAttachment(internshipId, file) {
    return super.post('/internship/operational-learning-teacher-attachment/', {
      internship_id: internshipId,
      file,
    });
  }

  deleteTeacherAttachment(pk) {
    return super.delete(
      `/internship/operational-learning-teacher-attachment/${pk}/`,
    );
  }
}

export default new LearningTaskApi();
