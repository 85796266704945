import styled from 'styled-components';
import { device } from 'styles/device';

export const H2 = styled.h2`
  margin-top: 90px;
  margin-bottom: 60px;
  color: ${({ theme }) => theme.secondary};
  font-size: 28px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  text-align: center;
  @media ${device.mobile} {
    font-size: 18px;
    text-align: center;
  }
`;
