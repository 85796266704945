import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { device } from 'styles/device';

export const RolesContainer = styled.div`
  display: flex;
  > div.role {
    margin-left: 30px;
    @media ${device.mobile} {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 25%;
    }
  }
  > div.role:first-child {
    margin-left: 0px;
  }
`;

export const StyledRole = styled.div`
  color: ${({ theme }) => theme.textColor3};
  font-size: 16px;
  .text {
    margin-left: 10px;
  }
  @media ${device.mobile} {
    font-size: 12px;
    padding-top: 10px;
    .text {
      margin-left: 5px;
    }
  }
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  color: ${({ color }) => color};
  font-size: 22px;
`;
