import React from 'react';
import PropTypes from 'prop-types';
import { Info } from 'shared';
import Localize from 'components/Localize';
import Application from './Application';

const search = (a, b) => a.toString().search(new RegExp(b.toString(), 'i')) > -1;

const byText = (text) => (item) => Object.entries(item).some(
  ([key, value]) => key && value && search(value, text),
);
const byStatus = (status) => (item) => (!status ? true : item.status === status);

const FilteredItems = ({ filter, applications }) => {
  const filtered = applications
    .filter(byStatus(filter.status))
    .filter(byText(filter.text));
  return (
    <>
      {filtered.length === 0 && (
        <Info>
          <Localize id="there-are-no-items-for-defined-filters" />
        </Info>
      )}
      {filtered.map((app, id) => (
        <Application key={id} data={app} />
      ))}
    </>
  );
};

FilteredItems.propTypes = {
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
      companyLogo: PropTypes.string,
      companyName: PropTypes.string,
      title: PropTypes.string,
      industryList: PropTypes.array,
      kind: PropTypes.string,
      city: PropTypes.string,
    }),
  ).isRequired,
  filter: PropTypes.shape({
    status: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

export default FilteredItems;
