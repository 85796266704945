import { actions, roles } from '../constants';

export const section = {
  InternshipSwitcher: 'InternshipSwitcher',
  ApplicantInternshipSwitcher: 'ApplicantInternshipSwitcher',
  InternshipRequests: 'InternshipRequests',
  CalendarEventVisibility: 'CalendarEventVisibility',
  schoolFiltering: 'schoolFiltering',
  classRoomFiltering: 'classRoomFiltering',
  sortApplicantByName: 'sortApplicantByName',
};

export default (role, can, rules) => {
  if (role === roles.Applicant) {
    can(actions.see, section.InternshipSwitcher);
    can(actions.see, section.ApplicantInternshipSwitcher);
    can(actions.see, section.InternshipRequests);
    can(actions.edit, section.CalendarEventVisibility);
  }
  if (role === roles.Teacher) {
    can(actions.edit, section.CalendarEventVisibility);
    can(actions.see, section.schoolFiltering);
    can(actions.see, section.classRoomFiltering);
    can(actions.see, section.ApplicantInternshipSwitcher);
    can(actions.see, section.sortApplicantByName);
  }

  if (role === roles.CompanyEmployee) {
    can(actions.edit, section.CalendarEventVisibility);
  }

  if (role === roles.EducationProvider) {
    can(actions.edit, section.CalendarEventVisibility);
    can(actions.see, section.schoolFiltering);
    can(actions.see, section.classRoomFiltering);
    can(actions.see, section.ApplicantInternshipSwitcher);
    can(actions.see, section.sortApplicantByName);
  }
  return rules;
};
