import React from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import AttendeesProvider from '../EventForm/AttendeesProvider';

import {
  FieldLabelText,
  FieldLabelContent,
  WhoSeeSelectWrapper,
  FieldLabel,
  WhoSeeSelect,
  WhoSeeSelectOption,
  WhoSeeSelectOptionRole,
} from '../EventForm/EventFormStyled';

const customFilter = (option, searchText) => {
  const label = option.label.props.children;
  const flattenedLabel = label.slice(0, label.length).join() + label.slice(-1)[0].props.children;
  if (flattenedLabel.toLowerCase().includes(searchText.toLowerCase())) {
    return true;
  }
  return false;
};

const AttendeesSelect = ({ internshipId, meta, name }) => {
  const onChange = (value) => meta.setFieldValue(name, value);
  return (
    <FieldLabel noBorder>
      <FieldLabelText>
        <Localize id="attendees" />
      </FieldLabelText>
      <FieldLabelContent>
        <AttendeesProvider internshipId={internshipId}>
          {(users) => (
            <WhoSeeSelectWrapper>
              <Localize>
                {({ translate }) => (
                  <WhoSeeSelect
                    isMulti
                    options={users.map(({ pk, user, role_display }) => ({
                      value: pk,
                      label: (
                        <WhoSeeSelectOption>
                          {user.first_name} {user.last_name}{' '}
                          <WhoSeeSelectOptionRole>
                            {role_display}
                          </WhoSeeSelectOptionRole>
                        </WhoSeeSelectOption>
                      ),
                    }))}
                    filterOption={customFilter}
                    value={meta.values[name]}
                    isSearchable
                    onChange={onChange}
                    placeholder={translate('select')}
                  />
                )}
              </Localize>
            </WhoSeeSelectWrapper>
          )}
        </AttendeesProvider>
      </FieldLabelContent>
    </FieldLabel>
  );
};

AttendeesSelect.propTypes = {
  internshipId: PropTypes.number,
  meta: PropTypes.shape({
    setFieldValue: PropTypes.func,
    values: PropTypes.object,
  }).isRequired,
  name: PropTypes.string.isRequired,
};

AttendeesSelect.defaultProps = {
  internshipId: null,
};

export default AttendeesSelect;
