import styled from 'styled-components';
import { device } from 'styles/device';

export const PeriodWrapper = styled.div`
  display: flex;
  align-items: center;
  @media ${device.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const DateTitle = styled.div`
  text-transform: uppercase;
  padding-bottom: 5px;
`;

export const To = styled.div`
  padding: 0px 10px 10px;
`;
