import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import moment from 'moment';
import Localize from 'components/Localize';
import { DownloadButton } from 'components/DownloadUrl';
import editIcon from 'shared/lib/styles/images/edit.svg';
import documentIcon from 'styles/images/svg/document.svg';
import {
  AttachmentIcon,
  CheckBoxDescription,
  CheckBoxTitle,
  EditIcon,
  VisitNotePreviewContent,
  VisitNotePreviewWrap,
  AttachmentsWrap,
} from './VisitNotePreviewStyled';

const VisitNotePreview = ({ event, onItemEdit }) => {
  const [isAttachmentsOpen, setAttachmentsOpen] = useState(false);
  const {
    pk, description, end, attachments, author, start,
  } = event;
  return (
    <VisitNotePreviewWrap>
      <VisitNotePreviewContent>
        <div>
          <CheckBoxTitle>{description}</CheckBoxTitle>
          <CheckBoxDescription>
            <Localize
              id="onsite-appointment-created"
              data={{
                author: `${author.user.first_name} ${author.user.last_name}`,
              }}
            />
            ,&nbsp;
            {moment(start).format('DD.MM.YYYY')}&nbsp;
            {moment(start)
              .utc()
              .format('HH:mm')}{' '}
            -{' '}
            {moment(end)
              .utc()
              .format('HH:mm')}{' '}
            <Localize id="hr" />
          </CheckBoxDescription>
        </div>
        <div>
          {attachments.length > 0 && (
            <AttachmentIcon
              src={documentIcon}
              onClick={() => setAttachmentsOpen((open) => !open)}
            />
          )}
          {onItemEdit && (
            <EditIcon
              src={editIcon}
              onClick={() => onItemEdit({
                pk,
                description,
                end,
                attachments,
              })}
            />
          )}
        </div>
      </VisitNotePreviewContent>
      <Collapse isOpened={isAttachmentsOpen}>
        <AttachmentsWrap>
          {attachments.map(({ file, api_url }) => (
            <DownloadButton key={`task_attachment_${api_url}`} url={file} />
          ))}
        </AttachmentsWrap>
      </Collapse>
    </VisitNotePreviewWrap>
  );
};

VisitNotePreview.propTypes = {
  event: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    end: PropTypes.string,
    author: PropTypes.object.isRequired,
    start: PropTypes.string.isRequired,
    done: PropTypes.bool,
    attachments: PropTypes.array.isRequired,
    editForm: PropTypes.node,
    showAttachments: PropTypes.node,
  }).isRequired,
  onItemEdit: PropTypes.func,
};

VisitNotePreview.defaultProps = {
  onItemEdit: undefined,
};

export default VisitNotePreview;
