import ServerApi from 'shared/lib/api/ServerApi';
import { getDefaultLanguage } from 'shared/lib/lib/languages';

class ApplicationApi extends ServerApi {
  getAdvisorsData() {
    return super.get('/internship/application-assistant/');
  }

  getMyApplications() {
    return super.get(`/internship/application/?language=${getDefaultLanguage()}`);
  }

  getApplicationStatuses(language) {
    return super.get(`/internship/application-statuses/?language=${language}`);
  }
}

export default new ApplicationApi();
