import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import TaskCard from 'components/TaskCard/TaskCard';
import DocumentsList from 'components/DocumentsList/DocumentsList';
import { Collapse } from 'shared';
import Can from 'components/Can/Can';
import { section } from 'utils/access-rules/rules/internshipTask';
import { actions } from 'components/Can';
import { InternshipCommentForm } from 'pages/InternshipComment';
import InternshipReportContent from '../../InternshipReport/InternshipReportContent';
import CompletingPresentation from '../CompletingPresentation';
import AssessmentContainer from '../../Assessment/AssessmentContainer';
import {
  getDocumentsText,
  getDocumentsSubText,
  getAssessmentText,
  getAssessmentSubText,
  getOltText,
  getOltSubText,
  getReportText,
  getReportSubText,
  getPresentationText,
  getPresentationSubText,
} from '../applicantProfileUtils';

const InternshipRelatedBoxes = ({
  translate,
  contract,
  application,
  user,
  esuser,
  operational_learning,
  internship,
  uploaded_documents,
}) => (
  <div>
    <Element name="documents">
      <Collapse
        collapseInOpen
        headerText={translate(getDocumentsText(user.role))}
        previewText={(
          <Can I={actions.see} a={section.previewText}>
            {translate(getDocumentsSubText(user.role))}
          </Can>
        )}
      >
        <DocumentsList
          documents={[
            ...(contract
              && contract.document_list
              && contract.document_list.map((doc) => ({
                key: `contract${doc.pk}`,
                text: translate('internship-contract'),
                link: doc.file,
                nameFile: `${translate('internship-contract')}_of_${
                  user.user.first_name
                }_${user.user.last_name}`,
              }))),
            application.cv_url && {
              key: 'application',
              text: translate('application'),
              link: application.cv_url,
              nameFile: `${translate('application')}_of_${
                user.user.first_name
              }_${user.user.last_name}`,
            },
            ...uploaded_documents.map((doc) => ({
              key: `document${doc.pk}`,
              text: translate('application-document'),
              link: doc.image || doc.cv,
              nameFile: `${translate('application-document')}_of_${
                user.user.first_name
              }_${user.user.last_name}`,
            })),
          ].filter((doc) => doc)}
        />
      </Collapse>
    </Element>
    <Element id="assessment" name="assessment">
      <Collapse
        collapseInOpen
        headerText={translate(getAssessmentText(user.role))}
        previewText={(
          <Can I={actions.see} a={section.previewText}>
            {translate(getAssessmentSubText(user.role))}
          </Can>
        )}
      >
        <AssessmentContainer internshipId={internship.pk} />
      </Collapse>
    </Element>
    <Element id="internshipComment" name="internshipComment">
      <Collapse
        collapseInOpen
        headerText={translate(getAssessmentText(user.role))}
        previewText={(
          <Can I={actions.see} a={section.previewText}>
            {translate(getAssessmentSubText(user.role))}
          </Can>
        )}
      >
        <InternshipCommentForm internshipId={internship.pk} />
      </Collapse>
    </Element>
    <Element id="olt" name="operationalLearning">
      <Collapse
        collapseInOpen
        headerText={translate(getOltText(user.role))}
        previewText={(
          <Can I={actions.see} a={section.previewText}>
            {translate(getOltSubText(user.role), {
              internshipName: esuser.user.first_name,
            })}
          </Can>
        )}
      >
        <TaskCard data={operational_learning || {}} />
      </Collapse>
    </Element>
    <Element id="report" name="internshipReport">
      <Collapse
        collapseInOpen
        headerText={translate(getReportText(user.role))}
        previewText={(
          <Can I={actions.see} a={section.previewText}>
            {translate(getReportSubText(user.role), {
              internshipName: esuser.user.first_name,
            })}
          </Can>
        )}
      >
        <InternshipReportContent internshipId={internship.pk} viewOnly />
      </Collapse>
    </Element>
    <Element id="presentation" name="presentation">
      <Collapse
        collapseInOpen
        headerText={translate(getPresentationText(user.role))}
        previewText={(
          <Can I={actions.see} a={section.previewText}>
            <>
              {translate(getPresentationSubText(user.role), {
                internshipName: esuser.user.first_name,
              })}
            </>
          </Can>
        )}
      >
        <CompletingPresentation
          status={internship.presentation_completed}
          internshipId={internship.pk}
        />
      </Collapse>
    </Element>
  </div>
);

InternshipRelatedBoxes.propTypes = {
  internship: PropTypes.shape({
    pk: PropTypes.number,
    presentation_completed: PropTypes.bool,
  }).isRequired,
  esuser: PropTypes.shape({
    user: PropTypes.shape({
      first_name: PropTypes.string,
    }),
  }).isRequired,
  assessmentError: PropTypes.shape({
    message: PropTypes.string,
  }),
  operational_learning: PropTypes.shape({
    pk: PropTypes.number,
  }).isRequired,
  application: PropTypes.shape({
    cv_url: PropTypes.string,
  }).isRequired,
  contract: PropTypes.shape({
    document_list: PropTypes.arrayOf(
      PropTypes.shape({
        pk: PropTypes.number,
      }),
    ),
  }).isRequired,
  uploaded_documents: PropTypes.arrayOf(
    PropTypes.shape({
      pk: PropTypes.number,
      image: PropTypes.string,
      cv: PropTypes.string,
    }),
  ).isRequired,
  translate: PropTypes.func.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
    user: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  assessment: PropTypes.shape({
    group_list: PropTypes.arrayOf(PropTypes.shape({})),
    pk: PropTypes.number.isRequired,
    completed: PropTypes.bool.isRequired,
  }),
  answerChoices: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
};

InternshipRelatedBoxes.defaultProps = {
  assessment: null,
  answerChoices: null,
  assessmentError: null,
};

export default InternshipRelatedBoxes;
