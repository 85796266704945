import React from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import uploadIcon from 'shared/lib/styles/images/load.svg';
import { toBase64 } from 'shared/lib/lib/base64';
import { Button, FileInput, Thumbnail } from 'shared';

import { TaskSheet } from './TaskCardStyled';

const DocumentUploadButton = ({
  onChange,
  onDeleteTeacherAttachment,
  value,
  name,
}) => {
  const onFileUpload = (e) => {
    if (e.target.files.length > 0) {
      const value = e.target.files[0];
      toBase64(value).then(({ base64 }) => {
        const file = base64.split(',').pop();
        onChange({
          target: { name, value: { file } },
        });
      });
    }
  };
  const onDelete = (pk) => {
    onDeleteTeacherAttachment(pk);
    onChange({
      target: { name, value: undefined },
    });
  };
  if (value) {
    return (
      <TaskSheet>
        <Thumbnail
          className="task-sheet-thumb"
          deleteButtonClass="delete-button"
          data={value}
          onDelete={onDelete}
          prompt
          promptNode={<Localize id="are-you-sure-want-delete-task-sheet" />}
        />
      </TaskSheet>
    );
  }
  return (
    <FileInput onChange={onFileUpload}>
      <Button color="secondary" size="small" icon={uploadIcon}>
        <Localize id="upload-task-sheet" />
      </Button>
    </FileInput>
  );
};

DocumentUploadButton.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDeleteTeacherAttachment: PropTypes.func.isRequired,
  value: PropTypes.shape({
    file: PropTypes.string,
  }),
};

DocumentUploadButton.defaultProps = {
  value: undefined,
};

export default DocumentUploadButton;
