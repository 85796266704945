import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactSvg from 'react-svg';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSync } from '@fortawesome/free-solid-svg-icons';
import trashIcon from 'shared/lib/styles/images/trash.svg';
import { Tooltip } from 'react-tippy';
import { Element } from 'react-scroll';

import camera from 'styles/images/svg/camera-big.svg';
import { Button, Prompt } from 'shared';
import Paragraph from 'components/Paragraph';
import Localize from 'components/Localize';
import editIcon from 'shared/lib/styles/images/edit.svg';
import Can from 'components/Can/Can';
import { section } from 'utils/access-rules/rules/internshipRequest';
import { actions } from 'components/Can';
import { roles } from 'utils/access-rules/constants';
import { parseInternshipId } from 'utils/uri';
import checkIcon from 'shared/lib/styles/images/check.svg';
import ItemEditableForm from './ItemEditableForm';
import ContractButton from './ContractButton';
import { INTERNSHIP_REQUEST_STATUSES } from '../InternshipListContainer';

import {
  CameraCircle,
  ItemContainer,
  ItemData,
  ButtonsContainer,
  StatusIcon,
  DeleteIcon,
} from './InternshipsRequestItemStyled';

const initialState = {
  isEditable: false,
};

const InternshipsRequestItem = ({
  data,
  changeInternshipRequest,
  getInternships,
  uploadInternshipRequestAttachment,
  deleteInternshipRequestAttachment,
  deleteInternshipRequest,
  loading,
  currentUser,
  history,
  ...rest
}) => {
  const [state, setState] = useState(initialState);
  const [internshipLoading, setInternshipLoading] = useState(false);
  const onShowForm = () => setState({ ...state, isEditable: true });
  useEffect(() => {
    if (data.new_added) onShowForm();
  }, [data]);
  const isPeriodSet = !!data.start && !!data.end;
  const onChange = (...props) => changeInternshipRequest(...props).then((result) => {
    setInternshipLoading(false);
    if (currentUser.role === roles.Applicant) {
      setState({ ...state, isEditable: false });
    } else {
      if (props[1].accepted) {
        getInternships().then(() => {
          if (result && result.response) {
            history.push(
              `/applicant-profile/${
                result.response.applicant.pk
              }/${parseInternshipId(result.response.internship)}`,
            );
          }
          setState({ ...state, isEditable: false });
        });
      }
      setState({ ...state, isEditable: false });
    }
  });
  const onCancel = () => setState({ ...state, isEditable: false });
  const onDelete = () => deleteInternshipRequest && deleteInternshipRequest(data.pk);

  const onAccept = () => {
    setInternshipLoading(true);
    const internshipRequest = {
      ...data,
      start: data.start ? data.start : undefined,
      end: data.end ? data.end : undefined,
      attachments: undefined,
    };
    if (currentUser.role === roles.Teacher) {
      return onChange(data.pk, {
        ...internshipRequest,
        accepted: true,
      });
    }
    return onChange(data.pk, internshipRequest);
  };

  return (
    <Localize>
      {({ translate }) => (
        <ItemContainer {...rest}>
          <Element key={data.pk} name={data.pk.toString()}>
            <CameraCircle>
              {data.company_logo ? (
                <img src={data.company_logo} alt="company_logo" />
              ) : (
                <ReactSvg src={camera} />
              )}
            </CameraCircle>
            {!data.accepted
              && data.status !== INTERNSHIP_REQUEST_STATUSES.ACCEPTED && (
                <Can I={actions.see} a={section.deleteInternshipRequest}>
                  <Prompt
                    confirm={onDelete}
                    trigger={(
                      <DeleteIcon>
                        <div className="wrapper">
                          <ReactSvg src={trashIcon} />
                        </div>
                      </DeleteIcon>
                    )}
                  >
                    <Localize
                      id="are-you-sure-want-delete"
                      data={{ name: data.company_name }}
                    />
                  </Prompt>
                </Can>
            )}
            {data.status === INTERNSHIP_REQUEST_STATUSES.WAITING_FOR_APPROVAL
              && !data.accepted && (
                <StatusIcon color="orange">
                  <Tooltip
                    title={translate('internship-waiting-approval')}
                    theme="light"
                    arrow
                  >
                    <div className="wrapper">
                      <FontAwesomeIcon icon={faSync} />
                    </div>
                  </Tooltip>
                </StatusIcon>
            )}
            {(data.status === INTERNSHIP_REQUEST_STATUSES.ACCEPTED
              || data.accepted) && (
              <StatusIcon color="green">
                <Tooltip
                  title={translate('internship-request-accepted')}
                  theme="light"
                  arrow
                >
                  <div className="wrapper">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                </Tooltip>
              </StatusIcon>
            )}
            <Paragraph className="paragraph" withTopMargin />
            {state.isEditable ? (
              <ItemEditableForm
                data={data}
                onChange={onChange}
                onCancel={onCancel}
                uploadInternshipRequestAttachment={
                  uploadInternshipRequestAttachment
                }
                deleteInternshipRequestAttachment={
                  deleteInternshipRequestAttachment
                }
                loading={loading && internshipLoading}
                userRole={currentUser.role}
                setInternshipLoading={setInternshipLoading}
              />
            ) : (
              <>
                <ul>
                  <ItemData isFilled={!!data.company_name}>
                    {data.company_name || <Localize id="company" />}
                  </ItemData>
                  <ItemData isFilled={isPeriodSet}>
                    {isPeriodSet ? (
                      `${moment(data.start).format('DD.MM.YYYY')} - ${moment(
                        data.end,
                      ).format('DD.MM.YYYY')}`
                    ) : (
                      <Localize id="period" />
                    )}
                  </ItemData>
                  <ItemData isFilled={!!data.company_employee}>
                    {data.company_employee || <Localize id="contact-person" />}
                  </ItemData>
                  <ItemData isFilled={!!data.company_employee}>
                    {data.company_phone || <Localize id="company-phone" />}
                  </ItemData>
                  <ItemData isFilled={!!data.company_email}>
                    {data.company_email || <Localize id="company-email" />}
                  </ItemData>
                  <ItemData isFilled={!!data.company_address}>
                    {data.company_address || <Localize id="company-address" />}
                  </ItemData>
                </ul>
                <ButtonsContainer>
                  <ContractButton
                    data={data.attachments}
                    company={data.company_name}
                  />
                  <Tooltip
                    title={
                      ((data.status === INTERNSHIP_REQUEST_STATUSES.ACCEPTED
                        || data.accepted)
                        && translate('internship-request-accepted'))
                      || (data.status
                        === INTERNSHIP_REQUEST_STATUSES.WAITING_FOR_APPROVAL
                        && !data.accepted)
                        ? translate('internship-waiting-approval')
                        : ''
                    }
                    theme="light"
                    style={{
                      display: 'block',
                    }}
                    position="bottom"
                    arrow
                  >
                    <Button
                      icon={editIcon}
                      disableBoxShadow
                      color="secondary"
                      size="small"
                      onClick={onShowForm}
                      disabled={
                        data.status === INTERNSHIP_REQUEST_STATUSES.ACCEPTED
                        || data.accepted
                      }
                    >
                      {data.status
                        === INTERNSHIP_REQUEST_STATUSES.WAITING_FOR_INFORMATION
                      && !data.accepted ? (
                        <Localize id="fill-out-request" />
                        ) : (
                          <Localize id="edit" />
                        )}
                    </Button>
                  </Tooltip>
                  {data.status
                    === INTERNSHIP_REQUEST_STATUSES.WAITING_FOR_APPROVAL
                    && !data.accepted
                    && currentUser.role === roles.Teacher && (
                      <Prompt
                        confirm={onAccept}
                        trigger={(
                          <Button
                            icon={checkIcon}
                            loading={loading && internshipLoading}
                            disableBoxShadow
                            color="approve"
                            size="small"
                            type="submit"
                          >
                            <Localize id="accept" />
                          </Button>
                        )}
                      >
                        <Localize id="are-you-sure-you-want-accept-internship-request" />
                      </Prompt>
                  )}
                </ButtonsContainer>
              </>
            )}
          </Element>
        </ItemContainer>
      )}
    </Localize>
  );
};

InternshipsRequestItem.propTypes = {
  data: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    company_name: PropTypes.string,
    company_employee: PropTypes.string,
    company_phone: PropTypes.string,
    company_email: PropTypes.string,
    company_address: PropTypes.string,
    company_logo: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    location: PropTypes.string,
    attachments: PropTypes.array,
    status: PropTypes.string,
    accepted: PropTypes.bool,
    new_added: PropTypes.bool,
  }).isRequired,
  changeInternshipRequest: PropTypes.func.isRequired,
  getInternships: PropTypes.func.isRequired,
  uploadInternshipRequestAttachment: PropTypes.func,
  deleteInternshipRequestAttachment: PropTypes.func,
  deleteInternshipRequest: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

InternshipsRequestItem.defaultProps = {
  deleteInternshipRequest: null,
  uploadInternshipRequestAttachment: null,
  deleteInternshipRequestAttachment: null,
};

export default withRouter(InternshipsRequestItem);
