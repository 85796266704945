'use strict';

exports.__esModule = true;
exports.Prompt = exports.default = undefined;

var _Prompt = require('./Prompt');

Object.defineProperty(exports, 'Prompt', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Prompt).default;
  }
});

var _Modal = require('./Modal');

var _Modal2 = _interopRequireDefault(_Modal);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Modal2.default;