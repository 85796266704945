import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Calendar from 'components/SchedulerCalendar/Calendar';
import EventInfo from 'components/SchedulerCalendar/EventInfo';
import { ActiveLanguageProvider } from 'pages/LocalizeProvider';
import { ScheduleCalendarWrapper } from './ScheduleCalendarStyled';
import EventForm from './EventForm';

class ScheduleCalendar extends React.Component {
  constructor() {
    super();
    this.state = {
      navDate: moment(),
    };
  }

  onChangeNavDate = (date) => {
    this.setState({ navDate: date });
    const { onChangeNavDate } = this.props;
    onChangeNavDate(date);
  };

  render() {
    const { navDate } = this.state;
    const {
      date,
      events,
      selectedEvent,
      closeForm,
      onChangeDate,
      onEventAdd,
      onEventDelete,
      onSendEvent,
      onCancelForm,
      onEventEdit,
      notifications,
      internshipId,
      shouldAddAbsence,
      isStudentProfile,
      onGetEvents,
    } = this.props;
    return (
      <ActiveLanguageProvider>
        {(activeLan) => (
          <ScheduleCalendarWrapper>
            <Calendar
              date={date}
              meetList={events.APPOINTMENT}
              missesList={events.ABSENCE}
              taskList={events.SPECIAL_TASK}
              onsiteAppointments={events.ONSITE_APPOINTMENT}
              onChangeNavDate={this.onChangeNavDate}
              onChangeDate={onChangeDate}
            />
            <EventInfo
              date={date}
              navDate={navDate}
              meetList={events.APPOINTMENT}
              missesList={events.ABSENCE}
              taskList={events.SPECIAL_TASK}
              onsiteAppointments={events.ONSITE_APPOINTMENT}
              onEventEdit={onEventEdit}
              onEventDelete={onEventDelete}
              onEventAdd={onEventAdd}
              language={activeLan.code}
              notifications={notifications}
              calendar={events}
              closeForm={closeForm}
              shouldAddAbsence={shouldAddAbsence}
              onGetEvents={onGetEvents}
            >
              {selectedEvent && (
                <EventForm
                  closeForm={closeForm}
                  date={date}
                  event={selectedEvent}
                  onSendEvent={onSendEvent}
                  onCancel={onCancelForm}
                  internshipId={internshipId}
                  onChangeDate={onChangeDate}
                  isStudentProfile={isStudentProfile}
                />
              )}
            </EventInfo>
          </ScheduleCalendarWrapper>
        )}
      </ActiveLanguageProvider>
    );
  }
}

ScheduleCalendar.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  events: PropTypes.objectOf(PropTypes.object),
  selectedEvent: PropTypes.object,
  onChangeDate: PropTypes.func.isRequired,
  onChangeNavDate: PropTypes.func.isRequired,
  onEventDelete: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  onEventAdd: PropTypes.func.isRequired,
  onEventEdit: PropTypes.func.isRequired,
  onSendEvent: PropTypes.func.isRequired,
  onCancelForm: PropTypes.func.isRequired,
  onGetEvents: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.object),
  internshipId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shouldAddAbsence: PropTypes.bool,
  isStudentProfile: PropTypes.bool,
};

ScheduleCalendar.defaultProps = {
  internshipId: null,
  selectedEvent: null,
  events: null,
  shouldAddAbsence: true,
  isStudentProfile: true,
  notifications: null,
};

export default ScheduleCalendar;
