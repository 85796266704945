import React from 'react';
import PropTypes from 'prop-types';
import { SecondaryButton } from 'shared';
import Localize from 'components/Localize';
import check from 'styles/images/svg/check.svg';
import { MessageWrap, SuccessText, CheckIcon } from './MessageStyled';

const Success = ({ handleClose }) => (
  <MessageWrap>
    <SuccessText>
      <CheckIcon src={check} />
      <Localize id="great-your-application-has-been-sent" />
    </SuccessText>
    <SecondaryButton size="small" onClick={handleClose}>
      <Localize id="done" />
    </SecondaryButton>
  </MessageWrap>
);

Success.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default Success;
