import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import PropTypes from 'prop-types';
import { InformationVideoContainer } from './InformationModalStyled';

const Video = ({ src }) => {
  const videoNode = useRef(null);

  useEffect(() => {
    if (src) {
      videojs(videoNode.current, {
        controls: true,
        sources: [
          {
            src,
            type: 'video/mp4',
          },
        ],
      });
    }
  }, [src]);

  return (
    <InformationVideoContainer>
      <div data-vjs-player>
        <video ref={videoNode} key={src} className="video-js">
          <track kind="captions" />
        </video>
      </div>
    </InformationVideoContainer>
  );
};

Video.propTypes = {
  src: PropTypes.string,
};

Video.defaultProps = {
  src: '',
};

export default Video;
