import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Error, Loader } from 'shared';
import WhiteArea from 'components/WhiteArea';
import { getLearningTask } from 'actions/applicantLearningTaskAction';
import Task from './Task';

const LearningTask = ({
  getLearningTask,
  loading,
  error,
  task,
  internshipId
}) => {
  const stableGetLearningTask = useCallback(getLearningTask, [internshipId]);
  useEffect(() => {
    stableGetLearningTask(internshipId);
  }, [stableGetLearningTask, internshipId]);
  return (
    <WhiteArea>
      {loading && <Loader />}
      {error &&
        (Object.values(error) && Object.values(error)[0] ? (
          <Error message={Object.values(error)[0]} />
        ) : (
          <Error />
        ))}
      {task && <Task task={task} internshipId={internshipId} />}
    </WhiteArea>
  );
};

LearningTask.propTypes = {
  internshipId: PropTypes.number.isRequired,
  getLearningTask: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  task: PropTypes.shape({
    submitted: PropTypes.bool.isRequired,
    report: PropTypes.string,
    task: PropTypes.string,
    due_date: PropTypes.string,
    api_url: PropTypes.string,
    pk: PropTypes.number.isRequired,
    applicant_attachment_list: PropTypes.arrayOf,
    teacher_attachment: PropTypes.object
  })
};

LearningTask.defaultProps = {
  error: undefined,
  task: undefined
};

const mapStateToProps = (
  { learningTask: { data, loading, error } },
  { internshipId }
) => ({
  loading,
  error,
  task: data.byInternship[internshipId],
  internshipId
});

export default connect(mapStateToProps, { getLearningTask })(LearningTask);
