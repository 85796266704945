import React from 'react';
import PropTypes from 'prop-types';
import { ContainerWrap } from './ContainerStyled';

const Container = ({ children, className }) => (
  <ContainerWrap className={className}>
    {children}
  </ContainerWrap>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Container.defaultProps = {
  className: null,
};


export default Container;
