import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EyeIcon } from 'shared/lib/styles/images/eye.svg';
import { ReactComponent as DownloadIcon } from 'shared/lib/styles/images/upload.svg';
import {
  DocumentItemWrap,
  ControlsWrap,
  Control,
  EyeControl,
} from './DocumentItemStyled';

function DocumentItem({ documentData, children }) {
  const downloadControl = useRef(null);

  async function createFile(e) {
    e.preventDefault();
    if (!downloadControl.current) return;

    const response = await fetch(documentData.link);
    const data = await response.blob();
    const fileName = `${documentData.nameFile}.${documentData.link
      .split('.')
      .pop()}`;

    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
      window.navigator.msSaveOrOpenBlob(data, fileName);
    } else {
      const metadata = {
        type: data.type,
      };
      const file = new File([data], fileName, metadata);
      const objectURL = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.href = objectURL;
      link.download = fileName;
      link.click();
      link.remove();
    }
  }

  return (
    <DocumentItemWrap>
      {children}
      <ControlsWrap>
        {documentData.link && (
          <EyeControl as="a" href={documentData.link} target="_black">
            <EyeIcon />
          </EyeControl>
        )}
        {documentData.link && (
          <Control ref={downloadControl} onClick={createFile}>
            <DownloadIcon />
          </Control>
        )}
      </ControlsWrap>
    </DocumentItemWrap>
  );
}

DocumentItem.propTypes = {
  documentData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  children: PropTypes.node.isRequired,
};

DocumentItem.defaultProps = {
  documentData: {},
};

export default DocumentItem;
