import styled from 'styled-components';

export const SearchInputWrap = styled.label`
  position: relative;
  display: inline-block;
  padding: 9px 37px 11px 12px;
  border: 1px solid ${({ theme }) => theme.secondary};
  border-radius: 3px;
  margin-left: auto;
  overflow: hidden;
  cursor: text;
`;

export const SearchIconWrap = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  width: 16px;
  height: 16px;
  margin-top: -2px;
  transform: translateY(-42%);

  & svg {
    width: 100%;
    height: auto;
  }
`;

export const SearchInput = styled.input`
  display: inline-block;
  width: 100%;
  padding: 0;
  color: ${({ theme }) => theme.secondary};
  text-overflow: ellipsis;
  background-color: transparent;
  border: none;
  transition: max-width 0.3s ease;

  &::placeholder {
    color: ${({ theme }) => theme.grayA0};
  }
`;
