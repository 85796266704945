'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _shared = require('shared');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _DownloadUrlStyled = require('./DownloadUrlStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var initialState = {
  blobURL: undefined,
  blob: undefined,
  loading: true,
  error: false
};

var DownloadUrl = function DownloadUrl(_ref) {
  var url = _ref.url,
      children = _ref.children;

  var _useState = (0, _react.useState)(initialState),
      state = _useState[0],
      setState = _useState[1];

  (0, _react.useEffect)(function () {
    fetch(url).then(function (response) {
      return response.blob();
    }).then(function (blob) {
      return setState({
        blob: blob,
        blobURL: URL.createObjectURL(blob),
        loading: false
      });
    }).catch(function () {
      return setState({
        loading: false,
        error: true
      });
    });
  }, [url]);
  return _react2.default.createElement(
    _DownloadUrlStyled.DownloadUrlContent,
    null,
    state.error && _react2.default.createElement(_shared.Error, null),
    children({
      blob: state.blob,
      url: state.blobURL,
      loading: state.loading,
      error: state.error
    })
  );
};

DownloadUrl.propTypes = process.env.NODE_ENV !== "production" ? {
  url: _propTypes2.default.string.isRequired,
  children: _propTypes2.default.func.isRequired
} : {};

exports.default = DownloadUrl;
module.exports = exports['default'];