import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Localize from 'components/Localize';
import { connect } from 'react-redux';
import { setSelectedInternship } from 'actions/selectedInternshipActions';
import Can from 'components/Can';
import { actions } from 'utils/access-rules/constants';
import { section } from 'utils/access-rules/rules/sections';
import InternshipSwitcher from 'components/InternshipSwitcher';
import InternshipInfo from '../../ApplicantProfile/InternshipInfo';

const InternshipSwitcherContainer = ({
  isAuthenticated,
  internships,
  selectedInternshipId,
  userRole,
  history,
  setSelectedInternship,
}) => {
  const options = internships.map(({ pk, company_name }) => ({
    value: pk,
    label: company_name,
  }));
  const onInternshipChange = ({ value }) => {
    const pathArray = history.location.pathname.split('/');
    const currentPath = pathArray[1];
    const internship = internships.find(({ pk }) => pk === value);
    if (!internship) {
      setSelectedInternship({ pk: null });
      history.push(`/${currentPath}/`);
    } else {
      setSelectedInternship(internship);
      history.push(`/${currentPath}/${value}`);
    }
  };
  useEffect(() => {
    const pathArray = history.location.pathname.split('/');
    if (
      internships.length > 0
      && pathArray[pathArray.length - 1] === ':internshipId'
    ) {
      onInternshipChange(options[0]);
    }
  }, [history.location.pathname]);
  if (!isAuthenticated) {
    return null;
  }
  return (
    <Can I={actions.see} a={section.InternshipSwitcher}>
      <Localize>
        {({ translate }) => (
          <InternshipSwitcher
            options={[
              { value: null, label: translate('no-internship') },
              ...options,
            ]}
            onChange={onInternshipChange}
            selectedInternshipId={selectedInternshipId}
            userRole={userRole}
          />
        )}
      </Localize>
      <InternshipInfo
        selectedInternshipId={selectedInternshipId}
        margin="0 auto"
        padding="10px 10px 0"
      />
    </Can>
  );
};

InternshipSwitcherContainer.propTypes = {
  internships: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedInternshipId: PropTypes.number,
  isAuthenticated: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  setSelectedInternship: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

InternshipSwitcherContainer.defaultProps = {
  selectedInternshipId: null,
};

const mapStateToProps = ({ currentUser, selectedInternship }) => ({
  isAuthenticated: !!currentUser.data,
  userRole: currentUser.data && currentUser.data.role,
  internships: (currentUser.data && currentUser.data.internships) || [],
  selectedInternshipId: selectedInternship && selectedInternship.pk,
});

export default connect(
  mapStateToProps,
  { setSelectedInternship },
)(withRouter(InternshipSwitcherContainer));
