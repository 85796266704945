import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getKindList } from 'actions/kindActions';
import { connect } from 'react-redux';

const Kind = ({
  getKindList, name, loading, error, data,
}) => {
  const stableGetKindList = useCallback(getKindList, []);
  useEffect(() => {
    stableGetKindList();
  }, [stableGetKindList]);

  if (loading || error || !data) {
    return null;
  }

  const field = data.find((field) => field.value === name);
  if (!field) {
    return name;
  }
  return field.title;
};

Kind.propTypes = {
  name: PropTypes.string.isRequired,
};

const mapStateToProps = ({ kind: { data, error, loading } }) => ({
  loading,
  error,
  data,
});

export default connect(
  mapStateToProps,
  { getKindList },
)(Kind);
