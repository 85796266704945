import React from 'react';
import PropTypes from 'prop-types';
import { SecondaryButton } from 'shared';
import Localize from 'components/Localize';
import arrowRightIcon from 'styles/images/svg/arrow-right.svg';
import {
  MessageWrap,
  MessageButtonGroup,
  MessageButton,
  MessageButtonText,
} from './MessageStyled';

const ProfileOptions = ({ /* createProfile, */ uploadCv }) => (
  <MessageWrap>
    <MessageButtonGroup>
      {/*
      <MessageButton>
        <SecondaryButton
          rightIcon={arrowRightIcon}
          size="small"
          onClick={createProfile}
          width="100%"
        >
          <MessageButtonText>
            <Localize id="create-special-profile" />
          </MessageButtonText>
        </SecondaryButton>
      </MessageButton>
    */}
      <MessageButton>
        <SecondaryButton
          rightIcon={arrowRightIcon}
          size="small"
          onClick={uploadCv}
          width="100%"
        >
          <MessageButtonText>
            <Localize id="upload-cv" />
          </MessageButtonText>
        </SecondaryButton>
      </MessageButton>
    </MessageButtonGroup>
  </MessageWrap>
);

ProfileOptions.propTypes = {
  uploadCv: PropTypes.func.isRequired,
  // createProfile: PropTypes.func.isRequired,
};

export default ProfileOptions;
