import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addMessage } from 'actions/chatActions';
import chatApi from 'api/ChatApi';
import SendMessage from '../SendMessage';
import ActionResults from '../../ActionResults';

const SendMessageToStudent = ({
  selectedApplicants,
  selectedInternship,
  showActionResult,
  closeActionModel,
  addMessage,
}) => {
  const handleSubmit = ({ message }, form) => {
    if (message) {
      const sendMessage = selectedApplicants.map((applicant) => {
        const selectedApplicantInternship = selectedInternship[applicant.pk];
        if (selectedApplicantInternship && selectedApplicantInternship.value) {
          return chatApi
            .getUsers(selectedApplicantInternship.value.pk)
            .then((users) => {
              const activeUser = users.find((user) => user.role === 'APPLICANT');
              const messageToAdd = {
                internship_id: selectedApplicantInternship.value.pk,
                message,
                recepient: activeUser.api_url,
              };
              return addMessage(
                selectedApplicantInternship.value.pk,
                messageToAdd,
              )
                .then(() => ({
                  result: 'success',
                  user: applicant.esuser.user,
                }))
                .catch(() => ({
                  result: 'failed',
                  user: applicant.esuser.user,
                }));
            });
        }
        return chatApi.getUsers(null, applicant.pk).then((users) => {
          const activeUser = users.find((user) => user.role === 'APPLICANT');
          if (activeUser) {
            const messageToAdd = {
              message,
              recepient: activeUser.api_url,
            };
            return addMessage(null, messageToAdd)
              .then(() => ({
                result: 'success',
                user: applicant.esuser.user,
              }))
              .catch(() => ({
                result: 'failed',
                user: applicant.esuser.user,
              }));
          }
        });
      });
      return Promise.all(sendMessage)
        .then((values) => {
          showActionResult(
            <ActionResults results={values} onClose={closeActionModel} />,
          );
        })
        .then(() => form.resetForm());
    }
  };
  return (
    <SendMessage
      handleSubmit={handleSubmit}
      submitButtonText="send-message-to-student"
      handleClose={closeActionModel}
    />
  );
};

SendMessageToStudent.propTypes = {
  selectedApplicants: PropTypes.array.isRequired,
  selectedInternship: PropTypes.shape({}).isRequired,
  showActionResult: PropTypes.func.isRequired,
  closeActionModel: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
};

export default connect(
  undefined,
  {
    addMessage,
  },
)(SendMessageToStudent);
