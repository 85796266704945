import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Error, Loader } from 'shared';
import { getSpecialTasks, patchSpecialTasks } from 'actions/specialTaskAction';
import SpecialTask from 'components/SpecialTask';
import Localize from 'components/Localize';
import CheckBox from 'components/Form/CheckBox';
import { sortTasks } from 'api/SpecialTaskApi';
import { filterByInternship } from 'reducers/reducersUtils';

const SpecialTasks = ({
  tasks,
  getSpecialTasks,
  patchSpecialTasks,
  loading,
  error,
  selectedInternshipId,
}) => {
  const stableGetSpecialTasks = useCallback(getSpecialTasks, [
    selectedInternshipId,
  ]);
  useEffect(() => {
    stableGetSpecialTasks(selectedInternshipId || null);
  }, [stableGetSpecialTasks, selectedInternshipId]);
  if (error) {
    return <Error />;
  }
  const handleItemClick = (pk, checked) => {
    patchSpecialTasks(pk, { done: checked });
  };
  return (
    <Loader loading={loading}>
      {tasks.length === 0 ? (
        <CheckBox disabled>
          <Localize id="at-the-moment-you-have-no-extra-tasks" />
        </CheckBox>
      ) : (
        sortTasks(tasks).map((task) => (
          <SpecialTask
            key={`special_task_${task.pk}`}
            task={task}
            isAbsenceRelated={task.medical_document_required}
            onItemClick={handleItemClick}
            patchSpecialTasks={patchSpecialTasks}
          />
        ))
      )}
    </Loader>
  );
};

SpecialTasks.propTypes = {
  selectedInternshipId: PropTypes.number,
  getSpecialTasks: PropTypes.func.isRequired,
  patchSpecialTasks: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      pk: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      deadline: PropTypes.string,
      author: PropTypes.object.isRequired,
      created: PropTypes.string.isRequired,
      done: PropTypes.bool.isRequired,
      attachments: PropTypes.array.isRequired,
    }),
  ).isRequired,
};

SpecialTasks.defaultProps = {
  error: undefined,
  selectedInternshipId: null,
};

const mapStateToProps = ({
  specialTasks: { data, loading, error },
  selectedInternship,
}) => {
  const selectedInternshipId = selectedInternship && selectedInternship.pk;
  const specialTasks = filterByInternship(selectedInternshipId, data);
  return {
    tasks: specialTasks || [],
    loading,
    error,
    selectedInternshipId,
  };
};

export default connect(
  mapStateToProps,
  {
    getSpecialTasks,
    patchSpecialTasks,
  },
)(SpecialTasks);
