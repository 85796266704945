'use strict';

exports.__esModule = true;
exports.StyledToggle = exports.ToggleLabel = exports.ToggleWrap = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n'], ['\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  align-items: center;\n  color: ', ';\n  line-height: 23px;\n  margin-left: ', ';\n'], ['\n  align-items: center;\n  color: ', ';\n  line-height: 23px;\n  margin-left: ', ';\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  .react-toggle-track {\n    background-color: transparent !important;\n    border: 1px solid ', ' !important;\n    width: 58px;\n    height: 30px;\n  }\n\n  .react-toggle-thumb {\n    background-color: ', ' !important;\n    border: none !important;\n    width: 26px;\n    height: 26px;\n    top: 2px;\n    left: 2px;\n  }\n  &.react-toggle--checked {\n    .react-toggle-thumb {\n      left: 30px;\n      background-color: ', ' !important;\n    }\n  }\n'], ['\n  .react-toggle-track {\n    background-color: transparent !important;\n    border: 1px solid ', ' !important;\n    width: 58px;\n    height: 30px;\n  }\n\n  .react-toggle-thumb {\n    background-color: ', ' !important;\n    border: none !important;\n    width: 26px;\n    height: 26px;\n    top: 2px;\n    left: 2px;\n  }\n  &.react-toggle--checked {\n    .react-toggle-thumb {\n      left: 30px;\n      background-color: ', ' !important;\n    }\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactToggle = require('react-toggle');

var _reactToggle2 = _interopRequireDefault(_reactToggle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var ToggleWrap = exports.ToggleWrap = _styledComponents2.default.label(_templateObject);

var ToggleLabel = exports.ToggleLabel = _styledComponents2.default.span(_templateObject2, function (_ref) {
  var theme = _ref.theme;
  return theme.textColor69;
}, function (_ref2) {
  var right = _ref2.right;
  return right ? '10px' : 0;
});

var StyledToggle = exports.StyledToggle = (0, _styledComponents2.default)(_reactToggle2.default)(_templateObject3, function (_ref3) {
  var theme = _ref3.theme;
  return theme.borderColor;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.textColor9D;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.primary;
});