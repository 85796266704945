import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';

const dataReducer = (state = null, action) => {
  switch (action.type) {
    case successType(types.GET_CONTRACT):
      return action.response ? action.response[0] : null;
    case successType(types.UPLOAD_CONTRACT_FILE):
      return {
        ...state,
        document_list: [...state.userDocuments, action.response],
      };
    case successType(types.DELETE_CONTRACT_FILE):
      return {
        ...state,
        document_list: state.userDocuments.filter((d) => d.pk !== action.fileId),
      };
    default:
      return state;
  }
};

export default createReducer(
  [types.GET_CONTRACT, types.UPLOAD_CONTRACT_FILE, types.DELETE_CONTRACT_FILE],
  dataReducer,
);
