'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Localize = require('../../Localize');

var _Localize2 = _interopRequireDefault(_Localize);

var _freeSolidSvgIcons = require('@fortawesome/free-solid-svg-icons');

var _ErrorStyled = require('./ErrorStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Error = function Error(_ref) {
  var message = _ref.message,
      details = _ref.details;
  return _react2.default.createElement(
    _ErrorStyled.ErrorWrap,
    null,
    _react2.default.createElement(_ErrorStyled.ErrorIcon, { icon: _freeSolidSvgIcons.faExclamationTriangle }),
    _react2.default.createElement(
      _ErrorStyled.ErrorContent,
      null,
      message || _react2.default.createElement(_Localize2.default, { id: 'request-failed' }),
      details
    )
  );
};

Error.propTypes = process.env.NODE_ENV !== "production" ? {
  details: _propTypes2.default.node,
  message: _propTypes2.default.node
} : {};

Error.defaultProps = {
  details: null,
  message: null
};

exports.default = Error;
module.exports = exports['default'];