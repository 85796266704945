import React from 'react';
import { NoMessagesIcon } from 'shared';
import Localize from 'components/Localize';
import { NoMessagesText, NoMessagesWrapper } from './NoMessagesStyled';

function NoMessages() {
  return (
    <NoMessagesWrapper>
      <NoMessagesIcon />
      <NoMessagesText>
        <Localize id="you-dont-have-messages-yet" />
      </NoMessagesText>
    </NoMessagesWrapper>
  );
}

export default NoMessages;
