import React from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import { roles } from 'utils/access-rules/constants';

const InternshipReportSelector = ({ row, selectedInternship, currentUser }) => {
  let link = `/es/applicant-profile/${row.pk}`;
  if (row.esuser.internships[0]) link += `/${row.esuser.internships[0].pk}#report`;
  if (
    selectedInternship[row.esuser.pk]
    && selectedInternship[row.esuser.pk].value
  ) {
    link = `/es/applicant-profile/${row.pk}/${
      selectedInternship[row.esuser.pk].value.pk
    }#report`;
    if (selectedInternship[row.esuser.pk].value.internship_report_submitted) {
      return (
        <a href={link}>
          <Localize id="submitted" />
        </a>
      );
    }
    if (selectedInternship[row.esuser.pk].value.internship_report_exists) {
      return (
        <a href={link}>
          <Localize
            id="x-days-written"
            data={{
              days:
                selectedInternship[row.esuser.pk].value
                  .internship_report_days_amount,
            }}
          />
        </a>
      );
    }
    if (currentUser.role !== roles.CompanyEmployee) {
      return (
        <a href={link}>
          <Localize id="not-written-yet" />
        </a>
      );
    }
    return <Localize id="not-written-yet" />;
  }
  return null;
};

InternshipReportSelector.propTypes = {
  row: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    esuser: PropTypes.shape({
      pk: PropTypes.number.isRequired,
      internships: PropTypes.array,
    }).isRequired,
  }).isRequired,
  selectedInternship: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
};

export default InternshipReportSelector;
