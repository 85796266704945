import { actions, roles } from '../constants';

export const section = {
  specialTasks: 'specialTasks',
  unSubmittedLearningTask: 'unSubmittedLearningTask',
  learningTaskRating: 'learningTaskRating',
  learningTaskDescription: 'learningTaskDescription',
  absenceEntry: 'absenceEntry',
  onSiteAppointment: 'onSiteAppointment',
  previewText: 'previewText',
  internshipRequest: 'internshipRequest',
  resendLearningTask: 'resendLearningTask',
};

export default (role, can, rules) => {
  if (role === roles.Teacher) {
    can(actions.see, section.specialTasks);
    can(actions.edit, section.learningTaskDescription);
    can(actions.see, section.absenceEntry);
    can(actions.see, section.unSubmittedLearningTask);
    can(actions.see, section.onSiteAppointment);
    can(actions.see, section.internshipRequest);
    can(actions.see, section.resendLearningTask);
    can(actions.see, section.previewText);
  }

  if (role === roles.CompanyEmployee) {
    can(actions.edit, section.learningTaskRating);
    can(actions.see, section.unSubmittedLearningTask);
    can(actions.see, section.absenceEntry);
    can(actions.see, section.resendLearningTask);
    can(actions.see, section.previewText);
  }

  if (role === roles.EducationProvider) {
    can(actions.see, section.specialTasks);
    can(actions.see, section.absenceEntry);
    can(actions.see, section.unSubmittedLearningTask);
    can(actions.see, section.onSiteAppointment);
    can(actions.see, section.resendLearningTask);
    can(actions.see, section.previewText);
  }

  if (role === roles.Applicant) {
    can(actions.see, section.previewText);
  }
  return rules;
};
