import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';
import { groupByInternship, setGroupedValues } from 'utils/groupByInternship';

const changeUserNotification = (users = [], notification, isIncreased) => users.map((user) => {
  if (notification.sender.pk === user.pk) {
    const unread_count = isIncreased
      ? user.unread_count + 1
      : user.unread_count - 1;
    return { ...user, unread_count };
  }
  return user;
});

const changeRoomNotification = (rooms = [], notification, isIncreased) => rooms.map((room) => {
  if (
    notification.room === room.api_url
      || (notification.instance && notification.instance.room === room.api_url)
  ) {
    const unread_count = isIncreased
      ? room.unread_count + 1
      : room.unread_count - 1;
    return { ...room, unread_count };
  }
  return room;
});

const dataReducer = (state = {}, action) => {
  switch (action.type) {
    case successType(types.GET_CHAT_USERS):
      if (action.response) {
        return {
          ...state,
          ...setGroupedValues(
            state,
            groupByInternship(action.response, action.internshipId),
            'users',
          ),
        };
      }
      return state;
    case successType(types.GET_CHAT_ROOMS):
      if (action.response) {
        return {
          ...state,
          ...setGroupedValues(
            state,
            groupByInternship(action.response),
            'rooms',
          ),
        };
      }
      return state;
    case successType(types.MARK_NOTIFICATION_AS_READ):
      if (action.response && action.response.kind === 'CHAT_MESSAGE') {
        const internshipId = action.response.internship
          ? action.response.internship.pk
          : 'global';
        const existingState = state[internshipId] ? state[internshipId] : {};
        if (action.response.instance && action.response.instance.room) {
          return {
            ...state,
            [internshipId]: {
              ...existingState,
              rooms: changeRoomNotification(
                existingState.rooms,
                action.response,
                false,
              ),
            },
          };
        }
        return {
          ...state,
          [internshipId]: {
            ...existingState,
            users: changeUserNotification(
              existingState.users,
              action.response,
              false,
            ),
          },
        };
      }
      return state;
    case types.LOGOUT:
      return {};
    default:
      return state;
  }
};

export default createReducer(
  [types.GET_CHAT_USERS, types.GET_CHAT_MESSAGES, types.GET_CHAT_ROOMS],
  dataReducer,
);
