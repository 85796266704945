import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Localize from 'components/Localize';
import { roles } from 'utils/access-rules/constants';

const InternshipStatusSelector = ({ row, selectedInternship, currentUser }) => {
  let link = `/es/applicant-profile/${row.pk}`;
  if (row.esuser.internships[0]) link += `/${row.esuser.internships[0].pk}#your-practices`;
  if (
    selectedInternship[row.esuser.pk]
    && selectedInternship[row.esuser.pk].value
  ) {
    link = `/es/applicant-profile/${row.pk}/${
      selectedInternship[row.esuser.pk].value.pk
    }#your-practices`;
    if (selectedInternship[row.esuser.pk].value.approved_at) {
      return (
        <Localize
          id="approved-at"
          data={{
            starts_at: moment(
              selectedInternship[row.esuser.pk].value.starts_at,
            ).format('DD.MM.YYYY'),
            ends_at: moment(
              selectedInternship[row.esuser.pk].value.ends_at,
            ).format('DD.MM.YYYY'),
            company_name: selectedInternship[row.esuser.pk].value.company_name,
          }}
        />
      );
    }
    if (currentUser.role !== roles.CompanyEmployee) {
      if (selectedInternship[row.esuser.pk].value.accepted_by_teacher_at) {
        return (
          <a href={link}>
            <Localize id="waiting-for-teachers-approval" />
          </a>
        );
      }
      if (selectedInternship[row.esuser.pk].value.applied) {
        return <Localize id="applied" />;
      }
      if (selectedInternship[row.esuser.pk].value.reacted_and_not_applied) {
        return <Localize id="looked-but-not-applied" />;
      }
      return <Localize id="not-looked-yet" />;
    }
  }
  return null;
};

InternshipStatusSelector.propTypes = {
  row: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    esuser: PropTypes.shape({
      pk: PropTypes.number.isRequired,
      internships: PropTypes.array,
    }).isRequired,
  }).isRequired,
  selectedInternship: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
};

export default InternshipStatusSelector;
