import styled, { css } from 'styled-components';
import ReactSVG from 'react-svg';
import Modal from 'components/Modal';
import { device } from 'styles/device';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ITEM_STATUS = {
  DONE: 'DONE',
  IN_PROGRESS: 'IN_PROGRESS',
  INACTIVE: 'INACTIVE',
};

const itemWidth = (props) => (props.mobile === 'true' ? 80 : 110);
const lineWidth = (props) => (props.mobile === 'true' ? 40 : 70);
const itemSize = (props) => `${itemWidth(props)}px`;
const lineSize = (props) => `${lineWidth(props)}px`;
const curveSize = (props) => `${itemWidth(props) / 2 + lineWidth(props) / 2}px`;

const mediumWidth = (props) => `${5 * itemWidth(props) + 4 * lineWidth(props)}px`; // 3 items + 2 on the sides
const bigWidth = (props) => `${6 * itemWidth(props) + 5 * lineWidth(props)}px`; // 4 items + 2 on the sides

const scheme = (props) => ({
  small: `(min-width: 0) and (max-width: ${mediumWidth(props)})`,
  medium: `(min-width: ${mediumWidth(props)}) and  (max-width: ${bigWidth(
    props,
  )})`,
  big: `(min-width: ${bigWidth(props)})`,
});

const wrapperWidth = (countInRow) => css`
  //number of circle * countInRow + number of circle * countInRow: to be properly wrapped by flex
  width: calc(
    ${(props) => itemSize(props)}* ${countInRow} + ${(props) => lineSize(props)}*
      ${countInRow}
  );
`;
export const SnakeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  position: relative;
  //move to left lineSize/2 to me centered (because wrapper width has length with additional lineSize)
  left: calc(${(props) => lineSize(props)} / 2);
  ${wrapperWidth(4)};
  @media ${(props) => scheme(props).medium} {
    ${wrapperWidth(3)}
  }
  @media ${(props) => scheme(props).small} {
    ${wrapperWidth(2)}
  }
`;

const getLabelColor = ({ status, theme }) => {
  switch (status) {
    case ITEM_STATUS.INACTIVE:
      return theme.grayA0;
    default:
      return theme.secondary;
  }
};

export const SnakeItemLabel = styled.div`
  position: absolute;
  line-height: ${(props) => (props.mobile === 'true' ? 11 : 19)}px;
  text-align: center;
  border-radius: 10px;
  color: ${(props) => getLabelColor(props)};
  top: calc(${(props) => itemSize(props)} + 5px);
  background-color: ${({ theme }) => theme.white};
  z-index: 1;
  font-size: ${(props) => (props.mobile === 'true' ? 9 : 14)}px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;

const getStatusColor = ({ status, theme }) => {
  switch (status) {
    case ITEM_STATUS.IN_PROGRESS:
      return theme.yellowBackgroundColor;
    default:
      return theme.greenBackgroundColor;
  }
};
export const SnakeItemStatus = styled(ReactSVG)`
  position: absolute;
  top: 0;
  left: calc((${(props) => itemSize(props)} / 4) * 3);
  z-index: 2;
  & svg {
    width: ${(props) => (props.mobile === 'true' ? 19 : 26)}px;
    height: ${(props) => (props.mobile === 'true' ? 19 : 26)}px;
  }
  & ellipse {
    fill: ${(props) => getStatusColor(props)};
  }
  @media ${device.tablet} {
    font-size: 14px;
  }
`;

const getIconColor = ({ status, theme }) => {
  switch (status) {
    case ITEM_STATUS.DONE:
      return theme.white;
    case ITEM_STATUS.IN_PROGRESS:
      return theme.secondary;
    case ITEM_STATUS.INACTIVE:
      return theme.grayA0;
    default:
      return theme.mainBackgroundColor;
  }
};

export const FAIcon = styled(FontAwesomeIcon)`
  font-size: ${(props) => (props.mobile === 'true' ? 40 : 50)}px;
  color: ${(props) => getIconColor(props)};
  & ellipse {
    fill: ${(props) => getStatusColor(props)};
  }
  @media ${device.tablet} {
    font-size: 14px;
  }
`;

export const SnakeItemIcon = styled(ReactSVG)`
  z-index: 1;
  display: flex;
  align-items: center;
  & path, line, circle, rect {
    //stroke: ${(props) => props.theme.white}
    stroke: ${(props) => getIconColor(props)};
  }
  & svg {
    width: ${(props) => (props.mobile === 'true' ? '35px' : 'auto')};
  }
  > div {
    width: 100%;
  }
`;

const getBorderColor = ({ status, theme }) => {
  switch (status) {
    case ITEM_STATUS.INACTIVE:
      return theme.grayA0;
    default:
      return theme.secondary;
  }
};

const curve = css`
  width: ${(props) => curveSize(props)};
  height: ${(props) => curveSize(props)}; /* size of your margin */
  border-right: 2px solid ${(props) => getBorderColor(props)};
  border-top-right-radius: 100%;
`;

const roundRight = (start, end) => css`
  &:nth-child(${start})::after {
    ${curve}
  }
  &:nth-child(${end})::after {
    ${curve};
    transform: rotate(90deg) translateX(-${(props) => curveSize(props)});
  }
`;
const roundLeft = (start, end) => css`
  &:nth-child(${start})::before {
    ${curve};
    transform: rotate(-90deg);
  }
  &:nth-child(${end})::before {
    ${curve};
    transform: rotate(180deg) translateY(${(props) => curveSize(props)});
  }
`;

const uglyRight = (number) => css`
  &:nth-child(${number}) {
    position: absolute;
    z-index: 1;
    left: calc(
      ${(props) => itemSize(props)}* ${number - 1} + ${(props) => lineSize(props)} *
        ${number - 2} + ${(props) => curveSize(props)} / 2
    );
    top: calc(
      ${(props) => itemSize(props)} / 2 + ${(props) => curveSize(props)} / 2
    );
    &::before,
    &::after {
      display: none;
    }
  }
`;
/* const uglyLeft = (number, row) => css`
  &:nth-child(${number}) {
    position: absolute;
    z-index: 1;
    left: calc(-${(props) => curveSize(props)} / 2 - ${(props) => itemSize(props)});
    top: calc(
      ${(props) => itemSize(props)}* ${row} + ${(props) => lineSize(props)} *
        ${row - 2} + ${(props) => curveSize(props)} / 2
    );
    &::before,
    &::after {
      display: none;
    }
  }
`; */

const hideLeftLine = (number) => css`
  &:nth-child(${number})::before {
    display: none;
  }
`;
const hideRightLine = (number) => css`
  &:nth-child(${number})::after {
    display: none;
  }
`;
const alignRight = (number) => css`
  &:nth-child(${number}) {
    margin-left: auto;
  }
`;
export const SnakeItem = styled.div`
  width: ${(props) => itemSize(props)};
  height: ${(props) => itemSize(props)};
  border-radius: 100%;
  background-color: ${(props) => props.status === ITEM_STATUS.DONE
    ? props.theme.secondary
    : props.theme.white};
  border: 2px solid ${(props) => getBorderColor(props)};
  //left margin as line size - it depends on wrapper width
  margin: 0 ${(props) => lineSize(props)} ${(props) => lineSize(props)} 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &.presentation {
    .text {
      width: 150px;
    }
  }
  cursor: ${(props) => (props.status === ITEM_STATUS.INACTIVE || !props.onItemClick)
    && !props.scrollTo
    ? 'auto'
    : 'pointer'};

  /*lines*/
  &::before, &::after {
    content:'';
    width: ${(props) => lineSize(props)};/* size of your margin */
    border-top: 2px solid ${(props) => getBorderColor(props)};
    position: absolute;
    top: 50%;
    z-index: 0;
  }
  &::before{
    right: 100%;
  }
  &::after {
    left: 100%;
  }
  @media ${(props) => scheme(props).small} {
    ${hideLeftLine(1)}
    ${roundRight(2, 3)}
    ${roundLeft(4, 5)}
    ${roundRight(6, 7)}
    ${hideLeftLine(8)}
  }
  @media ${(props) => scheme(props).medium} {
    ${hideLeftLine(1)}
    ${roundRight(3, 4)}
    ${roundLeft(6, 7)}
    ${hideRightLine(8)}
  }

  @media ${(props) => scheme(props).big} {
    ${hideLeftLine(1)}
    ${roundRight(4, 6)}
    ${hideLeftLine(8)}
  }

  /*order*/
  @media ${(props) => scheme(props).small} {
    ${alignRight(8)}
    &:nth-child(1) {
      order: 1;
    }
    &:nth-child(2) {
      order: 2;
    }
    &:nth-child(3) {
      order: 4;
    }
    &:nth-child(4) {
      order: 3;
    }
    &:nth-child(5) {
      order: 5;
    }
    &:nth-child(6) {
      order: 6;
    }
    &:nth-child(7) {
      order: 8;
    }
    &:nth-child(8) {
      order: 7;
    }
  }
  @media ${(props) => scheme(props).medium} {
    &:nth-child(1) {
      order: 1;
    }
    &:nth-child(2) {
      order: 2;
    }
    &:nth-child(3) {
      order: 3;
    }
    &:nth-child(4) {
      order: 6;
    }
    &:nth-child(5) {
      order: 5;
    }
    &:nth-child(6) {
      order: 4;
    }
    &:nth-child(7) {
      order: 7;
    }
    &:nth-child(8) {
      order: 8;
    }
  }
  @media ${(props) => scheme(props).big} {
    &:nth-child(1) {
      order: 1;
    }
    &:nth-child(2) {
      order: 2;
    }
    &:nth-child(3) {
      order: 3;
    }
    &:nth-child(4) {
      order: 4;
    }
    &:nth-child(5) {
      order: 4;
    }
    &:nth-child(6) {
      order: 6;
    }
    &:nth-child(7) {
      order: 5;
    }
    &:nth-child(8) {
      order: 4;
    }
    ${alignRight(8)}
  }

  /* ugly duck*/
  @media ${(props) => scheme(props).big} {
    ${uglyRight(5)}
  }
`;

export const SnakeItemPopup = styled(Modal)`
  padding: 20px !important;
`;

export const SnakeItemPopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
