import ServerApi from 'shared/lib/api/ServerApi';

export const sortTasks = (tasks) => [...tasks].sort((a, b) => new Date(b.created) - new Date(a.created));

class SpecialTaskApi extends ServerApi {
  get(internshipId) {
    if (internshipId) {
      return super.get(`/internship/special-task/?internship=${internshipId}`);
    }
    return super.get('/internship/special-task/?limit=100');
  }

  update(pk, task) {
    return super.put(`/internship/special-task/${pk}/`, {
      ...task,
    });
  }

  patch(pk, task) {
    return super.patch(`/internship/special-task/${pk}/`, task);
  }

  create(task) {
    return super.post('/internship/special-task/', {
      ...task,
    });
  }

  remove(pk) {
    return super.delete(`/internship/special-task/${pk}/`);
  }

  uploadAttachment(value) {
    return super.post('/internship/special-task-attachment/', value);
  }

  removeAttachment(pk) {
    return super.delete(`/internship/special-task-attachment/${pk}/`);
  }
}

export default new SpecialTaskApi();
