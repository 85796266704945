import styled from 'styled-components';
import { device } from 'styles/device';

const nameColumnWidth = 60;
const answerColumnWidth = (100 - nameColumnWidth) / 4;

export const QuestionnaireTable = styled.table`
  width: 100%;
  tr:last-child {
    td {
      border-bottom: none;
    }
  }
`;

const nameColumnStyle = `
  width: ${nameColumnWidth}%;
`;
const answerColumnStyle = `width: ${answerColumnWidth}%;`;

export const NameTableHead = styled.th`
  ${nameColumnStyle}
  border-bottom: 2px solid ${({ theme }) => theme.secondary};
  text-align: left;
  color: ${({ theme }) => theme.secondary};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  vertical-align: bottom;
  padding: 20px 0px;
  @media ${device.tablet} {
    font-size: 14px;
    padding: 15px 0px;
  }
  @media ${device.mobile} {
    font-size: 14px;
    padding: 15px 0px;
  }
`;
export const AnswerTableHead = styled.th`
  ${answerColumnStyle}
  border-bottom: 2px solid ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.textColor3};
  font-size: 16px;
  vertical-align: middle;
  padding: 20px 15px;
  @media ${device.tablet} {
    font-size: 14px;
    padding: 15px 15px;
  }
  @media ${device.mobile} {
    font-size: 12px;
    padding: 10px 5px;
    border-bottom: none;
    width: ${({ width }) => width}%;
  }
`;
export const NameTableColumn = styled.td`
  ${nameColumnStyle}
  font-weight: ${({ theme }) => theme.fontWeightBold};
  padding: 20px 0px 10px;
  font-size: 16px;
  @media ${device.tablet} {
    font-size: 14px;
    padding: 15px 0px 10px;
  }
  @media ${device.mobile} {
    font-size: 14px;
    padding: 15px 0px 0px;
  }
`;
export const AnswerTableColumn = styled.td`
  ${answerColumnStyle}
  text-align: center;
  padding: 10px 0px;
  border-bottom: ${({ isLast, theme }) => isLast ? `1px solid ${theme.textColorD8}; padding-bottom: 30px;` : 'none'};
  @media ${device.mobile} {
    ${({ isLast }) => isLast ? 'padding: 0px 0px 50px;' : 'padding: 0px 0px 40px;'};
  }
`;
export const QuestionTableColumn = styled.td`
  padding: 10px 20px;
  border-bottom: ${({ isLast, theme }) => isLast ? `1px solid ${theme.textColorD8}; padding-bottom: 30px;` : 'none'};
  font-size: 16px;
  @media ${device.tablet} {
    font-size: 14px;
  }
  @media ${device.mobile} {
    font-size: 14px;
    padding: 10px 0px;
  }
`;
export const QuestionnaireWrapper = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 30px 22px;
  border-radius: 3px;
  background-color: #ffffff;
  @media ${device.tablet} {
    .tab {
      font-size: 10px;
      max-width: 300px;
      width: 120px;
    }
    .tab.active-tab {
      width: 100%;
      max-width: 300px;
      font-size: 12px;
      text-align: center;
      justify-content: center;
    }
  }
  @media ${device.mobile} {
    padding: 30px 5px;
    .tab-wrapper {
      flex-direction: column;
    }
    .tab,
    .tab.active-tab {
      width: 100%;
      border-right: none;
      border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    }
  }
`;
