import styled from 'styled-components';

export const StyledNotificationItem = styled.a`
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.grayE6};
  background-color: ${({ theme, unread }) => unread ? theme.grayF3 : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme }) => theme.textColor};

  &:last-child {
    border-bottom: none;
  }
`;
