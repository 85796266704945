import ServerApi from 'shared/lib/api/ServerApi';
import { getDefaultLanguage } from 'shared/lib/lib/languages';

class EntityInstructionApi extends ServerApi {
  getEntityInstruction(identifier) {
    return super.get(
      `/pages/entity-instruction/?identifier=${identifier}&language=${getDefaultLanguage()}`,
    );
  }

  setEntityInstructionRating(data) {
    return super.post('/pages/set-entity-instruction-rating/', data);
  }
}

export default new EntityInstructionApi();
