import React from 'react';
import PropTypes from 'prop-types';
import Can from 'components/Can/Can';
import { actions } from 'components/Can';
import { section } from 'utils/access-rules/rules/sections';
import { ReactComponent as FilterIcon } from 'shared/lib/styles/images/filter.svg';
import { FilterPanelWrap, FilterIconWrap } from './FilterPanelStyled';

function FilterPanel({ children }) {
  return (
    <FilterPanelWrap>
      <FilterIconWrap>
        <Can I={actions.see} a={section.schoolFiltering}>
          <FilterIcon />
        </Can>
      </FilterIconWrap>
      {children}
    </FilterPanelWrap>
  );
}

FilterPanel.propTypes = {
  children: PropTypes.node,
};

FilterPanel.defaultProps = {
  children: null,
};

export default FilterPanel;
