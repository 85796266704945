import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { compose } from 'redux';

import Localize from 'components/Localize';
import { Footer } from 'shared';

const FooterWrapper = (props) => <Footer {...props} translate={Localize} />;

export default compose(withLocalize)(FooterWrapper);
