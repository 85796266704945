import ServerApi from 'shared/lib/api/ServerApi';

class UserApi extends ServerApi {
  updateUser(
    pk,
    {
      email, phone, location, birthday, avatar,
    },
    avatarOnly = false,
  ) {
    if (avatarOnly) {
      return super.patch(`/internship/esuser/${pk}/`, { avatar });
    }
    return super.patch(`/internship/esuser/${pk}/`, {
      phone: {
        number: phone,
        verified: true,
      },
      location,
      birthday,
      user: {
        email,
      },
      avatar,
    });
  }

  updateAvatar(pk, avatar) {
    return super.patch(`/internship/esuser/${pk}/`, { avatar });
  }
}

export default new UserApi();
