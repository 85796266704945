import tagApi from 'api/TagApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getTagList() {
  return createAction({
    type: types.GET_TAG_LIST,
    callAPI: () => tagApi.getTagList(),
    shouldCallAPI: (state) => !state.tag.data && !state.tag.loading,
  });
}
