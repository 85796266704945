import styled from 'styled-components';
import { Button } from 'shared';

export const ApplyButtonWrapper = styled(Button)`
  min-width: 0 !important;
  text-decoration: none;
  text-transform: capitalize;
  white-space: nowrap;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
`;
