'use strict';

exports.__esModule = true;
exports.IconStyled = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  width: 24px;\n  cursor: pointer;\n'], ['\n  width: 24px;\n  cursor: pointer;\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactSvg = require('react-svg');

var _reactSvg2 = _interopRequireDefault(_reactSvg);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var IconStyled = exports.IconStyled = (0, _styledComponents2.default)(_reactSvg2.default)(_templateObject);