import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import { parseInternshipId } from 'utils/uri';
import { InternshipInfoWrapper, InfoWrapper } from './InternshipInfoStyled';

const InternshipInfo = ({
  internship, loading, margin, padding,
}) => {
  if (loading || !internship) {
    return null;
  }
  console.log(111111111111, internship);
  return (
    <InfoWrapper>
      <div>
        <InternshipInfoWrapper margin={margin} padding={padding}>
          <Localize id="internship-period" />
          {': '}
        </InternshipInfoWrapper>
        {internship.company_name && (
          <InternshipInfoWrapper margin={margin} padding={padding}>
            <Localize id="company" />
            {': '}
          </InternshipInfoWrapper>
        )}
        {internship.company_address && (
          <InternshipInfoWrapper margin={margin} padding={padding}>
            <Localize id="company-address" />
            {': '}
          </InternshipInfoWrapper>
        )}
        {internship.company_employee && (
          <InternshipInfoWrapper margin={margin} padding={padding}>
            <Localize id="contact-person" />
            {': '}
          </InternshipInfoWrapper>
        )}
        {internship.company_phone && (
          <InternshipInfoWrapper margin={margin} padding={padding}>
            <Localize id="company-phone" />
            {': '}
          </InternshipInfoWrapper>
        )}
      </div>
      <div>
        <InternshipInfoWrapper margin={margin} padding={padding}>
          {moment(internship.start).format('DD.MM.YYYY')} -{' '}
          {moment(internship.end).format('DD.MM.YYYY')}
        </InternshipInfoWrapper>
        {internship.company_name && (
          <InternshipInfoWrapper margin={margin} padding={padding}>
            {internship.company_name}
          </InternshipInfoWrapper>
        )}
        {internship.company_address && (
          <InternshipInfoWrapper margin={margin} padding={padding}>
            {internship.company_address}
          </InternshipInfoWrapper>
        )}
        {internship.company_employee && (
          <InternshipInfoWrapper margin={margin} padding={padding}>
            {internship.company_employee}
          </InternshipInfoWrapper>
        )}
        {internship.company_phone && (
          <InternshipInfoWrapper margin={margin} padding={padding}>
            {internship.company_phone}
          </InternshipInfoWrapper>
        )}
      </div>
    </InfoWrapper>
  );
};

InternshipInfo.propTypes = {
  loading: PropTypes.bool.isRequired,
  internship: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    company_name: PropTypes.string,
    company_address: PropTypes.string,
    company_phone: PropTypes.string,
    company_employee: PropTypes.string,
  }),
  margin: PropTypes.string,
  padding: PropTypes.string,
};

InternshipInfo.defaultProps = {
  internship: null,
  margin: null,
  padding: null,
};

const mapStateToProps = (
  { internshipRequest: { data, loading, error } },
  { selectedInternshipId },
) => ({
  internship: data
    ?.filter(({ internship }) => !!internship)
    ?.find(
      ({ internship }) => parseInt(parseInternshipId(internship), 10) === selectedInternshipId,
    ),
  loading,
  error,
});

export default connect(mapStateToProps)(InternshipInfo);
