import notificationApi from 'api/NotificationApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getUserNotifications(limit) {
  return createAction({
    type: types.GET_NOTIFICATIONS,
    callAPI: () => notificationApi.getUserNotifications(limit),
  });
}

export function markNotificationAsRead(pk) {
  return createAction({
    type: types.MARK_NOTIFICATION_AS_READ,
    callAPI: () => notificationApi.markNotificationAsRead(pk),
  });
}

export function addNotification(notification) {
  return (dispatch) => {
    dispatch({
      type: types.ADD_NOTIFICATION,
      data: notification,
      kind: notification.kind,
    });
  };
}
export function deleteNotification(pk) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_NOTIFICATION, data: pk });
  };
}
