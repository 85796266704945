import styled from 'styled-components';
import _Popup from 'reactjs-popup';
import { device } from 'styles/device';

export const Popup = styled(_Popup)`
  padding: 20px;
  overflow-y: auto;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  @media ${device.mobile} {
    padding: 0;
    position: absolute !important;
    width: 100%;
  }
`;

export const ButtonClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: transparent;
  font-size: 25px;
  border: none;
  top: 24px;
  right: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.secondary};
  z-index: 1;
`;
