'use strict';

exports.__esModule = true;
exports.default = callAPIMiddleware;
function callAPIMiddleware(_ref) {
  var dispatch = _ref.dispatch,
      getState = _ref.getState;

  return function (next) {
    return function (action) {
      var types = action.types,
          callAPI = action.callAPI,
          _action$shouldCallAPI = action.shouldCallAPI,
          shouldCallAPI = _action$shouldCallAPI === undefined ? function () {
        return true;
      } : _action$shouldCallAPI,
          _action$payload = action.payload,
          payload = _action$payload === undefined ? {} : _action$payload;


      if (!types) {
        // Normal action: pass it on
        return next(action);
      }

      if (!Array.isArray(types) || types.length !== 3 || !types.every(function (type) {
        return typeof type === 'string';
      })) {
        throw new Error('Expected an array of three string types.');
      }

      if (typeof callAPI !== 'function') {
        throw new Error('Expected callAPI to be a function.');
      }

      if (!shouldCallAPI(getState())) {
        return Promise.resolve();
      }

      var requestType = types[0],
          successType = types[1],
          failureType = types[2];


      dispatch(Object.assign({}, payload, {
        type: requestType
      }));

      return callAPI().then(function (response) {
        return dispatch(Object.assign({}, payload, {
          response: response,
          type: successType
        }));
      }, function (error) {
        return dispatch(Object.assign({}, payload, {
          error: error,
          type: failureType
        }));
      });
    };
  };
}
module.exports = exports['default'];