import styled from 'styled-components';
import Input from 'components/Form/Input';
import { device } from 'styles/device';

export const InputStyled = styled(Input)`
  font-size: 14px;
  transition: max-width 0.3s ease;

  input {
    max-width: 108px;
    &:focus {
      max-width: 300px;
      width: 300px;
      @media ${device.mobile} {
        max-width: 150px;
        width: 150px;
      }
      @media ${device.tablet} {
        max-width: 210px;
        width: 210px;
      }
    }
  }

  & .icon-wrapper {
    width: 20px;
    height: 20px;
  }
`;
