'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _freeSolidSvgIcons = require('@fortawesome/free-solid-svg-icons');

var _InfoStyled = require('./InfoStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Info = function Info(_ref) {
  var children = _ref.children,
      className = _ref.className;
  return _react2.default.createElement(
    _InfoStyled.InfoWrap,
    { className: className },
    _react2.default.createElement(_InfoStyled.InfoIcon, { icon: _freeSolidSvgIcons.faExclamationCircle }),
    _react2.default.createElement(
      _InfoStyled.InfoContent,
      null,
      children
    )
  );
};

Info.propTypes = process.env.NODE_ENV !== "production" ? {
  children: _propTypes2.default.node.isRequired,
  className: _propTypes2.default.string
} : {};

Info.defaultProps = {
  className: ''
};

exports.default = Info;
module.exports = exports['default'];