import ServerApi from 'shared/lib/api/ServerApi';
import moment from 'moment';

export const sortMessages = (items) => [...items].sort((a, b) => new Date(a.created) > new Date(b.created) ? 1 : -1);

class ChatApi extends ServerApi {
  getUsers(internshipId, applicantId) {
    if (internshipId) {
      return super.get(
        `/internship/chat-users/${internshipId}/?internship=${internshipId}`,
      );
    }
    return super.get(`/internship/chat-users/?applicant_id=${applicantId}`);
  }

  getMessages() {
    return super.get('/internship/chat-message/?limit=100');
  }

  addMessage(data) {
    return super.post('/internship/chat-message/', data);
  }

  addRoomMessage(data) {
    return super.post('/internship/send-chatroom-message/', data);
  }

  getRooms() {
    return super.get('/internship/chat-room/');
  }

  markMessageAsRead(messageId) {
    return super.patch(`/internship/chat-message/${messageId}/`, {
      read_at: moment()
        .utc()
        .format(),
    });
  }
}

export default new ChatApi();
