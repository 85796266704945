export default {
  filter: {
    schoolName: '',
    classroom: '',
    search: '',
    sort: 0,
    clearButtonVisible: false,
  },
  currentUser: {},
  application: {
    data: {
      advisorsData: [],
    },
  },
  assessment: {
    data: {
      questions: null,
      answerChoices: null,
    },
  },
  internshipComment: {},
  notifications: {},
  selectedInternship: null,
  iframe: {
    data: {
      iframeSrc: '',
      iframeKey: 0,
      language: 'de',
    },
  },
  entityInstruction: {
    identifier: null,
    title: null,
    content: null,
    video: null,
    rating: null,
  },
  applicant: {
    list: null,
    applicant: null,
  },
};
