'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _InputFeedbackStyled = require('./InputFeedbackStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var InputFeedback = function InputFeedback(_ref) {
  var error = _ref.error;
  return error ? _react2.default.createElement(
    _InputFeedbackStyled.InputFeedbackWrap,
    null,
    error
  ) : null;
};

InputFeedback.propTypes = process.env.NODE_ENV !== "production" ? {
  error: _propTypes2.default.string
} : {};

InputFeedback.defaultProps = {
  error: null
};

exports.default = InputFeedback;
module.exports = exports['default'];