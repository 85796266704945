import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as ThemeProviderBase } from 'styled-components';
import themeComp from 'shared/lib/styles/theme';
import themeApp from 'styles/theme';
import { GlobalStyle } from 'styles/global';

const ThemeProvider = ({ children }) => (
  <ThemeProviderBase theme={{ ...themeComp, ...themeApp }}>
    <GlobalStyle />
    {children}
  </ThemeProviderBase>
);

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
