import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import { EVENT_TYPES, EVENT_VISIBILITY } from 'utils/calendarHelpers';
import { buildInternshipUrl } from 'api/CalendarApi';
import { addEvent } from 'actions/calendarActions';
import EventForm from '../../../../components/SchedulerCalendar/EventForm';
import ActionResults from '../../ActionResults';

const EnterAbsence = ({
  selectedApplicants,
  selectedInternship,
  showActionResult,
  closeActionModel,
  addEvent,
}) => {
  const newEvent = {};
  newEvent.kind = EVENT_TYPES.ABSENCE;
  newEvent.title = '';
  newEvent.description = '';
  newEvent.place = '';
  newEvent.start = '';
  newEvent.end = '';
  newEvent.visibility = EVENT_VISIBILITY.STUDENT_ONLY;
  newEvent.attendees = [];
  newEvent.isNew = true;

  const handleSubmit = (values) => {
    if (values) {
      const setEvent = selectedApplicants.map((applicant) => {
        const selectedApplicantInternship = selectedInternship[applicant.pk];
        return addEvent({
          ...values,
          internship:
            selectedApplicantInternship && selectedApplicantInternship.value
              ? buildInternshipUrl(selectedApplicantInternship.value.pk)
              : null,
        })
          .then(() => ({
            result: 'success',
            user: applicant.esuser.user,
          }))
          .catch(() => ({
            result: 'failed',
            user: applicant.esuser.user,
          }));
      });
      return Promise.all(setEvent).then((values) => {
        showActionResult(
          <ActionResults results={values} onClose={closeActionModel} />,
        );
      });
    }
  };
  return (
    <EventForm
      closeForm={closeActionModel}
      date={moment()}
      event={newEvent}
      submitManageStudents={handleSubmit}
      onCancel={closeActionModel}
      isStudentProfile
    />
  );
};

EnterAbsence.propTypes = {
  selectedApplicants: PropTypes.array.isRequired,
  selectedInternship: PropTypes.shape({}).isRequired,
  showActionResult: PropTypes.func.isRequired,
  closeActionModel: PropTypes.func.isRequired,
  addEvent: PropTypes.func.isRequired,
};

export default connect(undefined, {
  addEvent,
})(EnterAbsence);
