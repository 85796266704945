'use strict';

exports.__esModule = true;
exports.Mobile = exports.Tablet = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactMedia = require('react-media');

var _reactMedia2 = _interopRequireDefault(_reactMedia);

var _device = require('../../styles/device');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MediaQuery = function MediaQuery(_ref) {
  var children = _ref.children,
      query = _ref.query,
      queries = _ref.queries;
  return _react2.default.createElement(
    _reactMedia2.default,
    { query: query, queries: queries },
    children
  );
};

var Tablet = function Tablet(_ref2) {
  var children = _ref2.children;
  return _react2.default.createElement(
    MediaQuery,
    { query: _device.device.tablet },
    children
  );
};

var Mobile = function Mobile(_ref3) {
  var children = _ref3.children;
  return _react2.default.createElement(
    MediaQuery,
    { query: _device.device.mobile },
    children
  );
};

exports.default = MediaQuery;
exports.Tablet = Tablet;
exports.Mobile = Mobile;