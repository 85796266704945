import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const AuthenticateRoute = ({ isAuthenticated, ...props }) => {
  if (isAuthenticated) {
    return <Route {...props} />;
  }
  return <Redirect to="/login?next=/" />;
};

AuthenticateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ currentUser: { data } }) => ({
  isAuthenticated: !!data,
});

export default connect(mapStateToProps)(AuthenticateRoute);
