import React from 'react';
import PropTypes from 'prop-types';
import ApplicantItem from './ApplicantItem';
import { Applicants } from './ApplicantsListStyled';

export const ApplicantsList = ({ applicants }) => (
  <Applicants>
    {applicants.map((applicant) => {
      const internship = applicant.esuser.internships[0];
      return (
        <ApplicantItem
          key={applicant.pk}
          applicantId={applicant.pk}
          internship={internship}
          firstName={applicant.esuser.user.first_name}
          lastName={applicant.esuser.user.last_name}
          classroom={applicant.classroom}
          vacancy={internship && internship.vacancy}
          location={internship && internship.location}
          status={internship && internship.status}
        />
      );
    })}
  </Applicants>
);

ApplicantsList.propTypes = {
  applicants: PropTypes.array,
};

ApplicantsList.defaultProps = {
  applicants: [],
};

export default ApplicantsList;
