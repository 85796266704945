'use strict';

exports.__esModule = true;

var _createTypes = require('./createTypes');

var _createTypes2 = _interopRequireDefault(_createTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (_ref) {
  var type = _ref.type,
      callAPI = _ref.callAPI,
      _ref$shouldCallAPI = _ref.shouldCallAPI,
      shouldCallAPI = _ref$shouldCallAPI === undefined ? function () {
    return true;
  } : _ref$shouldCallAPI,
      _ref$payload = _ref.payload,
      payload = _ref$payload === undefined ? {} : _ref$payload;
  return {
    // Types of actions to emit before and after
    types: (0, _createTypes2.default)(type),
    // Check the cache (optional):
    shouldCallAPI: shouldCallAPI,
    // Perform the fetching:
    callAPI: callAPI,
    // Arguments to inject in begin/end actions
    payload: payload
  };
};

module.exports = exports['default'];