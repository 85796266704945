import userApi from 'api/UserApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function updateUser(pk, data, avatarOnly) {
  return createAction({
    type: types.UPDATE_USER,
    callAPI: () => userApi.updateUser(pk, data, avatarOnly),
  });
}
