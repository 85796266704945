import styled from 'styled-components';
import ReactSVG from 'react-svg';

export const VisitNotePreviewWrap = styled.div`
  & + & {
    margin-top: 20px;
  }
`;

export const VisitNotePreviewContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CheckBoxTitle = styled.div`
  color: ${({ theme }) => theme.secondary};
`;

export const CheckBoxDescription = styled.div`
  color: ${({ theme }) => theme.grayA0};
  font-size: 14px;
`;

export const EditIcon = styled(ReactSVG)`
  display: inline-block;
  cursor: pointer;
  svg path {
    stroke: ${({ theme }) => theme.secondary};
  }
  margin-left: 20px;
  margin-right: 10px;
`;

export const RemoveIcon = styled(ReactSVG)`
  display: inline-block;
  cursor: pointer;
  svg path {
    stroke: ${({ theme }) => theme.primary};
  }
`;

export const AttachmentIcon = styled(ReactSVG)`
  display: inline-block;
  cursor: pointer;
  svg path {
    stroke: ${({ theme }) => theme.secondary};
  }
  margin-right: 10px;
`;

export const AttachmentsWrap = styled.div`
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  > * {
    margin: 5px;
  }
`;
