import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getInternshipsRequestsList,
  changeInternshipRequest,
  uploadInternshipRequestAttachment,
  deleteInternshipRequestAttachment,
  addInternshipRequest,
  deleteInternshipRequest,
} from 'actions/internshipRequestActions';
import { Loader } from 'shared';
import { roles } from 'utils/access-rules/constants';
import InternshipsRequestsList from '../InternshipsRequestsList';

export const INTERNSHIP_REQUEST_STATUSES = {
  WAITING_FOR_INFORMATION: 'WAITING_FOR_INFORMATION',
  WAITING_FOR_APPROVAL: 'WAITING_FOR_APPROVAL',
  ACCEPTED: 'ACCEPTED',
};

const InternshipsRequestsListContainer = ({
  internshipRequests: _internshipRequests,
  changeInternshipRequest,
  getInternshipsRequestsList,
  uploadInternshipRequestAttachment,
  deleteInternshipRequestAttachment,
  addInternshipRequest,
  deleteInternshipRequest,
  currentUser,
  loading,
  error,
  applicantId,
  getApplicants,
}) => {
  const stableGetInternshipsRequestsList = useCallback(
    getInternshipsRequestsList,
    [_internshipRequests],
  );
  useEffect(() => {
    if (!_internshipRequests) stableGetInternshipsRequestsList();
  }, [stableGetInternshipsRequestsList, _internshipRequests]);

  if (loading && !_internshipRequests) {
    return <Loader />;
  }
  let internshipRequests = _internshipRequests;
  if (applicantId && internshipRequests) {
    internshipRequests = internshipRequests.filter(
      (request) => request.applicant.pk === applicantId,
    );
  }
  if (internshipRequests && currentUser.role === roles.Teacher) {
    internshipRequests = internshipRequests.filter(
      (request) => request.status !== INTERNSHIP_REQUEST_STATUSES.WAITING_FOR_INFORMATION,
    );
  }
  return (
    <InternshipsRequestsList
      internships={internshipRequests}
      error={error}
      changeInternshipRequest={changeInternshipRequest}
      uploadInternshipRequestAttachment={uploadInternshipRequestAttachment}
      deleteInternshipRequestAttachment={deleteInternshipRequestAttachment}
      addInternshipRequest={addInternshipRequest}
      deleteInternshipRequest={deleteInternshipRequest}
      currentUser={currentUser}
      getInternships={getApplicants}
      loading={loading}
    />
  );
};
InternshipsRequestsListContainer.propTypes = {
  internshipRequests: PropTypes.arrayOf(PropTypes.object),
  changeInternshipRequest: PropTypes.func.isRequired,
  getInternshipsRequestsList: PropTypes.func.isRequired,
  uploadInternshipRequestAttachment: PropTypes.func.isRequired,
  deleteInternshipRequestAttachment: PropTypes.func.isRequired,
  addInternshipRequest: PropTypes.func.isRequired,
  getApplicants: PropTypes.func.isRequired,
  deleteInternshipRequest: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  currentUser: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }).isRequired,
  applicantId: PropTypes.number,
};

InternshipsRequestsListContainer.defaultProps = {
  error: null,
  applicantId: null,
  internshipRequests: [],
};

const mapStateToProps = ({
  internshipRequest: { error, loading, data },
  currentUser,
}) => ({
  internshipRequests: data,
  error,
  loading,
  currentUser: currentUser && currentUser.data,
});

export default connect(
  mapStateToProps,
  {
    getInternshipsRequestsList,
    changeInternshipRequest,
    uploadInternshipRequestAttachment,
    deleteInternshipRequestAttachment,
    addInternshipRequest,
    deleteInternshipRequest,
  },
)(InternshipsRequestsListContainer);
