import React from 'react';
import PropTypes from 'prop-types';
import ReactSvg from 'react-svg';

import uploadDocument from 'shared/lib/styles/images/upload-document.svg';
import uploadedContract from 'shared/lib/styles/images/uploaded-contract.svg';
import Localize from 'components/Localize';
import { StyledButton } from './ContractButtonStyled';

const ContractButton = ({ data, company }) => (
  <StyledButton>
    {data && data.length > 0 ? (
      <>
        <ReactSvg src={uploadedContract} />
        <span className="text">
          <Localize id="internship-contract" /> {company}
        </span>
      </>
    ) : (
      <>
        <ReactSvg src={uploadDocument} />
        <span className="text">
          <Localize id="upload-internship-contract" />
        </span>
      </>
    )}
  </StyledButton>
);

ContractButton.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  company: PropTypes.string,
};

ContractButton.defaultProps = {
  company: '',
};

export default ContractButton;
