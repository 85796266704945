import React from 'react';
import PropTypes from 'prop-types';
import { TitleWrapper } from './TitleStyled';

const Title = ({ vacancy }) => <TitleWrapper>{vacancy.title}</TitleWrapper>;

Title.propTypes = {
  vacancy: PropTypes.object.isRequired,
};

export default Title;
