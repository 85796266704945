'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactCollapse = require('react-collapse');

var _reactRouterDom = require('react-router-dom');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Localize = require('../Localize');

var _Localize2 = _interopRequireDefault(_Localize);

var _CollapseStyled = require('./CollapseStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Collapse(_ref) {
  var headerText = _ref.headerText,
      previewText = _ref.previewText,
      children = _ref.children,
      collapseInOpen = _ref.collapseInOpen,
      onlyOpen = _ref.onlyOpen,
      id = _ref.id,
      history = _ref.history;

  var _useState = (0, _react.useState)(collapseInOpen || false),
      isOpened = _useState[0],
      setIsOpened = _useState[1];

  function collapseToggle() {
    setIsOpened(!isOpened);
  }

  (0, _react.useEffect)(function () {
    if (history.location.hash === '#' + id) {
      setIsOpened(true);
    }
  }, [history.location.hash]);

  return _react2.default.createElement(
    _CollapseStyled.CollapseWrapper,
    { id: id },
    _react2.default.createElement(
      _CollapseStyled.CollapseHeader,
      { onlyOpen: onlyOpen, onClick: collapseToggle },
      _react2.default.createElement(
        _CollapseStyled.CollapseHeaderText,
        null,
        headerText
      ),
      isOpened ? _react2.default.createElement(
        _Localize2.default,
        null,
        function (_ref2) {
          var translate = _ref2.translate;
          return _react2.default.createElement(
            _CollapseStyled.CollapseHeaderElement,
            null,
            translate('hide')
          );
        }
      ) : _react2.default.createElement(
        _Localize2.default,
        null,
        function (_ref3) {
          var translate = _ref3.translate;
          return _react2.default.createElement(
            _CollapseStyled.CollapseHeaderElement,
            null,
            translate('show')
          );
        }
      )
    ),
    _react2.default.createElement(
      _CollapseStyled.CollapsePreview,
      null,
      previewText
    ),
    _react2.default.createElement(
      _reactCollapse.Collapse,
      { isOpened: isOpened },
      children
    )
  );
}

Collapse.propTypes = process.env.NODE_ENV !== "production" ? {
  previewText: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
  headerText: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
  children: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
  collapseInOpen: _propTypes2.default.bool,
  onlyOpen: _propTypes2.default.bool,
  id: _propTypes2.default.string,
  history: _propTypes2.default.object.isRequired
} : {};

Collapse.defaultProps = {
  headerText: undefined,
  previewText: undefined,
  collapseInOpen: undefined,
  onlyOpen: undefined,
  children: null,
  id: undefined
};

exports.default = (0, _reactRouterDom.withRouter)(Collapse);
module.exports = exports['default'];