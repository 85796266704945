import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';
import { combineReducers } from 'redux';
import { parseInternshipId } from 'api/CalendarApi';
import {
  addEvent,
  deleteEvent,
  editEvent,
  sortEvents,
} from 'utils/calendarHelpers';

const modifyEvent = (action, events) => {
  switch (action.type) {
    case types.DELETE_CALENDAR_EVENT:
      return deleteEvent(action.data, events);
    case types.ADD_CALENDAR_EVENT:
      return addEvent(action.data, events);
    case types.EDIT_CALENDAR_EVENT:
      return editEvent(action.data, events);
    default:
      return {};
  }
};

const byInternship = (state = {}, action) => {
  switch (action.type) {
    // this fetch from student profile page
    case successType(types.GET_INTERNSHIP):
      return {
        ...state,
        [action.response.pk]: sortEvents(action.response.calendar_events),
      };
    case successType(types.GET_CALENDAR_EVENTS):
      return {
        ...state,
        [action.response.internshipId]: sortEvents(action.response.data),
      };
    case types.DELETE_CALENDAR_EVENT:
    case types.ADD_CALENDAR_EVENT:
    case types.EDIT_CALENDAR_EVENT:
      // eslint-disable-next-line no-case-declarations
      if (action.data) {
        const internshipId = parseInternshipId(action.data.internship) || 'global';
        return {
          ...state,
          [internshipId]: modifyEvent(
            action,
            state[internshipId] || state.personal,
          ),
        };
      }
      return state;
    case types.LOGOUT:
      return {};
    default:
      return state;
  }
};

const dataReducer = combineReducers({
  byInternship,
});

export default createReducer([types.GET_CALENDAR_EVENTS], dataReducer);
