'use strict';

exports.__esModule = true;
exports.ModalContent = exports.DeleteButton = exports.ThumbnailContainer = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  0% {}\n  10%,\n  20% {\n    transform: rotate(-6deg);\n  }\n  30%,\n  50%,\n  70%,\n  90% {\n    transform: rotate(6deg);\n  }\n  40%,\n  60%,\n  80% {\n    transform: rotate(-6deg);\n  }\n  to {\n  }\n'], ['\n  0% {}\n  10%,\n  20% {\n    transform: rotate(-6deg);\n  }\n  30%,\n  50%,\n  70%,\n  90% {\n    transform: rotate(6deg);\n  }\n  40%,\n  60%,\n  80% {\n    transform: rotate(-6deg);\n  }\n  to {\n  }\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);\n  width: 125px;\n  height: 125px;\n  background-image: url("', '");\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  position: relative;\n  border-radius: ', ';\n  cursor: pointer;\n'], ['\n  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);\n  width: 125px;\n  height: 125px;\n  background-image: url("', '");\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  position: relative;\n  border-radius: ', ';\n  cursor: pointer;\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  ', ' infinite 1s;\n'], ['\n  ', ' infinite 1s;\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  position: absolute;\n  top: -15px;\n  right: -15px;\n  background-color: ', ';\n  width: 35px;\n  height: 35px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: ', ';\n  border: none;\n  cursor: ', ';\n  &:hover {\n    animation: ', ';\n  }\n'], ['\n  position: absolute;\n  top: -15px;\n  right: -15px;\n  background-color: ', ';\n  width: 35px;\n  height: 35px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: ', ';\n  border: none;\n  cursor: ', ';\n  &:hover {\n    animation: ', ';\n  }\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n  text-align: center;\n  img {\n    max-width: 100%;\n  }\n'], ['\n  text-align: center;\n  img {\n    max-width: 100%;\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var shaking = (0, _styledComponents.keyframes)(_templateObject);

var ThumbnailContainer = exports.ThumbnailContainer = _styledComponents2.default.div(_templateObject2, function (_ref) {
  var bgImage = _ref.bgImage;
  return bgImage;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.borderRadiusDefault;
});

var animation = function animation() {
  return (0, _styledComponents.css)(_templateObject3, shaking);
};

var DeleteButton = exports.DeleteButton = _styledComponents2.default.button(_templateObject4, function (_ref3) {
  var theme = _ref3.theme,
      disabled = _ref3.disabled;
  return disabled ? theme.grayE6 : theme.primary;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.borderRadiusRound;
}, function (_ref5) {
  var disabled = _ref5.disabled;
  return disabled ? 'initial' : 'pointer';
}, function (_ref6) {
  var disabled = _ref6.disabled;
  return disabled ? 'none' : animation;
});

var ModalContent = exports.ModalContent = _styledComponents2.default.div(_templateObject5);