import React from 'react';
import PropTypes from 'prop-types';
import { HashLink } from 'react-router-hash-link';
import { StyledNotificationItem } from './NotificationItemStyled';

function NotificationItem({
  children, to, unread, ...rest
}) {
  return (
    <StyledNotificationItem
      smooth
      as={HashLink}
      to={to}
      unread={unread ? unread.toString() : undefined}
      {...rest}
    >
      {children}
    </StyledNotificationItem>
  );
}

NotificationItem.propTypes = {
  unread: PropTypes.bool.isRequired,
  children: PropTypes.node,
  to: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

NotificationItem.defaultProps = {
  children: null,
  to: '#',
};

export default NotificationItem;
