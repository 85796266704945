import styled from 'styled-components';
import Select from 'components/Form/Select';
import SearchInput from 'components/Form/SearchInput';
import { Tooltip } from 'react-tippy';
import { device } from 'styles/device';
import { Button } from 'shared';

export const CalendarWrap = styled.div`
  padding: 20px 0 40px;
`;

export const InternshipListWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const FilterSelect = styled(Select)`
  ${({ isActive, theme }) => isActive && `background-color: ${theme.secondary}`};
  margin-right: 8px;
  width: 100%;

  ${({ withoutIndicator }) => withoutIndicator
    && `
    .select__control {
      padding-right: 10px;

    }
    .select__indicators {
      display: none;
    }
  `}

  & .select__control {
    min-height: 42px;
  }

  & .select__single-value {
    display: block;
    font-size: 14px;
    color: ${({ isActive, theme }) => (isActive ? '#fff' : theme.secondary)};
    text-align: left;
    justify-content: flex-start;
  }

  & .select__menu {
    font-size: 14px;
  }

  & path {
    stroke: ${({ isActive, theme }) => !isActive && theme.secondary};
  }

  @media ${device.mobile} {
    max-width: 100%;
    flex-grow: 1;
    margin-right: 0;
    margin-top: 8px;
  }
`;

export const ToolTip = styled(Tooltip)`
  @media ${device.mobile} {
    width: ${({ mobileWidth }) => mobileWidth};
  }
`;
export const ClassroomSelect = styled(FilterSelect)`
  width: 120px;

  @media ${device.mobile} {
    width: 100%;
    min-width: auto;
    flex-grow: 0;
    margin-right: 8px;
  }
`;

export const ClassroomNameSelect = styled(FilterSelect)`
  max-width: 247px;
  width: 100vw;
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const Search = styled(SearchInput)`
  @media ${device.tablet} {
  }

  @media ${device.mobile} {
    width: 100%;
    margin-top: 8px;
  }
`;

export const SortApplicantButton = styled(Button)`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.secondary};
  height: 44px;
  font-size: 22px;
  padding: 0 14px;
  color: ${({ theme }) => theme.secondary};
  font-weight: bold;
  @media ${device.mobile} {
    margin-top: 8px;
  }
`;

export const ClearButton = styled(SortApplicantButton)`
  margin-left: 8px;
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.white};
  .text {
    font-size: 14px;
  }
  .content {
    display: flex;
    align-items: center;
  }
  svg {
    margin-left: 8px;
    font-size: 18px;
  }
`;

export const ClassroomAndSort = styled.div`
  display: flex;
  @media ${device.mobile} {
    width: 100%;
  }
`;
