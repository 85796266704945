import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Localize from 'components/Localize';

const EducationalAdvisorAssessmentSelector = ({ row, selectedInternship }) => {
  let link = `/es/applicant-profile/${row.pk}`;
  if (row.esuser.internships[0]) link += `/${row.esuser.internships[0].pk}#assessment`;
  if (
    selectedInternship[row.esuser.pk]
    && selectedInternship[row.esuser.pk].value
  ) {
    link = `/es/applicant-profile/${row.pk}/${
      selectedInternship[row.esuser.pk].value.pk
    }#assessment`;
    if (
      selectedInternship[row.esuser.pk].value
        .assessment_education_provider_submitted_at
    ) {
      return (
        <a href={link}>
          <Localize
            id="submitted-on"
            data={{
              date: moment(
                selectedInternship[row.esuser.pk].value
                  .assessment_education_provider_submitted_at,
              ).format('DD.MM.YYYY'),
            }}
          />
        </a>
      );
    }
    if (
      selectedInternship[row.esuser.pk].value
        .assessment_education_provider_updated_at
    ) {
      return (
        <a href={link}>
          <Localize
            id="saved-on"
            data={{
              date: moment(
                selectedInternship[row.esuser.pk].value
                  .assessment_education_provider_updated_at,
              ).format('DD.MM.YYYY'),
            }}
          />
        </a>
      );
    }
    return (
      <a href={link}>
        <Localize id="not-filled" />
      </a>
    );
  }
  return null;
};

EducationalAdvisorAssessmentSelector.propTypes = {
  row: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    esuser: PropTypes.shape({
      pk: PropTypes.number.isRequired,
      internships: PropTypes.array,
    }).isRequired,
  }).isRequired,
  selectedInternship: PropTypes.shape({}).isRequired,
};

export default EducationalAdvisorAssessmentSelector;
