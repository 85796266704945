import React from 'react';
import PropTypes from 'prop-types';
import { Duration, MediaQuery } from 'shared';
import Localize from 'components/Localize';
import { Kind } from 'components/Format';
import { ReactComponent as LocationIcon } from 'styles/images/svg/location.svg';
import { ReactComponent as KindIcon } from 'styles/images/svg/hat.svg';
import { ReactComponent as CalendarIcon } from 'styles/images/svg/calendar2.svg';
import { device } from 'styles/device';
import {
  ApplicationBody,
  ApplicationCompany,
  ApplicationIndustry,
  ApplicationLogo,
  ApplicationLogoWrap,
  ApplicationLogoLetters,
  ApplicationStatus,
  ApplicationTitle,
  ApplicationWrap,
  ApplicationDetails,
  ApplicationDetailsItem,
} from './ApplicationStyled';
import Status from './Status';

const Application = ({ data }) => (
  <MediaQuery queries={device}>
    {(matches) => (
      <ApplicationWrap>
        <ApplicationLogoWrap>
          {data.companyLogo ? (
            <ApplicationLogo src={data.companyLogo} alt="" />
          ) : (
            <ApplicationLogoLetters>
              {' '}
              {data.companyName[0]}
            </ApplicationLogoLetters>
          )}
        </ApplicationLogoWrap>
        {matches.mobile && (
          <ApplicationStatus>
            <Status name={data.status} />
          </ApplicationStatus>
        )}
        <ApplicationBody>
          <ApplicationTitle>{data.title}</ApplicationTitle>
          <ApplicationDetails>
            {data.industryList.map(({ slug, name }) => (
              <ApplicationIndustry key={slug}>{name}</ApplicationIndustry>
            ))}
            <ApplicationCompany>
              <Localize id="at" /> {data.companyName}
            </ApplicationCompany>
            <ApplicationDetailsItem>
              <LocationIcon /> {data.city}
            </ApplicationDetailsItem>
            <ApplicationDetailsItem>
              <KindIcon /> <Kind name={data.kind} />
            </ApplicationDetailsItem>
            <ApplicationDetailsItem>
              <CalendarIcon /> <Duration start={new Date(data.created)} />
            </ApplicationDetailsItem>
          </ApplicationDetails>
        </ApplicationBody>
        {!matches.mobile && (
          <ApplicationStatus>
            <Status name={data.status} />
          </ApplicationStatus>
        )}
      </ApplicationWrap>
    )}
  </MediaQuery>
);

Application.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    companyLogo: PropTypes.string,
    companyName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    industryList: PropTypes.array.isRequired,
    kind: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
  }).isRequired,
};

export default Application;
