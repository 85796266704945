import styled from 'styled-components';

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  color: ${({ theme }) => theme.textColor69};
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;
`;

export const ChatMessagesPanelWrapper = styled.div`
  padding: 18px;
`;
