import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import location from 'styles/images/svg/location.svg';
import { Kind as KindFormat } from 'components/Format';
import hat from 'styles/images/svg/hat.svg';
import { CityTagDurationWrapper } from './CityTagDurationStyled';

const CityTagDuration = ({ vacancy }) => (
  <CityTagDurationWrapper>
    <li>
      <ReactSVG src={location} />
      {vacancy.address.city.name}
    </li>
    <li>
      <ReactSVG src={hat} />
      <KindFormat name={vacancy.kind} />
    </li>
  </CityTagDurationWrapper>
);

CityTagDuration.propTypes = {
  vacancy: PropTypes.object.isRequired,
};

export default CityTagDuration;
