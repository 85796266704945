import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Error, Loader } from 'shared';
import Page from 'components/Page';
import Localize from 'components/Localize';
import InformationModal from 'components/InformationModal';
import { ReactComponent as InfoIcon } from 'styles/images/svg/info.svg';
import { getMyApplications } from 'actions/applicationActions';
import Filter from './Filter';
import FilteredItems from './FilteredItems';

const YourApplications = ({
  getMyApplications,
  myApplications,
  selectedInternshipId,
  error,
  loading,
}) => {
  const stableGetMyApplications = useCallback(getMyApplications, []);
  useEffect(() => {
    stableGetMyApplications();
  }, [stableGetMyApplications]);

  const [filter, setFilter] = useState({ status: undefined, text: '' });
  const [informationModalOpen, setInformationModalOpen] = useState('');

  const renderContent = () => {
    if (error) {
      return <Error />;
    }
    if (loading) {
      return <Loader />;
    }
    return (
      <FilteredItems
        filter={filter}
        applications={myApplications.map((app) => ({
          status: app.status,
          created: app.created,
          companyLogo: app.application.vacancy.company.logo,
          companyName: app.application.vacancy.company.name,
          title: app.application.vacancy.title,
          industryList: app.application.vacancy.industry_list,
          kind: app.application.vacancy.kind,
          city: app.application.vacancy.address.city.name,
        }))}
      />
    );
  };

  const handleTextChanged = (e) => {
    setFilter({
      status: filter.status,
      text: e.target.value,
    });
  };
  const handleStatusChanged = (data) => {
    setFilter({
      status: data ? data.value : null,
      text: filter.text,
    });
  };
  return (
    <>
      <InformationModal
        open={informationModalOpen}
        onCloseModal={() => setInformationModalOpen('')}
      />
      <Page
        title={<Localize id="your-applications" />}
        description={
          <Localize id="your-overview-all-your-applications-you-have-sent" />
        }
        backTo={`/applicant/${selectedInternshipId || ''}`}
        icon={<InfoIcon />}
        onClick={() => setInformationModalOpen('APPLICATIONS')}
      >
        <Filter
          filter={filter}
          onTextChanged={handleTextChanged}
          onStatusChanged={handleStatusChanged}
        />
        {renderContent()}
      </Page>
    </>
  );
};

YourApplications.propTypes = {
  getMyApplications: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  myApplications: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
      companyLogo: PropTypes.string,
      companyName: PropTypes.string,
      title: PropTypes.string,
      industryList: PropTypes.array,
      kind: PropTypes.string,
      city: PropTypes.string,
    }),
  ).isRequired,
  selectedInternshipId: PropTypes.number,
};

YourApplications.defaultProps = {
  error: undefined,
  selectedInternshipId: null,
};

const mapStateToProps = ({
  myApplications: { data, error, loading },
  selectedInternship,
}) => ({
  error,
  loading,
  myApplications: data || [],
  selectedInternshipId: selectedInternship && selectedInternship.pk,
});

export default connect(mapStateToProps, { getMyApplications })(
  YourApplications,
);
