import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Cover from 'components/Cover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import vacancyApi from 'api/VacancyApi';
import accessTokenStorage from 'shared/lib/lib/accessTokenStorage';
import { Error } from 'shared';
import { isJsonString } from 'utils/isJsonString';
import { setIframeSrc } from 'actions/iframeActions';
import Message from './Message';
import ProfileOptions from './ProfileOptions';
import VacancyList from './VacancyList';
import UploadAttachments from './UploadAttachments';
import Success from './Success';
import { BackButton, ApplicationAssistant } from './MessageStyled';

const FillRequest = ({
  // isInternshipExist,
  internshipRequest,
  visible,
  onClose,
  getVacancyList,
  vacancyList,
  vacancyLoading,
  vacancyError,
  children,
  setIframeSrc,
  iframeSrc,
  iframeKey,
  language,
}) => {
  // const hasAcceptedInternshipRequest = internshipRequest.some(
  //   (internship) => internship.status === 'ACCEPTED',
  // );
  // const [visible, setVisibility] = useState(
  //   !isInternshipExist && !hasAcceptedInternshipRequest,
  // );
  const [visibleContent, setVisibleContent] = useState(null);
  const [selectedVacancy, setSelectedVacancy] = useState(null);
  const [selectedProfileOptions, setSelectedProfileOptions] = useState(false);
  const [videoUrl, setVideoUrl] = useState(undefined);
  const [audioUrl, setAudioUrl] = useState(undefined);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const handleClose = () => {
    setVisibleContent(null);
    setSelectedVacancy(null);
    setSelectedProfileOptions(false);
    onClose();
  };

  const handleVacancyList = () => {
    setSelectedProfileOptions(false);
    getVacancyList();
    setVisibleContent('VacancyList');
  };
  const createProfile = (vacancy) => {
    setSelectedVacancy(vacancy);
    if (selectedProfileOptions && vacancy) {
      vacancyApi
        .addApplication(vacancy.api_url, videoUrl, audioUrl)
        .then(() => setSuccess(true))
        .catch(() => setError(true));
    } else {
      setVisibleContent('ProfileOptions');
    }
  };
  const openUploadAttachments = () => {
    setVisibleContent('UploadAttachments');
  };
  const submitUploadAttachments = () => {
    setSelectedProfileOptions(true);
    if (selectedVacancy) {
      setUploadLoading(true);
      vacancyApi
        .addApplication(selectedVacancy.api_url)
        .then(() => {
          setUploadLoading(false);
          setSuccess(true);
        })
        .catch(() => {
          setUploadLoading(false);
          setError(true);
        });
    } else {
      getVacancyList();
      setVisibleContent('VacancyList');
    }
  };
  const handleBack = () => {
    setError(null);
    setSuccess(null);
    setSelectedProfileOptions(false);
    if (visibleContent === 'VacancyList') {
      setVisibleContent(null);
    } else if (visibleContent === 'ProfileOptions') {
      if (selectedVacancy) {
        setVisibleContent('VacancyList');
      } else {
        setVisibleContent(null);
      }
    } else if (visibleContent === 'UploadAttachments') {
      setVisibleContent('ProfileOptions');
    } else if (visibleContent === 'ApplicationAssistant') {
      setVisibleContent('ProfileOptions');
    } else {
      handleClose();
    }
  };
  const openIframe = () => {
    setSelectedProfileOptions(true);
    if (selectedVacancy) {
      const applyLink = vacancyApi.getApplyLink(
        selectedVacancy.company.pk,
        selectedVacancy.pk,
      );
      setIframeSrc(
        `${applyLink}/?coming_from=ES-Search&auth_token=${accessTokenStorage.get()}`,
      );
      setVisibleContent('ApplicationAssistant');
    } else {
      const applyLink = vacancyApi.getApplyLink(
        process.env.REACT_APP_NOW_COMPANY_ID,
        process.env.REACT_APP_NOW_VACANCY_ID,
      );
      setIframeSrc(
        `${applyLink}/?coming_from=ES-Create&auth_token=${accessTokenStorage.get()}`,
      );
      setVisibleContent('ApplicationAssistant');
    }
  };
  const handleIframeTask = (e) => {
    if (isJsonString(e.data)) {
      const data = JSON.parse(e.data);
      if (data.type === 'Look for vacancies') {
        if (data.videoUrl) {
          setVideoUrl(data.videoUrl);
        }
        if (data.audioUrl) {
          setAudioUrl(data.audioUrl);
        }
        getVacancyList();
        setVisibleContent('VacancyList');
      } else if (data.type === 'Go back') {
        handleClose();
      }
    }
  };
  const closeSuccessPopup = () => {
    setSuccess(null);
    handleClose();
  };
  const stableHandleIframeTask = useCallback(handleIframeTask, []);

  useEffect(() => {
    window.addEventListener('message', stableHandleIframeTask);
  }, [stableHandleIframeTask]);

  return (
    <div>
      {visible && (
        <BackButton size="small" onClick={handleBack}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </BackButton>
      )}
      <Cover
        visible={visible}
        height={visibleContent === 'ApplicationAssistant' ? '800px' : '100%'}
        content={
          (error && <Error />)
          || (success && <Success handleClose={closeSuccessPopup} />)
          || (visibleContent === 'VacancyList' && (
            <VacancyList
              vacancyList={vacancyList}
              loading={vacancyLoading}
              error={vacancyError}
              handleSubmit={createProfile}
            />
          ))
          || (visibleContent === 'ProfileOptions' && (
            <ProfileOptions
              uploadCv={openUploadAttachments}
              createProfile={openIframe}
            />
          ))
          || (visibleContent === 'UploadAttachments' && (
            <UploadAttachments
              selectedVacancy={selectedVacancy}
              onSubmit={submitUploadAttachments}
              uploadLoading={uploadLoading}
            />
          ))
          || (visibleContent === 'ApplicationAssistant' && (
            <ApplicationAssistant
              key={iframeKey}
              src={`${iframeSrc}&language=${language}`}
              allow="camera *;microphone *"
            />
          )) || (
            <Message
              internshipRequest={internshipRequest}
              onClose={handleClose}
              handleVacancyList={handleVacancyList}
              createProfile={() => createProfile(null)}
            />
          )
        }
      >
        {children}
      </Cover>
    </div>
  );
};

FillRequest.propTypes = {
  children: PropTypes.node.isRequired,
  // isInternshipExist: PropTypes.bool.isRequired,
  internshipRequest: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  getVacancyList: PropTypes.func.isRequired,
  vacancyList: PropTypes.array.isRequired,
  vacancyLoading: PropTypes.bool.isRequired,
  vacancyError: PropTypes.object,
  setIframeSrc: PropTypes.func.isRequired,
  iframeSrc: PropTypes.string.isRequired,
  iframeKey: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
};

FillRequest.defaultProps = {
  vacancyError: undefined,
};

const mapStateToProps = ({
  // currentUser: { data },
  internshipRequest,
  vacancy,
  iframe,
}) => ({
  // isInternshipExist: data.internships.length > 0,
  internshipRequest: internshipRequest.data || [],
  vacancyList: vacancy.data || [],
  vacancyLoading: vacancy.loading,
  vacancyError: vacancy.error,
  iframeSrc: iframe.data ? iframe.data.iframeSrc : '',
  iframeKey: iframe.data.iframeKey,
  language: iframe.data.language,
});

export default connect(
  mapStateToProps,
  { setIframeSrc },
)(FillRequest);
