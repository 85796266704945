import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';
import aes from 'crypto-js/aes';

const dataReducer = (state = null, action) => {
  switch (action.type) {
    case successType(types.GET_CURRENT_USER):
      if (action.response && action.response.length > 0) {
        const currentUser = action.response[0];
        // TODO Add key as env variable
        localStorage.setItem(
          'current-user-role',
          aes.encrypt(currentUser.role, 'ufo-key'),
        );
        return { ...action.response[0] };
      }
      return state;
    case successType(types.UPDATE_USER):
      if (action.response) {
        return { ...action.response };
      }
      return state;
    case types.LOGOUT:
      return null;
    default:
      return state;
  }
};

export default createReducer([types.GET_CURRENT_USER], dataReducer);
