'use strict';

exports.__esModule = true;
exports.LoadingContent = exports.Loading = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  position: relative;\n  ', '\n'], ['\n  position: relative;\n  ', '\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n    position: absolute;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n  '], ['\n    position: absolute;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n  ']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  opacity: ', ';\n'], ['\n  opacity: ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var Loading = exports.Loading = _styledComponents2.default.div(_templateObject, function (props) {
  return props.fullScreen && (0, _styledComponents.css)(_templateObject2);
});

var LoadingContent = exports.LoadingContent = _styledComponents2.default.div(_templateObject3, function (props) {
  return props.isLoading ? 0.2 : 1;
});