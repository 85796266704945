import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Localize from 'components/Localize';

import {
  StyledRole,
  StyledIcon,
  RolesContainer,
} from './QuestionnaireRolesStyled';

const roles = [
  {
    role: 'student',
    color: '#e95160',
  },
  {
    role: 'teacher',
    color: '#183a64',
  },
  {
    role: 'company',
    color: '#f3a6ac',
  },
  {
    role: 'education-companion',
    color: '#5da3d9',
  },
];

const QuestionnaireRoles = () => (
  <RolesContainer>
    {roles.map((item) => (
      <StyledRole className="role" key={item.role}>
        <StyledIcon icon={faCheck} color={item.color} />
        <span className="text">
          <Localize id={item.role} />
        </span>
      </StyledRole>
    ))}
  </RolesContainer>
);

QuestionnaireRoles.propTypes = {};

export default QuestionnaireRoles;
