import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  CheckBoxLabel,
  CheckBoxIconUnchecked,
  CheckBoxIconChecked,
} from './CheckBoxStyled';

const CheckBox = forwardRef(
  ({
    children, className, value, disabled, size, ...props
  }, ref) => (
    <CheckBoxLabel
      ref={ref}
      className={className}
      disabled={disabled}
      size={size}
    >
      {/* disabled value has checked icon by design */}
      <input
        type="checkbox"
        checked={value}
        disabled={disabled}
        onChange={() => {}}
        {...props}
      />
      <CheckBoxIconUnchecked disabled={disabled} />
      <CheckBoxIconChecked disabled={disabled} />
      {children}
    </CheckBoxLabel>
  ),
);

CheckBox.propTypes = {
  children: PropTypes.node,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
};

CheckBox.defaultProps = {
  children: undefined,
  value: false,
  disabled: false,
  className: undefined,
  size: null,
};

export default CheckBox;
