import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Popup, ButtonClose } from './ModalStyled';

function Modal({
  className,
  children,
  open,
  isOpen: isOpenProp = false,
  onCloseModal,
  ...props
}) {
  const [isOpen, setInOpen] = useState(isOpenProp);

  useEffect(() => {
    setInOpen(open);
  }, [open]);

  function onOpen() {
    setInOpen(true);
  }

  function onClose() {
    setInOpen(false);
  }

  return (
    <Popup
      className={className}
      open={isOpen}
      onClose={onCloseModal}
      {...props}
    >
      <ButtonClose type="button" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </ButtonClose>
      {children({ onOpen, onClose })}
    </Popup>
  );
}

Modal.propTypes = {
  open: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

Modal.defaultProps = {
  open: false,
  isOpen: false,
  className: undefined,
};

export default Modal;
