'use strict';

exports.__esModule = true;
exports.RemoveButton = exports.ButtonLinkBack = exports.SecondaryButton = exports.PrimaryButton = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouterDom = require('react-router-dom');

var _reactSvg = require('react-svg');

var _reactSvg2 = _interopRequireDefault(_reactSvg);

var _arrowLeft = require('../../styles/images/arrow-left.svg');

var _arrowLeft2 = _interopRequireDefault(_arrowLeft);

var _trash = require('../../styles/images/trash.svg');

var _trash2 = _interopRequireDefault(_trash);

var _Button = require('./Button');

var _Button2 = _interopRequireDefault(_Button);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var PrimaryButton = function PrimaryButton(_ref) {
  var props = _objectWithoutProperties(_ref, []);

  return _react2.default.createElement(_Button2.default, _extends({ color: _Button.BUTTON_COLORS.PRIMARY }, props));
};

exports.PrimaryButton = PrimaryButton;
var SecondaryButton = function SecondaryButton(_ref2) {
  var props = _objectWithoutProperties(_ref2, []);

  return _react2.default.createElement(_Button2.default, _extends({ color: _Button.BUTTON_COLORS.SECONDARY }, props));
};

exports.SecondaryButton = SecondaryButton;
var ButtonLink = function ButtonLink(_ref3) {
  var props = _objectWithoutProperties(_ref3, []);

  return _react2.default.createElement(_Button2.default, _extends({ as: _reactRouterDom.Link }, props));
};

var ButtonLinkBack = function ButtonLinkBack(_ref4) {
  var props = _objectWithoutProperties(_ref4, []);

  return _react2.default.createElement(
    ButtonLink,
    _extends({
      color: _Button.BUTTON_COLORS.SECONDARY,
      variant: _Button.BUTTON_VARIANTS.CONTAINED
    }, props),
    _react2.default.createElement(_reactSvg2.default, { src: _arrowLeft2.default })
  );
};

exports.ButtonLinkBack = ButtonLinkBack;
var RemoveButton = function RemoveButton(_ref5) {
  var props = _objectWithoutProperties(_ref5, []);

  return _react2.default.createElement(
    PrimaryButton,
    props,
    _react2.default.createElement(_reactSvg2.default, { src: _trash2.default })
  );
};
exports.RemoveButton = RemoveButton;