import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Form, EditIcon, CitySelect, DatePicker,
} from 'shared';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import birthdayIcon from 'styles/images/svg/birthday-user-field.svg';
import phoneIcon from 'styles/images/svg/phone-user-field.svg';
import emailIcon from 'styles/images/svg/email-user-field.svg';
import cityIcon from 'styles/images/svg/city-user-field.svg';
import InternshipsRequestsList from 'components/InternshipsRequestsList/InternshipListContainer';
import Can from 'components/Can';
import {
  PrimaryUserDataWrap,
  Paragraph,
  UserInfo,
  InfoList,
  UserInfoField,
  CheckIcon,
  HiddenButton,
} from './PrimaryUserDataStyled';
import UserAvatarUploader from './UserAvatarUploader';
import { actions } from '../../utils/access-rules/constants';
import { section } from '../../utils/access-rules/rules/sections';

const initialState = {
  isEditable: false,
};

function PrimaryUserData({
  pk,
  className,
  noTitle,
  avatar,
  userFirstName,
  userLastName,
  birthday,
  phone,
  email,
  location,
  onClickEdit,
  updateUser,
}) {
  const onSubmit = (values) => {
    if (values.avatar !== avatar) {
      return updateUser(pk, {
        ...values,
        avatar: values.avatar.split(',').pop(),
      });
    }
    return updateUser(pk, values);
  };
  const [state, setState] = useState(initialState);
  const submitButtonRef = useRef();
  const onSave = () => submitButtonRef.current.click();
  return (
    <PrimaryUserDataWrap className={className}>
      {!noTitle && (
        <Paragraph
          title={`${userFirstName} ${userLastName}`}
          rightText
          icon={
            state.isEditable ? (
              <CheckIcon onClick={onSave} icon={faCheck} />
            ) : (
              <EditIcon />
            )
          }
          onClick={() => setState({ ...state, isEditable: !state.isEditable })}
        />
      )}
      <UserInfo>
        <Form
          initialValues={{
            birthday,
            email,
            phone,
            avatar,
            location,
          }}
          onSubmit={onSubmit}
        >
          {({ values, ...rest }) => (
            <>
              <UserAvatarUploader
                avatar={values.avatar}
                userFirstName={userFirstName}
                userLastName={userLastName}
                isEditable={state.isEditable}
                meta={rest}
              />
              <InfoList>
                <UserInfoField
                  icon={birthdayIcon}
                  onClickEdit={onClickEdit}
                  name="birthday"
                  value={values.birthday}
                  isEditable={state.isEditable}
                  component={DatePicker}
                  older16
                />
                <UserInfoField
                  icon={emailIcon}
                  onClickEdit={onClickEdit}
                  name="email"
                  value={values.email}
                  isEditable={state.isEditable}
                />
                <UserInfoField
                  icon={phoneIcon}
                  onClickEdit={onClickEdit}
                  name="phone"
                  value={values.phone}
                  isEditable={state.isEditable}
                />
                <UserInfoField
                  icon={cityIcon}
                  onClickEdit={onClickEdit}
                  name="location"
                  value={values.location}
                  isEditable={state.isEditable}
                  component={CitySelect}
                />
                <HiddenButton ref={submitButtonRef} type="submit" />
              </InfoList>
            </>
          )}
        </Form>
      </UserInfo>
      <Can I={actions.see} a={section.InternshipRequests}>
        <InternshipsRequestsList />
      </Can>
    </PrimaryUserDataWrap>
  );
}

PrimaryUserData.propTypes = {
  pk: PropTypes.number,
  className: PropTypes.string,
  noTitle: PropTypes.bool,
  avatar: PropTypes.string,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  birthday: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  location: PropTypes.string,
  onClickEdit: PropTypes.func,
  updateUser: PropTypes.func,
};

PrimaryUserData.defaultProps = {
  pk: null,
  className: undefined,
  noTitle: false,
  avatar: '',
  userFirstName: '',
  userLastName: '',
  birthday: '',
  phone: '',
  email: '',
  location: '',
  onClickEdit: undefined,
  updateUser: null,
};

export default PrimaryUserData;
