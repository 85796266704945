import styled from 'styled-components';
import { device } from 'styles/device';
import { CalendarNavHeightDesktop, CalendarNavHeightMobile } from 'components/SchedulerCalendar/Calendar/CalendarStyled';

export const Arrow = styled.span`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(${CalendarNavHeightDesktop}/2);
    width: 33px;
    height: 30px;
    padding: 10px;
    transform: translateY(-50%);
    ${({ isNext }) => (isNext ? 'transform: rotate(-180deg) translateY(50%); right: 18%' : 'left: 18%;')};

    @media ${device.mobile} {
      top: calc(${CalendarNavHeightMobile}/2);
      width: 30px;
      padding: 11px;
      ${({ isNext }) => (isNext ? 'right: 5%' : 'left: 5%')};
    }

    & svg {
      width: 100%;
      height: auto;
      transition: transform 0.3s
    }

    &:hover svg {
      transform: translateX(-5px);
    }

`;
