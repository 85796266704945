'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Carousel = require('../Carousel');

var _Carousel2 = _interopRequireDefault(_Carousel);

var _ContactBox = require('../ContactBox');

var _ContactBox2 = _interopRequireDefault(_ContactBox);

var _quotes = require('../../styles/images/quotes.png');

var _quotes2 = _interopRequireDefault(_quotes);

var _JobAdvisorStyled = require('./JobAdvisorStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var JobAdvisor = function JobAdvisor(_ref) {
  var data = _ref.data;

  var _useState = (0, _react.useState)(data[0]),
      currentSlide = _useState[0],
      setCurrentSlide = _useState[1];

  var onChange = function onChange(index) {
    return setCurrentSlide(data[index]);
  };
  return _react2.default.createElement(
    _JobAdvisorStyled.JobAdvisorContainer,
    null,
    _react2.default.createElement(
      _Carousel2.default,
      { onChange: onChange },
      data.map(function (advisor) {
        return _react2.default.createElement(
          _JobAdvisorStyled.Slide,
          { bgImage: advisor.cover, key: advisor.pk },
          _react2.default.createElement(
            _JobAdvisorStyled.AdvisorMessage,
            { bgImage: _quotes2.default },
            advisor.title
          ),
          _react2.default.createElement(
            _JobAdvisorStyled.AdvisorName,
            null,
            advisor.assistant_info
          )
        );
      })
    ),
    _react2.default.createElement(
      _JobAdvisorStyled.ContactBoxContainer,
      null,
      _react2.default.createElement(_ContactBox2.default, { data: currentSlide })
    )
  );
};

JobAdvisor.propTypes = process.env.NODE_ENV !== "production" ? {
  data: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    cover: _propTypes2.default.string.isRequired,
    pk: _propTypes2.default.number.isRequired,
    title: _propTypes2.default.string.isRequired,
    assistant_info: _propTypes2.default.string.isRequired
  }))
} : {};

JobAdvisor.defaultProps = {
  data: null
};

exports.default = JobAdvisor;
module.exports = exports['default'];