import learningTaskApi from 'api/LearningTaskApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getLearningTask(internshipId) {
  return createAction({
    type: types.GET_APPLICANT_LEARNING_TASK,
    callAPI: () => learningTaskApi.getTask(internshipId).then((data) => ({
      internshipId,
      data: data.find(({ pk }) => pk === internshipId),
    })),
    shouldCallAPI: (state) => internshipId && !state.learningTask.data.byInternship[internshipId],
  });
}

export function updateLearningTask(pk, task) {
  return (dispatch) => learningTaskApi.updateTask(pk, task).then((data) => dispatch({
    type: types.UPDATE_APPLICANT_LEARNING_TASK,
    data,
  }));
}

export function deleteLearningTaskAttachment(pk) {
  return (dispatch) => learningTaskApi.deleteTaskAttachments(pk).then(() => dispatch({
    type: types.DELETE_APPLICANT_LEARNING_TASK_ATTACHMENT,
    pk,
  }));
}
