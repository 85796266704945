import styled from 'styled-components';
import ReactSVG from 'react-svg';

export const VisitNotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .show-hide-box {
    transition: max-height 0.2s ease-in-out;
  }
  .show-hide-box.expanded {
    height: auto;
  }
`;

export const AddButton = styled.button`
  color: ${({ theme }) => theme.secondary};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
`;

export const PlusIcon = styled(ReactSVG)`
  svg path {
    stroke: ${({ theme }) => theme.secondary};
  }
  svg {
    width: 20px;
    height: 20px;
  }
  margin-right: 20px;
`;

export const AddVisitWrap = styled.div`
  position: relative;
`;

export const ShowHideButton = styled.button`
  background: none;
  border: none;
  right: 0;
  left: 0;
  cursor: pointer;
  display: table;
  position: relative;
  margin: 0 auto;
`;
