import ServerApi from 'shared/lib/api/ServerApi';

export const STATE = {
  INACTIVE: 'INACTIVE',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
};

class ApplicantApi extends ServerApi {
  getState(internshipId) {
    return super.get(`/internship/state/?internship_id=${internshipId}`);
  }
}

export default new ApplicantApi();
