import React from 'react';
import PropTypes from 'prop-types';
// import { Time } from 'shared';
// import Localize from 'components/Localize';
// import Tooltip from 'components/Tooltip';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { AppliedIcon } from './AppliedStyled';

const Applied = ({ vacancy, children }) => {
  if (vacancy.applied) {
    return (
      // <Tooltip
      //   message={
      //     <span>
      //       <Localize id="already-applied" /> <Time date={vacancy.applied} />
      //     </span>
      //   }
      // >
      <span>
        <AppliedIcon icon={faCalendarCheck} />
      </span>
      // </Tooltip>
    );
  }
  return children;
};

Applied.propTypes = {
  vacancy: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default Applied;
