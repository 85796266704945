import * as types from 'actions/types';
import initialState from 'store/initialState';
import { successType, createReducer } from 'shared/lib/lib/callAPI';

const dataReducer = (state = initialState.application.data, action) => {
  switch (action.type) {
    case successType(types.SET_ADVISORS_DATA):
      return {
        ...state,
        advisorsData: action.response,
      };
    default:
      return state;
  }
};

export default createReducer(types.SET_ADVISORS_DATA, dataReducer);
