import moment from 'moment';
import Localize from 'components/Localize';
import React from 'react';

export const EVENT_TYPES = {
  INTERNSHIP_DAY: 'INTERNSHIP_DAY',
  ABSENCE: 'ABSENCE',
  APPOINTMENT: 'APPOINTMENT',
  ONSITE_APPOINTMENT: 'ONSITE_APPOINTMENT',
};

export const EVENT_VISIBILITY = {
  ALL_STUDENTS: 'ALL_STUDENTS',
  STUDENT_ASSIGNEES: 'STUDENT_ASSIGNEES',
  STUDENT_ONLY: 'STUDENT_ONLY',
  ONLY_ME: 'ONLY_ME',
  ATTENDEES: 'ATTENDEES',
  EVERYONE: 'EVERYONE',
  EVERYONE_EXCEPT_STUDENT: 'EVERYONE_EXCEPT_STUDENT',
};

export const WHO_SEE_OPTIONS = [
  {
    label: <Localize id="all-students" />,
    value: EVENT_VISIBILITY.ALL_STUDENTS,
    isStudentProfile: false,
    isTeacherProfile: true,
    isStudent: false,
  },
  {
    label: <Localize id="student-assignees" />,
    value: EVENT_VISIBILITY.STUDENT_ASSIGNEES,
    isStudentProfile: true,
    isTeacherProfile: false,
    isStudent: false,
  },
  {
    label: <Localize id="student-only" />,
    value: EVENT_VISIBILITY.STUDENT_ONLY,
    isStudentProfile: true,
    isTeacherProfile: false,
    isStudent: false,
  },
  {
    label: <Localize id="only-me" />,
    value: EVENT_VISIBILITY.ONLY_ME,
    isStudentProfile: true,
    isTeacherProfile: true,
    isStudent: false,
  },
  {
    label: <Localize id="attendees" />,
    value: EVENT_VISIBILITY.ATTENDEES,
    isStudentProfile: true,
    isTeacherProfile: true,
    isStudent: true,
  },
  {
    label: <Localize id="everyone" />,
    value: EVENT_VISIBILITY.EVERYONE,
  },
  {
    label: <Localize id="everyone-except-student" />,
    value: EVENT_VISIBILITY.EVERYONE_EXCEPT_STUDENT,
  },
];

export function sortEvents(events) {
  const sortedEvents = Object.create(null);

  if (events.length === 0) {
    return {};
  }
  events.forEach((event) => {
    if (event.kind === EVENT_TYPES.INTERNSHIP_DAY) return;

    const createdAt = moment(event.start);
    const createdAtYear = createdAt.year();
    const createdAtMonth = createdAt.format('MM');
    const eventType = event.kind;

    if (!sortedEvents[eventType]) {
      sortedEvents[eventType] = Object.create(null);
    }

    if (!sortedEvents[eventType][createdAtYear]) {
      sortedEvents[eventType][createdAtYear] = Object.create(null);
    }

    if (!sortedEvents[eventType][createdAtYear][createdAtMonth]) {
      sortedEvents[eventType][createdAtYear][createdAtMonth] = [];
    }

    sortedEvents[eventType][createdAtYear][createdAtMonth].push(event);
  });

  return sortedEvents;
}

export function sortSpecialTasks(tasks) {
  const sortedTasks = Object.create(null);

  tasks.forEach((task) => {
    const deadline = moment(task.deadline);
    const deadlineYear = deadline.year();
    const deadlineMonth = deadline.format('MM');
    const eventType = 'SPECIAL_TASK';
    if (!sortedTasks[eventType]) {
      sortedTasks[eventType] = Object.create(null);
    }

    if (deadlineYear && !sortedTasks[eventType][deadlineYear]) {
      sortedTasks[eventType][deadlineYear] = Object.create(null);
    }

    if (deadlineYear && !sortedTasks[eventType][deadlineYear][deadlineMonth]) {
      sortedTasks[eventType][deadlineYear][deadlineMonth] = [];
    }

    if (deadlineYear) {
      sortedTasks[eventType][deadlineYear][deadlineMonth].push(task);
    }
  });

  return sortedTasks;
}

export const getEventListByDate = (eventList, currentYear, currentMonth) => {
  if (
    eventList
    && eventList[currentYear]
    && eventList[currentYear][currentMonth]
  ) {
    return eventList[currentYear][currentMonth];
  }

  return undefined;
};

export const getEventFromEventList = (event, eventList) => {
  const eventType = event.kind;
  const eventCreatedAt = moment(event.start);
  const createdAtYear = eventCreatedAt.year();
  const createdAtMonth = eventCreatedAt.format('MM');
  let foundedEvent;
  try {
    if (eventList[eventType]) {
      const eventListInDate = eventList[eventType][createdAtYear][createdAtMonth];
      const indexFoundedEvent = eventListInDate.findIndex(
        (_event) => _event.pk === event.pk,
      );

      foundedEvent = {
        event: eventListInDate[indexFoundedEvent],
        eventIndex: indexFoundedEvent,
      };
    }
  } catch (error) {
    // console.log(error);
  }

  return foundedEvent;
};

export const modifyEventInvitee = (invitee, events) => events.map((event) => ({
  ...event,
  invitees: event.invitees.map((i) => {
    if (i.pk === invitee.pk) {
      return invitee;
    }
    return i;
  }),
}));

export const editEvent = (updatedEvent, eventList) => {
  let updatedEvents = Object.assign(eventList, {});
  const eventStartAtYear = moment(updatedEvent.start).year();
  const eventStartAtMonth = moment(updatedEvent.start).format('MM');
  const eventType = updatedEvent.kind;
  const eventListWhereIsEvent = [
    ...getEventListByDate(
      eventList[eventType],
      eventStartAtYear,
      eventStartAtMonth,
    ),
  ];
  const eventInList = getEventFromEventList(updatedEvent, updatedEvents);
  if (eventInList && eventInList.eventIndex > -1) {
    eventListWhereIsEvent[eventInList.eventIndex] = updatedEvent;
    const updatedEventList = [...eventListWhereIsEvent];
    updatedEvents = {
      ...updatedEvents,
      [eventType]: {
        ...updatedEvents[eventType],
        [eventStartAtYear]: {
          ...updatedEvents[eventType][eventStartAtYear],
          [eventStartAtMonth]: updatedEventList,
        },
      },
    };
  }
  return updatedEvents;
};

export const addEvent = (event, eventList) => {
  if (event && eventList) {
    // FIXME: change stringify to another way
    let updatedEvents = JSON.stringify(eventList);
    updatedEvents = JSON.parse(updatedEvents);

    const eventStartAtYear = moment(event.start).year();
    const eventStartAtMonth = moment(event.start).format('MM');
    const eventType = event.kind;

    if (!updatedEvents[eventType]) {
      updatedEvents[eventType] = Object.create(null);
    }

    if (!updatedEvents[eventType][eventStartAtYear]) {
      updatedEvents[eventType][eventStartAtYear] = Object.create(null);
    }

    if (!updatedEvents[eventType][eventStartAtYear][eventStartAtMonth]) {
      updatedEvents[eventType][eventStartAtYear][eventStartAtMonth] = [];
    }

    const updatedEventList = updatedEvents[eventType][eventStartAtYear][
      eventStartAtMonth
    ].filter((_event) => _event.pk !== event.pk);

    updatedEventList.push(event);

    updatedEvents = {
      ...updatedEvents,
      [eventType]: {
        ...updatedEvents[eventType],
        [eventStartAtYear]: {
          ...updatedEvents[eventType][eventStartAtYear],
          [eventStartAtMonth]: updatedEventList,
        },
      },
    };

    return updatedEvents;
  }
};

export const deleteEvent = (event, eventList) => {
  if (event) {
    let updatedEvents = Object.assign(eventList, {});
    const eventStartAtYear = moment(event.start).format('YYYY');
    const eventStartAtMonth = moment(event.start).format('MM');
    const eventType = event.kind;
    const eventsByType = updatedEvents[eventType];
    const eventListWhereIsEvent = getEventListByDate(
      eventsByType,
      eventStartAtYear,
      eventStartAtMonth,
    );
    const eventInList = getEventFromEventList(event, updatedEvents);

    if (eventInList.eventIndex > -1) {
      const newEventList = [...eventListWhereIsEvent];
      newEventList.splice(eventInList.eventIndex, 1);
      updatedEvents = {
        ...updatedEvents,
        [eventType]: {
          ...updatedEvents[eventType],
          [eventStartAtYear]: {
            ...updatedEvents[eventType][eventStartAtYear],
            [eventStartAtMonth]: newEventList,
          },
        },
      };
    }

    return updatedEvents;
  }
};

/**
 * Destruct from {'year':{'month':[...events]}} to event list
 */
export const destructEvents = (eventObj) => {
  if (!eventObj) {
    return [];
  }
  const items = [];
  Object.values(eventObj).forEach((value) => {
    Object.values(value).forEach((events) => {
      items.push(...events);
    });
  });
  return items;
};
