'use strict';

exports.__esModule = true;
exports.SocialLinks = exports.Links = exports.Box = exports.Container = exports.Wrapper = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  color: #5da4da;\n  background-color: #161616;\n  z-index: 0;\n'], ['\n  color: #5da4da;\n  background-color: #161616;\n  z-index: 0;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  max-width: 1140px;\n  width: auto;\n  margin: 0 auto;\n  display: flex;\n  justify-content: space-between;\n  padding: 50px 20px 40px;\n  background-color: #161616;\n  h3 {\n    font-size: 15px;\n    margin-bottom: 0px;\n  }\n  @media ', ' {\n    flex-direction: column;\n    padding: 10px 50px 40px;\n    h3 {\n      margin-top: 30px;\n    }\n  }\n'], ['\n  max-width: 1140px;\n  width: auto;\n  margin: 0 auto;\n  display: flex;\n  justify-content: space-between;\n  padding: 50px 20px 40px;\n  background-color: #161616;\n  h3 {\n    font-size: 15px;\n    margin-bottom: 0px;\n  }\n  @media ', ' {\n    flex-direction: column;\n    padding: 10px 50px 40px;\n    h3 {\n      margin-top: 30px;\n    }\n  }\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  ', ';\n  ', ';\n'], ['\n  ', ';\n  ', ';\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  @media ', ' {\n    padding-top: 0px;\n  }\n  li {\n    margin-top: 20px;\n    a {\n      color: #f3f4f1;\n      font-size: 13px;\n      font-weight: 400;\n    }\n    a:hover {\n      text-decoration: underline;\n    }\n  }\n'], ['\n  @media ', ' {\n    padding-top: 0px;\n  }\n  li {\n    margin-top: 20px;\n    a {\n      color: #f3f4f1;\n      font-size: 13px;\n      font-weight: 400;\n    }\n    a:hover {\n      text-decoration: underline;\n    }\n  }\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n  display: flex;\n  margin-left: -5px;\n  flex-wrap: wrap;\n  max-width: 150px;\n  padding-top: 20px;\n  li {\n    margin: 5px;\n  }\n'], ['\n  display: flex;\n  margin-left: -5px;\n  flex-wrap: wrap;\n  max-width: 150px;\n  padding-top: 20px;\n  li {\n    margin: 5px;\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _device = require('../../styles/device');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var Wrapper = exports.Wrapper = _styledComponents2.default.footer(_templateObject);

var Container = exports.Container = _styledComponents2.default.div(_templateObject2, _device.device.mobile);

var Box = exports.Box = _styledComponents2.default.div(_templateObject3, function (_ref) {
  var withHeader = _ref.withHeader;
  return !withHeader && 'padding-top: 22px';
}, function (_ref2) {
  var isLanguagesContainer = _ref2.isLanguagesContainer;
  return isLanguagesContainer && 'padding-top: 40px';
});

var Links = exports.Links = _styledComponents2.default.ul(_templateObject4, _device.device.mobile);

var SocialLinks = exports.SocialLinks = _styledComponents2.default.ul(_templateObject5);