'use strict';

exports.__esModule = true;

var _Duration = require('./Duration');

Object.defineProperty(exports, 'Duration', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Duration).default;
  }
});

var _Time = require('./Time');

Object.defineProperty(exports, 'Time', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Time).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }