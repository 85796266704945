import styled from 'styled-components';
import ScrollToBottom from 'react-scroll-to-bottom';
import DownArrow from 'styles/images/svg/down-arrow.svg';

export const ChatMessagesWrapper = styled(ScrollToBottom)`
  height: 300px;
  .scroll-top {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    left: 17px;
    transition: 0.3s;
    &:before {
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      content: '';
      position: absolute;
      background-image: url(${DownArrow});
      background-size: 60%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  & > div {
    &::-webkit-scrollbar {
      width: 9px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.grayF3};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.grayA0};
    }
  }
`;
export const ChatMessagesPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ControlPanel = styled.div`
  display: flex;
  align-items: flex-end;
  padding-top: 14px;
  padding-left: 17px;
  padding-bottom: 18px;
`;

export const SendButton = styled.button`
  padding: 12px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: transform 0.35s ease;

  &:hover {
    transform: translateY(4px) translateX(-4px);
  }
`;
