'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Localize = require('../Localize');

var _Localize2 = _interopRequireDefault(_Localize);

var _FooterStyled = require('./FooterStyled');

var _LanguageSwitcher = require('./LanguageSwitcher');

var _LanguageSwitcher2 = _interopRequireDefault(_LanguageSwitcher);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var Footer = function Footer(_ref) {
  var className = _ref.className,
      updateIframe = _ref.updateIframe,
      rest = _objectWithoutProperties(_ref, ['className', 'updateIframe']);

  return _react2.default.createElement(
    _FooterStyled.Wrapper,
    { className: className },
    _react2.default.createElement(
      _FooterStyled.Container,
      null,
      _react2.default.createElement(
        _FooterStyled.Box,
        null,
        _react2.default.createElement(
          _FooterStyled.Links,
          null,
          _react2.default.createElement(
            'li',
            null,
            _react2.default.createElement(
              'a',
              { href: 'https://sommerschule.iba-digital.de/p/imprint/' },
              _react2.default.createElement(_Localize2.default, { id: 'imprint' })
            )
          )
        )
      ),
      _react2.default.createElement(
        _FooterStyled.Box,
        { isLanguagesContainer: true },
        _react2.default.createElement(_LanguageSwitcher2.default, _extends({}, rest, { updateIframe: updateIframe }))
      )
    )
  );
};
Footer.propTypes = process.env.NODE_ENV !== "production" ? {
  className: _propTypes2.default.string,
  updateIframe: _propTypes2.default.func.isRequired
} : {};

Footer.defaultProps = {
  className: undefined
};

exports.default = Footer;
module.exports = exports['default'];