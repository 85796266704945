import styled from 'styled-components';

export const NotificationList = styled.div`
  width: 75vw;
  max-width: 420px;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeightUpperBold};
  color: ${({ theme }) => theme.textColor};
  text-align: right;
  max-height: 400px;
  overflow-y: auto;
`;

export const NotificationContent = styled.div``;

export const NotificationTime = styled.div`
  margin-left: 10px;
  padding: 0 10px;
  border-left: 1px solid ${({ theme }) => theme.grayE6};
  font-size: 12px;
  min-width: 77px;
`;
