'use strict';

exports.__esModule = true;
exports.TextInputControl = exports.TextInputPlaceholder = exports.TextInputWrap = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  position: relative;\n  z-index: 2;\n'], ['\n  position: relative;\n  z-index: 2;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  line-height: 10px;\n  background-color: #fff;\n  top: -4px;\n  font-size: 12px;\n  padding: 0 5px;\n  z-index: 3;\n  color: ', ';\n'], ['\n  line-height: 10px;\n  background-color: #fff;\n  top: -4px;\n  font-size: 12px;\n  padding: 0 5px;\n  z-index: 3;\n  color: ', ';\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  line-height: 55px;\n  margin-left: 15px;\n  position: absolute;\n  top: 0;\n  transition: all 200ms ease;\n  color: ', ';\n  z-index: -1;\n  &:focus {\n    ', ';\n  }\n  ', '\n'], ['\n  line-height: 55px;\n  margin-left: 15px;\n  position: absolute;\n  top: 0;\n  transition: all 200ms ease;\n  color: ', ';\n  z-index: -1;\n  &:focus {\n    ', ';\n  }\n  ', '\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  display: block;\n  width: 100%;\n  font-size: 16px;\n  font-weight: ', ';\n  background-color: transparent;\n  background-clip: padding-box;\n  color: ', ';\n\n  padding: 7px 12px;\n  border: 1px solid ', ';\n  border-color: ', ';\n  border-radius: 3px;\n  height: 55px;\n  &:focus, &:-webkit-autofill {\n    ~ ', ' {\n      ', ';\n    }\n  }\n\n  &:disabled,\n  &[readonly] {\n    background-color: ', ';\n    opacity: 1;\n  }\n'], ['\n  display: block;\n  width: 100%;\n  font-size: 16px;\n  font-weight: ', ';\n  background-color: transparent;\n  background-clip: padding-box;\n  color: ', ';\n\n  padding: 7px 12px;\n  border: 1px solid ', ';\n  border-color: ', ';\n  border-radius: 3px;\n  height: 55px;\n  &:focus, &:-webkit-autofill {\n    ~ ', ' {\n      ', ';\n    }\n  }\n\n  &:disabled,\n  &[readonly] {\n    background-color: ', ';\n    opacity: 1;\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var TextInputWrap = exports.TextInputWrap = _styledComponents2.default.div(_templateObject);
var withValue = (0, _styledComponents.css)(_templateObject2, function (_ref) {
  var invalid = _ref.invalid,
      theme = _ref.theme;
  return invalid ? theme.errorColor : theme.textColor9D;
});
var TextInputPlaceholder = exports.TextInputPlaceholder = _styledComponents2.default.span(_templateObject3, function (_ref2) {
  var invalid = _ref2.invalid,
      theme = _ref2.theme;
  return invalid ? theme.errorColor : theme.textColor9D;
}, withValue, function (_ref3) {
  var hasValue = _ref3.hasValue;
  return hasValue ? withValue : '';
});

var TextInputControl = exports.TextInputControl = _styledComponents2.default.input(_templateObject4, function (_ref4) {
  var theme = _ref4.theme;
  return theme.fontWeightMedium;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.textColor69;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.textColorD8;
}, function (_ref7) {
  var invalid = _ref7.invalid,
      theme = _ref7.theme;
  return invalid ? theme.errorColor : theme.textColorD8;
}, TextInputPlaceholder, withValue, function (_ref8) {
  var theme = _ref8.theme;
  return theme.grayE6;
});