import React from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import { Button, Prompt } from 'shared';
import {
  Table,
  TR,
  TD,
  Input,
  ResizableInput,
  TH,
  Error,
} from './ReportDayTasksStyled';

const getHash = () => Math.random()
  .toString(36)
  .substring(7);

const addEmptyRows = (tasks) => {
  const rows = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= 5; i++) {
    if (tasks[i]) {
      rows.push(tasks[i]);
    } else {
      rows.push({
        pk: getHash(),
        description: null,
        department: null,
        hours: null,
      });
    }
  }
  return rows;
};

const filterInactiveTasks = (tasks) => tasks.filter((t) => {
  if (t.description || t.department || t.hours) {
    return true;
  }
  return false;
});

const ReportDayTasks = ({ tasks, meta, disabled }) => {
  const tasksRows = addEmptyRows(tasks);
  const onChange = (taskId, fieldName, value) => {
    const newTasks = tasksRows.map((task) => {
      if (taskId === task.pk) {
        if (fieldName === 'hours' && value < 0) {
          return {
            ...task,
            [fieldName]: 0,
          };
        }
        return {
          ...task,
          [fieldName]: value,
        };
      }
      return task;
    });
    const filteredTasks = filterInactiveTasks(newTasks);
    meta.setFieldValue('tasks', filteredTasks);
  };
  const onClear = (taskId) => {
    const newTasks = tasksRows.map((task) => {
      if (taskId === task.pk) {
        return {
          pk: task && task.pk ? task.pk : null,
          description: '',
          department: '',
          hours: '',
        };
      }
      return task;
    });
    const filteredTasks = filterInactiveTasks(newTasks);
    meta.setFieldValue('tasks', filteredTasks);
  };
  const isError = meta.errors.tasks
    && meta.touched.tasks
    && meta.touched.tasks.length === tasks.length;
  return (
    <>
      <Table>
        <thead>
          <tr>
            <TH>
              <Localize id="description" />
            </TH>
            <TH width={200}>
              <Localize id="department" />
            </TH>
            <TH width={100}>
              <Localize id="hours" />
            </TH>
            <TH />
          </tr>
        </thead>
        <tbody>
          {tasksRows.map((taskRow, index) => (
            <TR key={taskRow.pk}>
              <TD>
                <ResizableInput
                  disabled={disabled}
                  error={isError && tasks[index] && !tasks[index].description}
                  value={taskRow.description}
                  handleChange={(event) => onChange(taskRow.pk, 'description', event.target.value)}
                />
              </TD>
              <TD>
                <Input
                  disabled={disabled}
                  value={taskRow.department}
                  onChange={(event) => onChange(taskRow.pk, 'department', event.target.value)}
                />
              </TD>
              <TD width={60}>
                <Input
                  disabled={disabled}
                  type="number"
                  step="0.01"
                  value={parseFloat(taskRow.hours)}
                  onChange={(event) => onChange(taskRow.pk, 'hours', event.target.value)}
                />
              </TD>
              <TD width={80} height={45}>
                {tasks[index] && !disabled && (
                  <Prompt
                    confirm={() => onClear(taskRow.pk)}
                    trigger={(
                      <Button className="clear-button" type="button">
                        <Localize id="clear" />
                      </Button>
                    )}
                  >
                    <Localize id="are-you-sure-you-want-clear-entry" />
                  </Prompt>
                )}
              </TD>
            </TR>
          ))}
        </tbody>
      </Table>
      {isError && <Error>{meta.errors.tasks}</Error>}
    </>
  );
};

ReportDayTasks.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      pk: PropTypes.number.isRequired,
      description: PropTypes.string,
      department: PropTypes.string,
      hours: PropTypes.string,
    }),
  ),
  meta: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    errors: PropTypes.shape({
      tasks: PropTypes.string,
    }),
    touched: PropTypes.shape({
      tasks: PropTypes.array,
    }),
  }).isRequired,
  disabled: PropTypes.bool,
};

ReportDayTasks.defaultProps = {
  tasks: [],
  disabled: false,
};

export default ReportDayTasks;
