import styled from 'styled-components';
import { device } from 'styles/device';

export const TitleWrapper = styled.h3`
  color: #000;
  display: block;

  @media ${device.mobile} {
    line-height: 32px;
  }
`;
