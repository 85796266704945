'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  &::-webkit-scrollbar {\n    width: 5px;\n  }\n  /* Track */\n  &::-webkit-scrollbar-track {\n    background: #d0d0d0;\n  }\n\n  /* Handle */\n  &::-webkit-scrollbar-thumb {\n    background: ', ';\n  }\n\n  /* Handle on hover */\n  &::-webkit-scrollbar-thumb:hover {\n    background: ', ';\n  }\n'], ['\n  &::-webkit-scrollbar {\n    width: 5px;\n  }\n  /* Track */\n  &::-webkit-scrollbar-track {\n    background: #d0d0d0;\n  }\n\n  /* Handle */\n  &::-webkit-scrollbar-thumb {\n    background: ', ';\n  }\n\n  /* Handle on hover */\n  &::-webkit-scrollbar-thumb:hover {\n    background: ', ';\n  }\n']);

var _styledComponents = require('styled-components');

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

exports.default = (0, _styledComponents.css)(_templateObject, function (_ref) {
  var theme = _ref.theme;
  return theme.primary;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.primary;
});
module.exports = exports['default'];