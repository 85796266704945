import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Form,
  InputField,
  FileList,
  DatePicker,
  CitySelect,
} from 'shared';
import checkIcon from 'shared/lib/styles/images/check.svg';
import Localize from 'components/Localize';
import { roles } from 'utils/access-rules/constants';
import {
  ButtonsContainer,
  PeriodLabel,
  To,
  StyledDateSelect,
} from './ItemEditableFormStyled';

const ItemEditableForm = ({
  data,
  loading,
  onChange,
  uploadInternshipRequestAttachment,
  deleteInternshipRequestAttachment,
  onCancel,
  userRole,
  setInternshipLoading,
}) => {
  let validationSchema = {
    start: {
      lessDateThan: (values) => values.end,
    },
    company_phone: { phone: true },
    company_email: { email: true },
    company_address: {
      maxLength: 50,
    },
  };
  if (userRole === roles.Teacher) {
    validationSchema = {
      ...validationSchema,
      start: {
        ...validationSchema.start,
      },
    };
  }
  const onSubmit = (values) => {
    setInternshipLoading(true);
    const internshipRequest = {
      ...values,
      start: values.start ? values.start : undefined,
      end: values.end ? values.end : undefined,
      attachments: undefined,
    };
    return onChange(data.pk, internshipRequest);
  };

  const onFileAdd = (attachments) => {
    const filesToUpload = attachments.map((attachment) => {
      if (!attachment.pk) {
        return uploadInternshipRequestAttachment(
          data.pk,
          attachment.file.split(',').pop(),
        );
      }
      return null;
    });
    return Promise.all(filesToUpload);
  };

  const onFileDelete = (fileId) => deleteInternshipRequestAttachment(fileId);

  return (
    <div>
      <Form
        initialValues={{
          company_name: data.company_name,
          start: data.start,
          end: data.end,
          company_employee: data.company_employee,
          company_phone: data.company_phone,
          company_email: data.company_email,
          company_address: data.company_address,
          attachments: data.attachments,
        }}
        onSubmit={onSubmit}
        validateSchema={validationSchema}
      >
        {(form) => (
          <Localize>
            {({ translate }) => (
              <>
                <InputField
                  name="company_name"
                  placeholder={translate('company')}
                />
                <PeriodLabel>
                  <Localize id="period" />:
                </PeriodLabel>
                <StyledDateSelect>
                  <InputField
                    name="start"
                    component={DatePicker}
                    meta={form}
                    yearsInFuture={10}
                    yearsInPrevious={0}
                  />
                </StyledDateSelect>
                <To>
                  <Localize id="to" />
                </To>
                <StyledDateSelect>
                  <InputField
                    name="end"
                    component={DatePicker}
                    meta={form}
                    yearsInFuture={10}
                    yearsInPrevious={0}
                  />
                </StyledDateSelect>
                <InputField
                  name="company_employee"
                  placeholder={translate('contact-person')}
                />
                <InputField
                  meta={form}
                  name="company_phone"
                  placeholder={translate('company-phone')}
                />
                <InputField
                  name="company_email"
                  placeholder={translate('company-email')}
                />
                <InputField
                  name="company_address"
                  placeholder={translate('company-address')}
                  component={CitySelect}
                />
                <InputField
                  meta={form}
                  name="attachments"
                  fileListOnTop
                  onFileAdd={onFileAdd}
                  onFileDelete={onFileDelete}
                  component={FileList}
                />
                <ButtonsContainer>
                  <Button
                    loading={loading}
                    type="submit"
                    icon={checkIcon}
                    disableBoxShadow
                    color="secondary"
                    size="small"
                  >
                    <Localize id="save" />
                  </Button>
                  <Button
                    type="button"
                    disableBoxShadow
                    size="small"
                    color="primary"
                    className="btn-cancel"
                    onClick={onCancel}
                  >
                    <Localize id="cancel" />
                  </Button>
                </ButtonsContainer>
              </>
            )}
          </Localize>
        )}
      </Form>
    </div>
  );
};

ItemEditableForm.propTypes = {
  data: PropTypes.shape({
    pk: PropTypes.number,
    title: PropTypes.string,
    company_name: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    company_employee: PropTypes.string,
    company_phone: PropTypes.string,
    company_email: PropTypes.string,
    company_address: PropTypes.string,
    status: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  uploadInternshipRequestAttachment: PropTypes.func.isRequired,
  deleteInternshipRequestAttachment: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  setInternshipLoading: PropTypes.func.isRequired,
};

export default ItemEditableForm;
