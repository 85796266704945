'use strict';

exports.__esModule = true;
exports.PromptButtons = exports.PromptBody = exports.PromptWrap = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  padding: 20px;\n'], ['\n  padding: 20px;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  color: ', ';\n  font-size: 22px;\n  line-height: 28px;\n  display: flex;\n  align-items: center;\n'], ['\n  color: ', ';\n  font-size: 22px;\n  line-height: 28px;\n  display: flex;\n  align-items: center;\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  padding-top: 20px;\n  display: flex;\n  justify-content: flex-end;\n  button.prompt-button {\n    text-transform: capitalize;\n    margin-left: 10px;\n    width: 100px;\n  }\n'], ['\n  padding-top: 20px;\n  display: flex;\n  justify-content: flex-end;\n  button.prompt-button {\n    text-transform: capitalize;\n    margin-left: 10px;\n    width: 100px;\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var PromptWrap = exports.PromptWrap = _styledComponents2.default.div(_templateObject);

var PromptBody = exports.PromptBody = _styledComponents2.default.div(_templateObject2, function (_ref) {
  var theme = _ref.theme;
  return theme.textColor;
});

var PromptButtons = exports.PromptButtons = _styledComponents2.default.div(_templateObject3);