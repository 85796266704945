import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Localize from 'components/Localize';
import { roles } from 'utils/access-rules/constants';

import { LinkButton } from '../ManageStudentsStyled';

const OperationalLearningSelector = ({
  row,
  selectedInternship,
  currentUser,
  openActionModal,
}) => {
  let link = `/es/applicant-profile/${row.pk}`;
  if (row.esuser.internships[0]) link += `/${row.esuser.internships[0].pk}#olt`;
  if (
    selectedInternship[row.esuser.pk]
    && selectedInternship[row.esuser.pk].value
  ) {
    link = `/es/applicant-profile/${row.pk}/${
      selectedInternship[row.esuser.pk].value.pk
    }#olt`;
    if (
      selectedInternship[row.esuser.pk].value.olt_submited_and_company_commented
    ) {
      return (
        <a href={link}>
          <Localize id="submitted-company-commented" />
        </a>
      );
    }
    if (selectedInternship[row.esuser.pk].value.olt_submitted_at) {
      return (
        <a href={link}>
          <Localize
            id="submitted-on"
            data={{
              date: moment(
                selectedInternship[row.esuser.pk].value.olt_submitted_at,
              ).format('DD.MM.YYYY'),
            }}
          />
        </a>
      );
    }
    if (selectedInternship[row.esuser.pk].value.olt_report_changed) {
      if (currentUser.role !== roles.CompanyEmployee) {
        return (
          <a href={link}>
            <Localize
              id="saved-on"
              data={{
                date: moment(
                  selectedInternship[row.esuser.pk].value.olt_report_changed,
                ).format('DD.MM.YYYY'),
              }}
            />
          </a>
        );
      }
      return (
        <Localize
          id="saved-on"
          data={{
            date: moment(
              selectedInternship[row.esuser.pk].value.olt_report_changed,
            ).format('DD.MM.YYYY'),
          }}
        />
      );
    }
    if (selectedInternship[row.esuser.pk].value.olt_task_changed) {
      if (currentUser.role !== roles.CompanyEmployee) {
        return (
          <a href={link}>
            <Localize
              id="set-on"
              data={{
                date: moment(
                  selectedInternship[row.esuser.pk].value.olt_task_changed,
                ).format('DD.MM.YYYY'),
              }}
            />
          </a>
        );
      }
      return (
        <Localize
          id="set-on"
          data={{
            date: moment(
              selectedInternship[row.esuser.pk].value.olt_task_changed,
            ).format('DD.MM.YYYY'),
          }}
        />
      );
    }
    if (currentUser.role !== roles.CompanyEmployee) {
      return (
        <LinkButton onClick={openActionModal}>
          <Localize id="not-set-yet" />
        </LinkButton>
      );
    }
    return <Localize id="not-set-yet" />;
  }
  return null;
};

OperationalLearningSelector.propTypes = {
  row: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    esuser: PropTypes.shape({
      pk: PropTypes.number.isRequired,
      internships: PropTypes.array,
    }).isRequired,
  }).isRequired,
  selectedInternship: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  openActionModal: PropTypes.func.isRequired,
};

export default OperationalLearningSelector;
