import ServerApi from 'shared/lib/api/ServerApi';
import { getDefaultLanguage } from 'shared/lib/lib/languages';

class TagApi extends ServerApi {
  getTagList() {
    return super.get(`/tagging/tag/?language=${getDefaultLanguage()}`);
  }
}

export default new TagApi();
