import ServerApi from 'shared/lib/api/ServerApi';
import { getDefaultLanguage } from 'shared/lib/lib/languages';

class UsersApi extends ServerApi {
  getUsers(internshipId) {
    return super.get(
      `/internship/related-users/?internship_id=${internshipId}&language=${getDefaultLanguage()}`,
    );
  }
}

export default new UsersApi();
