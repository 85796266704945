'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Localize = require('../Localize');

var _Localize2 = _interopRequireDefault(_Localize);

var _upload = require('../../styles/images/upload.svg');

var _upload2 = _interopRequireDefault(_upload);

var _shared = require('shared');

var _DownloadUrlStyled = require('./DownloadUrlStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DownloadButton = function DownloadButton(_ref) {
  var url = _ref.url,
      children = _ref.children;
  return _react2.default.createElement(
    _DownloadUrlStyled.DownloadUrlContent,
    null,
    _react2.default.createElement(
      _shared.Button,
      {
        as: 'a',
        color: 'secondary',
        size: 'small',
        rightIcon: _upload2.default,
        href: url,
        download: true
      },
      children || _react2.default.createElement(_Localize2.default, { id: 'download' })
    )
  );
};

DownloadButton.propTypes = process.env.NODE_ENV !== "production" ? {
  url: _propTypes2.default.string.isRequired,
  children: _propTypes2.default.node
} : {};

DownloadButton.defaultProps = {
  children: undefined
};

exports.default = DownloadButton;
module.exports = exports['default'];