import styled from 'styled-components';

export const NoMessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

export const NoMessagesText = styled.p`
  margin-top: 40px;
  font-size: 24px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  color: ${({ theme }) => theme.primary};
`;
