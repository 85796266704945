import { Ability, AbilityBuilder } from '@casl/ability';
import { AES, enc } from 'crypto-js';
import routes from './rules/routes';
import writeTask from './rules/internshipTask';
import sections from './rules/sections';
import notifications from './rules/notifications';
import internshipRequest from './rules/internshipRequest';
import assessment from './rules/assessment';
import instructions from './rules/instructions';

export default () => {
  const encryptedRole = localStorage.getItem('current-user-role');
  const role = AES.decrypt(encryptedRole, 'ufo-key').toString(enc.Utf8);
  const { can, cannot, rules } = AbilityBuilder.extract();
  const _rules = routes(role, can, rules, cannot)
    .concat(writeTask(role, can, rules, cannot))
    .concat(notifications(role, can, rules, cannot))
    .concat(internshipRequest(role, can, rules, cannot))
    .concat(assessment(role, can, rules, cannot))
    .concat(instructions(role, can, rules, cannot))
    .concat(sections(role, can, rules, cannot));
  return new Ability(_rules);
};
