import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';

const dataReducer = (state = null, action) => {
  switch (action.type) {
    case successType(types.GET_MY_APPLICATIONS):
      return [...action.response];
    case types.LOGOUT:
      return null;
    default:
      return state;
  }
};

export default createReducer(types.GET_MY_APPLICATIONS, dataReducer);
