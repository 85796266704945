'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactFontawesome = require('@fortawesome/react-fontawesome');

var _faChevronDown = require('@fortawesome/free-solid-svg-icons/faChevronDown');

var _reactjsPopup = require('reactjs-popup');

var _reactjsPopup2 = _interopRequireDefault(_reactjsPopup);

var _Localize = require('../../Localize');

var _Localize2 = _interopRequireDefault(_Localize);

var _languages = require('../../../lib/languages');

var _LanguageSwitcherStyled = require('./LanguageSwitcherStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LanguageSwitcher = function LanguageSwitcher(_ref) {
  var languages = _ref.languages,
      setActiveLanguage = _ref.setActiveLanguage,
      updateIframe = _ref.updateIframe;

  var _useState = (0, _react.useState)(false),
      isPopupOpen = _useState[0],
      onPopupOpen = _useState[1];

  var onPopupOpenShow = function onPopupOpenShow() {
    return onPopupOpen(!isPopupOpen);
  };
  var onPopupOpenHide = function onPopupOpenHide() {
    return onPopupOpen(false);
  };
  var onSelectLanguage = function onSelectLanguage(code) {
    setActiveLanguage(code);
    (0, _languages.setDefaultLanguage)(code);
    onPopupOpenHide(false);
    updateIframe();
  };
  return _react2.default.createElement(
    _LanguageSwitcherStyled.LanguageSwitcherContainer,
    null,
    _react2.default.createElement(
      _LanguageSwitcherStyled.SwitcherTrigger,
      { onClick: onPopupOpenShow },
      _react2.default.createElement(_Localize2.default, { id: 'select-language' }),
      ' ',
      _react2.default.createElement(_reactFontawesome.FontAwesomeIcon, { icon: _faChevronDown.faChevronDown }),
      _react2.default.createElement(
        _reactjsPopup2.default,
        {
          onClose: onPopupOpenHide,
          open: isPopupOpen,
          position: 'bottom center',
          on: 'click',
          menu: true,
          closeOnDocumentClick: true,
          mouseLeaveDelay: 300,
          mouseEnterDelay: 0,
          arrow: false,
          contentStyle: { padding: 0, width: '100%' },
          overlayStyle: { position: 'relative', width: '100%', top: '10px' }
        },
        _react2.default.createElement(
          _LanguageSwitcherStyled.Languages,
          null,
          languages.map(function (language) {
            return _react2.default.createElement(
              'li',
              {
                key: language.code,
                onClick: function onClick() {
                  return onSelectLanguage(language.code);
                }
              },
              language.name
            );
          })
        )
      )
    )
  );
};

LanguageSwitcher.propTypes = process.env.NODE_ENV !== "production" ? {
  languages: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    code: _propTypes2.default.string.isRequired,
    name: _propTypes2.default.string.isRequired
  })).isRequired,
  setActiveLanguage: _propTypes2.default.func.isRequired,
  updateIframe: _propTypes2.default.func.isRequired
} : {};

exports.default = LanguageSwitcher;
module.exports = exports['default'];