export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const LOGOUT = 'LOGOUT';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
export const GET_NOT_READ_NOTIFICATIONS = 'GET_NOT_READ_NOTIFICATIONS';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const GET_NOTIFICATIONS_COUNT = 'GET_NOTIFICATIONS_COUNT';

export const SET_ADVISORS_DATA = 'SET_ADVISORS_DATA';

export const GET_CONTRACT = 'GET_CONTRACT';
export const UPLOAD_CONTRACT_FILE = 'UPLOAD_CONTRACT_FILE';
export const DELETE_CONTRACT_FILE = 'DELETE_CONTRACT_FILE';

export const SET_REPORT = 'SET_REPORT';
export const EDIT_REPORT_DAY = 'EDIT_REPORT_DAY';
export const EDIT_REPORT_CONCLUSION = 'EDIT_REPORT_CONCLUSION';
export const UPLOAD_DAY_ATTACHMENT = 'UPLOAD_DAY_ATTACHMENT';
export const SET_CURRENT_PHOTO_LIST = 'SET_CURRENT_PHOTO_LIST';
export const DELETE_DAY_ATTACHMENT = 'DELETE_DAY_ATTACHMENT';
export const ADD_DAY = 'ADD_DAY';
export const ADD_DAY_TASKS = 'ADD_DAY_TASKS';
export const EDIT_DAY_TASK = 'EDIT_DAY_TASK';
export const DELETE_DAY_TASK = 'DELETE_DAY_TASK';

export const GET_INTERNSHIPS_REQUESTS_LIST = 'GET_INTERNSHIPS_LIST';
export const ADD_INTERNSHIP_REQUEST = 'ADD_INTERNSHIP_REQUEST';
export const CHANGE_INTERNSHIP_REQUEST = 'CHANGE_INTERNSHIP';
export const DELETE_INTERNSHIP_REQUEST = 'DELETE_INTERNSHIP_REQUEST';
export const UPLOAD_INTERNSHIP_REQUEST_ATTACHMENT = 'UPLOAD_INTERNSHIP_REQUEST_ATTACHMENT';
export const DELETE_INTERNSHIP_REQUEST_ATTACHMENT = 'DELETE_INTERNSHIP_REQUEST_ATTACHMENT';

export const GET_INTERNSHIP = 'GET_INTERNSHIP';

export const GET_QUALIFICATION = 'GET_QUALIFICATION';
export const ADD_QUALIFICATION = 'ADD_QUALIFICATION';
export const EDIT_QUALIFICATION = 'EDIT_QUALIFICATION';
export const REMOVE_QUALIFICATION = 'REMOVE_QUALIFICATION';

export const GET_CALENDAR_EVENTS = 'GET_CALENDAR_EVENTS';
export const ADD_CALENDAR_EVENT = 'ADD_CALENDAR_EVENT';
export const DELETE_CALENDAR_EVENT = 'DELETE_CALENDAR_EVENT';
export const EDIT_CALENDAR_EVENT = 'EDIT_CALENDAR_EVENT';
export const EDIT_INVITEE_STATUS = 'EDIT_INVITEE_STATUS';

export const GET_SKILLS = 'GET_SKILLS';
export const GET_USER_SKILLS = 'GET_USER_SKILLS';
export const SET_USER_SKILLS = 'SET_USER_SKILLS';

export const GET_APPLICANT_STATE = 'GET_APPLICANT_STATE';
export const SET_SELECTED_INTERNSHIP = 'SET_SELECTED_INTERNSHIP';

export const GET_SPECIAL_TASKS = 'GET_SPECIAL_TASKS';
export const CREATE_SPECIAL_TASKS = 'CREATE_SPECIAL_TASKS';
export const UPDATE_SPECIAL_TASKS = 'UPDATE_SPECIAL_TASKS';
export const REMOVE_SPECIAL_TASKS = 'REMOVE_SPECIAL_TASKS';

export const GET_ATTACHMENTS = 'GET_ATTACHMENTS';
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';
export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT';

export const GET_MY_APPLICATIONS = 'GET_MY_APPLICATIONS';
export const GET_APPLICATION_STATUSES = 'GET_APPLICATION_STATUSES';

export const GET_ASSESSMENT = 'GET_ASSESSMENT';
export const GET_ASSESSMENT_ANSWER_CHOICES = 'GET_ASSESSMENT_ANSWER_CHOICES';
export const CHANGE_ASSESSMENT_ITEMS = 'CHANGE_ASSESSMENT_ITEMS';
export const CHANGE_ASSESSMENT = 'CHANGE_ASSESSMENT';

export const GET_KIND_LIST = 'GET_KIND_LIST';

export const GET_APPLICANT_LEARNING_TASK = 'GET_APPLICANT_LEARNING_TASK';
export const UPDATE_APPLICANT_LEARNING_TASK = 'UPDATE_APPLICANT_LEARNING_TASK';
export const DELETE_APPLICANT_LEARNING_TASK_ATTACHMENT = 'DELETE_APPLICANT_LEARNING_TASK_ATTACHMENT';

export const UPDATE_INTERNSHIP_LEARNING_TASK = 'UPDATE_INTERNSHIP_LEARNING_TASK';

export const UPDATE_USER = 'UPDATE_USER';
export const GET_USERS = 'GET_USERS';

export const GET_CHAT_USERS = 'GET_CHAT_USERS';
export const ADD_CHAT_MESSAGE = 'ADD_CHAT_MESSAGE';
export const ADD_CHAT_ROOM_MESSAGE = 'ADD_CHAT_ROOM_MESSAGE';
export const ADD_CHAT_MESSAGE_FROM_NOTIFICATION = 'ADD_CHAT_MESSAGE_FROM_NOTIFICATION';
export const GET_CHAT_MESSAGES = 'GET_CHAT_MESSAGES';
export const GET_CHAT_ROOMS = 'GET_CHAT_ROOMS';
export const MARK_MESSAGE_AS_READ = 'MARK_MESSAGE_AS_READ';
export const MARK_MESSAGE_AS_READ_FROM_NOTIFICATION = 'MARK_MESSAGE_AS_READ_FROM_NOTIFICATION';

export const GET_APPLICANTS = 'GET_APPLICANTS';
export const GET_APPLICANT = 'GET_APPLICANT';

export const GET_TAG_LIST = 'GET_TAG_LIST';

export const GET_VACANCY_LIST = 'GET_VACANCY_LIST';
export const ADD_APPLICATION = 'ADD_APPLICATION';
export const SET_IFRAME_SRC = 'SET_IFRAME_SRC';
export const REFRESH_IFRAME = 'REFRESH_IFRAME';

export const COMPLETE_PRESENTATION = 'COMPLETE_PRESENTATION';

export const GET_ENTITY_INSTRUCTION = 'GET_ENTITY_INSTRUCTION';
export const SET_ENTITY_INSTRUCTION_RATING = 'SET_ENTITY_INSTRUCTION_RATING';

export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const SET_FILTERS = 'SET_FILTERS';

export const GET_INTERNSHIP_COMMENT = 'GET_INTERNSHIP_COMMENT';
export const CHANGE_INTERNSHIP_COMMENT = 'CHANGE_INTERNSHIP_COMMENT';
