import React from 'react';
import PropTypes from 'prop-types';
import { UserAvatarWrapper } from './UserAvatarStyled';

function UserAvatar({ avatar, className }) {
  return <UserAvatarWrapper className={className} src={avatar} />;
}

UserAvatar.propTypes = {
  avatar: PropTypes.string,
  className: PropTypes.string,
};

UserAvatar.defaultProps = {
  avatar: undefined,
  className: undefined,
};

export default UserAvatar;
