import React from 'react';
import PropTypes from 'prop-types';
import progressIcon from 'styles/images/svg/status-progress.svg';
import commitmentIcon from 'styles/images/svg/status-commitment.svg';
import tryElseIcon from 'styles/images/svg/status-try-else.svg';
import ReactSVG from 'react-svg';
import { StatusText, StatusWrap } from './StatusStyled';
import StatusesProvider from './StatusesProvider';

const StatusIconMap = {
  ACCEPTED: commitmentIcon,
  UNDER_CONSIDERATION: progressIcon,
  TRY_AGAIN: tryElseIcon,
};

const Status = ({ name }) => (
  <StatusesProvider>
    {(statuses) => {
      const status = statuses.find(({ key }) => key === name);
      if (status) {
        return (
          <StatusWrap>
            <ReactSVG src={StatusIconMap[name]} />
            <StatusText>
              {status.value}
            </StatusText>
          </StatusWrap>
        );
      }
      return null;
    }}
  </StatusesProvider>
);

Status.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Status;
