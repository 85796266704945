export const groupByInternship = (data, internshipId) => {
  const _data = data.map((m) => ({
    ...m,
    internshipId: (m.internship && m.internship.pk) || internshipId,
  }));
  const groupedData = _data.reduce((objectsByKeyValue, obj) => {
    const _objectsByKeyValue = objectsByKeyValue;
    const value = obj.internshipId ? obj.internshipId : 'global';
    _objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});
  return {
    ...groupedData,
    all: data,
  };
};

export const addItemToInternship = (state, item) => {
  const { internship } = item;
  if (internship && internship.pk) {
    const existingState = state[internship.pk] || [];
    return {
      ...state,
      [internship.pk]: [item, ...existingState],
    };
  }
  const existingState = state.global || [];
  return {
    ...state,
    global: [item, ...existingState],
  };
};

export const setGroupedValues = (state, grouped, prop) => {
  const data = {};
  Object.keys(grouped).forEach((internship) => {
    const currentState = state[internship];
    data[internship] = { ...currentState, [prop]: grouped[internship] };
  });
  return data;
};
