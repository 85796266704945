'use strict';

exports.__esModule = true;
exports.CollapseHeaderElement = exports.CollapsePreview = exports.CollapseHeaderText = exports.CollapseHeaderIcon = exports.CollapseHeaderIconWrap = exports.CollapseHeader = exports.CollapseWrapper = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  margin-top: 40px;\n  font-size: 16px;\n  color: ', ';\n\n  & .ReactCollapse--collapse {\n    transition: height 0.2s ease;\n  }\n'], ['\n  margin-top: 40px;\n  font-size: 16px;\n  color: ', ';\n\n  & .ReactCollapse--collapse {\n    transition: height 0.2s ease;\n  }\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  position: relative;\n  padding: 4px;\n  //padding-left: ', ';\n  font-size: 16px;\n  font-weight: ', ';\n  color: ', ';\n  text-transform: uppercase;\n  border-bottom: 2px solid ', ';\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  @media ', ' {\n    font-size: 14px;\n  }\n'], ['\n  position: relative;\n  padding: 4px;\n  //padding-left: ', ';\n  font-size: 16px;\n  font-weight: ', ';\n  color: ', ';\n  text-transform: uppercase;\n  border-bottom: 2px solid ', ';\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  @media ', ' {\n    font-size: 14px;\n  }\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  position: absolute;\n  left: 19px;\n  top: 50%;\n  transform: translateY(-50%);\n'], ['\n  position: absolute;\n  left: 19px;\n  top: 50%;\n  transform: translateY(-50%);\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  position: relative;\n  width: 14px;\n  height: 14px;\n\n  &::before,\n  &::after {\n    content: \'\';\n    position: absolute;\n    top: 50%;\n    display: block;\n    width: 100%;\n    height: 2px;\n    background-color: ', ';\n    border-radius: 2px;\n    opacity: 1;\n    transition: transform 0.25s, opacity 0.3s ease;\n  }\n\n  &::after {\n    transform: rotate(90deg);\n    ', ';\n  }\n'], ['\n  position: relative;\n  width: 14px;\n  height: 14px;\n\n  &::before,\n  &::after {\n    content: \'\';\n    position: absolute;\n    top: 50%;\n    display: block;\n    width: 100%;\n    height: 2px;\n    background-color: ', ';\n    border-radius: 2px;\n    opacity: 1;\n    transition: transform 0.25s, opacity 0.3s ease;\n  }\n\n  &::after {\n    transform: rotate(90deg);\n    ', ';\n  }\n']),
    _templateObject5 = _taggedTemplateLiteralLoose([''], ['']),
    _templateObject6 = _taggedTemplateLiteralLoose(['\n  margin-top: 12px;\n  margin-bottom: 20px;\n  @media ', ' {\n    font-size: 12px;\n  }\n'], ['\n  margin-top: 12px;\n  margin-bottom: 20px;\n  @media ', ' {\n    font-size: 12px;\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _device = require('../../styles/device');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var CollapseWrapper = exports.CollapseWrapper = _styledComponents2.default.div(_templateObject, function (_ref) {
  var theme = _ref.theme;
  return theme.textColor;
});

var CollapseHeader = exports.CollapseHeader = _styledComponents2.default.div(_templateObject2, function (_ref2) {
  var onlyOpen = _ref2.onlyOpen;
  return onlyOpen ? '0' : '60px';
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.fontWeightBold;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.secondary;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.secondary;
}, _device.device.mobile);

var CollapseHeaderIconWrap = exports.CollapseHeaderIconWrap = _styledComponents2.default.div(_templateObject3);

var CollapseHeaderIcon = exports.CollapseHeaderIcon = _styledComponents2.default.div(_templateObject4, function (_ref6) {
  var theme = _ref6.theme;
  return theme.grayA0;
}, function (_ref7) {
  var isOpened = _ref7.isOpened;
  return isOpened && 'transform: rotate(90deg) scale(0); opacity: 0;';
});

var CollapseHeaderText = exports.CollapseHeaderText = _styledComponents2.default.div(_templateObject5);

var CollapsePreview = exports.CollapsePreview = _styledComponents2.default.div(_templateObject6, _device.device.mobile);

var CollapseHeaderElement = exports.CollapseHeaderElement = _styledComponents2.default.div(_templateObject5);