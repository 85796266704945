import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Info } from 'shared';
import Localize from 'components/Localize';

const InternshipSelectionProvider = ({ selectedInternshipId, children }) => {
  if (selectedInternshipId) {
    return children({ selectedInternshipId });
  }
  return (
    <Info>
      <Localize id="you-havent-had-any-active-internship-yet" />
    </Info>
  );
};

InternshipSelectionProvider.propTypes = {
  children: PropTypes.func.isRequired,
  selectedInternshipId: PropTypes.number,
};

InternshipSelectionProvider.defaultProps = {
  selectedInternshipId: null,
};

const mapStateToProps = ({ selectedInternship }) => ({
  selectedInternshipId: selectedInternship && selectedInternship.pk,
});

export default connect(mapStateToProps)(InternshipSelectionProvider);
