import styled, { css } from 'styled-components';
import { ReactComponent as UncheckIcon } from 'styles/images/svg/rectangle.svg';
import { ReactComponent as CheckIcon } from 'styles/images/svg/rectangle-checked.svg';

const CheckBoxIcon = css`
  margin-right: 20px;
  flex-shrink: 0;
  rect {
    stroke: ${({ disabled, theme }) => disabled ? theme.grayA0 : theme.secondary};
  }
  path {
    fill: ${({ disabled, theme }) => (disabled ? theme.grayA0 : 'inherited')};
  }
`;
export const CheckBoxIconUnchecked = styled(UncheckIcon)`
  ${CheckBoxIcon}
`;
export const CheckBoxIconChecked = styled(CheckIcon)`
  ${CheckBoxIcon}
`;

export const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  color: ${({ disabled, theme }) => (disabled ? theme.grayA0 : 'initial')};

  input[type='checkbox'],
  ${CheckBoxIconChecked} {
    display: none;
  }
  input[type='checkbox']:checked ~ ${CheckBoxIconChecked} {
    display: inline-block;
  }
  input[type='checkbox']:checked ~ ${CheckBoxIconUnchecked} {
    display: none;
  }
  svg {
    margin: ${({ size }) => size === 'small' && '0px'};
    height: ${({ size }) => size === 'small' && '20px'};
    width: ${({ size }) => size === 'small' && '20px'};
  }
  svg:hover {
    cursor: pointer;
  }
`;
