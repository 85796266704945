import styled from 'styled-components';
import { device } from 'styles/device';
import emoji1 from 'styles/images/emoji1.png';
import emoji2 from 'styles/images/emoji2.png';
import emoji3 from 'styles/images/emoji3.png';
import emoji4 from 'styles/images/emoji4.png';
import emoji5 from 'styles/images/emoji5.png';
import emoji1grey from 'styles/images/emoji1-grey.png';
import emoji2grey from 'styles/images/emoji2-grey.png';
import emoji3grey from 'styles/images/emoji3-grey.png';
import emoji4grey from 'styles/images/emoji4-grey.png';
import emoji5grey from 'styles/images/emoji5-grey.png';
import whitePlay from 'styles/images/svg/white-play.svg';
import whitePause from 'styles/images/svg/white-pause.svg';
import Modal from '../Modal';

export const InfoModal = styled(Modal)`
  .information-modal-content {
    width: 1000px !important;
    height: 550px;
    overflow: hidden;
    padding: 30px !important;
    @media ${device.mobile} {
      width: 100% !important;
      padding: 15px !important;
      height: auto;
      overflow: auto;
    }
  }
`;

export const InformationTitle = styled.div`
  display: flex;
  position: relative;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  color: ${({ theme }) => theme.secondary};
  margin: 20px 0;
`;

export const InformationRow = styled.div`
  display: flex;
  height: 380px;
  margin-bottom: 20px;
  @media ${device.mobile} {
    height: 100%;
    flex-direction: column;
  }
`;

export const InformationVideoColumn = styled.div`
  flex: auto;
`;

export const InformationVideoContainer = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  .video-js {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: black;
    border-radius: 3px;

    video {
      border-radius: 3px;
    }
    .vjs-big-play-button,
    .vjs-subs-caps-button {
      display: none;
    }
    .vjs-load-progress div {
      background: white;
    }
    &.vjs-has-started {
      .vjs-play-control {
        position: absolute;
        top: 25px;
        margin: 0 !important;
        left: 16px;
        width: 21px !important;
        height: 26px !important;
      }
      .vjs-progress-control {
        display: flex !important;
        font-size: 24px;
      }
      .vjs-current-time {
        position: absolute;
        width: 100%;
        top: 5px;
        line-height: 3em !important;
      }
      .vjs-play-progress {
        border-radius: 10px;
      }
      .vjs-play-progress::before {
        color: ${({ theme }) => theme.secondary};
      }
      .vjs-fullscreen-control {
        display: flex !important;
        position: absolute;
        right: 0;
        top: 18px;
        .vjs-icon-placeholder::before {
          font-size: 40px;
          line-height: 1;
        }
      }
      .vjs-time-tooltip {
        font-size: 16px !important;
      }
      .vjs-load-progress {
        background-color: white;
        width: 100% !important;
        border-radius: 10px;
        > div {
          height: 6px;
          border-radius: 6px;
          width: 100% !important;
        }
      }
      .vjs-mouse-display {
        font-size: 16px;
      }
      .vjs-control-bar {
        background: transparent !important;
        width: auto !important;
        margin: 65px 20px;
        .vjs-current-time {
          display: block;
        }
        .vjs-duration {
          display: none;
        }
      }
      .vjs-slider-bar {
        background: ${({ theme }) => theme.secondary};
      }
      .vjs-volume-control {
        margin-left: -10px;
      }
      .vjs-volume-panel {
        display: flex !important;
        position: absolute;
        top: 22px;
        left: 50px;
        font-size: 16px;
        align-items: center;
        height: 30px;
        .vjs-icon-placeholder::before {
          font-size: 32px;
          line-height: 1;
        }
      }
      .vjs-volume-bar {
        &.vjs-slider {
          background: white;
        }
      }
      .vjs-volume-level {
        &::before {
          color: ${({ theme }) => theme.secondary};
        }
        background-color: ${({ theme }) => theme.secondary};
      }
      &.vjs-user-inactive.vjs-playing .vjs-control-bar {
        margin: 25px 20px !important;
      }

      &.vjs-user-inactive.vjs-playing
        .vjs-control-bar
        > :not(.vjs-progress-control) {
        visibility: visible;
        opacity: 0;
        transition: visibility 1s, opacity 1s;
      }
      &.vjs-user-active.vjs-playing
        .vjs-control-bar
        > :not(.vjs-progress-control) {
        visibility: visible;
        opacity: 1;
        transition: visibility 1s, opacity 1s;
      }
    }
    .vjs-control-bar {
      font-weight: bold;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      margin: 20px;
      width: 120px;
      height: auto;
      background: ${({ theme }) => theme.secondary};
      border-radius: 10px;
      cursor: pointer;
      .vjs-volume-panel,
      .vjs-progress-control,
      .vjs-fullscreen-control {
        display: none;
      }
      .vjs-paused,
      .vjs-playing {
        &:focus {
          outline: none;
        }
      }
      .vjs-paused,
      .vjs-play-control {
        background-image: url(${whitePlay});
        background-repeat: no-repeat;
        background-size: cover;
        width: 25px;
        height: 31px;
        margin: 10px 0 10px 10px;
        border-radius: 0;
        .vjs-icon-placeholder {
          display: none;
        }
      }
      .vjs-playing {
        background-image: url(${whitePause});
        background-repeat: no-repeat;
        background-size: cover;
        width: 25px;
        height: 31px;
        margin: 10px 0 10px 10px;
        border-radius: 0;
        font-size: 3em;
        .vjs-icon-placeholder {
          display: none;
        }
        .vjs-icon-placeholder::before {
          margin-top: -25px;
          margin-left: -10px;
        }
      }
      .vjs-paused {
        &.vjs-ended {
          background-image: none;
          .vjs-icon-placeholder {
            display: block;
          }
          .vjs-icon-placeholder::before {
            font-size: 3em;
            line-height: 0.8;
          }
        }
      }
      .vjs-current-time,
      .vjs-duration {
        display: block;
        line-height: 0;
        font-size: 22px;
      }
      .vjs-current-time {
        display: none;
      }
      .vjs-remaining-time,
      .vjs-time-divider {
        display: none !important;
      }
    }
  }
`;

export const InformationContent = styled.div`
  width: 320px;
  height: 100%;
  overflow: auto;
  margin-left: 30px;
  @media ${device.mobile} {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
`;

export const InformationFeedback = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  color: ${({ theme }) => theme.secondary};
  @media ${device.mobile} {
    flex-direction: column;
    justify-content: center;
  }
`;

export const RatingEmojis = styled.div`
  display: flex;
  div {
    height: 30px;
    width: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 8px;
    cursor: pointer;
  }
  @media ${device.mobile} {
    margin: 20px 0;
  }
  .emoji1 {
    background-image: url(${emoji1grey});
    &:hover {
      background-image: url(${emoji1});
    }
    &.selected {
      background-image: url(${emoji1});
    }
  }
  .emoji2 {
    background-image: url(${emoji2grey});
    &:hover {
      background-image: url(${emoji2});
    }
    &.selected {
      background-image: url(${emoji2});
    }
  }
  .emoji3 {
    background-image: url(${emoji3grey});
    &:hover {
      background-image: url(${emoji3});
    }
    &.selected {
      background-image: url(${emoji3});
    }
  }
  .emoji4 {
    background-image: url(${emoji4grey});
    &:hover {
      background-image: url(${emoji4});
    }
    &.selected {
      background-image: url(${emoji4});
    }
  }
  .emoji5 {
    background-image: url(${emoji5grey});
    &:hover {
      background-image: url(${emoji5});
    }
    &.selected {
      background-image: url(${emoji5});
    }
  }
`;
