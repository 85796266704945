'use strict';

exports.__esModule = true;

var _DownloadUrl = require('./DownloadUrl');

Object.defineProperty(exports, 'default', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_DownloadUrl).default;
  }
});

var _DownloadButton = require('./DownloadButton');

Object.defineProperty(exports, 'DownloadButton', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_DownloadButton).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }