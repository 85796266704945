import ServerApi from 'shared/lib/api/ServerApi';

class AuthApi extends ServerApi {
  getAuthUser() {
    return super.get('/internship/esuser/');
  }

  login(email, password) {
    return super.post('/internship/login/', {
      email,
      password,
    });
  }

  forgotPassword(email) {
    return super.postWithCustomUrl(
      'https://admin.iba-digital.de/de/api/password_reset/',
      {
        email,
      },
    );
  }

  logout() {
    return super.post('/auth/logout/', undefined, false);
  }
}

export default new AuthApi();
