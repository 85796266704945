import React from 'react';
import PropTypes from 'prop-types';
import { SecondaryButton } from 'shared';
import Localize from 'components/Localize';
import arrowRightIcon from 'styles/images/svg/arrow-right.svg';
import { Link } from 'react-router-dom';
import {
  MessageWrap,
  MessageItem,
  MessageTitle,
  MessageButtonGroup,
  MessageButton,
  MessageButtonText,
} from './MessageStyled';

const Message = ({
  onClose,
  internshipRequest,
  handleVacancyList,
  createProfile,
}) => (
  <MessageWrap>
    <MessageTitle>
      <Localize id="what-would-you-do-proceed" />
    </MessageTitle>
    <MessageItem>
      {internshipRequest.some(
        (internship) => internship.company_name
          && internship.start
          && internship.end
          && internship.company_employee,
      ) ? (
        <Localize id="you-should-be-patient-until-internship-accepted" />
        ) : (
          <Localize id="then-fill-information-about-your-contract" />
        )}
    </MessageItem>
    <MessageItem>
      <Localize id="then-next-steps-will-be-released-here-for-you" />
    </MessageItem>
    <MessageButtonGroup>
      <MessageButton>
        <SecondaryButton
          rightIcon={arrowRightIcon}
          size="small"
          as={Link}
          to="/settings"
          onClick={onClose}
          width="100%"
        >
          <MessageButtonText>
            <Localize id="already-found" />
          </MessageButtonText>
        </SecondaryButton>
      </MessageButton>
      <MessageButton>
        <SecondaryButton
          rightIcon={arrowRightIcon}
          size="small"
          onClick={handleVacancyList}
          width="100%"
        >
          <MessageButtonText>
            <Localize id="search-for-internships" />
          </MessageButtonText>
        </SecondaryButton>
      </MessageButton>
      <MessageButton>
        <SecondaryButton
          rightIcon={arrowRightIcon}
          size="small"
          onClick={createProfile}
          width="100%"
        >
          <MessageButtonText>
            <Localize id="create-profile" />
          </MessageButtonText>
        </SecondaryButton>
      </MessageButton>
    </MessageButtonGroup>
  </MessageWrap>
);

Message.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleVacancyList: PropTypes.func.isRequired,
  createProfile: PropTypes.func.isRequired,
  internshipRequest: PropTypes.array.isRequired,
  // prop: PropTypes.array.isRequired
};

export default Message;
