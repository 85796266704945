import React from 'react';
import PropTypes from 'prop-types';
import { LocalizedDate } from 'pages/LocalizeProvider';

function WeekDay({ weekNumber, date }) {
  return (
    <LocalizedDate date={date}>
      {(moment) => moment.day(weekNumber).format('dd')}
    </LocalizedDate>
  );
}

WeekDay.propTypes = {
  weekNumber: PropTypes.number.isRequired,
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
};

export default WeekDay;
