import styled from 'styled-components';

export const VacancyListWrapper = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    height: 100%;
    width: 100%;
  }
`;
