import styled from 'styled-components';
import { device } from 'styles/device';

export const VacancyWrap = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  cursor: pointer;

  @media ${device.mobile} {
    display: block;
  }
`;

export const VacancyLogo = styled.div`
  padding: 10px;
  border-color: #e6e6e6;
  border-style: solid;
  border-right-width: 1px;

  display: flex;
  align-items: center;
  width: 116px;
  flex-shrink: 0;

  > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  @media ${device.mobile} {
    width: 103px;
    height: 103px;
    border-bottom-width: 1px;
    float: left;
    margin-right: 10px;
    margin-bottom: 5px;
  }
`;

export const VacancyContent = styled.div`
  //margin: 1rem;
  padding: 15px 30px;
  font-family: Montserrat-Medium, sans-serif;

  @media ${device.tablet} {
    font-size: 14px;
  }
  @media ${device.mobile} {
    padding: 12px;
  }

  h3 {
    font-size: 24px;
    margin-bottom: 12px;

    + ul {
      margin-bottom: 0;
      @media ${device.tablet} {
        margin-bottom: 5px;
      }
    }

    @media ${device.tablet} {
      font-size: 22px;
    }
    @media ${device.mobile} {
      min-height: 100px;
      //padding: 18px;
    }
  }
`;

export const ApplyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  padding-right: 40px;
  @media ${device.tablet} {
    padding-right: 20px;
  }
  @media ${device.mobile} {
    display: flex;
    padding: 12px;
  }
`;

export const VacancyLogoLetters = styled.span`
  color: #fff;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.secondaryLight};
  font-size: 55px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeightUpperBold};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
