import styled from 'styled-components';

export const Container = styled.div`
  max-width: 400px;
  margin: auto;
  width: 100%;
  padding: 80px 0;
`;

export const Tip = styled.div`
  margin-bottom: 20px;
`;
