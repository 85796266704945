import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { device } from 'styles/device';
import { Field } from 'formik';
import { REPORT_ITEM_TYPES } from '../Report';

export const ReportItemContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.grayE6};
  border-radius: ${({ theme }) => theme.borderRadiusDefault};
  width: 100%;
  margin: 10px 0px;
  &:hover {
    background-color: ${({ theme, isEditable }) => !isEditable ? theme.grayF3 : theme.white};
  }
`;

export const ReportItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 18px 22px;
  .Dropdown-root {
    .Dropdown-control {
      padding: 8px 25px 4px 12px;
      .Dropdown-arrow-wrapper {
        top: 10px;
      }
    }
  }
  ${({ isEditable, theme }) => isEditable
    && `border-bottom: 2px solid ${theme.secondary}; padding-bottom: 5px;`}
  ${({ isEditable, type }) => !isEditable
    && type === REPORT_ITEM_TYPES.ADD_DAY
    && 'flex-direction: row-reverse; justify-content: flex-end;'}
`;

export const DataStatus = styled.div`
  display: flex;
  align-items: center;
  .icon {
    margin-left: 30px;
  }
`;

const reportContentEditableStyles = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .date {
    margin-left: 20px;
  }
`;

export const ReportContent = styled.div`
  .name {
    color: ${({ theme }) => theme.secondary};
    font-size: 18px;
    font-weight: ${({ theme }) => theme.fontWeightBold};
    ${({ isEditable }) => isEditable && 'margin-right: 20px;'}
    ${({ isEditable, type }) => !isEditable && type === REPORT_ITEM_TYPES.ADD_DAY && 'margin-left: 15px;'}
  }
  .date {
    color: ${({ theme }) => theme.textColor};
    font-size: 14px;
  }
  ${({ isEditable }) => isEditable && reportContentEditableStyles}
`;

export const ReportEditableHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const NoInternshipCheckBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  @media ${device.mobile} {
    margin-top: 10px;
    margin-left: 0px;
  }
  div:first-of-type {
    margin-bottom: 0px;
  }
`;

export const ItemStatus = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: ${({ theme }) => theme.white};
    font-size: 12px;
  }
  background-color: ${({ isActive, theme }) => isActive ? theme.green : theme.grayE6};
`;

export const ChevronIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  ${({ iseditable }) => iseditable && 'transform: rotate(180deg);'}
`;

export const EditReportItemContainer = styled.div`
  padding: 18px 22px;
  [type='file'] {
    display: none;
  }
`;

export const EditReportItemHead = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.secondary};
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
  .name {
    font-size: 18px;
    color: ${({ theme }) => theme.secondary};
    font-weight: ${({ theme }) => theme.fontWeightBold};
  }
`;

export const EditableDateInput = styled(Field)`
  font-size: 14px;
  color: ${({ theme }) => theme.textColor};
  border: none;
  text-align: right;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 40px;
  .btn-add {
    margin-right: 20px;
  }
  margin-left: 30px;
  @media ${device.mobile} {
    margin-left: 0px;
    flex-wrap: wrap;
    .btn-add {
      width: 100%;
      margin: 10px 0px 20px;
    }
    .btn-save {
      width: 100%;
    }
  }
`;

export const Description = styled(Field)`
  width: 100%;
  margin-top: 20px;
  height: 100px;
  border: none;
  resize: none;
  ::placeholder {
    color: ${({ theme }) => theme.textColor};
    font-weight: ${({ theme }) => theme.fontWeightMedium};
  }
  background-color: ${({ disabled }) => disabled && '#fff'};
`;
