import styled from 'styled-components';

export const ModalContent = styled.div`
  background: ${({ theme }) => theme.white};
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .avatar {
    cursor: pointer;
  }
`;

export const ErrorText = styled.div`
  margin-top: 30px;
  color: ${({ theme }) => theme.errorColor};
`;
