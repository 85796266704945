import styled from 'styled-components';

export const MessagesWrapper = styled.ul`
  padding: 30px;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  font-style: italic;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;
