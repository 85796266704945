import React from 'react';
import PropTypes from 'prop-types';
import { NotificationCircleBadge } from './CircleBadgeStyled';

function CircleBadge({ className }) {
  return <NotificationCircleBadge className={className} />;
}

CircleBadge.propTypes = {
  className: PropTypes.string,
};

CircleBadge.defaultProps = {
  className: undefined,
};

export default CircleBadge;
