import React from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import calendarIcon from 'styles/images/svg/calendar.svg';
import chatIcon from 'styles/images/svg/chat.svg';
import noMessage from 'styles/images/svg/no-message.svg';
import noEvents from 'styles/images/svg/noEvents.svg';
import bewirbIcon from 'styles/images/svg/bewirb.svg';
import { connect } from 'react-redux';
import { filterByInternship } from 'reducers/reducersUtils';
import { KIND } from 'api/NotificationApi';
import { Block, BlockIcon, Blocks } from './BottomLinksStyled';

const BottomLinks = ({ notifications, messages, selectedInternship }) => {
  const unCheckedChatNotification = messages.find((m) => !m.read_at);
  const unCheckedCalendarNotification = notifications.find(
    (n) => !n.read_at && n.kind === KIND.CALENDAR_EVENT,
  );
  const handleScroll = () => {
    document.body.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <Blocks>
      <Block
        onClick={handleScroll}
        to={`/calendar/${selectedInternship || ''}`}
      >
        {unCheckedCalendarNotification ? (
          <BlockIcon src={calendarIcon} />
        ) : (
          <BlockIcon src={noEvents} />
        )}
        <Localize id="dates-absences" />
      </Block>
      <Block onClick={handleScroll} to={`/chat/${selectedInternship || ''}`}>
        {unCheckedChatNotification ? (
          <BlockIcon src={chatIcon} />
        ) : (
          <BlockIcon src={noMessage} />
        )}
        <Localize id="messages" />
      </Block>
      <Block
        onClick={handleScroll}
        to={`/your-applications/${selectedInternship || ''}`}
      >
        <BlockIcon src={bewirbIcon} />
        <Localize id="your-applications" />
      </Block>
    </Blocks>
  );
};

BottomLinks.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedInternship: PropTypes.number,
};

BottomLinks.defaultProps = {
  selectedInternship: null,
};

const mapStateToProps = ({ notifications, selectedInternship, messages }) => ({
  notifications: filterByInternship(
    selectedInternship && selectedInternship.pk,
    notifications.data,
  ),
  messages: filterByInternship(
    selectedInternship && selectedInternship.pk,
    messages.data,
  ),
  selectedInternship: selectedInternship && selectedInternship.pk,
});

export default connect(mapStateToProps)(BottomLinks);
