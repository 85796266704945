'use strict';

exports.__esModule = true;
exports.FileContainer = exports.FilesListContainer = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  display: flex;\n  flex-wrap: wrap;\n  @media ', ' {\n    .document-thumb {\n      width: 120px;\n      height: 120px;\n    }\n  }\n  @media ', ' {\n    justify-content: center;\n    .document-thumb {\n      width: 100px;\n      height: 100px;\n    }\n  }\n'], ['\n  display: flex;\n  flex-wrap: wrap;\n  @media ', ' {\n    .document-thumb {\n      width: 120px;\n      height: 120px;\n    }\n  }\n  @media ', ' {\n    justify-content: center;\n    .document-thumb {\n      width: 100px;\n      height: 100px;\n    }\n  }\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  margin: 15px;\n'], ['\n  margin: 15px;\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _device = require('../../../styles/device');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var FilesListContainer = exports.FilesListContainer = _styledComponents2.default.div(_templateObject, _device.device.tablet, _device.device.mobile);

var FileContainer = exports.FileContainer = _styledComponents2.default.div(_templateObject2);