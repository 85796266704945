import * as types from './types';

export function setFilters(filters) {
  return (dispatch) => {
    dispatch({
      type: types.SET_FILTERS,
      ...filters,
    });
  };
}

export function clearFilters() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_FILTERS,
    });
  };
}
