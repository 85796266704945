import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import { WHO_SEE_OPTIONS } from 'utils/calendarHelpers';
import { roles } from 'utils/access-rules/constants';
import { WhoSeeSelectWrapper, WhoSeeSelect, ToolTip } from '../EventFormStyled';

const WhoSeeSelectComponent = ({
  name,
  meta,
  isStudentProfile,
  currentUser,
}) => {
  const whoSeeOptions = currentUser.role === roles.Applicant
    ? WHO_SEE_OPTIONS.filter((option) => option.isStudent)
    : WHO_SEE_OPTIONS.filter((option) => isStudentProfile ? option.isStudentProfile : option.isTeacherProfile);
  const onChange = (data) => {
    meta.setFieldValue(name, data.value);
  };
  return (
    <Localize>
      {({ translate }) => (
        <WhoSeeSelectWrapper>
          <ToolTip title={translate('personal-meeting')} theme="light" arrow>
            <WhoSeeSelect
              isActive={meta.values.visibility}
              options={whoSeeOptions}
              name="visibility"
              value={whoSeeOptions.find(
                (option) => option.value === meta.values.visibility,
              )}
              onChange={onChange}
              placeholder={translate('select')}
            />
          </ToolTip>
        </WhoSeeSelectWrapper>
      )}
    </Localize>
  );
};

WhoSeeSelectComponent.propTypes = {
  meta: PropTypes.shape({
    values: PropTypes.shape({
      visibility: PropTypes.oneOf(Object.values(WHO_SEE_OPTIONS)),
    }),
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  isStudentProfile: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ currentUser: { data } }) => ({
  currentUser: data,
});

export default connect(mapStateToProps)(WhoSeeSelectComponent);
