import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Error } from 'shared';
import { getApplicant, getApplicants } from 'actions/internshipsActions';
import Page from 'components/Page/Page';
import Paragraph from 'components/Paragraph';
import { ActiveLanguageProvider } from 'pages/LocalizeProvider';
import { PrimaryUserData } from '../ApplicantProfileStyled';
import LoadingInternshipPage from '../LoadingInternshipPage';
import ApplicantInternshipSwitcher from '../ApplicantInternshipSwitcher';
import ApplicantProfile from '../ApplicantProfile';
import InternshipInfo from '../InternshipInfo';

const ApplicantProfileContainer = ({
  loading,
  error,
  applicant,
  getApplicant,
  getApplicants,
  applicantId,
  internshipId,
  currentUser,
  history,
}) => {
  const [loader, setLoader] = useState(true);
  const stableGetApplicant = useCallback(getApplicant, [applicantId]);
  useEffect(() => {
    if (!applicant || applicantId !== applicant.pk) {
      stableGetApplicant(applicantId);
    }
  }, [stableGetApplicant, applicantId, applicant]);
  useEffect(() => {
    if (applicant && applicant.pk === applicantId) {
      setLoader(false);
    }
  }, [applicant, applicantId]);
  if (loading || !applicant || loader) return <LoadingInternshipPage />;
  if (error) {
    return (
      <Page withJobAdvisor={false}>
        <Error />
      </Page>
    );
  }
  const onInternshipChange = (selectedInternshipId) => {
    if (selectedInternshipId !== 'no-internship') {
      history.push(`/applicant-profile/${applicantId}/${selectedInternshipId}`);
    } else {
      history.push(`/applicant-profile/${applicantId}/`);
    }
  };
  const { esuser } = applicant;
  return (
    <Page withJobAdvisor={false} pageIsLoading={false}>
      <Paragraph
        className="applicant-profile-main-paragraph"
        withTopMargin
        title={`${esuser.user.first_name} ${esuser.user.last_name}`}
        rightText={(
          <ApplicantInternshipSwitcher
            applicant={applicant}
            onInternshipChange={onInternshipChange}
            selectedInternshipId={internshipId}
            userRole={currentUser.role}
          />
        )}
      />
      <InternshipInfo
        selectedInternshipId={internshipId}
        padding="0px 10px 20px 10px"
      />
      <PrimaryUserData
        noTitle
        avatar={esuser.avatar}
        userFirstName={esuser.user.first_name}
        userLastName={esuser.user.last_name}
        birthday={esuser.birthday}
        phone={esuser.phone && esuser.phone.number}
        email={esuser.user.email}
        location={esuser.location}
      />
      <ActiveLanguageProvider>
        {({ code }) => (
          <ApplicantProfile
            applicant={applicant}
            selectedInternshipId={internshipId}
            key={internshipId}
            getApplicants={getApplicants}
            language={code}
          />
        )}
      </ActiveLanguageProvider>
    </Page>
  );
};

ApplicantProfileContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  applicant: PropTypes.shape({
    pk: PropTypes.number,
    esuser: PropTypes.object,
  }),
  getApplicant: PropTypes.func.isRequired,
  getApplicants: PropTypes.func.isRequired,
  applicantId: PropTypes.number.isRequired,
  internshipId: PropTypes.number.isRequired,
  currentUser: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

ApplicantProfileContainer.defaultProps = {
  error: null,
  applicant: null,
};

const mapStateToProps = (
  { applicants: { data, loading, error }, currentUser },
  {
    match: {
      params: { applicantId, internshipId },
    },
  },
) => ({
  applicantId: parseInt(applicantId, 0),
  internshipId: internshipId ? parseInt(internshipId, 0) : null,
  loading,
  error,
  applicant: data.applicant,
  currentUser: currentUser.data,
});

export default connect(mapStateToProps, { getApplicant, getApplicants })(
  ApplicantProfileContainer,
);
