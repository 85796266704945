'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _InputFeedback = require('../InputFeedback');

var _InputFeedback2 = _interopRequireDefault(_InputFeedback);

var _GroupInputStyled = require('./GroupInputStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var GroupInput = function GroupInput(_ref) {
  var Component = _ref.innerComponent,
      form = _ref.form,
      field = _ref.field,
      withBottomMargin = _ref.withBottomMargin,
      rest = _objectWithoutProperties(_ref, ['innerComponent', 'form', 'field', 'withBottomMargin']);

  var errors = form.errors,
      touched = form.touched;

  var error = touched[field.name] && errors[field.name];
  return _react2.default.createElement(
    _GroupInputStyled.GroupInputWrap,
    { withBottomMargin: withBottomMargin },
    _react2.default.createElement(Component, _extends({
      invalid: error ? !!error : undefined
    }, field, rest, {
      meta: form
    })),
    _react2.default.createElement(_InputFeedback2.default, { error: error })
  );
};

GroupInput.propTypes = process.env.NODE_ENV !== "production" ? {
  innerComponent: _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.element, _propTypes2.default.object]).isRequired,
  form: _propTypes2.default.shape({
    errors: _propTypes2.default.object,
    touched: _propTypes2.default.object
  }).isRequired,
  field: _propTypes2.default.shape({
    name: _propTypes2.default.string
  }).isRequired,
  withBottomMargin: _propTypes2.default.bool
} : {};

GroupInput.defaultProps = {
  withBottomMargin: true
};

exports.default = GroupInput;
module.exports = exports['default'];