import styled from 'styled-components';

export const SpecialTasksWrapper = styled.div`
  .show-hide-box {
    transition: max-height 0.2s ease-in-out;
  }
  .show-hide-box.expanded {
    height: auto;
  }
`;

export const ShowHideButton = styled.button`
  background: none;
  border: none;
  right: 0;
  left: 0;
  cursor: pointer;
  display: table;
  position: relative;
  margin: 0 auto;
`;
