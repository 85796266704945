import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { buildInternshipUrl } from 'utils/uri';
import { createSpecialTasks } from 'actions/specialTaskAction';
import TaskForm from '../../../ApplicantProfile/SpecialTasks/TaskForm';
import ActionResults from '../../ActionResults';

const AddTodo = ({
  selectedApplicants,
  selectedInternship,
  showActionResult,
  closeActionModel,
  createSpecialTasks,
}) => {
  const handleSubmit = (values) => {
    const addTodo = selectedApplicants.map((applicant) => {
      if (
        selectedInternship[applicant.pk]
        && selectedInternship[applicant.pk].value
      ) {
        return createSpecialTasks({
          ...values,
          internship: buildInternshipUrl(
            selectedInternship[applicant.pk].value.pk,
          ),
        })
          .then(() => ({
            result: 'success',
            user: applicant.esuser.user,
          }))
          .catch(() => ({
            result: 'failed',
            user: applicant.esuser.user,
          }));
      }
      return createSpecialTasks({
        ...values,
        internship: null,
        assigned_to: applicant ? applicant.esuser.api_url : undefined,
      })
        .then(() => ({
          result: 'success',
          user: applicant.esuser.user,
        }))
        .catch(() => ({
          result: 'failed',
          user: applicant.esuser.user,
        }));
    });
    return Promise.all(addTodo).then((values) => {
      showActionResult(
        <ActionResults results={values} onClose={closeActionModel} />,
      );
    });
  };
  return <TaskForm onSubmit={handleSubmit} onCancel={closeActionModel} />;
};

AddTodo.propTypes = {
  selectedApplicants: PropTypes.array.isRequired,
  selectedInternship: PropTypes.shape({}).isRequired,
  showActionResult: PropTypes.func.isRequired,
  closeActionModel: PropTypes.func.isRequired,
  createSpecialTasks: PropTypes.func.isRequired,
};

export default connect(
  undefined,
  {
    createSpecialTasks,
  },
)(AddTodo);
