import internshipRequestApi from 'api/internshipRequestApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getInternshipsRequestsList() {
  return createAction({
    type: types.GET_INTERNSHIPS_REQUESTS_LIST,
    callAPI: () => internshipRequestApi.getInternshipsRequestsList(),
  });
}

export function changeInternshipRequest(pk, data) {
  return createAction({
    type: types.CHANGE_INTERNSHIP_REQUEST,
    callAPI: () => internshipRequestApi.changeInternshipRequest(pk, data),
  });
}

export function deleteInternshipRequest(pk) {
  return createAction({
    type: types.DELETE_INTERNSHIP_REQUEST,
    callAPI: () => internshipRequestApi.deleteInternshipRequest(pk),
    payload: { internshipRequestId: pk },
  });
}

export function uploadInternshipRequestAttachment(requestId, file) {
  return createAction({
    type: types.UPLOAD_INTERNSHIP_REQUEST_ATTACHMENT,
    callAPI: () => internshipRequestApi.uploadInternshipRequestAttachment(requestId, file),
  });
}

export function deleteInternshipRequestAttachment(fileId) {
  return createAction({
    type: types.DELETE_INTERNSHIP_REQUEST_ATTACHMENT,
    callAPI: () => internshipRequestApi.deleteInternshipRequestAttachment(fileId),
    payload: { fileId },
  });
}

export function addInternshipRequest() {
  return createAction({
    type: types.ADD_INTERNSHIP_REQUEST,
    callAPI: () => internshipRequestApi.addInternshipRequest(),
  });
}
