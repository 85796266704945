import styled from 'styled-components';

import { InputField } from 'shared';
import { device } from 'shared/src/styles/device';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 500px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: ${({ theme }) => theme.borderRadiusDefault};
  padding: 9px 24px;
  justify-content: space-between;
  @media ${device.tablet} {
    width: 280px;
  }
  @media ${device.laptop} {
    width: 425px;
  }
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.secondary};
  display: flex;
  align-items: center;
  .icon {
    width: 60px;
  }
`;

export const Text = styled.span`
  font-size: 16px;
  font-weight: 500;
  word-break: break-word;
`;

export const StyledInputField = styled(InputField)`
  height: 42px;
  + .label {
    display: none;
  }
`;
