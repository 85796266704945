'use strict';

exports.__esModule = true;
exports.SpinIndicator = exports.SpinContainer = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  z-index: 100;\n\n  //in order to show rounded indicator\n  min-height: 30px; //height of indicator\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  @keyframes load8 {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n'], ['\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  z-index: 100;\n\n  //in order to show rounded indicator\n  min-height: 30px; //height of indicator\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  @keyframes load8 {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  box-sizing: border-box;\n  width: 30px;\n  height: 30px;\n  border: 3px solid transparent;\n  border-top-color: ', ';\n  border-right-color: ', ';\n  border-radius: ', ';\n  animation: load8 1s infinite linear;\n'], ['\n  box-sizing: border-box;\n  width: 30px;\n  height: 30px;\n  border: 3px solid transparent;\n  border-top-color: ', ';\n  border-right-color: ', ';\n  border-radius: ', ';\n  animation: load8 1s infinite linear;\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var SpinContainer = exports.SpinContainer = _styledComponents2.default.div(_templateObject);

var SpinIndicator = exports.SpinIndicator = _styledComponents2.default.div(_templateObject2, function (_ref) {
  var theme = _ref.theme;
  return theme.primary;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.primary;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.borderRadiusRound;
});