import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Page from 'components/Page';
import Localize from 'components/Localize';
import { getApplicants } from 'actions/internshipsActions';
import Checkbox from 'components/Form/CheckBox';
import { Button, Loader, Error } from 'shared';
import { roles } from 'utils/access-rules/constants';

import ActionDropdown from './ActionDropdown';
import SetOlt from './ActionDropdown/SetOlt';
import ActionModal from './ActionModal';
import NameSurnameSelector from './NameSurnameSelector';
import LastLoginSelector from './LastLoginSelector';
import InternshipSelector from './InternshipSelector';
import InternshipStatusSelector from './InternshipStatusSelector';
import OperationalLearningSelector from './OperationalLearningSelector';
import InternshipReportSelector from './InternshipReportSelector';
import StudentAssessmentSelector from './StudentAssessmentSelector';
import EducationalAdvisorAssessmentSelector from './EducationalAdvisorAssessmentSelector';
import CompanyAssessmentSelector from './CompanyAssessmentSelector';
import TeacherAssessmentSelector from './TeacherAssessmentSelector';
import PresentationCompletedSelector from './PresentationCompletedSelector';

import {
  columnNames,
  filterTypes,
  filterMap,
  notFilters,
  getClassroomOptions,
  getSchoolNameOptions,
  getCurrentInternships,
  compareEndDate,
} from './ManageStudentsUtils';
import {
  FilterPanel,
  FilterButtons,
  ClassroomNameSelect,
  ClassroomSelect,
  ResetFilter,
  StudentTable,
} from './ManageStudentsStyled';

const ManageStudents = ({
  getApplicants,
  applicants,
  loading,
  error,
  user,
}) => {
  const [classroomOptions, setClassroomOptions] = useState([]);
  const [schoolNameOptions, setSchoolNameOptions] = useState([]);
  const [filters, setFilters] = useState({});
  const [columnFilters, setColumnFilters] = useState({});
  const [selectedInternship, setSelectedInternship] = useState({});
  const [filteredApplicants, setFilteredApplicants] = useState({});
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [filterApplicants, setFilterApplicants] = useState([]);
  const stableGetApplicants = useCallback(getApplicants, []);
  useEffect(() => {
    stableGetApplicants();
  }, [stableGetApplicants]);

  const handleInternshipChanged = (internship, userId) => {
    setSelectedInternship((selectedInternship) => ({
      ...selectedInternship,
      [userId]: internship,
    }));
  };

  useEffect(() => {
    if (classroomOptions.length === 0 && schoolNameOptions.length === 0) {
      setClassroomOptions(getClassroomOptions(applicants));
      setSchoolNameOptions(getSchoolNameOptions(applicants));
    }

    applicants.forEach((applicant) => {
      const currentInternships = getCurrentInternships(applicant);
      if (currentInternships.length > 0) {
        handleInternshipChanged(
          {
            label: currentInternships.sort(compareEndDate)[0].company_name,
            value: currentInternships.sort(compareEndDate)[0],
          },
          applicant.esuser.pk,
        );
      } else if (applicant.esuser.internships.length === 1) {
        handleInternshipChanged(
          {
            label: applicant.esuser.internships[0].company_name,
            value: applicant.esuser.internships[0],
          },
          applicant.esuser.pk,
        );
      }
    });
    setFilterApplicants(applicants);
  }, [classroomOptions.length, schoolNameOptions.length, applicants]);

  useEffect(() => {
    Object.keys(columnFilters).forEach((filterKey) => {
      if (filterKey === columnNames.LAST_LOGIN) {
        Object.keys(columnFilters[filterKey]).forEach((key) => {
          if (key === filterTypes.NEVER) {
            if (columnFilters[filterKey][key]) {
              setFilteredApplicants((filteredApplicants) => ({
                ...filteredApplicants,
                [filterKey]: new Set(
                  applicants
                    .filter((applicant) => !applicant.esuser.user.last_login)
                    .map((applicant) => applicant.esuser.pk.toString()),
                ),
              }));
            } else if (filteredApplicants[filterKey]) {
              applicants
                .filter((applicant) => !applicant.esuser.user.last_login)
                .forEach((applicant) => {
                  filteredApplicants[filterKey].delete(
                    applicant.esuser.pk.toString(),
                  );
                  setFilteredApplicants((filteredApplicants) => ({
                    ...filteredApplicants,
                    [filterKey]: new Set(filteredApplicants[filterKey]),
                  }));
                });
            }
          }
        });
      } else {
        Object.keys(columnFilters[filterKey]).forEach((key) => {
          Object.keys(selectedInternship).forEach((userId) => {
            if (selectedInternship[userId].value) {
              if (
                Object.values(filterMap).some((i) => Object.keys(i).includes(key))
                && selectedInternship[userId].value[filterMap[filterKey][key]]
                && !Object.keys(filterMap[filterKey])
                  .filter(
                    (fk) => Object.keys(filterMap[filterKey]).indexOf(fk)
                      < Object.keys(filterMap[filterKey]).indexOf(key),
                  )
                  .some(
                    (k) => selectedInternship[userId].value[filterMap[filterKey][k]],
                  )
              ) {
                if (columnFilters[filterKey][key]) {
                  setFilteredApplicants((filteredApplicants) => ({
                    ...filteredApplicants,
                    [filterKey]: new Set(filteredApplicants[filterKey]).add(
                      userId,
                    ),
                  }));
                } else if (filteredApplicants[filterKey]) {
                  filteredApplicants[filterKey].delete(userId);
                  setFilteredApplicants((filteredApplicants) => ({
                    ...filteredApplicants,
                    [filterKey]: new Set(filteredApplicants[filterKey]),
                  }));
                }
              } else if (
                notFilters.some((filter) => filter === key)
                && !Object.values(filterMap[filterKey]).some(
                  (filter) => selectedInternship[userId].value[filter],
                )
              ) {
                if (columnFilters[filterKey][key]) {
                  setFilteredApplicants((filteredApplicants) => ({
                    ...filteredApplicants,
                    [filterKey]: new Set(filteredApplicants[filterKey]).add(
                      userId,
                    ),
                  }));
                } else if (filteredApplicants[filterKey]) {
                  filteredApplicants[filterKey].delete(userId);
                  setFilteredApplicants((filteredApplicants) => ({
                    ...filteredApplicants,
                    [filterKey]: new Set(filteredApplicants[filterKey]),
                  }));
                }
              } else if (
                (Object.values(filterMap).some((i) => Object.keys(i).includes(key))
                  && !selectedInternship[userId].value[
                    filterMap[filterKey][key]
                  ])
                || (notFilters.some((filter) => filter === key)
                  && !Object.values(filterMap[filterKey]).some(
                    (filter) => !selectedInternship[userId].value[filter],
                  ))
              ) {
                if (columnFilters[filterKey][key]) {
                  setFilteredApplicants((filteredApplicants) => ({
                    ...filteredApplicants,
                    [filterKey]: filteredApplicants[filterKey]
                      ? new Set(filteredApplicants[filterKey])
                      : new Set(),
                  }));
                } else if (filteredApplicants[filterKey]) {
                  setFilteredApplicants((filteredApplicants) => ({
                    ...filteredApplicants,
                    [filterKey]: new Set(filteredApplicants[filterKey]),
                  }));
                }
              }
            }
          });
        });
      }
    });
  }, [columnFilters]);

  useEffect(() => {
    if (
      ![].concat(
        ...Object.values(filteredApplicants).map((users) => Array.from(users)),
      ).length
      && ![]
        .concat(...Object.values(columnFilters).map((fk) => Object.values(fk)))
        .some((filterValue) => filterValue === true)
    ) {
      setFilterApplicants(applicants);
    } else {
      setFilterApplicants(
        filterApplicants.filter((applicant) => []
          .concat(
            ...Object.values(filteredApplicants).map((users) => Array.from(users)),
          )
          .includes(applicant.esuser.pk.toString())),
      );
    }
  }, [filteredApplicants]);

  const onSelectChange = (data, e) => {
    setFilters((filters) => ({
      ...filters,
      [e.name]: data || undefined,
    }));
  };

  const handleFilter = () => {
    getApplicants(filters);
  };

  const resetFilter = () => {
    setFilters({});
    setFilteredApplicants({});
    setColumnFilters({});
    setSelectedInternship({});
    getApplicants();
  };

  const handleActionModal = (content) => {
    setModalContent(content);
    setIsActionModalOpen(true);
  };

  const closeActionModel = (event) => {
    if (event.target === event.currentTarget) {
      setIsActionModalOpen(false);
    }
  };
  const columns = [
    {
      name: columnNames.NAME_SURNAME,
      minWidth: '80px',
      maxWidth: '80px',
      selector: (row) => (
        <NameSurnameSelector
          selectedInternship={selectedInternship}
          row={row}
        />
      ),
      sortable: true,
    },
    ...(user.role !== roles.CompanyEmployee
      ? [
        {
          name: columnNames.LAST_LOGIN,
          minWidth: '80px',
          maxWidth: '80px',
          selector: (row) => <LastLoginSelector row={row} />,
          filter: ['never'],
        },
      ]
      : []),
    ...(user.role !== roles.CompanyEmployee
      ? [
        {
          name: columnNames.INTERNSHIP,
          minWidth: '200px',
          selector: (row) => (
            <InternshipSelector
              row={row}
              handleChange={handleInternshipChanged}
              selectedInternship={selectedInternship}
            />
          ),
        },
      ]
      : []),
    {
      name: columnNames.INTERNSHIP_STATUS,
      maxWidth: '100px',
      selector: (row) => (
        <InternshipStatusSelector
          row={row}
          selectedInternship={selectedInternship}
          currentUser={user}
        />
      ),
      ...(user.role !== roles.CompanyEmployee && {
        filter: [
          filterTypes.NOT_LOOKED_YET,
          filterTypes.LOOKED_BUT_NOT_APPLIED,
          filterTypes.APPLIED,
          filterTypes.WAITING_FOR_TEACHERS_APPROVAL,
          filterTypes.APPROVED,
        ],
      }),
    },
    {
      name: columnNames.OPERATIONAL_LEARNING_TASK,
      minWidth: '90px',
      maxWidth: '90px',
      selector: (row) => (
        <OperationalLearningSelector
          row={row}
          selectedInternship={selectedInternship}
          currentUser={user}
          openActionModal={() => handleActionModal(
            <SetOlt
              selectedApplicants={selectedApplicants}
              selectedInternship={selectedInternship}
              showActionResult={handleActionModal}
              closeActionModel={() => setIsActionModalOpen(false)}
            />,
          )}
        />
      ),
      filter: [
        filterTypes.NOT_SET_YET,
        filterTypes.SET,
        filterTypes.SAVED,
        filterTypes.SUBMITTED,
        filterTypes.SUBMITTED_COMPANY_COMMENTED,
      ],
    },
    {
      name: columnNames.INTERNSHIP_REPORT,
      minWidth: '90px',
      maxWidth: '90px',
      selector: (row) => (
        <InternshipReportSelector
          row={row}
          selectedInternship={selectedInternship}
          currentUser={user}
        />
      ),
      filter: [
        filterTypes.NOT_WRITTEN_YET,
        filterTypes.WRITTEN,
        filterTypes.SUBMITTED,
      ],
    },
    {
      name: columnNames.STUDENT_ASSESSMENT,
      minWidth: '90px',
      maxWidth: '90px',
      selector: (row) => (
        <StudentAssessmentSelector
          row={row}
          selectedInternship={selectedInternship}
        />
      ),
      filter: [filterTypes.NOT_FILLED, filterTypes.SAVED, filterTypes.SUBMITTED],
    },
    ...(user.role !== roles.CompanyEmployee
      ? [
        {
          name: columnNames.EDUCATIONAL_ADVISOR_ASSESSMENT,
          selector: (row) => (
            <EducationalAdvisorAssessmentSelector
              row={row}
              selectedInternship={selectedInternship}
            />
          ),
          filter: [
            filterTypes.NOT_FILLED,
            filterTypes.SAVED,
            filterTypes.SUBMITTED,
          ],
        },
      ]
      : []),
    {
      name: columnNames.COMPANY_ASSESSMENT,
      minWidth: '150px',
      selector: (row) => (
        <CompanyAssessmentSelector
          row={row}
          selectedInternship={selectedInternship}
        />
      ),
      filter: [filterTypes.NOT_FILLED, filterTypes.SAVED, filterTypes.SUBMITTED],
    },
    ...(user.role !== roles.CompanyEmployee
      ? [
        {
          name: columnNames.TEACHER_ASSESSMENT,
          minWidth: '90px',
          maxWidth: '90px',
          selector: (row) => (
            <TeacherAssessmentSelector
              row={row}
              selectedInternship={selectedInternship}
            />
          ),
          filter: [
            filterTypes.NOT_FILLED,
            filterTypes.SAVED,
            filterTypes.SUBMITTED,
          ],
        },
      ]
      : []),
    ...(user.role !== roles.CompanyEmployee
      ? [
        {
          name: columnNames.PRESENTATION_COMPLETED,
          selector: (row) => (
            <PresentationCompletedSelector
              row={row}
              selectedInternship={selectedInternship}
            />
          ),
          filter: [filterTypes.YES, filterTypes.NOT_YET],
        },
      ]
      : []),
  ];

  return (
    <Page>
      <FilterPanel>
        <ClassroomNameSelect
          clearIndicator
          // isActive={schoolName}
          options={schoolNameOptions}
          onChange={onSelectChange}
          name="school"
          value={filters.school || null}
          placeholder={<Localize id="school" />}
        />
        <ClassroomSelect
          clearIndicator
          // isActive={classroom}
          onChange={onSelectChange}
          options={classroomOptions}
          name="classroom"
          value={filters.classroom || null}
          placeholder={<Localize id="class" />}
        />
        <FilterButtons>
          <Button
            type="button"
            color="secondary"
            size="small"
            onClick={handleFilter}
          >
            <Localize id="filter" />
          </Button>
          <ResetFilter onClick={resetFilter}>
            <Localize id="reset-filter" />
          </ResetFilter>
        </FilterButtons>
      </FilterPanel>
      {loading && <Loader />}
      {(error && <Error />) || (
        <StudentTable
          title={<Localize id="manage-students" />}
          columns={columns}
          data={filterApplicants}
          selectableRows
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={{ size: 'small' }}
          onSelectedRowsChange={({ selectedRows }) => setSelectedApplicants(selectedRows)}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          persistTableHead
        />
      )}
      {(error && <Error />) || (loading && <Loader />) || (
        <ActionDropdown
          selectedApplicants={selectedApplicants}
          selectedInternship={selectedInternship}
          submit={handleActionModal}
          closeActionModel={() => setIsActionModalOpen(false)}
          currentUser={user}
        />
      )}
      <ActionModal isOpen={isActionModalOpen} onClose={closeActionModel}>
        {modalContent}
      </ActionModal>
    </Page>
  );
};

ManageStudents.propTypes = {
  applicants: PropTypes.array.isRequired,
  getApplicants: PropTypes.func.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.shape({}),
};

ManageStudents.defaultProps = {
  error: undefined,
};

const mapStateToProps = ({
  applicants: { data, loading, error },
  currentUser,
}) => ({
  applicants: data.list || [],
  user: currentUser && currentUser.data,
  loading,
  error,
});

export default connect(mapStateToProps, { getApplicants })(ManageStudents);
