import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { MediaQuery } from 'shared';
import Localize from 'components/Localize';

import { device } from 'shared/src/styles/device';
import { resendAssessment } from 'actions/assessmentActions';
import {
  resetSubmittedProperty,
  getQuestionnaireTableHeadText,
} from '../assessmentUtils';
import {
  QuestionnaireTable,
  NameTableColumn,
  NameTableHead,
  AnswerTableColumn,
  AnswerTableHead,
  QuestionTableColumn,
} from './QuestionnaireStyled';
import AnswerCheckbox from './AnswerCheckbox';
import QuestionnaireRoles from './QuestionnaireRoles';
import CommentsForms from './CommentsForms';

const Questionnaire = ({
  answers,
  data,
  userRole,
  onSave: _onSave,
  onSubmit: _onSubmit,
  isComparingMode,
  isCompleted,
  isEditable,
  assessment,
  resendAssessment,
  error,
  saveError,
  saveSuccess,
}) => {
  const [groups, setGroups] = useState(data);
  const [changedItems, setChangedItems] = useState([]);
  const [touched, setTouched] = useState(false);

  const getAnswerKey = (answerValue) => {
    if (!answerValue) return '';
    return Object.keys(answers).find((answerKey) => {
      if (answers[answerKey] === answerValue) {
        return answerKey;
      }
      return '';
    });
  };
  const isItemHasAnswer = (item) => item[userRole] !== '';
  const getValidationStatus = (groups) => {
    let isValid = true;
    groups.forEach((group) => {
      group.item_list.forEach((item) => {
        if (!isItemHasAnswer(item)) isValid = false;
      });
    });

    return isValid;
  };
  const [isValid, setValid] = useState(getValidationStatus(groups));
  const onItemChange = (pk, answer) => {
    setTouched(true);
    const changedGroups = groups.map((group) => {
      const changedItemList = group.item_list.map((item) => {
        if (item.pk === pk) {
          const changedItem = {
            ...item,
            [userRole]: getAnswerKey(answer),
            value: getAnswerKey(answer),
          };
          const items = changedItems.filter((i) => i.pk !== changedItem.pk);
          setChangedItems([...items, changedItem]);
          return changedItem;
        }
        return item;
      });
      return {
        ...group,
        item_list: changedItemList,
      };
    });
    setValid(getValidationStatus(changedGroups));
    setGroups(changedGroups);
  };
  const onSave = (data) => {
    setTouched(false);
    _onSave(changedItems, data);
    setChangedItems([]);
  };
  const onSubmit = (data) => {
    setTouched(false);
    _onSubmit(changedItems, data);
    setChangedItems([]);
  };
  const onResend = () => {
    const data = {
      ...resetSubmittedProperty(userRole.toUpperCase()),
    };
    resendAssessment(assessment.pk, data);
  };
  const titles = Object.values(answers);
  return (
    <div>
      {isComparingMode && <QuestionnaireRoles />}
      <QuestionnaireTable>
        <thead>
          <MediaQuery queries={device}>
            {(matches) => {
              const renderedTitles = titles.map((title, index) => (
                <AnswerTableHead key={index} width={100 / titles.length}>
                  {title}
                </AnswerTableHead>
              ));
              return matches.mobile ? (
                <>
                  <tr>{renderedTitles}</tr>
                  <tr>
                    <NameTableHead colSpan={titles.length}>
                      <Localize
                        id={getQuestionnaireTableHeadText(
                          userRole.toUpperCase(),
                        )}
                      />{' '}
                      ...
                    </NameTableHead>
                  </tr>
                </>
              ) : (
                <tr>
                  <NameTableHead>
                    <Localize
                      id={getQuestionnaireTableHeadText(userRole.toUpperCase())}
                    />{' '}
                    ...
                  </NameTableHead>
                  {renderedTitles}
                </tr>
              );
            }}
          </MediaQuery>
        </thead>
        <tbody>
          {groups.map((group, index) => {
            const questions = group.item_list;
            return (
              <Fragment key={index}>
                <tr key={index}>
                  <NameTableColumn colSpan={titles.length}>
                    {group.name}
                  </NameTableColumn>
                </tr>
                {questions.map((question, questionIndex) => (
                  <MediaQuery queries={device} key={questionIndex}>
                    {(matches) => {
                      const answersCells = titles.map((title, index) => (
                        <AnswerTableColumn
                          key={index}
                          isLast={questions.length === questionIndex + 1}
                        >
                          <AnswerCheckbox
                            pk={question.pk}
                            question={question}
                            answer={title}
                            onChange={onItemChange}
                            answers={answers}
                            userRole={userRole}
                            isComparingMode={isComparingMode}
                            disabled={isCompleted || !isEditable}
                          />
                        </AnswerTableColumn>
                      ));
                      return matches.mobile ? (
                        <>
                          <tr>
                            <QuestionTableColumn colSpan={titles.length}>
                              ... {question.name}
                            </QuestionTableColumn>
                          </tr>
                          <tr>{answersCells}</tr>
                        </>
                      ) : (
                        <tr key={questionIndex}>
                          <QuestionTableColumn
                            isLast={questions.length === questionIndex + 1}
                          >
                            ... {question.name}
                          </QuestionTableColumn>
                          {answersCells}
                        </tr>
                      );
                    }}
                  </MediaQuery>
                ))}
              </Fragment>
            );
          })}
        </tbody>
      </QuestionnaireTable>
      <CommentsForms
        comments={assessment}
        changedItems={changedItems}
        isCompleted={isCompleted}
        isEditable={isEditable}
        isComparingMode={isComparingMode}
        onSave={onSave}
        onSubmit={onSubmit}
        isValid={isValid}
        userRole={userRole}
        error={error}
        onResend={onResend}
        touched={touched}
        saveError={saveError}
        saveSuccess={saveSuccess}
      />
    </div>
  );
};

Questionnaire.propTypes = {
  answers: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      item_list: PropTypes.array,
    }),
  ).isRequired,
  error: PropTypes.shape({
    detail: PropTypes.string,
  }),
  assessment: PropTypes.shape({
    pk: PropTypes.number,
    completed: PropTypes.bool,
    applicant_comments: PropTypes.string,
    company_employee_comments: PropTypes.string,
    educational_provider_comments: PropTypes.string,
    teacher_comments: PropTypes.string,
  }).isRequired,
  isCompleted: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isComparingMode: PropTypes.bool,
  isEditable: PropTypes.bool,
  resendAssessment: PropTypes.func.isRequired,
  saveError: PropTypes.bool.isRequired,
  saveSuccess: PropTypes.bool.isRequired,
};

Questionnaire.defaultProps = {
  isComparingMode: false,
  isEditable: true,
  error: null,
};

export default connect(undefined, { resendAssessment })(Questionnaire);
