import entityInstructionApi from 'api/EntityInstructionApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getEntityInstruction(identifier) {
  return createAction({
    type: types.GET_ENTITY_INSTRUCTION,
    callAPI: () => entityInstructionApi.getEntityInstruction(identifier),
  });
}

export function setEntityInstructionRating(data) {
  return createAction({
    type: types.SET_ENTITY_INSTRUCTION_RATING,
    callAPI: () => entityInstructionApi.setEntityInstructionRating(data),
  });
}
