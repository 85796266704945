import styled from 'styled-components';
import AutosizeTextarea from 'react-autosize-textarea';

export const TextAreaWrapper = styled.div`
  flex-grow: 1;
  //margin-top: 12px;
  border-radius: ${({ theme }) => theme.borderRadiusDefault};
  border: 1px solid
    ${({ invalid, theme }) => (invalid ? theme.errorColor : theme.grayF3)};
`;

export const TextArea = styled(AutosizeTextarea)`
  display: flex;
  width: 100%;
  padding: 12px 18px 6px;
  font-style: italic;
  line-height: 22px;
  font-weight: ${({ theme }) => theme.fontWeightUpperBold};
  color: ${({ theme }) => theme.textColor};
  word-break: break-word;
  border: none;
  resize: none;

  &::placeholder {
    color: ${({ theme }) => theme.textColor};
    font-weight: ${({ theme }) => theme.fontWeightMedium};
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.primary};
  }
`;
