import applicantApi from 'api/ApplicantApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getApplicantState(internshipId) {
  return createAction({
    type: types.GET_APPLICANT_STATE,
    callAPI: () => applicantApi.getState(internshipId),
    // state retrieved each time page renders.
  });
}
