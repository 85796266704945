/*eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import format from 'date-fns/format';

import { Button, Prompt } from 'shared';
import uploadIcon from 'styles/images/svg/upload.svg';
import arrowRightIcon from 'styles/images/svg/arrow-right.svg';
import refreshIcon from 'styles/images/svg/refresh.svg';
import Localize from 'components/Localize';
import {
  ReportsListContainer,
  ReportsContainer,
  ReportsActions
} from './ReportStyled';
import ReportDay from './ReportDay';
import { parseDay } from './dayParser';

const initialState = {
  activeReportItem: null,
  activeReportType: null
};

export const REPORT_ITEM_TYPES = {
  DAY: 'day',
  CONCLUSION: 'conclusion',
  ADD_DAY: 'add-day'
};

const removeFakeIds = tasks =>
  tasks.map(task => {
    if (typeof task.pk === 'string') {
      return {
        ...task,
        pk: undefined
      };
    }
    return task;
  });

const Report = ({
  report,
  onDayChange: _onDayChange,
  onConclusionChange: _onConclusionChange,
  onAddDay: _onAddDay,
  submitReport,
  resendReport,
  onDeleteDayAttachment,
  viewOnly,
  addDayTasks,
  editDayTask,
  deleteDayTask,
  ...rest
}) => {
  const [state, setState] = useState(initialState);
  const editTasks = tasks => {
    const promises = tasks.map(t => editDayTask(t.pk, t));
    return Promise.all(promises);
  };
  const deleteTasks = tasks => {
    const promises = tasks.map(t => deleteDayTask(t.pk));
    return Promise.all(promises);
  };
  const setActiveReportItem = (pk, type) => {
    if (type === REPORT_ITEM_TYPES.DAY) {
      const dayData = report.day_list.find(r => r.pk === pk);
      const index = report.day_list.indexOf(dayData) + 1;
      setState({
        activeReportItem: { ...dayData, index },
        activeReportType: type
      });
      return;
    }
    if (type === REPORT_ITEM_TYPES.ADD_DAY) {
      const index = report.day_list.length + 1;
      setState({
        activeReportItem: {
          report: '',
          index,
          date: format(new Date(), 'yyyy-MM-dd'),
          tasks: []
        },
        activeReportType: type
      });
      return;
    }
    setState({
      activeReportItem: { pk: report.pk, report: report.conclusion },
      activeReportType: type
    });
  };
  const onDayChange = (dayId, report) => {
    return _onDayChange(dayId, {
      ...report,
      tasks: removeFakeIds(report.tasks)
    }).then(() => setState({ activeReportItem: null, activeReportType: null }));
  };
  const onConclusionChange = (dayId, conclusion) =>
    _onConclusionChange(dayId, conclusion).then(() =>
      setState({ activeReportItem: null, activeReportType: null })
    );
  const onAddDay = (day, attachments) =>
    _onAddDay({
      ...day,
      tasks: removeFakeIds(day.tasks),
      attachment_list: attachments,
      internship: report.api_url
    }).then(data => {
      const newDay = data.response;
      if (!newDay) return;
      const promises = attachments.map(attachment =>
        onUploadAttachment(
          {
            file: attachment.base64,
            kind: file.type.includes('image') ? 'DOCUMENT_PHOTO' : null
          },
          newDay.api_url
        )
      );
      Promise.all(promises).then(() =>
        _onDayChange(newDay.pk, newDay).then(() =>
          setState({ activeReportItem: null, activeReportType: null })
        )
      );
    });
  return (
    <ReportsContainer>
      <ReportsListContainer>
        {report.day_list.map((day, index) => (
          <Localize key={day.pk}>
            {({ translate }) => (
              <ReportDay
                onChange={onDayChange}
                text={`${translate('day')} ${index + 1}`}
                data={day}
                type={REPORT_ITEM_TYPES.DAY}
                isSubmitted={report.submitted || viewOnly}
                {...rest}
              />
            )}
          </Localize>
        ))}
      </ReportsListContainer>
      <ReportsListContainer>
        {!report.submitted && !viewOnly && (
          <Localize>
            {({ translate }) => (
              <ReportDay
                data={report}
                onChange={onAddDay}
                text={translate('add-another-day')}
                onSelect={setActiveReportItem}
                type={REPORT_ITEM_TYPES.ADD_DAY}
                isSubmitted={report.submitted || viewOnly}
              />
            )}
          </Localize>
        )}
        <Localize>
          {({ translate }) => (
            <ReportDay
              data={report}
              onChange={onConclusionChange}
              type={REPORT_ITEM_TYPES.CONCLUSION}
              text={translate('conclusion')}
              onSelect={setActiveReportItem}
              isSubmitted={report.submitted || viewOnly}
            />
          )}
        </Localize>
      </ReportsListContainer>
      <ReportsActions>
        <Button
          className="btn-upload"
          icon={uploadIcon}
          disableBoxShadow
          size="small"
          variant="outlined"
          color="secondary"
          as="a"
          href={report.report_url}
          target="_blank"
        >
          <Localize id="download" />
        </Button>
        {!viewOnly && (
          <Prompt
            confirm={submitReport}
            trigger={
              <Button
                className="btn-submit"
                icon={arrowRightIcon}
                disableBoxShadow
                color="secondary"
                size="small"
                disabled={report.submitted}
              >
                <Localize id="submit-report" />
              </Button>
            }
          >
            <Localize id="are-you-sure-want-submit-form" />
          </Prompt>
        )}
        {viewOnly && (
          <Prompt
            confirm={resendReport}
            trigger={
              <Button
                className="btn-submit"
                icon={refreshIcon}
                disableBoxShadow
                color="secondary"
                variant="outlined"
                size="small"
                disabled={!report.submitted}
              >
                <Localize id="resend" />
              </Button>
            }
          >
            <Localize id="are-you-sure-want-resend-form" />
          </Prompt>
        )}
      </ReportsActions>
    </ReportsContainer>
  );
};

Report.propTypes = {
  report: PropTypes.shape({
    day_list: PropTypes.arrayOf(PropTypes.object),
    conclusion: PropTypes.string,
    api_url: PropTypes.string,
    pk: PropTypes.number,
    report_url: PropTypes.string
  }).isRequired,
  onDayChange: PropTypes.func.isRequired,
  onConclusionChange: PropTypes.func.isRequired,
  onAddDay: PropTypes.func.isRequired,
  onUploadAttachment: PropTypes.func.isRequired,
  onDeleteDayAttachment: PropTypes.func.isRequired,
  submitReport: PropTypes.func.isRequired,
  resendReport: PropTypes.func.isRequired,
  addDayTasks: PropTypes.func.isRequired,
  editDayTask: PropTypes.func.isRequired,
  deleteDayTask: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool
};

Report.defaultProps = {
  viewOnly: false
};

export default Report;
