import React from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import uploadIcon from 'shared/lib/styles/images/upload.svg';
import documentIcon from 'styles/images/svg/document.svg';
import { Button } from 'shared';

const DownloadButton = ({ url, children }) => (
  <Button
    as="a"
    color="secondary"
    size="small"
    icon={documentIcon}
    rightIcon={uploadIcon}
    href={url}
    download
  >
    {children || <Localize id="download" />}
  </Button>
);

DownloadButton.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node,
};

DownloadButton.defaultProps = {
  children: undefined,
};

export default DownloadButton;
