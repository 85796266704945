'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _send = require('../../styles/images/send.svg');

var _send2 = _interopRequireDefault(_send);

var _SendIconStyled = require('./SendIconStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EditIcon = function EditIcon() {
  return _react2.default.createElement(_SendIconStyled.IconStyled, { src: _send2.default });
};

exports.default = EditIcon;
module.exports = exports['default'];