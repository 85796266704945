'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Thumbnail = require('../../Thumbnail');

var _Thumbnail2 = _interopRequireDefault(_Thumbnail);

var _FilesListStyled = require('./FilesListStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FilesList = function FilesList(_ref) {
  var files = _ref.files,
      onFileDelete = _ref.onFileDelete,
      className = _ref.className,
      disabled = _ref.disabled;
  return _react2.default.createElement(
    _FilesListStyled.FilesListContainer,
    { className: className },
    files.map(function (file) {
      return _react2.default.createElement(
        _FilesListStyled.FileContainer,
        { key: file.pk },
        _react2.default.createElement(_Thumbnail2.default, {
          data: file,
          onDelete: onFileDelete,
          className: 'document-thumb',
          disabled: disabled
        })
      );
    })
  );
};

FilesList.propTypes = process.env.NODE_ENV !== "production" ? {
  files: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    kind: _propTypes2.default.string,
    pk: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.string])
  })).isRequired,
  onFileDelete: _propTypes2.default.func.isRequired,
  className: _propTypes2.default.string,
  disabled: _propTypes2.default.bool
} : {};

FilesList.defaultProps = {
  className: undefined,
  disabled: false
};

exports.default = FilesList;
module.exports = exports['default'];