import styled from 'styled-components';
import { Button as _Button } from 'shared';
import TextareaAutosize from 'react-textarea-autosize';
import _Select from 'components/Form/Select';
import { Tooltip } from 'react-tippy';

export const FormWrapper = styled.div`
  position: ${({ isInModal }) => isInModal && 'relative'};
`;

export const EventFormWrapper = styled.div`
  position: absolute;
  top: 100%;
  max-width: 100%;
  padding: 20px;
  right: 0;
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  z-index: 100;
`;

export const FormEventName = styled.h2`
  font-size: 26px;
  font-weight: ${({ theme }) => theme.fontWeightUpperBold};
  color: ${({ theme }) => theme.secondary};
  text-transform: uppercase;
  text-align: center;
`;

export const EventFormContent = styled.div``;

export const ButtonText = styled.span`
  margin-left: 10px;
`;

export const ButtonsWrapper = styled.div`
  padding-top: 20px;
`;

export const Button = styled(_Button)`
  display: flex;
  width: 100%;
  text-align: center;
  margin-right: 16px;
  align-items: center;
  height: 50px;
  font-size: 18px;
  justify-content: center;
`;
export const ButtonClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: transparent;
  font-size: 25px;
  border: none;
  top: 18px;
  right: 12px;
  cursor: pointer;
  color: ${({ theme }) => theme.secondary};
`;
export const DateText = styled.p`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding-right: 20px;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  color: ${({ theme }) => theme.secondary};
  min-height: 50px;
`;
export const Textarea = styled(TextareaAutosize)`
  width: 100%;
  padding: 12px 0;
  font-size: ${({ lighttext }) => (lighttext ? '16px' : '18px')};
  font-weight: ${({ theme, lighttext }) => lighttext ? '500' : theme.fontWeightBold};
  color: ${({ theme }) => theme.secondary};
  border-radius: ${({ theme }) => theme.borderRadiusDefault};
  border: none;
  resize: none;
  &:focus::placeholder {
    color: transparent;
  }
  &::placeholder {
    font-size: 16px;
    color: ${({ theme, iserror }) => (iserror ? theme.primary : theme.grayA0)};
    font-weight: ${({ theme }) => theme.fontWeightLight};
    font-style: italic;
  }
  &::-webkit-scrollbar {
    width: 9px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.grayF3};
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.grayA0};
  }
`;

export const Select = styled(_Select)`
  width: 100%;
  border: none;
  ${({ isActive, theme }) => isActive && `background-color: ${theme.secondary}`};
  background-color: transparent;

  & .select__indicator {
    width: 20px;
    & path {
      stroke: ${({ theme }) => theme.secondary};
    }
  }
  & .select__indicators {
  }

  & .select__single-value {
    font-size: 18px;
    font-weight: ${({ theme }) => theme.fontWeightMedium};
    color: ${({ isActive, theme }) => isActive ? theme.secondary : theme.textColor69};
    text-transform: none;
  }

  & .select__control {
    min-height: 50px;
  }

  & .select__control--is-focused {
    .select__placeholder {
      display: none;
    }
  }

  & .select__value-container {
    height: auto;
    flex-direction: row;
    justify-content: flex-start;
  }

  & .select__value-container::after {
    left: auto;
    right: 10px;
    top: 50%;
    border-color: ${({ theme }) => theme.textColor69} transparent transparent
      transparent;
    transform: translateY(-50%);
  }

  & .select__menu {
    width: 100%;
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fontWeightMedium};
    color: ${({ theme }) => theme.textColor69};
    text-transform: none;
    background-color: white;
  }

  & .select__option--is-selected.select__option--is-focused {
    color: white;
  }
  & .select__option:active {
    color: white;
  }
  & .select__placeholder {
    color: ${({ theme, hasErrors }) => hasErrors ? theme.primary : theme.grayA0};
    font-weight: ${({ theme }) => theme.fontWeightBold};
    font-size: 18px;
    height: 100%;
    margin: 0 !important;
    text-align: left;
    width: 100%;
  }
`;

export const DateSelect = styled(Select)`
  & .select__option:first-child {
    display: none;
  }
  .select__value-container{
    overflow: visible !important;
  }
  & .select__placeholder{
    color: ${({ theme, hasErrors }) => hasErrors ? theme.primary : theme.grayA0};
    font-weight: ${({ theme }) => theme.fontWeightBold};
    font-size: 18px;
    height: 100%;
    margin: 0 !important;
  }
  & .select__single-value{
    font-weight: ${({ theme }) => theme.fontWeightBold};
    color: ${({ theme, hasErrors }) => hasErrors ? theme.primary : theme.grayA0};
  }
  .select__single-value{
    font-weight: ${({ theme }) => theme.fontWeightBold};
    color: ${({ theme }) => theme.secondary};
  }
    & .select__control {
    min-height: 50px;
    border: none!important;
    background-color: transparent !important;
  }
    & .select__indicators {
    width: 20px;
    & path {
      stroke:  ${({ theme, hasErrors }) => hasErrors ? theme.primary : theme.grayA0};
    }
    }
    & .select__indicators {
      position: absolute;
      top: 81%;
      left: 66%;
      transform: translateX(-50%);
      width: 16px;
  }
    & .select__control{
     padding: 0;
  }
    & .select__menu{
      padding: 10px 0;
      width: 110px;
      height: 360px;
    }
    & .select__menu-list{
      padding: 0 10px;
      max-height: 350px;
    }
    & .select__option{
         // font-weight: ${({ theme }) => theme.fontWeightBold};
        // font-size: 18px;
        // color: ${({ theme }) => theme.grayA0};
    }
`;
export const ToolTip = styled(Tooltip)`
  width: 100%;
`;
export const WhoSeeSelect = styled(Select)`
  & .select__control {
    border: 1px solid #c3c3c3;
    border-radius: 3px;
    background-color: transparent !important;
  }
  & .select__single-value {
    justify-content: flex-start;
    font-weight: ${({ theme }) => theme.fontWeightBold};
    color: ${({ theme }) => theme.secondary};
  }

  & .select__menu {
    text-align: left;
  }
  & .select__indicators {
    & path {
      stroke: ${({ theme, hasErrors }) => hasErrors ? theme.primary : theme.grayA0};
    }
  }
`;

export const DateFieldsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const HourText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeightBold};
  color: ${({ theme }) => theme.secondary};
  margin-left: 5px;
`;
export const DateFieldText = styled.label`
  margin-right: 10px;
  font-style: italic;
  color: ${({ theme }) => theme.textColor69};
`;

export const DateGroupSeparator = styled.span`
  font-size: 18px;
  margin-right: 5px;
  margin-left: 5px;
  color: ${({ theme }) => theme.grayA0};
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

export const SelectWrapper = styled.div`
  display: flex;
`;

export const WhoSeeSelectWrapper = styled(SelectWrapper)`
  max-width: 100%;
  width: 100%;
`;
export const FieldDoubleBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    padding-right: 20px;
  }
  & > div {
    flex-grow: 1;
  }
`;
export const FieldTripleBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const WholeDay = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px;
`;
export const FieldLabel = styled.div`
  textarea {
    border-radius: 0;
    border-bottom: 1px solid
      ${({ hasErrors, theme }) => hasErrors ? theme.primary : theme.textColor69};
    border-bottom: 1px solid
      ${({ noBorder }) => (noBorder ? 'transparent' : 'inherit')};
  }
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  &:last-child {
  }
`;
export const LabelExplanation = styled.p`
  font-size: 18px;
  margin-top: 5px;
  font-weight: ${({ theme }) => theme.fontWeightLight};
  color: ${({ theme }) => theme.textColor69};
  margin-bottom: 15px;
`;

export const FieldLabelText = styled.div`
  text-transform: uppercase;
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeightLight};
  color: ${({ theme }) => theme.textColor69};
`;

export const FieldLabelContent = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.grayA0};
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
  > div {
    width: 100%;
  }
`;

export const FieldError = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.primary};
`;

export const DatePickerStyled = styled.div`
  padding-top: 8px;
  .Dropdown-root {
    .Dropdown-control {
      padding: 8px 24px 6px 14px;
      .Dropdown-arrow-wrapper {
        right: 10px;
        top: 10px;
      }
    }
  }
`;

export const WhoSeeSelectOption = styled.div``;
export const WhoSeeSelectOptionRole = styled.span`
  font-style: italic;
`;

export const ExcuseContainer = styled.div`
  display: flex;
  align-items: center;
  .label {
    padding-bottom: 8px;
  }
`;

export const EventType = styled.div`
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary};
`;
