import styled from 'styled-components';
import { device } from 'styles/device';
import { ReactComponent as StatusItmage } from 'styles/images/svg/statusimage.svg';
import { NavLink } from 'react-router-dom';

export const ArrowGoIcon = styled.div`
  position: absolute;
  display: inline-block;
  top: 50%;
  right: 24px;
  width: 24px;
  height: 24px;
  transform: rotate(180deg) translateY(50%);

  & svg {
    width: 100%;
    transition: transform 0.3s ease;
  }
  & path {
    stroke: ${({ theme }) => theme.secondary};
  }
`;
export const ApplicantTextWrapper = styled.div`
  width: 100%;
  margin-left: 15px;
`;
export const StatusIcon = styled(StatusItmage)`
  opacity: ${({ status }) => (status === 'ACCEPTED' ? 1 : 0)};
  margin-top: 6px;
`;

export const Applicant = styled(NavLink)`
  position: relative;
  margin-top: 8px;
  padding: 14px 15px;
  padding-right: 70px;
  margin-bottom: 10px;
  font-size: 16px;
  color: ${({ theme }) => theme.textColor};
  font-weight: ${({ theme }) => theme.fontWeightUpperBold};
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  display: flex;

  &:hover {
    ${ArrowGoIcon} {
      & svg {
        transform: translateX(-10px);
      }
    }
  }

  &:first-child {
    margin-top: 0;
  }

  @media ${device.tablet} {
    font-size: 14px;
  }

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const ApplicantTop = styled.div``;

export const ApplicantBottom = styled.div`
  margin-top: 8px;
`;

export const ApplicantName = styled.div`
  font-size: 24px;
  color: ${({ theme }) => theme.secondary};
  font-weight: ${({ theme }) => theme.fontWeightBold};

  @media ${device.tablet} {
    font-size: 18px;
  }

  @media ${device.mobile} {
    font-size: 18px;
  }
`;

export const ApplicantInfList = styled.div`
  display: flex;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const ApplicantInfItem = styled.div`
  position: relative;
  padding: 0 12px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 2px;
    height: 14px;
    background-color: ${({ theme }) => theme.textColor};
    border-radius: 4px;
    transform: translateY(-50%);

    @media ${device.tablet} {
      display: none;
    }

    @media ${device.mobile} {
      display: none;
    }
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;

    &::after {
      display: none;
    }
  }

  @media ${device.mobile} {
    padding: 0;
    font-weight: ${({ boldOnMobile, theme }) => boldOnMobile ? theme.fontWeightHeavyBold : theme.fontWeightUpperBold};
  }
`;
