import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Localize from 'components/Localize';
import {
  NotificationTimeAgoContent,
  TimeUnit,
} from './NotificationTimeAgoStyled';

function NotificationTimeAgo({ children, ...rest }) {
  const secondDiff = moment().diff(children, 'seconds');
  const minuteDiff = moment().diff(children, 'minutes');
  const hourDiff = moment().diff(children, 'hours');
  const dayDiff = moment().diff(children, 'days');
  const weekDiff = moment().diff(children, 'weeks');
  let time = '';
  let timeUnit = '';
  if (weekDiff > 0) {
    time = weekDiff;
    timeUnit = 'weeks';
  } else if (dayDiff > 0) {
    time = dayDiff;
    timeUnit = 'days';
  } else if (hourDiff > 0) {
    time = hourDiff;
    timeUnit = 'hrs';
  } else if (minuteDiff > 0) {
    time = minuteDiff;
    timeUnit = 'mins';
  } else {
    time = secondDiff;
    timeUnit = 'sec';
  }
  return (
    <Localize>
      {({ translate }) => (
        <NotificationTimeAgoContent {...rest}>
          <div>{time}</div>
          <TimeUnit>{translate(timeUnit)}</TimeUnit>
        </NotificationTimeAgoContent>
      )}
    </Localize>
  );
}

NotificationTimeAgo.propTypes = {
  children: PropTypes.node,
};

NotificationTimeAgo.defaultProps = {
  children: null,
};

export default NotificationTimeAgo;
