import styled from 'styled-components';

import { device } from 'styles/device';

export const ReportDayAttachmentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin: 0px -10px;
  @media ${device.mobile} {
    margin: 10px 0px;
    justify-content: center;
  }
  .education-entry-thumb {
    width: 45px;
    height: 45px;
    margin: 10px;
    .delete-button {
      top: -10px;
      right: -10px;
      width: 25px;
      height: 25px;
      svg {
        width: 12px;
      }
    }
  }
`;
