import React from 'react';
import PropTypes from 'prop-types';
import { STATE } from 'api/ApplicantApi';
import WhiteArea from 'components/WhiteArea';
import Snake, { ITEM_STATUS } from './Snake';
import todoItems from './TodoItems';

const StateToStatus = {
  [STATE.INACTIVE]: ITEM_STATUS.INACTIVE,
  [STATE.IN_PROGRESS]: ITEM_STATUS.IN_PROGRESS,
  [STATE.DONE]: ITEM_STATUS.DONE,
};

const ApplicantState = ({
  states,
  onItemClick,
  scrollTo,
  selectedInternshipId,
}) => {
  const getStatus = (itemKey) => {
    const stateItem = states
      ? states.find(({ kind }) => kind === itemKey)
      : null;
    if (stateItem && StateToStatus[stateItem.status]) {
      return StateToStatus[stateItem.status];
    }
    return ITEM_STATUS.INACTIVE;
  };

  const items = todoItems.map((item) => ({
    ...item,
    status: getStatus(item.key),
  }));

  return (
    <WhiteArea>
      <Snake
        selectedInternshipId={selectedInternshipId}
        items={items}
        onItemClick={onItemClick}
        scrollTo={scrollTo}
      />
    </WhiteArea>
  );
};

ApplicantState.propTypes = {
  states: PropTypes.arrayOf(
    PropTypes.shape({
      kind: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onItemClick: PropTypes.func,
  scrollTo: PropTypes.func,
  selectedInternshipId: PropTypes.number,
};

ApplicantState.defaultProps = {
  onItemClick: undefined,
  scrollTo: undefined,
  selectedInternshipId: null,
};

export default ApplicantState;
