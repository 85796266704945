import * as types from 'actions/types';
import { createReducer } from 'shared/lib/lib/callAPI';
import initialState from 'store/initialState';

const dataReducer = (state = initialState.iframe, action) => {
  switch (action.type) {
    case types.SET_IFRAME_SRC:
      return {
        ...state,
        iframeSrc: action.iframeSrc,
        language: action.language,
      };
    case types.REFRESH_IFRAME:
      return {
        ...state,
        iframeKey: state.data && state.data.iframeKey + 1,
        language: action.language,
      };
    default:
      return state;
  }
};
export default createReducer(
  [types.SET_IFRAME_SRC, types.REFRESH_IFRAME],
  dataReducer,
);
