import { createGlobalStyle } from 'styled-components';
import fonts from 'shared/lib/styles/fonts';
import reset from 'shared/lib/styles/reset';
import normalize from 'shared/lib/styles/normalize';

export const GlobalStyle = createGlobalStyle`
  ${fonts}
  ${normalize}
  ${reset}
  #root, html, body {
    height: 100%;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-width: 320px;
  }

  body {
    margin: 0;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fontWeightMedium};
    line-height: 22px;
    color: ${({ theme }) => theme.textColor};
    background-color: ${({ theme }) => theme.white};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
