import vacancyApi from 'api/VacancyApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getVacancyList() {
  return createAction({
    type: types.GET_VACANCY_LIST,
    callAPI: () => vacancyApi.getVacancyList(),
  });
}

export function addApplication(vacancy, video, audio) {
  return createAction({
    type: types.ADD_APPLICATION,
    callAPI: () => vacancyApi.addApplication(vacancy, video, audio),
  });
}
