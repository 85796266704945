'use strict';

exports.__esModule = true;
exports.default = undefined;

var _ContactBox = require('./ContactBox');

var _ContactBox2 = _interopRequireDefault(_ContactBox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _ContactBox2.default;
module.exports = exports['default'];