import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from 'styles/images/svg/arrow-triangle-icon.svg';
import { Arrow } from './ArrowControllerStyled';

function ArrowControl({ isNext, isDisabled }) {
  const arrow = (
    <Arrow isNext={isNext}>
      <ArrowIcon />
    </Arrow>
  );

  return !isDisabled ? arrow : null;
}

ArrowControl.propTypes = {
  isNext: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

ArrowControl.defaultProps = {
  isNext: false,
  isDisabled: false,
};

export default ArrowControl;
