import { actions, roles } from '../constants';

export const section = {
  allNotifications: 'allNotifications',
};

export default (role, can, rules) => {
  if (role === roles.Teacher) {
    can(actions.see, section.allNotifications);
  }

  if (role === roles.CompanyEmployee) {
    can(actions.see, section.allNotifications);
  }

  if (role === roles.EducationProvider) {
    can(actions.see, section.allNotifications);
  }
  return rules;
};
