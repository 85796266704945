import ServerApi from 'shared/lib/api/ServerApi';

class InternshipRequestApi extends ServerApi {
  changeInternshipRequest(pk, data) {
    return super.patch(`/internship/internship-request/${pk}/`, data);
  }

  getInternshipsRequestsList() {
    return super.get('/internship/internship-request/?limit=100');
  }

  deleteInternshipRequest(pk) {
    return super.delete(`/internship/internship-request/${pk}/`);
  }

  uploadInternshipRequestAttachment(requestId, file) {
    return super.post('/internship/internship-request-attachment/', {
      internship_request_id: requestId,
      file,
    });
  }

  deleteInternshipRequestAttachment(fileId) {
    return super.delete(`/internship/internship-request-attachment/${fileId}/`);
  }

  addInternshipRequest() {
    return super.post('/internship/internship-request/');
  }
}

export default new InternshipRequestApi();
