import React from 'react';
import PropTypes from 'prop-types';
import { SearchInput, SearchInputWrap, SearchIconWrap } from './InputStyled';

function Input({ icon, className, ...props }) {
  return (
    <SearchInputWrap className={className}>
      {
        icon && (
        <SearchIconWrap className="icon-wrapper">
          { icon }
        </SearchIconWrap>
        )
      }
      <SearchInput {...props} />
    </SearchInputWrap>
  );
}

Input.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
};

Input.defaultProps = {
  icon: undefined,
  className: undefined,
};

export default Input;
