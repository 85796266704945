'use strict';

exports.__esModule = true;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var KEY = 'accessToken';

var AssessTokenStorage = function () {
  function AssessTokenStorage() {
    _classCallCheck(this, AssessTokenStorage);
  }

  AssessTokenStorage.isExist = function isExist() {
    return !!this.get();
  };

  AssessTokenStorage.get = function get() {
    return localStorage.getItem(KEY);
  };

  AssessTokenStorage.set = function set(accessToken) {
    localStorage.setItem(KEY, accessToken);
  };

  AssessTokenStorage.remove = function remove() {
    localStorage.removeItem(KEY);
  };

  return AssessTokenStorage;
}();

exports.default = AssessTokenStorage;
module.exports = exports['default'];