import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Prompt } from 'shared';
import Localize from 'components/Localize';
import CheckBox from 'components/Form/CheckBox';
import { completePresentation } from 'actions/internshipsActions';
import { getPresentationText } from 'pages/ApplicantProfile/applicantProfileUtils';
import { roles } from 'utils/access-rules/constants';

const CompletingPresentation = ({
  status,
  internshipId,
  completePresentation,
  currentUser,
}) => (
  <Prompt
    confirm={() => completePresentation(internshipId, !status)}
    trigger={(
      <CheckBox value={status} disabled={currentUser.role !== roles.Teacher}>
        <Localize id={getPresentationText(currentUser.role)} />
      </CheckBox>
)}
  >
    {status ? (
      <Localize id="are-you-sure-you-would-set-presentation-not-comple" />
    ) : (
      <Localize id="are-you-sure-you-would-set-presentation-completed" />
    )}
  </Prompt>
);

CompletingPresentation.propTypes = {
  status: PropTypes.bool.isRequired,
  internshipId: PropTypes.number.isRequired,
  completePresentation: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({ currentUser }) => ({
  currentUser: currentUser.data,
});

export default connect(
  mapStateToProps,
  { completePresentation },
)(CompletingPresentation);
