import styled from 'styled-components';
import { device } from 'styles/device';

export const ItemContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  max-width: 380px;
  min-width: 380px;
  padding: 10px 20px;
  border-radius: ${({ theme }) => theme.borderRadiusDefault};
  margin: 20px;
  flex: 1;
  align-self: flex-start;
  .paragraph .title {
    font-size: 16px;
  }
  .document-thumb {
    width: 100px;
    height: 100px;
  }
  @media ${device.tablet} {
    width: 230px;
  }
  @media ${device.mobile} {
    width: 100%;
    max-width: initial;
    min-width: initial;
    margin: 10px auto;
  }
`;

export const CameraCircle = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.grayF3};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  img {
    max-width: 100%;
  }
`;

export const ItemData = styled.ul`
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme, isFilled }) => isFilled ? theme.secondary : theme.grayA0};
`;

export const ButtonsContainer = styled.div`
  button {
    width: 100%;
    margin: 10px 0px;
  }
`;

export const StatusIcon = styled.div`
  position: absolute;
  top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ color, theme }) => color === 'green' ? theme.green : theme.warningColor};
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    svg {
      color: white;
    }
  }
`;

export const DeleteIcon = styled.div`
  position: absolute;
  top: 25px;
  right: 20px;
  cursor: pointer;
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    svg {
      color: ${({ theme }) => theme.grayA0};
      font-size: 24px;
      width: 22px;
      height: 25px;
    }
    svg path {
      stroke: ${({ theme }) => theme.primary};
    }
  }
  &:hover {
    opacity: 0.8;
  }
`;
