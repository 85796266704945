import moment from 'moment';
import { roles } from 'utils/access-rules/constants';

export const getSubmittedStatusByRole = (assessment, role) => {
  if (role === roles.Applicant) {
    return assessment.applicant_submitted_at;
  }
  if (role === roles.CompanyEmployee) {
    return assessment.company_employee_submitted_at;
  }
  if (role === roles.EducationProvider) {
    return assessment.education_provider_submitted_at;
  }
};

export const getSavedDateByRole = (assessment, role) => {
  if (role === roles.Applicant) {
    return assessment.applicant_submitted_at || assessment.applicant_updated_at;
  }
  if (role === roles.CompanyEmployee) {
    return (
      assessment.company_employee_submitted_at
      || assessment.company_employee_updated_at
    );
  }
  if (role === roles.EducationProvider) {
    return (
      assessment.education_provider_submitted_at
      || assessment.education_provider_updated_at
    );
  }
  if (role === roles.Teacher) {
    return assessment.teacher_updated_at;
  }
};

export const getSubmittedProperty = (assessment, role) => {
  const {
    applicant_submitted_at,
    company_employee_submitted_at,
    education_provider_submitted_at,
  } = assessment;
  const now = moment()
    .utc()
    .format();
  if (role === roles.Applicant) {
    return {
      applicant_submitted_at: now,
      completed: !!(
        company_employee_submitted_at && education_provider_submitted_at
      ),
    };
  }
  if (role === roles.CompanyEmployee) {
    return {
      company_employee_submitted_at: now,
      completed: !!(applicant_submitted_at && education_provider_submitted_at),
    };
  }
  if (role === roles.EducationProvider) {
    return {
      education_provider_submitted_at: now,
      completed: !!(company_employee_submitted_at && applicant_submitted_at),
    };
  }
};

export const resetSubmittedProperty = (role) => {
  if (role === roles.Applicant) {
    return {
      applicant_submitted_at: null,
      completed: false,
    };
  }
  if (role === roles.CompanyEmployee) {
    return {
      company_employee_submitted_at: null,
      completed: false,
    };
  }
  if (role === roles.EducationProvider) {
    return {
      education_provider_submitted_at: null,
      completed: false,
    };
  }
};

export const getQuestionnaireTableHeadText = (role) => {
  if (role === roles.Teacher) {
    return 'teacher-questionnaire-table-head';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-questionnaire-table-head';
  }
  if (role === roles.EducationProvider) {
    return 'provider-questionnaire-table-head';
  }
  return 'teacher-questionnaire-table-head';
};
