import { actions, roles } from '../constants';

export const section = {
  resendButton: 'resendButton',
  submitButton: 'submitButton',
};

export default (role, can, rules) => {
  if (role === roles.Teacher) {
    can(actions.see, section.resendButton);
  }
  if (role === roles.EducationProvider) {
    can(actions.see, section.submitButton);
  }
  if (role === roles.CompanyEmployee) {
    can(actions.see, section.submitButton);
  }
  if (role === roles.Applicant) {
    can(actions.see, section.submitButton);
  }
  return rules;
};
