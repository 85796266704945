import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import Can, { actions } from 'components/Can';

const CanRoute = ({
  to, path, component: Component, ...elseProps
}) => (
  <Route
    path={path}
    {...elseProps}
    render={(props) => (
      <Can I={actions.see} a={to} passThrough>
        {(can) => can ? <Component {...props} /> : <Redirect to="/" />}
      </Can>
    )}
  />
);


CanRoute.propTypes = {
  to: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};

export default CanRoute;
