import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';

export const ResizableTextarea = ({
  disabled,
  error,
  handleChange,
  value,
  ...props
}) => {
  const [state, setState] = useState({
    rows: 1,
    minRows: 1,
    maxRows: 10,
  });

  const textAreaRef = useRef(null);

  const setInitialSize = () => {
    const textareaLineHeight = 24;
    const { maxRows } = state;
    // eslint-disable-next-line no-bitwise
    const currentRows = ~~(
      textAreaRef.current.scrollHeight / textareaLineHeight
    );
    setState((state) => ({
      ...state,
      rows: currentRows < maxRows ? currentRows : maxRows,
    }));
  };

  const stableSetInitialSize = useCallback(setInitialSize, []);

  useEffect(() => {
    stableSetInitialSize();
  }, [stableSetInitialSize]);

  const onChange = (event) => {
    const textareaLineHeight = 24;
    const { minRows, maxRows } = state;

    const previousRows = event.target.rows;
    event.target.rows = minRows; // eslint-disable-line no-param-reassign

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight); // eslint-disable-line no-bitwise

    if (currentRows === previousRows) {
      event.target.rows = currentRows; // eslint-disable-line no-param-reassign
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows; // eslint-disable-line no-param-reassign
      event.target.scrollTop = event.target.scrollHeight; // eslint-disable-line no-param-reassign
    }

    setState({
      ...state,
      rows: currentRows < maxRows ? currentRows : maxRows,
    });
    handleChange(event);
  };

  const { rows } = state;

  return (
    <textarea
      {...props}
      ref={textAreaRef}
      disabled={disabled}
      error={error}
      rows={rows}
      value={value}
      onChange={onChange}
    />
  );
};

ResizableTextarea.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
};

ResizableTextarea.defaultProps = {
  value: '',
  error: '',
  disabled: false,
  handleChange: undefined,
};
