import styled from 'styled-components';
import BellNotification from 'components/BellNotification';
import { device } from 'styles/device';

export const StyledBellNotification = styled(BellNotification)`
  margin-right: 10px;
  @media ${device.mobile} {
    background-color: ${({ isUserDropdownOpen }) => isUserDropdownOpen && 'unset'};
    position: ${({ isUserDropdownOpen }) => isUserDropdownOpen && 'absolute'};
  }
`;
