import { getDefaultLanguage } from 'shared/lib/lib/languages';
import * as types from './types';

export function setIframeSrc(iframeSrc) {
  return (dispatch) => {
    dispatch({
      type: types.SET_IFRAME_SRC,
      iframeSrc,
      language: getDefaultLanguage(),
    });
  };
}

export function refreshIframe() {
  return (dispatch) => {
    dispatch({
      type: types.REFRESH_IFRAME,
      language: getDefaultLanguage(),
    });
  };
}
