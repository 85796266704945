export const size = {
  mobile: 500,
  tablet: 768,
  laptop: 1024,
  desktop: 1500,
};

export const device = {
  mobile: `(max-width: ${size.tablet - 1}px)`,
  tablet: `(min-width: ${size.tablet}px) and (max-width: ${size.laptop - 1}px)`,
  laptop: `(min-width: ${size.laptop}px) and (max-width: ${size.desktop - 1}px)`,
  desktop: `(min-width: ${size.desktop})`,
};
