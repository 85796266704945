import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Prompt } from 'shared';
import Localize from 'components/Localize';
import CheckBox from 'components/Form/CheckBox';

import { completePresentation } from 'actions/internshipsActions';
import {
  CompletePresentationWrapper,
  ButtonClose,
} from '../ActionDropdownStyled';
import ActionResults from '../../ActionResults';
import { UnaffectedUsers } from '../../ManageStudentsStyled';

const SetPresentationCompleted = ({
  selectedApplicants,
  selectedInternship,
  showActionResult,
  closeActionModel,
  completePresentation,
}) => {
  const handleSubmit = (checked) => {
    const setPresentation = selectedApplicants.map((applicant) => {
      const selectedApplicantInternship = selectedInternship[applicant.pk];
      if (
        selectedApplicantInternship
        && selectedApplicantInternship.value
        && !selectedApplicantInternship.value.presentation_completed
      ) {
        return completePresentation(
          selectedApplicantInternship.value.pk,
          checked,
        )
          .then(() => ({
            result: 'success',
            user: applicant.esuser.user,
          }))
          .catch(() => ({
            result: 'failed',
            user: applicant.esuser.user,
          }));
      }
      return null;
    });
    return Promise.all(setPresentation).then((values) => {
      showActionResult(
        <ActionResults results={values} onClose={closeActionModel} />,
      );
    });
  };
  const unaffectedUsers = selectedApplicants.filter(
    (applicant) => !(
      selectedInternship[applicant.pk]
        && selectedInternship[applicant.pk].value
        && !selectedInternship[applicant.pk].value.presentation_completed
    ),
  );
  return (
    <div>
      <CompletePresentationWrapper>
        <Prompt
          confirm={() => handleSubmit(true)}
          trigger={(
            <CheckBox>
              <Localize id="teacher-presentation-text" />
            </CheckBox>
)}
        >
          <Localize id="are-you-sure-you-would-set-presentation-completed" />
        </Prompt>
        <ButtonClose type="button" onClick={closeActionModel}>
          <FontAwesomeIcon icon={faTimes} />
        </ButtonClose>
      </CompletePresentationWrapper>
      {unaffectedUsers.length > 0 && (
        <UnaffectedUsers>
          <Localize id="following-users-will-not-be-affected" />
          {unaffectedUsers.map(
            (applicant) => ` ${applicant.esuser.user.first_name} ${applicant.esuser.user.last_name},`,
          )}
        </UnaffectedUsers>
      )}
    </div>
  );
};

SetPresentationCompleted.propTypes = {
  selectedApplicants: PropTypes.array.isRequired,
  selectedInternship: PropTypes.shape({}).isRequired,
  showActionResult: PropTypes.func.isRequired,
  closeActionModel: PropTypes.func.isRequired,
  completePresentation: PropTypes.func.isRequired,
};

export default connect(
  undefined,
  {
    completePresentation,
  },
)(SetPresentationCompleted);
