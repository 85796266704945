import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Page from 'components/Page';
import arrowRight from 'styles/images/svg/arrow-right.svg';
import Localize from 'components/Localize';
import InformationModal from 'components/InformationModal';
import { ReactComponent as InfoIcon } from 'styles/images/svg/info.svg';
import {
  Form, InputField, Button, Error, Loader,
} from 'shared';
import Textarea from 'components/Form/Textarea';
import InternshipSelectionProvider from 'components/InternshipSwitcher/InternshipSelectionProvider';
import {
  getComment,
  changeComment,
} from '../../actions/internshipCommentActions';
import {
  ButtonsContainer,
  CommentTitle,
  CommentWrapper,
} from './InternshipCommentStyled';

const InternshipComment = ({
  loading,
  error,
  comment,
  getComment: _getComment,
  changeComment: _changeComment,
  user,
  history,
  selectedInternship,
}) => {
  const stableGetComment = useCallback(_getComment, [selectedInternship]);
  useEffect(() => {
    if (selectedInternship) stableGetComment(selectedInternship.pk);
  }, [stableGetComment, selectedInternship]);
  const [informationModalOpen, setInformationModalOpen] = useState('');
  const onSubmit = (data) => _changeComment(selectedInternship.pk, data).then(() => {
    if (user.role === 'APPLICANT') {
      history.push(`/applicant/${selectedInternship.pk || ''}`);
    }
  });
  return (
    <>
      <InformationModal
        open={informationModalOpen}
        onCloseModal={() => setInformationModalOpen('')}
      />
      <Page
        title={<Localize id="internship-comment-title" />}
        backTo={`/applicant/${(selectedInternship && selectedInternship.pk)
          || ''}`}
        description={(
          <div>
            <Localize id="internship-comment-description" />
          </div>
        )}
        icon={<InfoIcon />}
        onClick={() => setInformationModalOpen('SELF_ASSESSMENT')}
      >
        {loading && <Loader />}
        <InternshipSelectionProvider>
          {() => (
            <Localize>
              {({ translate }) => (
                <Form
                  initialValues={{
                    comment,
                  }}
                  enableReinitialize
                >
                  {({ values, dirty, isSubmitting }) => {
                    const submit = () => onSubmit(values);
                    return (
                      <div>
                        <CommentWrapper>
                          <CommentTitle>
                            <Localize id="write-your-comment-here" />
                          </CommentTitle>
                          <InputField
                            rows={2}
                            name="comment"
                            placeholder={translate('comment')}
                            component={Textarea}
                          />
                        </CommentWrapper>
                        <ButtonsContainer>
                          {error && <Error />}
                          <Button
                            loading={isSubmitting}
                            disabled={!dirty}
                            onClick={submit}
                            type="button"
                            className="btn-submit"
                            icon={arrowRight}
                            disableBoxShadow
                            color="secondary"
                            size="small"
                          >
                            <Localize id="submit-report" />
                          </Button>
                        </ButtonsContainer>
                      </div>
                    );
                  }}
                </Form>
              )}
            </Localize>
          )}
        </InternshipSelectionProvider>
      </Page>
    </>
  );
};

InternshipComment.propTypes = {
  error: PropTypes.shape({
    detail: PropTypes.string,
  }),
  user: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  comment: PropTypes.string,
  answerChoices: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  getComment: PropTypes.func.isRequired,
  changeComment: PropTypes.func.isRequired,
  selectedInternship: PropTypes.shape({
    pk: PropTypes.number.isRequired,
  }).isRequired,
};

InternshipComment.defaultProps = {
  error: null,
  comment: null,
  answerChoices: null,
};

const mapStateToProps = ({
  comment: { data, loading, error },
  currentUser,
  selectedInternship,
}) => ({
  user: currentUser && currentUser.data,
  loading,
  error,
  comment: data && data.comment,
  selectedInternship,
});

export default connect(mapStateToProps, {
  getComment,
  changeComment,
})(InternshipComment);
