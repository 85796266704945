import React from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import {
  Error, Form, InputField, SecondaryButton,
} from 'shared';
import { login } from 'actions/authActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect, withRouter } from 'react-router-dom';
import { parse } from 'qs';
import {
  LoginWrap,
  MoodleButton,
  ButtonsContainer,
  StyledLink,
} from './LoginStyled';
import moodleIcon from '../../styles/images/svg/moodle.svg';

const Login = ({
  login, location, history, isAuthenticated,
}) => {
  const handleSubmit = ({ email, password }, formik) => login(email, password).then(() => {
    formik.resetForm();
    const { next } = parse(location.search.slice(1));
    history.push(next);
  });

  if (isAuthenticated) {
    const { next } = parse(location.search.slice(1));
    return <Redirect to={next} />;
  }
  return (
    <>
      <Form
        initialValues={{
          email: '',
          password: '',
        }}
        validateSchema={{
          email: {
            required: true,
            email: true,
          },
          password: { required: true },
        }}
        noPrompt
        onSubmit={handleSubmit}
      >
        {(form) => (
          <Localize>
            {({ translate }) => (
              <LoginWrap>
                {form.errors._error && <Error message={form.errors._error} />}
                <InputField
                  name="email"
                  autoComplete="username"
                  rounded
                  placeholder={translate('e-mail')}
                />
                <InputField
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  rounded
                  placeholder={translate('password')}
                />
                <ButtonsContainer>
                  <SecondaryButton
                    id="login-button"
                    type="submit"
                    disabled={!form.dirty}
                    loading={form.isSubmitting}
                  >
                    {translate('login-button')}
                  </SecondaryButton>
                  <StyledLink to="/forgot-password">
                    <Localize id="forgot-password" />
                  </StyledLink>
                </ButtonsContainer>
                <MoodleButton
                  id="moodle-login-button"
                  type="button"
                  icon={moodleIcon}
                  // disabled={!form.dirty}
                  // loading={form.isSubmitting}
                >
                  {translate('continue-sso')}
                </MoodleButton>
              </LoginWrap>
            )}
          </Localize>
        )}
      </Form>
    </>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = ({ currentUser: { data } }) => ({
  isAuthenticated: !!data,
});

export default compose(withRouter, connect(mapStateToProps, { login }))(Login);
