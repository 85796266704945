import styled from 'styled-components';
import _NotificationIcon from 'components/NotificationGeneralComponents/CircleBadge';

export const ChatTabWrapper = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  position: relative;
  padding: 10px 12px;
  height: 73px;
  ${({ isTabActive, theme }) => isTabActive && `color: ${theme.secondary}`};
  border-bottom: 2px solid;
  border-color: ${({ theme }) => theme.grayF3};
  border-bottom-color: ${({ isTabActive }) => isTabActive && 'white'};
  background-color: ${({ isTabActive, theme }) => isTabActive ? '#fff' : theme.grayF3};
  transition: background-color 0.3s, border-color 0.3s ease;
  border-right: 2px solid rgba(0, 0, 0, 0.05);
  cursor: ${({ isTabActive }) => !isTabActive && 'pointer'};
  &:hover {
    background-color: #fff;
    border-bottom-color: ${({ isTabActive }) => isTabActive && 'transparent'};
  }
  width: ${({ isTabActive, isGroupChatTab }) => isTabActive || isGroupChatTab ? 'auto' : '70px'};
  &.assessment {
    width: auto;
    height: auto;
  }
  padding: 10px;
  @media (max-width: 993px) {
    width: ${({ isTabActive, isGroupChatTab }) => isTabActive || isGroupChatTab ? 'auto' : '70px'};
    padding: 10px;
  }
  @media (max-width: 480px) {
    width: ${({ isTabActive, isGroupChatTab }) => isTabActive || isGroupChatTab ? 'auto' : '70px'};
    padding: 10px;
  }
`;

export const NotificationIcon = styled(_NotificationIcon)`
  left: 0;
  top: 10px;
  left: 12px;
  width: 11px;
  height: 11px;
`;
