'use strict';

exports.__esModule = true;
exports.GroupInputWrap = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  margin-bottom: ', ';\n'], ['\n  margin-bottom: ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var GroupInputWrap = exports.GroupInputWrap = _styledComponents2.default.div(_templateObject, function (_ref) {
  var withBottomMargin = _ref.withBottomMargin;
  return withBottomMargin ? '10px' : '0';
});