import { actions, roles } from '../constants';

export const section = {
  freeGuide: 'freeGuide',
};

export default (role, can, rules) => {
  if (role === roles.Applicant) {
    can(actions.see, section.freeGuide);
  }
  if (role === roles.Teacher) {
    can(actions.see, section.freeGuide);
  }
  if (role === roles.EducationProvider) {
    can(actions.see, section.freeGuide);
  }
  return rules;
};
