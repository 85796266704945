import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';

import { addMessage } from 'actions/chatActions';
import chatApi from 'api/ChatApi';
import SendMessage from '../SendMessage';
import ActionResults from '../../ActionResults';
import { UnaffectedUsers } from '../../ManageStudentsStyled';

const SendMessageToTeacher = ({
  selectedApplicants,
  selectedInternship,
  showActionResult,
  closeActionModel,
  addMessage,
}) => {
  const handleSubmit = ({ message }, form) => {
    if (message) {
      const sendMessage = selectedApplicants.map((applicant) => {
        const selectedApplicantInternship = selectedInternship[applicant.pk];
        if (selectedApplicantInternship && selectedApplicantInternship.value) {
          return chatApi
            .getUsers(selectedApplicantInternship.value.pk)
            .then((users) => {
              const activeUser = users.find((user) => user.role === 'TEACHER');
              if (activeUser) {
                const messageToAdd = {
                  internship_id: selectedApplicantInternship.value.pk,
                  message,
                  recepient: activeUser.api_url,
                };
                return addMessage(
                  selectedApplicantInternship.value.pk,
                  messageToAdd,
                )
                  .then(() => ({
                    result: 'success',
                    user: applicant.esuser.user,
                  }))
                  .catch(() => ({
                    result: 'failed',
                    user: applicant.esuser.user,
                  }));
              }
            });
        }
        return null;
      });
      return Promise.all(sendMessage)
        .then((values) => {
          showActionResult(
            <ActionResults results={values} onClose={closeActionModel} />,
          );
        })
        .then(() => form.resetForm());
    }
  };
  const unaffectedUsers = selectedApplicants.filter(
    (applicant) => !(
      selectedInternship[applicant.pk]
        && selectedInternship[applicant.pk].value
    ),
  );
  return (
    <div>
      <SendMessage
        handleSubmit={handleSubmit}
        submitButtonText="send-message-to-teacher"
        handleClose={closeActionModel}
      />
      {unaffectedUsers.length > 0 && (
        <UnaffectedUsers>
          <Localize id="following-users-will-not-be-affected" />
          {unaffectedUsers.map(
            (applicant) => ` ${applicant.esuser.user.first_name} ${applicant.esuser.user.last_name},`,
          )}
        </UnaffectedUsers>
      )}
    </div>
  );
};

SendMessageToTeacher.propTypes = {
  selectedApplicants: PropTypes.array.isRequired,
  selectedInternship: PropTypes.shape({}).isRequired,
  showActionResult: PropTypes.func.isRequired,
  closeActionModel: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
};

export default connect(
  undefined,
  {
    addMessage,
  },
)(SendMessageToTeacher);
