import React from 'react';
import PropTypes from 'prop-types';
import { TextArea, TextAreaWrapper } from './TextareaStyled';

const Textarea = ({ className, invalid, ...props }) => (
  <TextAreaWrapper className={className} invalid={invalid}>
    <TextArea {...props} />
  </TextAreaWrapper>
);

Textarea.propTypes = {
  className: PropTypes.string,
  invalid: PropTypes.bool,
};

Textarea.defaultProps = {
  className: undefined,
  invalid: false,
};

export default Textarea;
