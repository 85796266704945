import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ChatWrapper } from './ChatStyled';
import ChatTabPanel, { TABS } from './ChatTabPanel';
import ChatMessagesPanel from './ChatMessagesPanel';

const getActiveTabMessages = ({ activeUser, room }, messages) => {
  if (room) {
    const roomMessages = messages
      .filter((m) => m.room === room.api_url)
      .sort((a, b) => (a.pk > b.pk ? 1 : -1));
    const roomMessagesFiltered = roomMessages.filter((m, index) => {
      const isSameMessage = index !== 0
        && m.created === roomMessages[index - 1].created // same creating date
        && m.sender.pk === roomMessages[index - 1].sender.pk; // same sender pk
      return !isSameMessage;
    });
    const roomMessagesRead = roomMessagesFiltered.map((message) => {
      const readMessage = messages.find(
        (m) => m.room === message.room && m.read_at && m.created === message.created,
      );
      if (readMessage) return readMessage;
      return message;
    });
    return roomMessagesRead;
  }
  return messages.filter(
    (m) => m.recepient
      && m.sender
      && activeUser
      && (m.recepient.pk === activeUser.pk || m.sender.pk === activeUser.pk)
      && !m.room,
  );
};

const getOwner = (users, chatOwner) => {
  const owner = users.find((user) => user.is_owner);
  if (owner) return owner;
  return chatOwner;
};

const getActiveTab = (users, selectedTab) => {
  let selectedUser = users[0];
  if (selectedTab) {
    const user = users.find((u) => u.pk === selectedTab);
    if (user) selectedUser = user;
  }
  return {
    name: selectedUser.role,
    activeUser: selectedUser,
    id: selectedUser.pk,
  };
};

const Chat = ({
  users,
  internshipId,
  messages,
  globalRooms,
  internshipRooms,
  chatOwner,
  selectedTab,
}) => {
  const usersWithoutCurrent = users.filter((user) => user.is_owner === false);
  const owner = getOwner(users, chatOwner);
  const groupChat = globalRooms.find((r) => r.name === 'Gruppenchat (alle)');
  const groupChatWithoutStudent = internshipId
    && internshipRooms.find((r) => r.name === 'Begleitchat (ohne Schüler)');
  const [activeTab, setActiveTab] = useState(
    getActiveTab(usersWithoutCurrent, selectedTab),
  );
  const handleChangeTab = (id) => {
    if (groupChat && id === groupChat.api_url) {
      setActiveTab({
        name: TABS.GROUP_CHAT,
        room: groupChat,
        id: groupChat.api_url,
      });
      return;
    }
    if (groupChatWithoutStudent && id === groupChatWithoutStudent.api_url) {
      setActiveTab({
        name: TABS.GROUP_CHAT_WITHOUT_STUDENT,
        room: groupChatWithoutStudent,
        id: groupChatWithoutStudent.api_url,
      });
      return;
    }
    const activeUser = users.find((user) => user.pk === id);
    setActiveTab({ name: activeUser.role, activeUser, id: activeUser.pk });
  };
  const activeTabMessages = getActiveTabMessages(activeTab, messages);

  const activeMessages = activeTabMessages.filter(
    (m) => m?.recepient?.pk === owner?.pk || m?.sender?.pk === owner?.pk,
  );
  const noReadMessages = activeTabMessages.filter((m) => !m.read_at);
  return (
    <ChatWrapper>
      <ChatTabPanel
        activeTabName={activeTab.name}
        activeTabId={activeTab.id}
        users={usersWithoutCurrent}
        handleChangeTab={handleChangeTab}
        groupChat={groupChat}
        groupChatWithoutStudent={groupChatWithoutStudent}
        messages={messages}
      />
      <ChatMessagesPanel
        key={activeTab.id + activeMessages.length + noReadMessages.length}
        messages={activeMessages}
        activeTab={activeTab}
        internshipId={internshipId}
      />
    </ChatWrapper>
  );
};

Chat.propTypes = {
  internshipId: PropTypes.number,
  users: PropTypes.array.isRequired,
  messages: PropTypes.array.isRequired,
  globalRooms: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  internshipRooms: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  chatOwner: PropTypes.shape({
    pk: PropTypes.number.isRequired,
  }),
  selectedTab: PropTypes.number,
};

Chat.defaultProps = {
  internshipId: null,
  chatOwner: null,
  selectedTab: null,
};

export default Chat;
