import ServerApi from 'shared/lib/api/ServerApi';
import { getDefaultLanguage } from 'shared/lib/lib/languages';

class KindApi extends ServerApi {
  getKindList() {
    return super.get(
      `/recruiting/vacancykind/?language=${getDefaultLanguage()}`,
    );
  }
}

export default new KindApi();
