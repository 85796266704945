import moment from 'moment';
import { roles } from 'utils/access-rules/constants';

export const columnNames = {
  NAME_SURNAME: 'name-surname',
  LAST_LOGIN: 'last-login',
  INTERNSHIP: 'internship',
  INTERNSHIP_STATUS: 'internship-status',
  OPERATIONAL_LEARNING_TASK: 'operational-learning-task',
  INTERNSHIP_REPORT: 'provider-report-text',
  STUDENT_ASSESSMENT: 'student-assessment',
  EDUCATIONAL_ADVISOR_ASSESSMENT: 'educational-advisor-assessment',
  COMPANY_ASSESSMENT: 'company-assessment',
  TEACHER_ASSESSMENT: 'teacher-assessment',
  PRESENTATION_COMPLETED: 'presentation-completed',
};

export const filterTypes = {
  APPROVED: 'approved',
  WAITING_FOR_TEACHERS_APPROVAL: 'waiting-for-teachers-approval',
  APPLIED: 'applied',
  LOOKED_BUT_NOT_APPLIED: 'looked-but-not-applied',
  SUBMITTED_COMPANY_COMMENTED: 'submitted-company-commented',
  SUBMITTED: 'submitted',
  SAVED: 'saved',
  SET: 'set',
  WRITTEN: 'written',
  YES: 'yes',
  NOT_LOOKED_YET: 'not-looked-yet',
  NOT_SET_YET: 'not-set-yet',
  NOT_WRITTEN_YET: 'not-written-yet',
  NOT_FILLED: 'not-filled',
  NOT_YET: 'not-yet',
  NEVER: 'never',
};

export const filterMap = {
  [columnNames.INTERNSHIP_STATUS]: {
    [filterTypes.APPROVED]: 'approved_at',
    [filterTypes.WAITING_FOR_TEACHERS_APPROVAL]: 'accepted_by_teacher_at',
    [filterTypes.APPLIED]: 'applied',
    [filterTypes.LOOKED_BUT_NOT_APPLIED]: 'reacted_and_not_applied',
  },
  [columnNames.OPERATIONAL_LEARNING_TASK]: {
    [filterTypes.SUBMITTED_COMPANY_COMMENTED]:
      'olt_submited_and_company_commented',
    [filterTypes.SUBMITTED]: 'olt_submitted_at',
    [filterTypes.SAVED]: 'olt_report_changed',
    [filterTypes.SET]: 'olt_task_changed',
  },
  [columnNames.INTERNSHIP_REPORT]: {
    [filterTypes.SUBMITTED]: 'internship_report_submitted',
    [filterTypes.WRITTEN]: 'internship_report_exists',
  },
  [columnNames.STUDENT_ASSESSMENT]: {
    [filterTypes.SUBMITTED]: 'assessment_applicant_submitted_at',
    [filterTypes.SAVED]: 'assessment_applicant_updated_at',
  },
  [columnNames.EDUCATIONAL_ADVISOR_ASSESSMENT]: {
    [filterTypes.SUBMITTED]: 'assessment_education_provider_submitted_at',
    [filterTypes.SAVED]: 'assessment_education_provider_updated_at',
  },
  [columnNames.COMPANY_ASSESSMENT]: {
    [filterTypes.SUBMITTED]: 'assessment_company_employee_submitted_at',
    [filterTypes.SAVED]: 'assessment_company_employee_updated_at',
  },
  [columnNames.TEACHER_ASSESSMENT]: {
    [filterTypes.SUBMITTED]: 'assessment_teacher_submitted_at',
    [filterTypes.SAVED]: 'assessment_teacher_updated_at',
  },
  [columnNames.PRESENTATION_COMPLETED]: {
    [filterTypes.YES]: 'presentation_completed',
  },
};

export const notFilters = [
  filterTypes.NOT_LOOKED_YET,
  filterTypes.NOT_SET_YET,
  filterTypes.NOT_WRITTEN_YET,
  filterTypes.NOT_FILLED,
  filterTypes.NOT_YET,
];

export const getClassroomOptions = (applicants) => applicants
  .map(({ classroom }) => ({
    label: classroom.name,
    value: classroom.pk,
  }))
  .filter((c, i, a) => a.map((q) => q.value).indexOf(c.value) === i);

export const getSchoolNameOptions = (applicants) => applicants
  .reduce((a, b) => {
    if (!a.find((element) => element.value === b.classroom.school.name)) {
      a.push({
        label: b.classroom.school.name,
        value: b.classroom.school.pk,
      });
    }
    return a;
  }, [])
  .filter((c, i, a) => a.map((q) => q.value).indexOf(c.value) === i);

export const getCurrentInternships = (applicant) => applicant.esuser.internships.filter((internship) => {
  const startDate = moment(internship.starts_at);
  const endDate = moment(internship.ends_at);
  return moment().isBetween(startDate, endDate);
});

export const compareEndDate = (a, b) => moment(b.ends_at) - moment(a.ends_at);

export const actionTypes = {
  ADD_TODO: 'add-todo',
  SET_OLT: 'set-olt',
  SET_ON_SITE_MEETING: 'set-on-site-meeting',
  SET_EVENT: 'set-event',
  ENTER_ABSENCE: 'enter-absence',
  SEND_MESSAGE_TO_STUDENT: 'send-message-to-student',
  SEND_MESSAGE_TO_TEACHER: 'send-message-to-teacher',
  SEND_MESSAGE_TO_EDU_ADV: 'send-message-to-edu-adv',
  SEND_MESSAGE_TO_COMPANY: 'send-message-to-company',
  SEND_MESSAGE_TO_GROUP_CHAT: 'send-message-to-group-chat',
  SET_PRESENTATION_COMPLETED: 'set-presentation-completed',
};

export const actionOptions = [
  {
    value: actionTypes.ADD_TODO,
    disability: false,
  },
  {
    value: actionTypes.SET_OLT,
    disability: true,
    notAllowed: roles.CompanyEmployee,
  },
  {
    value: actionTypes.SET_ON_SITE_MEETING,
    disability: true,
    notAllowed: roles.CompanyEmployee,
  },
  {
    value: actionTypes.SET_EVENT,
    disability: false,
  },
  {
    value: actionTypes.ENTER_ABSENCE,
    disability: false,
  },
  {
    value: actionTypes.SEND_MESSAGE_TO_STUDENT,
    disability: false,
  },
  {
    value: actionTypes.SEND_MESSAGE_TO_EDU_ADV,
    disability: true,
  },
  {
    value: actionTypes.SEND_MESSAGE_TO_TEACHER,
    disability: true,
    notAllowed: roles.Teacher,
  },
  {
    value: actionTypes.SEND_MESSAGE_TO_COMPANY,
    disability: true,
    notAllowed: roles.CompanyEmployee,
  },
  {
    value: actionTypes.SEND_MESSAGE_TO_GROUP_CHAT,
    disability: true,
  },
  {
    value: actionTypes.SET_PRESENTATION_COMPLETED,
    disability: true,
    notAllowed: roles.CompanyEmployee,
  },
];
