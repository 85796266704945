import styled from 'styled-components';
import Select from 'components/Form/Select';
import { device } from 'styles/device';

export const InternshipSwitcherWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const SelectStyled = styled(Select)`
  margin-left: 10px;
  min-width: 300px;
  @media ${device.mobile} {
    margin-top: 10px;
    margin-left: 0px;
    min-width: 200px;
    .select__single-value {
      max-width: 190px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: nowrap;
    }
  }
`;
