import React from 'react';
import PropTypes from 'prop-types';
import { CoverContent, CoverWrap } from './CoverStyled';

const Cover = ({
  children, content, visible, height,
}) => {
  if (!visible) {
    return children;
  }
  return (
    <CoverWrap height={height}>
      <CoverContent>{content}</CoverContent>
    </CoverWrap>
  );
};

Cover.propTypes = {
  visible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  height: PropTypes.string,
};

Cover.defaultProps = {
  height: '100%',
};

export default Cover;
