import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Chat from 'pages/Chat/ChatContainer';
import Page from 'components/Page';
import Localize from 'components/Localize';
import InformationModal from 'components/InformationModal';
import { ReactComponent as InfoIcon } from 'styles/images/svg/info.svg';

const ChatPage = ({ selectedInternshipId }) => {
  const [informationModalOpen, setInformationModalOpen] = useState('');

  return (
    <>
      <InformationModal
        open={informationModalOpen}
        onCloseModal={() => setInformationModalOpen('')}
      />
      <Localize>
        {({ translate }) => (
          <Page
            title={translate('news')}
            backTo={`/applicant/${selectedInternshipId || ''}`}
            description={`${translate(
              'here-you-can-easily-go-directly-your-teacher',
            )} ${translate(
              'yours-educational-guide-and-your-contact-company-c',
            )}`}
            icon={<InfoIcon />}
            onClick={() => setInformationModalOpen('CHAT')}
          >
            <Chat
              key={selectedInternshipId}
              internshipId={selectedInternshipId || null}
            />
          </Page>
        )}
      </Localize>
    </>
  );
};
ChatPage.propTypes = {
  selectedInternshipId: PropTypes.number,
};

ChatPage.defaultProps = {
  selectedInternshipId: null,
};

const mapStateToProps = ({ selectedInternship }) => ({
  selectedInternshipId: selectedInternship && selectedInternship.pk,
});

export default connect(mapStateToProps)(ChatPage);
