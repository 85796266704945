import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DatePicker } from 'shared';
import CheckBox from 'components/Form/CheckBox';
import Localize from 'components/Localize';
import {
  DateSelect,
  DateTimeWrapper,
  TimeWrapper,
  Separator,
  SelectWrapper,
  Label,
  TimeContent,
  WholeDayContainer,
} from './DateTimeStyled';
import {
  getTimeOptions,
  getDisabledOptions,
  setInitialTime,
  getParsedDates,
  detectWholeDay,
} from './datetimeUtils';

const TIME_TYPES = {
  START: 'start',
  END: 'end',
};

const DateTime = ({
  meta, startName, endName, wholeDayOption,
}) => {
  const [state, setState] = useState({
    day: meta.values[startName] ? moment(meta.values[startName]) : moment(),
    start: setInitialTime(meta.values[startName]),
    end: setInitialTime(meta.values[endName]),
    startOptions: getTimeOptions(moment()),
    endOptions: getTimeOptions(moment()),
    isWholeDay: detectWholeDay(meta.values[startName], meta.values[endName]),
  });
  const onChange = ({ day, start, end }) => {
    const dates = getParsedDates(day, start, end);
    meta.setFieldValue(
      startName,
      moment(dates.startDateTime)
        .utc()
        .format(),
    );
    meta.setFieldValue(
      endName,
      moment(dates.endDateTime)
        .utc()
        .format(),
    );
  };
  const handleWholeDay = (event) => {
    const isWholeDay = event.target.checked;
    if (isWholeDay) {
      const start = moment(state.day).startOf('day');
      const end = moment(state.day).endOf('day');
      meta.setFieldValue(startName, start.utc().format());
      meta.setFieldValue(endName, end.utc().format());
      setState({
        ...state,
        isWholeDay,
        start: setInitialTime(start),
        end: setInitialTime(end),
      });
      return;
    }
    const start = moment(state.day)
      .startOf('day')
      .set('hour', 8)
      .set('minute', 0);
    const end = moment(state.day)
      .endOf('day')
      .set('hour', 17)
      .set('minute', 0);
    meta.setFieldValue(startName, start.utc().format());
    meta.setFieldValue(endName, end.utc().format());
    setState({
      ...state,
      isWholeDay,
      start: setInitialTime(start),
      end: setInitialTime(end),
    });
  };
  const stableOnChange = useCallback(onChange, [state]);
  useEffect(() => {
    if (state.day && state.start && state.end) {
      stableOnChange(state);
    }
  }, [stableOnChange, state]);

  const onChangeDay = (day) => {
    setState({ ...state, day: moment(day).utc(true) });
  };
  const onChangeTime = (time, action) => {
    if (action.name === TIME_TYPES.START) {
      const endOptions = getDisabledOptions(
        time.value,
        action.name,
        state.startOptions,
      );
      setState({
        ...state,
        endOptions,
        start: { label: time.label, value: time.value },
      });
    }
    if (action.name === TIME_TYPES.END) {
      const startOptions = getDisabledOptions(
        time.value,
        action.name,
        state.endOptions,
      );
      setState({
        ...state,
        startOptions,
        end: { label: time.label, value: time.value },
      });
    }
  };
  return (
    <DateTimeWrapper>
      <div>
        <Label>
          <Localize id="date" />
        </Label>
        <DatePicker
          name="day"
          key={state.date}
          value={moment(state.day).utc()}
          onChange={onChangeDay}
        />
      </div>
      <TimeWrapper>
        <Label>
          <Localize id="time" />
        </Label>
        <TimeContent>
          <SelectWrapper>
            <DateSelect
              isOptionDisabled={(option) => option.disabled}
              options={state.startOptions}
              name="start"
              onChange={onChangeTime}
              closeMenuOnSelect={false}
              placeholder="00:00"
              value={state.start}
              isDisabled={state.isWholeDay}
            />
          </SelectWrapper>
          <Separator>-</Separator>
          <SelectWrapper>
            <DateSelect
              isOptionDisabled={(option) => option.disabled}
              options={state.endOptions}
              name="end"
              onChange={onChangeTime}
              closeMenuOnSelect={false}
              placeholder="00:00"
              value={state.end}
              isDisabled={state.isWholeDay}
            />
          </SelectWrapper>
          {wholeDayOption && (
            <WholeDayContainer>
              <CheckBox value={state.isWholeDay} onChange={handleWholeDay}>
                <Localize id="whole-day" />
              </CheckBox>
            </WholeDayContainer>
          )}
        </TimeContent>
      </TimeWrapper>
    </DateTimeWrapper>
  );
};

DateTime.propTypes = {
  meta: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object,
  }).isRequired,
  startName: PropTypes.string.isRequired,
  endName: PropTypes.string.isRequired,
  wholeDayOption: PropTypes.bool,
};

DateTime.defaultProps = {
  wholeDayOption: false,
};

export default DateTime;
