import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';

const dataReducer = (state = [], action) => {
  switch (action.type) {
    case successType(types.GET_SPECIAL_TASKS):
      if (action.response) {
        return [...action.response];
      }
      return state;
    case successType(types.GET_INTERNSHIP):
      if (action.response) {
        return [...action.response.special_tasks];
      }
      return state;
    case successType(types.UPDATE_SPECIAL_TASKS):
      if (action.response) {
        return [
          ...state.filter((t) => t.pk !== action.response.pk),
          action.response,
        ];
      }
      return state;
    case successType(types.CREATE_SPECIAL_TASKS):
      if (action.response) {
        return [...state, action.response];
      }
      return state;
    case types.REMOVE_SPECIAL_TASKS:
      if (action.response) {
        return [...state.filter((t) => t.pk !== action.response.pk)];
      }
      return state;
    case types.LOGOUT:
      return [];
    default:
      return state;
  }
};

export default createReducer(
  [types.GET_SPECIAL_TASKS, types.UPDATE_SPECIAL_TASKS],
  dataReducer,
);
