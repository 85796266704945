import React from 'react';
import PropTypes from 'prop-types';

import Localize from 'components/Localize';
import InternshipSwitcher from 'components/InternshipSwitcher';
import Can from 'components/Can';
import { actions } from 'utils/access-rules/constants';
import { section } from 'utils/access-rules/rules/sections';

const ApplicantInternshipSwitcher = ({
  applicant,
  selectedInternshipId,
  onInternshipChange,
  userRole,
}) => {
  const onChange = ({ value }) => onInternshipChange(value);
  const options = applicant.esuser.internships.map(({ pk, company_name }) => ({
    value: pk,
    label: company_name,
  }));
  return (
    <Can I={actions.see} a={section.ApplicantInternshipSwitcher}>
      <Localize>
        {({ translate }) => (
          <InternshipSwitcher
            onChange={onChange}
            options={[
              { value: 'no-internship', label: translate('no-internship') },
              ...options,
            ]}
            selectedInternshipId={
              selectedInternshipId ? +selectedInternshipId : 'no-internship'
            }
            userRole={userRole}
          />
        )}
      </Localize>
    </Can>
  );
};

ApplicantInternshipSwitcher.propTypes = {
  applicant: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    esuser: PropTypes.object,
  }).isRequired,
  selectedInternshipId: PropTypes.number.isRequired,
  userRole: PropTypes.string,
  onInternshipChange: PropTypes.func.isRequired,
};

ApplicantInternshipSwitcher.defaultProps = {
  userRole: null,
};

export default ApplicantInternshipSwitcher;
