'use strict';

exports.__esModule = true;
exports.default = undefined;

var _FilesList = require('./FilesList');

var _FilesList2 = _interopRequireDefault(_FilesList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _FilesList2.default;
module.exports = exports['default'];