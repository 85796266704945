import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import logo from 'styles/images/svg/IBAlogo.svg';
import { updateUser } from 'actions/userActions';
import { getChatMessages } from 'actions/chatActions';
import ActionModal from 'pages/ManageStudents/ActionModal';
import UploadAvatarModal from 'components/UploadAvatarModal';
import UserActions from '../UserActions';
import Notifications from '../Notifications';
import {
  HeaderWrapper,
  HeaderContainer,
  UserPanel,
  HeaderLogo,
} from './HeaderStyled';
import Subscriber from '../Subscriber';

const Header = ({
  isAuthenticated,
  applicants,
  user,
  updateUser,
  getChatMessages,
}) => {
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(
    false,
  );
  const onVisibilityChange = (condition) => setIsUserDropdownOpen(condition);
  const onNotificationVisibilityChange = (condition) => setIsNotificationDropdownOpen(condition);

  const handleAvatarUpload = async (base64) => {
    try {
      await updateUser(user.pk, { avatar: base64.split(',').pop() }, true);
      setModalOpen(false);
      setModalError(false);
      getChatMessages();
    } catch (error) {
      setModalError(true);
    }
  };

  return (
    <>
      <HeaderWrapper
        isDropdownOpen={isUserDropdownOpen || isNotificationDropdownOpen}
      >
        <HeaderContainer>
          <a href="http://iba-digital.de">
            <HeaderLogo src={logo} alt="logo" />
          </a>

          {isAuthenticated && (
            <UserPanel>
              <Subscriber />
              <Notifications
                key={applicants.length}
                applicants={applicants}
                isDropdownOpen={isNotificationDropdownOpen}
                onVisibilityChange={onNotificationVisibilityChange}
                isUserDropdownOpen={isUserDropdownOpen}
              />
              <UserActions
                isDropdownOpen={isUserDropdownOpen}
                onVisibilityChange={onVisibilityChange}
                onModalOpen={() => setModalOpen(true)}
              />
            </UserPanel>
          )}
        </HeaderContainer>
      </HeaderWrapper>
      <ActionModal isOpen={modalOpen} onCloseModal={() => setModalOpen(false)}>
        <UploadAvatarModal
          user={user}
          onUpload={handleAvatarUpload}
          onClose={() => setModalOpen(false)}
          error={modalError}
        />
      </ActionModal>
    </>
  );
};

Header.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  updateUser: PropTypes.func.isRequired,
  getChatMessages: PropTypes.func.isRequired,
  applicants: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.shape({
    pk: PropTypes.string,
    first_name: PropTypes.string,
    email: PropTypes.string,
    birthday: PropTypes.string,
    phone: PropTypes.string,
    location: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
    role: PropTypes.string.isRequired,
  }),
};

Header.defaultProps = {
  applicants: [],
  user: null,
};

const mapStateToProps = ({ currentUser: { data }, applicants }) => ({
  isAuthenticated: !!data,
  user: {
    pk: data && data.pk,
    first_name: data && data.user && data.user.first_name,
    last_name: data && data.user && data.user.last_name,
    avatar: data && data.avatar,
    email: data && data.user && data.user.email,
    birthday: data && data.birthday,
    phone: data && data.phone,
    location: data && data.location,
  },
  applicants: applicants && applicants.data ? applicants.data : [],
});

export default connect(mapStateToProps, { updateUser, getChatMessages })(
  Header,
);
