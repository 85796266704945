import styled from 'styled-components';
import { device } from 'styles/device';

export const ApplicationWrap = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  & + & {
    margin-top: 10px;
  }

  @media ${device.mobile} {
    display: block;
  }
`;

export const ApplicationLogoWrap = styled.div`
  padding: 10px;
  border-color: ${({ theme }) => theme.borderColor};
  border-style: solid;
  border-right-width: 1px;

  display: flex;
  align-items: center;
  width: 116px;
  flex-shrink: 0;

  @media ${device.mobile} {
    width: 103px;
    height: 103px;
    border-bottom-width: 1px;
    float: left;
    margin-bottom: 5px;
  }
`;
export const ApplicationLogo = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const ApplicationBody = styled.div`
  flex: 1;
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${device.mobile} {
    display: block;
    padding: 15px 10px;
  }
`;

export const ApplicationTitle = styled.div`
  font-size: 22px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  color: ${({ theme }) => theme.textColor3};
  margin-bottom: 12px;
  word-break: break-word;
  @media ${device.mobile} {
    font-size: 18px;
  }
`;

export const ApplicationDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -2px 0 -2px -10px;

  > * {
    margin: 2px 0 2px 10px;
  }
`;

export const ApplicationDetailsItem = styled.div`
  color: ${({ theme }) => theme.grayA0};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

export const ApplicationIndustry = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.secondary};
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.secondary};
  border-radius: 3px;
  padding: 5px 10px;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  text-transform: uppercase;
`;

export const ApplicationCompany = styled.div`
  color: ${({ theme }) => theme.grayA0};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  margin-right: 40px;
  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const ApplicationStatus = styled.div`
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.mobile} {
    height: 103px;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  }
`;

export const ApplicationLogoLetters = styled.span`
  color: #fff;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.secondaryLight};
  font-size: 55px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeightUpperBold};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
