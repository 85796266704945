import styled from 'styled-components';
import AspectRatio from 'react-ratio';
import { device } from 'styles/device';
import { Field } from 'formik';
import { Button } from 'shared';

export const VisitNoteWrapper = styled.div`
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  background-color: #ffffff;
  padding: 28px 32px;
  display: flex;
  margin: ${({ submitManageStudents }) => submitManageStudents ? '0px' : '20px 0px 30px'};
  @media ${device.tablet} {
    max-width: 100%;
  }
`;

export const VisitNoteTitle = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.secondary};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  flex-grow: 1;
  margin-bottom: 20px;
`;
export const VisitNoteBoxLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
`;
export const VisitNoteIconWrapper = styled.div`
  font-size: 20px;
  margin-right: 10px;
`;
export const VisitNoteButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.secondary};
  transition: 0.3s;
  padding: 0 20px;
  margin-right: 9px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;
export const VisitNoteButtons = styled.div`
  margin-top: 20px;
  display: flex;
`;
export const VisitNoteSaveButton = styled(VisitNoteButton)`
  background-color: ${({ theme }) => theme.secondary};
  color: #fff;
  flex-grow: 1;
  font-size: 18px;
`;
export const VisitNoteSaveButtonContent = styled.div`
  display: flex;
`;
export const VisitNoteDeleteButton = styled(VisitNoteButton)`
  background-color: #fff;
  color: ${({ theme }) => theme.secondary};
  max-width: 62px;
  font-size: 28px;
`;

export const VisitNoteText = styled.p`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.grayF7};
  padding: 10px 15px;
  font-family: Open Sans, san-serif;
  color: ${({ theme }) => theme.textColor69};
  min-height: 100px;
`;
export const VisitNoteImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const VisitNoteRatio = styled(AspectRatio)`
  background-color: ${({ theme }) => theme.grayF7};
  max-width: 162px;
  width: 100%;
  margin-right: 20px;
  border-radius: 3px;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  & .Ratio-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const VisitNoteImageItem = styled(VisitNoteRatio)``;
export const VisitNoteAddImage = styled(VisitNoteRatio)`
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
  svg {
    transition: 0.3s;
    position: relative !important;
    width: 21px !important;
    height: 21px !important;
  }
  path {
    stroke: ${({ theme }) => theme.secondary};
  }
`;
export const VisitNoteImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const VisitNoteButtonImageDelete = styled.button`
  width: 29px;
  height: 29px;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
`;

export const VisitNoteDescription = styled(Field)`
  width: 100%;
  margin-top: 20px;
  height: 100px;
  border-radius: 3px;
  border: 1px solid #f3f3f1;
  padding: 12px 40px 12px 18px;
  resize: none;
  ::placeholder {
    color: rgb(117, 117, 117) !important;
    font-weight: ${({ theme }) => theme.fontWeightMedium};
  }
  background-color: ${({ disabled }) => disabled && '#fff'};
`;

export const VisitNoteCloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: transparent;
  font-size: 25px;
  border: none;
  top: 18px;
  right: 14px;
  cursor: pointer;
  color: ${({ theme }) => theme.secondary};
`;
