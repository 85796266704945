import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';
import initialState from 'store/initialState';

const dataReducer = (state = initialState.internshipComment, action) => {
  switch (action.type) {
    case successType(types.GET_INTERNSHIP_COMMENT):
      if (action.response) {
        return {
          ...state,
          ...action.response,
        };
      }
      return state;
    case successType(types.CHANGE_INTERNSHIP_COMMENT):
      if (action.response) {
        const newState = {
          ...state,
          ...action.response,
        };
        return newState;
      }
      return state;
    case types.LOGOUT:
      return null;
    default:
      return state;
  }
};

export default createReducer(
  [types.GET_INTERNSHIP_COMMENT, types.CHANGE_INTERNSHIP_COMMENT],
  dataReducer,
);
