import { actions, roles } from '../constants';

export const page = {
  Todo: 'Todo',
  Calendar: 'Calendar',
  Chat: 'Chat',
  InternshipReport: 'InternshipReport',
  InternshipComment: 'InternshipComment',
  LearningTask: 'LearningTask',
  YourApplications: 'YourApplications',
  Assessment: 'Assessment',
  ApplicantProfile: 'ApplicantProfile',
  Applicants: 'Applicants',
  Settings: 'Settings',
  ManageStudents: 'ManageStudents',
  Instructions: 'Instructions',
};

export default (role, can, rules) => {
  if (role === roles.Applicant) {
    can(actions.see, page.Todo);
    can(actions.see, page.Calendar);
    can(actions.see, page.Chat);
    can(actions.see, page.InternshipComment);
    can(actions.see, page.InternshipReport);
    can(actions.see, page.LearningTask);
    can(actions.see, page.YourApplications);
    can(actions.see, page.Assessment);
    can(actions.see, page.Settings);
    can(actions.see, page.Instructions);
  }
  if (role === roles.Teacher) {
    can(actions.see, page.Applicants);
    can(actions.see, page.ApplicantProfile);
    can(actions.see, page.ManageStudents);
    can(actions.see, page.Instructions);
  }
  if (role === roles.CompanyEmployee) {
    can(actions.see, page.Applicants);
    can(actions.see, page.ApplicantProfile);
    can(actions.see, page.Instructions);
  }
  if (role === roles.EducationProvider) {
    can(actions.see, page.Applicants);
    can(actions.see, page.ApplicantProfile);
    can(actions.see, page.ManageStudents);
    can(actions.see, page.Instructions);
  }
  return rules;
};
