'use strict';

exports.__esModule = true;
exports.BUTTON_VARIANTS = exports.BUTTON_SIZES = exports.BUTTON_COLORS = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactSvg = require('react-svg');

var _reactSvg2 = _interopRequireDefault(_reactSvg);

var _ButtonStyled = require('./ButtonStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var BUTTON_COLORS = exports.BUTTON_COLORS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  WHATSAPP: 'whatsapp',
  APPROVE: 'approve',
  DEFAULT: 'default'
};

var BUTTON_SIZES = exports.BUTTON_SIZES = {
  MEDIUM: 'medium',
  SMALL: 'small',
  LARGE: 'large'
};

var BUTTON_VARIANTS = exports.BUTTON_VARIANTS = {
  TEXT: 'text',
  OUTLINED: 'outlined',
  CONTAINED: 'contained'
};

var Button = function Button(_ref) {
  var icon = _ref.icon,
      children = _ref.children,
      className = _ref.className,
      type = _ref.type,
      as = _ref.as,
      disabled = _ref.disabled,
      loading = _ref.loading,
      rightIcon = _ref.rightIcon,
      width = _ref.width,
      rest = _objectWithoutProperties(_ref, ['icon', 'children', 'className', 'type', 'as', 'disabled', 'loading', 'rightIcon', 'width']);

  return _react2.default.createElement(
    _ButtonStyled.StyledButton,
    _extends({
      type: type,
      className: className,
      as: as,
      disabled: disabled || loading
    }, rest),
    icon && _react2.default.createElement(
      _ButtonStyled.IconContainer,
      _extends({ className: 'icon', withText: !!children }, rest),
      _react2.default.createElement(_reactSvg2.default, { src: icon })
    ),
    loading && _react2.default.createElement(_ButtonStyled.Spinner, rest),
    _react2.default.createElement(
      _ButtonStyled.ButtonContent,
      { className: 'content', isLoading: loading, width: width },
      children
    ),
    rightIcon && _react2.default.createElement(
      _ButtonStyled.IconContainer,
      _extends({ className: 'icon', withText: !!children, rightIcon: true }, rest),
      _react2.default.createElement(_reactSvg2.default, { src: rightIcon })
    )
  );
};

Button.propTypes = process.env.NODE_ENV !== "production" ? {
  type: _propTypes2.default.string,
  icon: _propTypes2.default.string,
  rightIcon: _propTypes2.default.string,
  className: _propTypes2.default.string,
  color: _propTypes2.default.oneOf(Object.values(BUTTON_COLORS)),
  size: _propTypes2.default.oneOf(Object.values(BUTTON_SIZES)),
  variant: _propTypes2.default.oneOf(Object.values(BUTTON_VARIANTS)),
  children: _propTypes2.default.oneOfType([_propTypes2.default.element, _propTypes2.default.string]),
  disableBoxShadow: _propTypes2.default.bool,
  as: _propTypes2.default.string,
  loading: _propTypes2.default.bool,
  disabled: _propTypes2.default.bool,
  width: _propTypes2.default.string
} : {};

Button.defaultProps = {
  icon: null,
  rightIcon: null,
  type: undefined,
  className: undefined,
  color: BUTTON_COLORS.DEFAULT,
  size: BUTTON_SIZES.MEDIUM,
  variant: BUTTON_VARIANTS.CONTAINED,
  children: null,
  disableBoxShadow: false,
  as: undefined,
  loading: false,
  disabled: false,
  width: null
};

exports.default = Button;