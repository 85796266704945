'use strict';

exports.__esModule = true;
exports.CitySelectWrap = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  .react-autosuggest__container {\n    position: relative;\n    flex: 1;\n  }\n\n  .react-autosuggest__suggestions-container {\n    display: none;\n  }\n\n  .react-autosuggest__suggestions-container--open {\n    display: block;\n    position: absolute;\n    top: 53px;\n    width: 100%;\n    border: 1px solid ', ';\n    background-color: ', ';\n    border-bottom-left-radius: 3px;\n    border-bottom-right-radius: 3px;\n    z-index: 2;\n    max-height: 300px;\n    overflow-y: auto;\n    &::-webkit-scrollbar-thumb {\n      background-color: ', ';\n    }\n  }\n\n  .react-autosuggest__suggestions-list {\n    margin: 0;\n    padding: 0;\n    list-style-type: none;\n  }\n\n  .react-autosuggest__suggestion {\n    cursor: pointer;\n    padding: 10px 20px;\n  }\n\n  .react-autosuggest__suggestion--highlighted {\n    background-color: ', ';\n  }\n'], ['\n  .react-autosuggest__container {\n    position: relative;\n    flex: 1;\n  }\n\n  .react-autosuggest__suggestions-container {\n    display: none;\n  }\n\n  .react-autosuggest__suggestions-container--open {\n    display: block;\n    position: absolute;\n    top: 53px;\n    width: 100%;\n    border: 1px solid ', ';\n    background-color: ', ';\n    border-bottom-left-radius: 3px;\n    border-bottom-right-radius: 3px;\n    z-index: 2;\n    max-height: 300px;\n    overflow-y: auto;\n    &::-webkit-scrollbar-thumb {\n      background-color: ', ';\n    }\n  }\n\n  .react-autosuggest__suggestions-list {\n    margin: 0;\n    padding: 0;\n    list-style-type: none;\n  }\n\n  .react-autosuggest__suggestion {\n    cursor: pointer;\n    padding: 10px 20px;\n  }\n\n  .react-autosuggest__suggestion--highlighted {\n    background-color: ', ';\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var CitySelectWrap = exports.CitySelectWrap = _styledComponents2.default.div(_templateObject, function (_ref) {
  var theme = _ref.theme;
  return theme.borderColor;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.white;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.textColor69;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.grayE6;
});