import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClock } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tippy';
import { tabNames } from '../../../ChatTabPanel';
import {
  MessageWrapper,
  MessageLi,
  UserAvatarWrapper,
  UserAvatar,
  MessageDeliveredIconWrap,
  MessageDeliveredIcon,
  UserAvatarCircle,
  AvatarLetters,
  MessageSenderLi,
  MessageSenderInfo,
  MessageSenderRole,
} from './MessageStyled';

function Message({
  isOwnMessage,
  isMessageSent,
  isMessageRead,
  avatar,
  text,
  userFirstName,
  userLastName,
  userRole,
  isLoading,
}) {
  return (
    <>
      <MessageLi className="chat-message" isOwnMessage={isOwnMessage}>
        <MessageWrapper isOwnMessage={isOwnMessage}>
          <UserAvatarWrapper isOwnMessage={isOwnMessage}>
            <Tooltip title={`${userFirstName}`} theme="light" arrow>
              <UserAvatarCircle isOwnMessage={isOwnMessage}>
                {avatar ? (
                  <UserAvatar avatar={avatar} />
                ) : (
                  <>
                    <AvatarLetters isOwnMessage={isOwnMessage}>
                      {' '}
                      {userFirstName && userFirstName[0]}
                      {userLastName && userLastName[0]}
                    </AvatarLetters>
                    {!userFirstName && !userLastName && (
                      <FontAwesomeIcon
                        className="avatar"
                        size="sm"
                        icon={faUser}
                      />
                    )}
                  </>
                )}
              </UserAvatarCircle>
            </Tooltip>
          </UserAvatarWrapper>
          {text}
        </MessageWrapper>
      </MessageLi>
      <MessageSenderLi isOwnMessage={isOwnMessage}>
        <MessageSenderInfo>
          <MessageSenderRole>{tabNames[userRole]}</MessageSenderRole>
          <MessageDeliveredIconWrap>
            {isOwnMessage && isMessageSent && <MessageDeliveredIcon />}
            {isOwnMessage && isMessageRead && <MessageDeliveredIcon />}
            {isLoading && (
              <FontAwesomeIcon className="avatar" size="sm" icon={faClock} />
            )}
          </MessageDeliveredIconWrap>
        </MessageSenderInfo>
      </MessageSenderLi>
    </>
  );
}

Message.propTypes = {
  isOwnMessage: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMessageSent: PropTypes.bool.isRequired,
  isMessageRead: PropTypes.bool.isRequired,
  avatar: PropTypes.string,
  text: PropTypes.string,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userRole: PropTypes.string,
};

Message.defaultProps = {
  isOwnMessage: false,
  isLoading: false,
  avatar: undefined,
  text: '',
  userFirstName: '',
  userLastName: '',
  userRole: '',
};

export default Message;
