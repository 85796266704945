'use strict';

exports.__esModule = true;
exports.Carousel = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactSlick = require('react-slick');

var _reactSlick2 = _interopRequireDefault(_reactSlick);

var _CarouselStyled = require('./CarouselStyled');

var _LeftNavButton = require('./LeftNavButton');

var _LeftNavButton2 = _interopRequireDefault(_LeftNavButton);

var _RightNavButton = require('./RightNavButton');

var _RightNavButton2 = _interopRequireDefault(_RightNavButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Carousel = exports.Carousel = function Carousel(_ref) {
  var dots = _ref.dots,
      speed = _ref.speed,
      children = _ref.children,
      onChange = _ref.onChange;

  var settings = {
    dots: dots,
    infinite: true,
    speed: speed,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: onChange,
    prevArrow: _react2.default.createElement(_LeftNavButton2.default, null),
    nextArrow: _react2.default.createElement(_RightNavButton2.default, null)
  };
  return _react2.default.createElement(
    _CarouselStyled.CarouselContainer,
    null,
    _react2.default.createElement(
      _reactSlick2.default,
      settings,
      children
    )
  );
};

Carousel.propTypes = process.env.NODE_ENV !== "production" ? {
  dots: _propTypes2.default.bool,
  speed: _propTypes2.default.number,
  children: _propTypes2.default.oneOfType([_propTypes2.default.arrayOf(_propTypes2.default.string), _propTypes2.default.arrayOf(_propTypes2.default.element)]),
  onChange: _propTypes2.default.func.isRequired
} : {};

Carousel.defaultProps = {
  dots: false,
  speed: 500,
  children: null
};

exports.default = Carousel;