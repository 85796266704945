import { useState, useEffect } from 'react';
import Fuse from 'fuse.js';

const fuse = new Fuse([], {
  keys: [
    'classroom.school.name',
    'vacancy',
    'classroom.name',
    'esuser.user.first_name',
    'esuser.user.last_name',
  ],
  threshold: 0.3,
});

function FilteredInternshipList({ list, filterBy, children }) {
  const [filteredList, setFilteredList] = useState([]);
  useEffect(() => {
    fuse.setCollection(list);
    setFilteredList(list);
  }, [list]);

  const compare = (a, b) => {
    if (
      a.esuser.user.first_name.toLowerCase()
      < b.esuser.user.first_name.toLowerCase()
    ) {
      return -1;
    }
    if (
      a.esuser.user.first_name.toLowerCase()
      > b.esuser.user.first_name.toLowerCase()
    ) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    const setSortedFilteredList = (listFiltered, sort) => {
      if (sort === 0) {
        const sortedList = []
          .concat(listFiltered)
          .sort((a, b) => compare(a, b));
        setFilteredList(sortedList);
      }
      if (sort === 1) {
        const sortedList = []
          .concat(listFiltered)
          .sort((a, b) => compare(b, a));
        setFilteredList(sortedList);
      }
    };
    if (list && filterBy.search.trim().length > 0) {
      setSortedFilteredList(fuse.search(filterBy.search), filterBy.sort);
      return;
    }
    if (list && filterBy.schoolName.trim().length > 0) {
      const filteredListBy = list.filter(
        (item) => item.classroom.school.name.toLowerCase()
          === filterBy.schoolName.toLowerCase(),
      );
      setSortedFilteredList(filteredListBy, filterBy.sort);
    } else {
      setSortedFilteredList(list, filterBy.sort);
    }
    if (list && filterBy.classroom.trim().length > 0) {
      const filteredListBy = list.filter(
        (item) => item.classroom.name === filterBy.classroom,
      );
      setSortedFilteredList(filteredListBy, filterBy.sort);
    } else {
      setSortedFilteredList(list, filterBy.sort);
    }
  }, [
    filterBy.search,
    filterBy.classroom,
    filterBy.schoolName,
    filterBy.sort,
    list,
  ]);

  return children(filteredList);
}

FilteredInternshipList.propTypes = {};

export default FilteredInternshipList;
