import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isSameDay } from 'react-dates';
import { getEventListByDate } from 'utils/calendarHelpers';
import {
  CalendarDay,
  EventBadge,
  EventBadgesContainer,
} from './CalendarDayStyled';

function DayContent({
  day,
  meetList,
  missesList,
  taskList,
  onsiteAppointments,
}) {
  const currentYear = moment(day).year();
  const currentMonth = moment(day).format('MM');
  const hasMeetEvents = getEventListByDate(meetList, currentYear, currentMonth);
  const hasMissesEvents = getEventListByDate(
    missesList,
    currentYear,
    currentMonth,
  );
  const hasTaskEvents = getEventListByDate(taskList, currentYear, currentMonth);
  const hasOnsiteEvents = getEventListByDate(
    onsiteAppointments,
    currentYear,
    currentMonth,
  );

  let countMeetsInDay = -1;
  let countMissesInDay = -1;
  let countTasksInDay = -1;
  let countOnSiteInDay = -1;

  if (hasMeetEvents) {
    countMeetsInDay = hasMeetEvents.findIndex((event) => isSameDay(day, moment(event.start)));
  }

  if (hasMissesEvents) {
    countMissesInDay = hasMissesEvents.findIndex((event) => isSameDay(day, moment(event.start)));
  }

  if (hasTaskEvents) {
    countTasksInDay = hasTaskEvents.findIndex((event) => isSameDay(day, moment(event.deadline)));
  }

  if (hasOnsiteEvents) {
    countOnSiteInDay = hasOnsiteEvents.findIndex((event) => isSameDay(day, moment(event.start)));
  }

  return (
    <CalendarDay>
      {day.format('D')}
      <EventBadgesContainer>
        {countMeetsInDay > -1 && <EventBadge meetEvent />}
        {countMissesInDay > -1 && (
          <EventBadge missEvent className="missEvent" />
        )}
        {countTasksInDay > -1 && <EventBadge taskEvent className="taskEvent" />}
        {countOnSiteInDay > -1 && <EventBadge onsiteEvent />}
      </EventBadgesContainer>
    </CalendarDay>
  );
}

const MemoizedDayContent = (props) => {
  const Day = useMemo(() => <DayContent {...props} />, [props]);

  return Day;
};

DayContent.propTypes = {
  day: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  meetList: PropTypes.objectOf(PropTypes.object),
  missesList: PropTypes.objectOf(PropTypes.object),
  taskList: PropTypes.objectOf(PropTypes.object),
  onsiteAppointments: PropTypes.objectOf(PropTypes.object),
};

DayContent.defaultProps = {
  day: moment(),
  meetList: null,
  missesList: null,
  taskList: null,
  onsiteAppointments: null,
};

export default MemoizedDayContent;
