import React from 'react';
import PropTypes from 'prop-types';
import UserAvatarUploader from 'components/UserPrimaryData/UserAvatarUploader';
import Localize from 'components/Localize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ButtonClose } from 'components/Modal/ModalStyled';
import { ModalContent, ErrorText } from './UploadAvatarModalStyled';

const UploadAvatarModal = ({
  user, onUpload, onClose, error,
}) => (
  <ModalContent>
    <ButtonClose type="button" onClick={onClose}>
      <FontAwesomeIcon icon={faTimes} />
    </ButtonClose>
    <Localize id="click-image-update-avatar" />
    <div className="avatar">
      <UserAvatarUploader
        avatar={user.avatar}
        userFirstName={user.first_name}
        userLastName={user.last_name}
        isEditable
        onAvatarUpload={onUpload}
      />
    </div>
    {error && (
      <ErrorText>
        <Localize id="issue-image-uploading-please-try-again" />
      </ErrorText>
    )}
  </ModalContent>
);

UploadAvatarModal.propTypes = {
  onUpload: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    pk: PropTypes.string,
    first_name: PropTypes.string,
    email: PropTypes.string,
    birthday: PropTypes.string,
    phone: PropTypes.string,
    location: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
    role: PropTypes.string.isRequired,
  }),
};

UploadAvatarModal.defaultProps = {
  user: null,
};

export default UploadAvatarModal;
