import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Localize from 'components/Localize';
import Questionnaire from 'pages/Assessment/Questionnaire';
import { QuestionnaireWrapper } from 'pages/Assessment/Questionnaire/QuestionnaireStyled';
import { ChatTabPanelWrapper } from 'components/Chat/ChatTabPanel/ChatTabPanelStyled';
import ChatTab from 'components/Chat/ChatTabPanel/ChatTab';
import {
  getSubmittedStatusByRole,
  getSavedDateByRole,
} from '../assessmentUtils';

const roles = {
  APPLICANT: 'applicant',
  TEACHER: 'teacher',
  COMPANY_EMPLOYEE: 'company_employee',
  EDUCATION_PROVIDER: 'education_provider',
  ALL_ASSESSMENTS: 'all_assessments',
  YOURS: 'yours',
};

export const tabNames = {
  COMPANY_EMPLOYEE: {
    role: roles.COMPANY_EMPLOYEE,
    tabName: <Localize id="company-employee" />,
  },
  APPLICANT: {
    role: roles.APPLICANT,
    tabName: <Localize id="candidate" />,
  },
  TEACHER: {
    role: roles.TEACHER,
    tabName: <Localize id="teacher" />,
  },
  EDUCATION_PROVIDER: {
    role: roles.EDUCATION_PROVIDER,
    tabName: <Localize id="education-provider" />,
  },
};

const getTabNames = (tabNames, userRole) => {
  const _tabNames = Object.values(tabNames).filter((t) => t.role !== userRole);
  const yourTab = {
    role: 'yours',
    tabName: <Localize id="yours" />,
  };
  const allTab = {
    role: roles.ALL_ASSESSMENTS,
    tabName: <Localize id="all-assessments" />,
  };
  return [allTab, yourTab, ..._tabNames];
};

const InternshipAssessmentTabs = ({ userRole, isCompleted, ...rest }) => {
  const _tabNames = getTabNames(tabNames, userRole);
  const [state, setState] = useState({
    activeTab: _tabNames[0],
  });
  const handleActiveTab = (activeTab) => setState({ ...state, activeTab });

  if (userRole === roles.TEACHER || userRole === roles.EDUCATION_PROVIDER) {
    return (
      <QuestionnaireWrapper>
        <ChatTabPanelWrapper className="tab-wrapper">
          {_tabNames.map((tab, index) => (
            <ChatTab
              className={`tab assessment ${tab.role === state.activeTab.role
                && 'active-tab'}`}
              key={index}
              handleActiveTab={() => handleActiveTab(tab)}
              isTabActive={tab.role === state.activeTab.role}
            >
              {tab.tabName}
            </ChatTab>
          ))}
        </ChatTabPanelWrapper>
        <Questionnaire
          {...rest}
          key={getSavedDateByRole(
            rest.assessment,
            state.activeTab.role.toUpperCase(),
          )}
          isComparingMode={state.activeTab.role === roles.ALL_ASSESSMENTS}
          userRole={
            state.activeTab.role === roles.YOURS
              ? userRole
              : state.activeTab.role
          }
          isEditable={
            (state.activeTab.role === userRole
              || state.activeTab.role === roles.YOURS)
            && !getSubmittedStatusByRole(rest.assessment, userRole.toUpperCase())
          }
          isCompleted={isCompleted}
        />
      </QuestionnaireWrapper>
    );
  }
  return (
    <QuestionnaireWrapper>
      <Questionnaire
        {...rest}
        userRole={userRole}
        key={getSavedDateByRole(rest.assessment, userRole.toUpperCase())}
        isEditable={
          !getSubmittedStatusByRole(rest.assessment, userRole.toUpperCase())
        }
        isCompleted={isCompleted}
      />
    </QuestionnaireWrapper>
  );
};

InternshipAssessmentTabs.propTypes = {
  userRole: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
};

export default InternshipAssessmentTabs;
