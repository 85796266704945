'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _edit = require('../../styles/images/edit.svg');

var _edit2 = _interopRequireDefault(_edit);

var _EditIconStyled = require('./EditIconStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EditIcon = function EditIcon(props) {
  return _react2.default.createElement(_EditIconStyled.IconStyled, _extends({ src: _edit2.default }, props));
};

exports.default = EditIcon;
module.exports = exports['default'];