import React from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';

import { InternshipSelect } from './InternshipSelectorStyled';

const InternshipSelector = ({ row, handleChange, selectedInternship }) => {
  const options = row.esuser.internships.map((internship) => ({
    value: internship,
    label: internship.company_name,
  }));
  return (
    <InternshipSelect
      options={[
        { value: null, label: <Localize id="no-internship" /> },
        ...options,
      ]}
      placeholder={<Localize id="select-internship" />}
      onChange={(data) => handleChange(data, row.esuser.pk)}
      value={selectedInternship[row.esuser.pk] || null}
      menuPosition="fixed"
    />
  );
};

InternshipSelector.propTypes = {
  row: PropTypes.shape({
    esuser: PropTypes.shape({
      pk: PropTypes.number.isRequired,
      internships: PropTypes.array,
    }).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedInternship: PropTypes.shape({}).isRequired,
};

export default InternshipSelector;
