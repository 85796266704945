import styled from 'styled-components';
import { device } from 'styles/device';
import Select from 'components/Form/Select';
import { EventBadge } from './CalendarDay/CalendarDayStyled';

export const CalendarNavHeightDesktop = '70px';
export const CalendarNavHeightMobile = '47px';

export const DayPickerSingleDateControllerWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 0;
  padding-top: 54%;
  background-color: white;

  .DayPicker {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    width: calc(100% - 40px) !important;
    max-width: 528px;
    min-width: 300px;
    overflow: hidden;
    margin: auto;
    font-family: 'Open Sans', 'san-serif';

    & > div > div {
      width: 100% !important;
    }

    &_transitionContainer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100% !important;
      height: auto !important;
    }

    &_focusRegion {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }

    &_wrapper__horizontal {
    }
  }

  .CalendarMonthGrid {
    width: 100% !important;
    height: 100%;
    left: 0;
    background: transparent;

    &_month__horizontal {
      width: 100% !important;
    }
  }

  .CalendarMonth {
    padding: 0 !important;
    background: transparent;

    &_table {
      width: 100%;
    }

    &_caption {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: ${CalendarNavHeightDesktop};
      padding-top: 3px;
      padding-bottom: 3px;
      margin-bottom: 50px;
      color: white;
      background-color: ${({ theme }) => theme.secondary};
      z-index: 3;

      @media ${device.mobile} {
        height: ${CalendarNavHeightMobile};
      }
    }
  }

  .DayPicker {
    &_weekHeaders {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      bottom: 0;
      left: 0;
      margin-left: 0;
    }

    &_weekHeader {
      top: 0;
      padding: 0 !important;
      width: 100%;
      font-weight: ${({ theme }) => theme.fontWeightBold};
      color: ${({ theme }) => theme.secondary};
      text-transform: uppercase;

      &_ul {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 16px;
        margin-top: 94px;
        padding: 0;

        @media ${device.mobile} {
          margin-top: 63px;
        }
      }

      &_li {
        width: calc(100% / 7) !important;
      }
    }
  }

  .CalendarDay {
    position: relative;
    padding: 0;
    padding-top: 15%;
    font-size: 20px;
    font-weight: ${({ theme }) => theme.fontWeightLight};
    color: #3a3335;
    border: none;

    @media ${device.tablet} {
      font-size: 16px;
    }

    @media ${device.mobile} {
      //font-size: 14px;
    }

    &__selected,
    &:hover {
      color: white;
    }

    &__selected {
      font-weight: ${({ theme }) => theme.fontWeightBold};
      color: white;
      background: ${({ theme }) => theme.secondary};

      & ${EventBadge}.missEvent {
        background-color: ${({ theme }) => theme.secondaryLight};
      }

      & ${EventBadge}.taskEvent {
        background-color: green;
      }
    }

    &__outside {
      color: ${({ theme }) => theme.grayA0};
    }

    &:hover {
      background-color: ${({ theme }) => theme.secondaryLight};
      border: none;

      &.CalendarDay__selected {
        background-color: ${({ theme }) => theme.secondary};
      }
    }
  }
`;

export const DateNavPanel = styled.div`
  display: flex;
  justify-content: center;
`;

export const CalendarSelect = styled(Select)`
  width: 100px;
  .select {
    &__single-value {
      font-size: 22px;
      font-weight: ${({ theme }) => theme.fontWeightBold};
      color: #fff;
      text-transform: uppercase;
    }

    &__menu {
      color: white;
      font-weight: ${({ theme }) => theme.fontWeightBold};
      text-transform: uppercase;
      background-color: ${({ theme }) => theme.secondary};
      border-radius: 0;

      &-list {
        padding: 0;
      }

      &::after,
      ::before {
        display: none;
      }
    }

    &__option {
      border: none;

      &--is-selected {
        border-color: ${({ theme }) => theme.primary};
        background-color: ${({ theme }) => theme.primary} !important;
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 3px;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: white transparent transparent transparent;
    opacity: 0.4;
    transform: translateX(-50%);
    transition: opacity 0.3s;
  }

  &:hover::after {
    opacity: 1;
  }
`;

export const DateSelectYear = styled(CalendarSelect)`
  margin-left: 5px;
`;

export const DateSelectMonth = styled(CalendarSelect)``;
