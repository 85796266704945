import React, { useContext } from 'react';
import moment from 'moment';
import { ReactReduxContext } from 'react-redux';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';
import { sortMessages } from 'api/ChatApi';
import NoMessages from 'components/Chat/NoMessages';
import Message from './Message';
import { MessagesWrapper } from './MessagesStyled';
import DateDivider from './DateDevider';
import { ChatMessagesWrapper } from '../ChatMessagesPanelStyled';

function isDayToday(date) {
  return moment(date).isSame(moment(), 'day');
}

function isDayYesterday(date) {
  return moment(date).isSame(moment().subtract(1, 'day'), 'day');
}

function Messages({ messages }) {
  const { store } = useContext(ReactReduxContext);
  const { user: currentUser } = store.getState().currentUser.data;
  const reverseMessages = sortMessages(messages);
  return (
    <ChatMessagesWrapper followButtonClassName="scroll-top">
      <MessagesWrapper>
        {messages.length === 0 && <NoMessages />}
        {messages
          && reverseMessages.map((message, idx, ownArray) => {
            const messageBeforeCurrentMessage = ownArray[idx - 1];
            const shouldBeDivider = messageBeforeCurrentMessage
              && !moment(messageBeforeCurrentMessage.created).isSame(
                moment(message.created),
                'day',
              );
            const messageIsWrittenToday = isDayToday(message.created);
            const messageWasWrittenYesterday = isDayYesterday(message.created);
            return (
              <React.Fragment key={message.pk}>
                {(idx === 0 || shouldBeDivider) && (
                  <DateDivider>
                    {messageIsWrittenToday && <Localize id="today" />}
                    {messageWasWrittenYesterday && <Localize id="yesterday" />}
                    {!messageIsWrittenToday
                      && !messageWasWrittenYesterday
                      && moment(message.created).format('DD.MM.YYYY')}
                  </DateDivider>
                )}
                <Message
                  text={message.message}
                  isMessageSent={!message.isLoading}
                  isMessageRead={!!message.read_at}
                  avatar={message.sender.avatar}
                  isOwnMessage={
                    message.sender.user.first_name === currentUser.first_name
                  }
                  userFirstName={message.sender.user.first_name}
                  userLastName={message.sender.user.last_name}
                  userRole={message.sender.role}
                  isLoading={message.isLoading}
                />
              </React.Fragment>
            );
          })}
      </MessagesWrapper>
    </ChatMessagesWrapper>
  );
}

Messages.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object),
};

Messages.defaultProps = {
  messages: [],
};

export default Messages;
