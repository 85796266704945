import styled, { css } from 'styled-components';
import Decor from 'styles/images/svg/avatar-decor.svg';
import _UserAvatar from '../UserAvatar';

const AVATAR_SIZE = 51;

export const MessageLi = styled.li`
  position: relative;
  display: flex;
  justify-content: ${({ isOwnMessage }) => isOwnMessage ? 'flex-end' : 'flex-start'};
`;

export const MessageSenderLi = styled.li`
  position: relative;
  display: flex;
  justify-content: ${({ isOwnMessage }) => isOwnMessage ? 'flex-end' : 'flex-start'};
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const MessageWrapper = styled.div`
  position: relative;
  min-width: 50px;
  max-width: 800px;
  padding: 14px 20px;
  ${({ isOwnMessage }) => isOwnMessage ? '  padding-right: 46px' : 'padding-left: 46px'};
  background-color: ${({ theme, isOwnMessage }) => isOwnMessage ? 'transparent' : theme.grayF3};
  border: ${({ theme }) => `1px solid ${theme.grayF3}`};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;

  &::before {
    content: '';
    position: absolute;
    ${({ isOwnMessage }) => (isOwnMessage ? 'right: -5px' : 'left: -5px')};
    top: calc(${AVATAR_SIZE}px / 2);
    bottom: 0;
    display: block;
    width: calc(${AVATAR_SIZE}px / 1.4);
    height: calc(100% - ${AVATAR_SIZE / 2.08}px);
    background-color: white;
    ${({ isOwnMessage, theme }) => isOwnMessage && `border-left: 1px solid ${theme.grayF3}`};
  }
`;

const own = () => css`
  content: '';
  position: absolute;
  left: -1px;
  top: -1px;
  display: block;
  width: 26px;
  height: 44px;
  background: url(${Decor}) left top no-repeat;
`;
export const UserAvatarCircle = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  & svg {
    color: #fff;
    width: 60% !important;
    height: 60% !important;
  }
  &:before {
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: 10;
    content: '';
    left: 0;
    top: 0;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.primary};
    display: ${({ newMessage }) => (newMessage ? 'block' : 'none')};
  }
`;

export const AvatarLetters = styled.span`
   background-color: ${({ theme, isOwnMessage }) => isOwnMessage ? theme.warningColor : theme.secondaryLight}
  font-style: normal;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeightUpperBold};
  position: absolute;
  top: 0;right: 0;left: 0;bottom: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  
`;
export const UserAvatarWrapper = styled.div`
  position: absolute;
  ${({ isOwnMessage }) => (isOwnMessage ? 'right: -20px' : 'left: -20px')};
  top: -1px;
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
  padding: 8px;
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadiusRound};

  &::after {
    ${({ isOwnMessage }) => isOwnMessage && own}
  }
`;
export const UserAvatarWrapperTab = styled.div`
  position: relative;
  flex-shrink: 0;
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
  padding: 8px;
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadiusRound};
  &::after {
    ${({ isOwnMessage }) => isOwnMessage && own}
  }
`;

export const UserAvatar = styled(_UserAvatar)``;

export const MessageDeliveredIconWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 21px;
`;

export const MessageDeliveredIcon = styled.div`
  position: relative;
  width: 10px;
  height: 10px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    height: 2px;
    border-radius: 1px;
    background-color: ${({ theme }) => theme.secondary};
    transform: translate(-50%);
  }

  &::before {
    width: 6px;
    transform: translate(-50%) rotate(50deg);
    margin-top: 2px;
    margin-left: -4px;
  }

  &::after {
    width: 12px;
    margin-left: 1px;
    transform: translate(-50%) rotate(-55deg);
  }
`;

export const MessageSenderInfo = styled.div`
  display: flex;
  top: 100%;
  margin-top: 6px;
  align-items: center;
`;

export const MessageSenderRole = styled.div`
  font-size: 12px;
  font-style: normal;
  padding-right: 10px;
`;
