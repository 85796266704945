import { roles } from 'utils/access-rules/constants';

export const getMainTitleSlug = (role) => {
  if (role === roles.Applicant) {
    return 'applicant-main-text';
  }
  if (role === roles.Teacher) {
    return 'teacher-main-text';
  }
  if (role === roles.EducationProvider) {
    return 'education-provide-main-text';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-employee-main-text';
  }
};

export const getMainDescriptionSlug = (role) => {
  if (role === roles.Applicant) {
    return 'applicant-main-subtext';
  }
  if (role === roles.Teacher) {
    return 'teacher-main-subtext';
  }
  if (role === roles.EducationProvider) {
    return 'education-provide-main-subtext';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-employee-main-subtext';
  }
};

export const getCalendarSlug = (role) => {
  if (role === roles.Applicant) {
    return 'applicant-calendar-text';
  }
  if (role === roles.Teacher) {
    return 'teacher-calendar-text';
  }
  if (role === roles.EducationProvider) {
    return 'education-provide-calendar-text';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-employee-calendar-text';
  }
};

export const getCalendarDescriptionSlug = (role) => {
  if (role === roles.Applicant) {
    return 'applicant-calendar-subtext';
  }
  if (role === roles.Teacher) {
    return 'teacher-calendar-subtext';
  }
  if (role === roles.EducationProvider) {
    return 'education-provide-calendar-subtext';
  }
  if (role === roles.CompanyEmployee) {
    return 'company-employee-calendar-subtext';
  }
};
