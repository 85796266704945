import moment from 'moment';

export const START = 'start';
export const END = 'end';

export const getTimeOptions = (time) => {
  const TIME_LIST_OPTIONS = [];

  if (time) {
    const day = moment(time)
      .utc()
      .startOf('day');
    for (let hour = 0; hour < 24; hour += 1) {
      for (let minutes = 0; minutes < 4; minutes += 1) {
        TIME_LIST_OPTIONS.push({
          disabled: false,
          label: `${day
            .set('hour', hour)
            .set('minute', minutes * 15)
            .format('HH:mm')}`,
          value: day
            .set('hour', hour)
            .set('minute', minutes * 15)
            .format(),
        });
        if (hour === 23 && minutes === 3) {
          TIME_LIST_OPTIONS.push({
            disabled: false,
            label: `${day
              .set('hour', hour)
              .set('minute', 59)
              .format('HH:mm')}`,
            value: day
              .set('hour', hour)
              .set('minute', 59)
              .format(),
          });
        }
      }
    }
  }
  return TIME_LIST_OPTIONS;
};

export const setInitialTime = (time) => {
  const timeString = moment(time).format('HH:mm');
  if (time) {
    return {
      label: timeString,
      value: moment(time).format(),
    };
  }
  return null;
};

export const getDisabledOptions = (value, type, array) => array.map((option) => {
  const upOption = { ...option };
  if (type === START) {
    if (
      moment(value)
        .add(10, 'minute')
        .isAfter(option.value, 'minute')
    ) {
      upOption.disabled = true;
      return upOption;
    }
    upOption.disabled = false;
    return upOption;
  }
  if (type === END) {
    if (
      moment(value)
        .subtract(10, 'minute')
        .isBefore(option.value, 'minute')
    ) {
      upOption.disabled = true;
      return upOption;
    }
    upOption.disabled = false;
    return upOption;
  }
  return upOption;
});

export const getAttendeesFromEvent = ({ attendees }) => attendees.map(({ pk, user }) => ({
  value: pk,
  label: `${user.first_name} ${user.last_name}`,
}));

export const getParsedDates = (day, start, end) => {
  const dayString = moment(day).format('DD-MM-YYYY');
  const startString = moment(start.value).format('HH:mm');
  const endString = moment(end.value).format('HH:mm');
  const startDateTimeString = `${dayString} ${startString}`;
  const endDateTimeString = `${dayString} ${endString}`;
  return {
    startDateTime: moment(startDateTimeString, 'DD-MM-YYYY HH:mm'),
    endDateTime: moment(endDateTimeString, 'DD-MM-YYYY HH:mm'),
  };
};

export const detectWholeDay = (start, end) => {
  const parsedStart = moment(start).format('HH:mm');
  const parsedEnd = moment(end).format('HH:mm');
  return parsedStart === '00:00' && parsedEnd === '23:59';
};
