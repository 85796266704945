import styled, { css } from 'styled-components';
import { device } from 'styles/device';

const taskCardSectionContentStyles = css`
  margin-top: 12px;
  color: ${({ isItalic, theme }) =>
    isItalic ? theme.textColor69 : theme.textColor};
  word-break: break-word;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  ${({ isItalic }) =>
    isItalic &&
    css`
      font-style: italic;
    `}
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const TaskCardWrapper = styled.div`
  position: relative;
  font-size: 16px;
  padding: 18px 20px 18px 26px;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
`;

export const TaskCardsWrap = styled.div``;

export const TaskCardSection = styled.div`
  padding: 26px 0;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  font-weight: ${({ theme }) => theme.fontWeightUpperBold};

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  .date {
    margin-top: 15px;
  }
`;

export const TaskCardSectionTitle = styled.div`
  color: ${({ theme }) => theme.secondary};
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

export const TaskCardSectionContent = styled.div`
  ${taskCardSectionContentStyles}
`;

export const ImageList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;

  @media ${device.mobile} {
    margin-top: 20px;
  }
`;

export const TaskFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;

  & .btn-upload {
    margin-right: 20px;
    & path {
      fill: transparent;
      stroke: white;
    }
  }
  @media ${device.mobile} {
    flex-wrap: wrap;
    & .btn-upload {
      margin-right: 0px;
    }
    .btn-save {
      width: 100%;
    }
    .btn-submit {
      margin: 10px 0px 20px;
      width: 100%;
    }
    button,
    a {
      width: 100%;
      margin-right: 0px;
    }
  }
  button + button {
    margin-left: 20px;
  }
`;

export const TaskCardSectionContentWithButton = styled.div`
  ${taskCardSectionContentStyles};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  button {
    word-break: normal;
  }
  .text {
    padding-right: 80px;
  }

  .input {
    flex: 1;
    padding-right: 20px;
  }
  @media ${device.mobile} {
    .input {
      width: 100%;
      padding-right: 0px;
    }
  }
`;

export const ButtonClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: transparent;
  font-size: 25px;
  border: none;
  top: 12px;
  right: 6px;
  cursor: pointer;
  color: ${({ theme }) => theme.secondary};
`;

export const TaskSheet = styled.div`
  .task-sheet-thumb {
    width: 100px;
    height: 100px;
    .delete-button {
      width: 30px;
      height: 30px;
      svg {
        width: 15px;
      }
    }
  }
`;
