'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

require('react-toggle/style.css');

var _ToggleStyled = require('./ToggleStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var Toggle = function Toggle(_ref) {
  var value = _ref.value,
      onChange = _ref.onChange,
      className = _ref.className,
      children = _ref.children,
      labelLeft = _ref.labelLeft,
      props = _objectWithoutProperties(_ref, ['value', 'onChange', 'className', 'children', 'labelLeft']);

  return _react2.default.createElement(
    _ToggleStyled.ToggleWrap,
    null,
    labelLeft && _react2.default.createElement(
      _ToggleStyled.ToggleLabel,
      null,
      children
    ),
    _react2.default.createElement(_ToggleStyled.StyledToggle, _extends({
      checked: value,
      onChange: onChange,
      icons: false,
      className: className
    }, props)),
    !labelLeft && _react2.default.createElement(
      _ToggleStyled.ToggleLabel,
      { right: true },
      children
    )
  );
};

Toggle.propTypes = process.env.NODE_ENV !== "production" ? {
  value: _propTypes2.default.bool.isRequired,
  onChange: _propTypes2.default.func.isRequired,
  className: _propTypes2.default.string,
  children: _propTypes2.default.node,
  labelLeft: _propTypes2.default.bool
} : {};

Toggle.defaultProps = {
  className: undefined,
  children: undefined,
  labelLeft: false
};

exports.default = Toggle;
module.exports = exports['default'];