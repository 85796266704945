"use strict";

exports.__esModule = true;
var requestType = exports.requestType = function requestType(type) {
  return type + "_REQUEST";
};
var successType = exports.successType = function successType(type) {
  return type + "_SUCCESS";
};
var failureType = exports.failureType = function failureType(type) {
  return type + "_FAILURE";
};

exports.default = function (type) {
  return [requestType(type), successType(type), failureType(type)];
};