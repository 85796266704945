'use strict';

exports.__esModule = true;
exports.default = undefined;

var _Thumbnail = require('./Thumbnail');

var _Thumbnail2 = _interopRequireDefault(_Thumbnail);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Thumbnail2.default;
module.exports = exports['default'];