import React from 'react';
import PropTypes from 'prop-types';
import { Can as CanBase } from '@casl/react';
import createAbility from 'utils/access-rules/abilities';

const Can = ({
  I, a, passThrough, children,
}) => (
  <CanBase I={I} a={a} passThrough={passThrough} ability={createAbility()}>
    {children}
  </CanBase>
);

Can.propTypes = {
  I: PropTypes.string.isRequired,
  a: PropTypes.string.isRequired,
  passThrough: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

Can.defaultProps = {
  passThrough: false,
};

export default Can;
