'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactFontawesome = require('@fortawesome/react-fontawesome');

var _freeSolidSvgIcons = require('@fortawesome/free-solid-svg-icons');

var _ModalStyled = require('./ModalStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var Modal = function Modal(_ref) {
  var children = _ref.children,
      rest = _objectWithoutProperties(_ref, ['children']);

  return _react2.default.createElement(
    _ModalStyled.PopupStyled,
    _extends({ modal: true, lockScroll: true }, rest),
    function (close) {
      return _react2.default.createElement(
        _ModalStyled.ModalContent,
        null,
        _react2.default.createElement(_reactFontawesome.FontAwesomeIcon, { className: 'close', icon: _freeSolidSvgIcons.faTimes, onClick: close }),
        typeof children === 'function' ? children(close) : children
      );
    }
  );
};

Modal.propTypes = process.env.NODE_ENV !== "production" ? {
  children: _propTypes2.default.oneOfType([_propTypes2.default.node, _propTypes2.default.func]).isRequired
} : {};

exports.default = Modal;
module.exports = exports['default'];