import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateSpecialTasks } from 'actions/specialTaskAction';
import TaskForm from './TaskForm';
import { EditTaskWrap } from './TaskFormStyled';

const EditTask = ({
  internshipId, task, updateSpecialTasks, onClose,
}) => {
  const handleSubmit = (values) => updateSpecialTasks(task.pk, {
    internshipId,
    ...task,
    ...values,
  }).then(onClose);
  return (
    <EditTaskWrap>
      <TaskForm task={task} onCancel={onClose} onSubmit={handleSubmit} />
    </EditTaskWrap>
  );
};

EditTask.propTypes = {
  task: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    deadline: PropTypes.string,
    attachments: PropTypes.array.isRequired,
  }).isRequired,
  updateSpecialTasks: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  internshipId: PropTypes.number.isRequired,
};

export default connect(
  undefined,
  { updateSpecialTasks },
)(EditTask);
