import styled from 'styled-components';
import { device } from 'styles/device';

export const CommentTitle = styled.div`
  color: ${({ theme }) => theme.secondary};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  padding-bottom: 5px;
`;

export const CommentWrapper = styled.div`
  margin-top: 15px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .btn-save {
    margin-right: 20px;
  }
  .btn-upload {
    margin-right: 20px;
  }
  @media ${device.mobile} {
    flex-wrap: wrap;
    .btn-upload {
      width: 100%;
      margin: 10px 0px;
    }
    .btn-save {
      width: 100%;
      margin: 10px 0px;
    }
    .btn-submit {
      width: 100%;
    }
  }
`;

export const CommentBox = styled.div`
  color: ${({ theme }) => theme.textColor};
  word-break: break-word;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
`;
