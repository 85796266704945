import internshipReportApi from 'api/InternshipReportApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getReport(internshipId) {
  return createAction({
    type: types.SET_REPORT,
    callAPI: () => internshipReportApi.getReport(internshipId),
  });
}

export function editDay(dayId, report) {
  return createAction({
    type: types.EDIT_REPORT_DAY,
    callAPI: () => internshipReportApi.editDay(dayId, report),
  });
}

export function editConclusion(reportId, report) {
  return createAction({
    type: types.EDIT_REPORT_CONCLUSION,
    callAPI: () => internshipReportApi.editConclusion(reportId, report),
  });
}

export function uploadDayAttachment(dayId, attachment) {
  return createAction({
    type: types.UPLOAD_DAY_ATTACHMENT,
    callAPI: () => internshipReportApi.uploadDayAttachment(dayId, attachment),
  });
}

export function deleteDayAttachment(attachment, day) {
  return createAction({
    type: types.DELETE_DAY_ATTACHMENT,
    callAPI: () => internshipReportApi.deleteDayAttachment(attachment, day),
  });
}

export function addDay(internship) {
  return createAction({
    type: types.ADD_DAY,
    callAPI: () => internshipReportApi.addDay(internship),
  });
}

export function submitReport(internshipId) {
  return createAction({
    type: types.SET_REPORT,
    callAPI: () => internshipReportApi.submitReport(internshipId),
  });
}

export function resendReport(internshipId) {
  return createAction({
    type: types.SET_REPORT,
    callAPI: () => internshipReportApi.resendReport(internshipId),
  });
}

export function addDayTasks(dayId, tasks) {
  return createAction({
    type: types.ADD_DAY_TASKS,
    callAPI: () => internshipReportApi.addDayTasks(dayId, tasks),
  });
}

export function editDayTask(taskId, task) {
  return createAction({
    type: types.EDIT_DAY_TASK,
    callAPI: () => internshipReportApi.editDayTask(taskId, task),
  });
}

export function deleteDayTask(taskId) {
  return createAction({
    type: types.DELETE_DAY_TASK,
    callAPI: () => internshipReportApi.deleteDayTask(taskId),
  });
}
