import React from 'react';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';

import { Error, Button } from 'shared';
import Can from 'components/Can/Can';
import { section } from 'utils/access-rules/rules/internshipRequest';
import { actions } from 'components/Can';
import InternshipsRequestItem from './InternshipsRequestItem';
import { StyledList, ButtonContainer } from './InternshipsRequestsListStyled';

const InternshipsRequestsList = ({
  internships,
  changeInternshipRequest,
  uploadInternshipRequestAttachment,
  deleteInternshipRequestAttachment,
  addInternshipRequest,
  deleteInternshipRequest,
  getInternships,
  currentUser,
  loading,
  error,
}) => (
  <div>
    {error && <Error />}
    <StyledList>
      {internships
        && internships.map((internship) => (
          <InternshipsRequestItem
            className="item"
            changeInternshipRequest={changeInternshipRequest}
            getInternships={getInternships}
            uploadInternshipRequestAttachment={
              uploadInternshipRequestAttachment
            }
            deleteInternshipRequestAttachment={
              deleteInternshipRequestAttachment
            }
            deleteInternshipRequest={deleteInternshipRequest}
            key={internship.pk}
            data={internship}
            loading={loading}
            currentUser={currentUser}
          />
        ))}
    </StyledList>
    <Can I={actions.see} a={section.addInternshipRequest}>
      <ButtonContainer>
        <Button color="secondary" onClick={addInternshipRequest}>
          <Localize id="add-new-internship-request" />
        </Button>
      </ButtonContainer>
    </Can>
  </div>
);

InternshipsRequestsList.propTypes = {
  internships: PropTypes.arrayOf(PropTypes.object),
  changeInternshipRequest: PropTypes.func.isRequired,
  uploadInternshipRequestAttachment: PropTypes.func,
  deleteInternshipRequestAttachment: PropTypes.func,
  addInternshipRequest: PropTypes.func,
  getInternships: PropTypes.func.isRequired,
  deleteInternshipRequest: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  currentUser: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }).isRequired,
};

InternshipsRequestsList.defaultProps = {
  error: null,
  deleteInternshipRequest: null,
  addInternshipRequest: null,
  deleteInternshipRequestAttachment: null,
  uploadInternshipRequestAttachment: null,
  internships: null,
};

export default InternshipsRequestsList;
