import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TableColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const TableColumnText = styled.div`
  display: flex;
  align-items: center;
`;
export const TableColumnName = styled.div`
  word-break: break-all;
`;
export const TableColumnFilterIcon = styled(FontAwesomeIcon)`
  margin-left: 2px;
`;
