export const roles = {
  Applicant: 'APPLICANT',
  Teacher: 'TEACHER',
  CompanyEmployee: 'COMPANY_EMPLOYEE',
  EducationProvider: 'EDUCATION_PROVIDER',
};

export const actions = {
  see: 'see',
  create: 'create',
  edit: 'edit',
  delete: 'delete',
};
