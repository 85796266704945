import styled from 'styled-components';
import { device } from 'styles/device';
import _Modal from 'components/Modal/Modal';
import { DayPickerSingleDateControllerWrapper } from 'components/SchedulerCalendar/Calendar/CalendarStyled';

export const ScheduleCalendarWrapper = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadiusDefault};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  margin: 0 auto;
  font-family: Montserrat, sans-serif;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;

    ${DayPickerSingleDateControllerWrapper} {
      padding-top: 122%;
    }

    & .DayPicker {
      max-width: 100%;
      top: 0;
      width: 100% !important;
    }
  }

  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;

    ${DayPickerSingleDateControllerWrapper} {
      padding-top: 122%;
    }

    & .DayPicker {
      max-width: 100%;
      top: 0;
      width: 100% !important;
    }
  }
`;

export const Modal = styled(_Modal)`
  width: 100%;
  right: 0;
  position: absolute !important;
  height: auto !important;
  overflow-y: visible !important;
  left: auto !important;
  bottom: auto !important;
  top: 100% !important;
  padding: 0 !important;
  .popup-content {
    width: 100% !important;
    overflow-y: hidden !important;
  }
  animation: changeHeight 0.3s;
  @keyframes changeHeight {
    from {
      max-height: 0;
    }
    to {
      max-height: 800px;
    }
  }
`;
