import styled from 'styled-components';
import { device } from 'styles/device';

export const CityTagDurationWrapper = styled.ul`
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  clear: both;
  margin-top: 7px;
  @media ${device.tablet} {
    margin-top: 0;
  }

  > li {
    padding: 5px 0;
    margin-right: 20px;
    display: flex;
    align-items: center;
    > div {
      margin-right: 10px;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  [data-src*='location'] {
    use {
      stroke: transparent;
    }
  }
`;
