import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Localize from 'components/Localize';
import { DownloadButton } from 'components/DownloadUrl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'shared';
import checkIcon from 'shared/lib/styles/images/check.svg';
import close from 'shared/lib/styles/images/close.svg';
import { editInviteeStatus } from 'actions/calendarActions';
import { INVITEES_STATUSES } from 'api/CalendarApi';
import { EVENT_TYPES, EVENT_VISIBILITY } from 'utils/calendarHelpers';
import { roles } from 'utils/access-rules/constants';
import InviteesList from '../InviteesList';
import {
  EventMoreCloseButton,
  EventMoreInfoBox,
  EventMoreInfoBoxInner,
  EventMoreInfoText,
  EventMoreInfoTitle,
  EventMoreInfoType,
  EventMoreInfoWrapper,
  DownloadButtonsWrapper,
  EventMoreInfoButtons,
} from './EventMoreInfoWindowStyled';

const isEventNotResponded = (event, currentUser, invitees) => {
  if (
    event.author.pk === currentUser.pk
    || (currentUser.role !== roles.Applicant
      && event.visibility !== EVENT_VISIBILITY.ATTENDEES)
    || event.kind === EVENT_TYPES.ABSENCE
  ) {
    return false;
  }
  const invitee = invitees.find((u) => u.esuser.pk === currentUser.pk);
  return invitee && invitee.status === null;
};

const EventMoreInfoWindowComponent = ({
  event,
  handleCloseMoreInfo,
  currentUser,
  editInviteeStatus,
  onGetEvents,
  onUpdateInvitee,
  invitees,
}) => {
  const onAcceptEvent = (e) => {
    e.preventDefault();
    const invitee = invitees && invitees.find((i) => i.esuser.pk === currentUser.pk);
    if (invitee) {
      editInviteeStatus(invitee.pk, {
        status: INVITEES_STATUSES.accepted,
      }).then(() => {
        handleCloseMoreInfo(e);
        onGetEvents();
        onUpdateInvitee(invitee.pk, INVITEES_STATUSES.accepted);
      });
    }
  };
  const onRejectEvent = (e) => {
    e.preventDefault();
    const invitee = invitees && invitees.find((i) => i.esuser.pk === currentUser.pk);
    if (invitee) {
      editInviteeStatus(invitee.pk, {
        status: INVITEES_STATUSES.rejected,
      }).then(() => {
        handleCloseMoreInfo(e);
        onGetEvents();
        onUpdateInvitee(invitee.pk, INVITEES_STATUSES.rejected);
      });
    }
  };
  return (
    <EventMoreInfoWrapper>
      <EventMoreCloseButton onClick={handleCloseMoreInfo}>
        <FontAwesomeIcon icon={faTimes} />
      </EventMoreCloseButton>
      <EventMoreInfoBox>
        <EventMoreInfoBoxInner>
          <EventMoreInfoType>
            <Localize id="title" />
          </EventMoreInfoType>
          <EventMoreInfoTitle>{event.title}</EventMoreInfoTitle>
        </EventMoreInfoBoxInner>
      </EventMoreInfoBox>
      <EventMoreInfoBox>
        <EventMoreInfoBoxInner>
          <EventMoreInfoType>
            <Localize id="date" />
          </EventMoreInfoType>
          <EventMoreInfoTitle>
            {moment(event.start).format('DD.MM.YYYY')}
          </EventMoreInfoTitle>
        </EventMoreInfoBoxInner>
        <EventMoreInfoBoxInner>
          <EventMoreInfoType>
            <Localize id="time-day" />
          </EventMoreInfoType>
          <EventMoreInfoTitle>
            {moment(event.start)
              .utc()
              .format('HH:mm')}{' '}
            -{' '}
            {moment(event.end)
              .utc()
              .format('HH:mm')}{' '}
            <Localize id="hr" />
          </EventMoreInfoTitle>
        </EventMoreInfoBoxInner>
      </EventMoreInfoBox>
      {isEventNotResponded(event, currentUser, invitees) && (
        <EventMoreInfoBox>
          <EventMoreInfoBoxInner>
            <EventMoreInfoType>
              <Localize id="respond" />
            </EventMoreInfoType>
            <EventMoreInfoButtons>
              <Button
                icon={checkIcon}
                disableBoxShadow
                color="secondary"
                size="small"
                type="button"
                onClick={onAcceptEvent}
              >
                <Localize id="accept" />
              </Button>
              <Button
                className="reject-button"
                color="secondary"
                icon={close}
                disableBoxShadow
                variant="outlined"
                size="small"
                type="button"
                onClick={onRejectEvent}
              >
                <Localize id="reject" />
              </Button>
            </EventMoreInfoButtons>
          </EventMoreInfoBoxInner>
        </EventMoreInfoBox>
      )}
      <EventMoreInfoBox>
        <EventMoreInfoBoxInner>
          <EventMoreInfoType>
            <Localize id="description" />
          </EventMoreInfoType>
          <EventMoreInfoText>{event.description}</EventMoreInfoText>
        </EventMoreInfoBoxInner>
      </EventMoreInfoBox>
      <EventMoreInfoBox>
        <EventMoreInfoBoxInner>
          <EventMoreInfoType>
            <Localize id="place" />
          </EventMoreInfoType>
          <EventMoreInfoText>{event.place}</EventMoreInfoText>
        </EventMoreInfoBoxInner>
      </EventMoreInfoBox>
      {invitees.length > 0 && event.kind !== EVENT_TYPES.ABSENCE && (
        <EventMoreInfoBox noBorder>
          <EventMoreInfoBoxInner>
            <EventMoreInfoType>
              <Localize id="invitees" />
            </EventMoreInfoType>
            <EventMoreInfoText>
              <InviteesList
                invitees={invitees}
                eventVisibility={event.visibility}
              />
            </EventMoreInfoText>
          </EventMoreInfoBoxInner>
        </EventMoreInfoBox>
      )}
      {event.attachments.length > 0 && (
        <EventMoreInfoBox noBorder>
          <EventMoreInfoBoxInner>
            <EventMoreInfoType>
              <Localize id="attachments" />
            </EventMoreInfoType>
            <DownloadButtonsWrapper>
              {event.attachments.map(({ file, pk }) => (
                <DownloadButton key={`task_attachment_${pk}`} url={file} />
              ))}
            </DownloadButtonsWrapper>
          </EventMoreInfoBoxInner>
        </EventMoreInfoBox>
      )}
      {event.medical_documents.length > 0 && (
        <EventMoreInfoBox noBorder>
          <EventMoreInfoBoxInner>
            <EventMoreInfoType>
              <Localize id="attachments" />
            </EventMoreInfoType>
            <DownloadButtonsWrapper>
              {event.medical_documents.map(({ file, pk }) => (
                <DownloadButton key={`medical_document_${pk}`} url={file} />
              ))}
            </DownloadButtonsWrapper>
          </EventMoreInfoBoxInner>
        </EventMoreInfoBox>
      )}
    </EventMoreInfoWrapper>
  );
};

EventMoreInfoWindowComponent.propTypes = {
  event: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    kind: PropTypes.string.isRequired,
    internship: PropTypes.string,
    medical_documents: PropTypes.array,
    attachments: PropTypes.array,
    place: PropTypes.string,
    description: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    title: PropTypes.string,
    author: PropTypes.shape({
      pk: PropTypes.number,
    }).isRequired,
    visibility: PropTypes.string,
  }).isRequired,
  invitees: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleCloseMoreInfo: PropTypes.func.isRequired,
  editInviteeStatus: PropTypes.func.isRequired,
  onGetEvents: PropTypes.func.isRequired,
  onUpdateInvitee: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    pk: PropTypes.number,
    role: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ currentUser: { data } }) => ({
  currentUser: data,
});

export const EventMoreInfoWindow = connect(
  mapStateToProps,
  { editInviteeStatus },
)(EventMoreInfoWindowComponent);

export const TaskMoreInfoWindow = ({ event, handleCloseMoreInfo }) => (
  <EventMoreInfoWrapper>
    <EventMoreCloseButton onClick={handleCloseMoreInfo}>
      <FontAwesomeIcon icon={faTimes} />
    </EventMoreCloseButton>
    <EventMoreInfoBox>
      <EventMoreInfoBoxInner>
        <EventMoreInfoType>
          <Localize id="description" />
        </EventMoreInfoType>
        <EventMoreInfoTitle>{event.description}</EventMoreInfoTitle>
      </EventMoreInfoBoxInner>
    </EventMoreInfoBox>
    <EventMoreInfoBox>
      <EventMoreInfoBoxInner>
        <EventMoreInfoType>
          <Localize id="deadline" />
        </EventMoreInfoType>
        <EventMoreInfoTitle>
          {moment.utc(event.deadline).format('YYYY-MM-DD')}
        </EventMoreInfoTitle>
      </EventMoreInfoBoxInner>
    </EventMoreInfoBox>
    <EventMoreInfoBox>
      <EventMoreInfoBoxInner>
        <EventMoreInfoType>
          <Localize id="date" />
        </EventMoreInfoType>
        <EventMoreInfoTitle>
          {moment.utc(event.created).format('YYYY-MM-DD')}
        </EventMoreInfoTitle>
      </EventMoreInfoBoxInner>
    </EventMoreInfoBox>
    <EventMoreInfoBox noBorder={event.attachments.length === 0}>
      <EventMoreInfoBoxInner>
        <EventMoreInfoType>
          <Localize id="status" />
        </EventMoreInfoType>
        <EventMoreInfoTitle>
          {event.done ? <Localize id="done" /> : <Localize id="in-progress" />}
        </EventMoreInfoTitle>
      </EventMoreInfoBoxInner>
    </EventMoreInfoBox>
    {event.attachments.length > 0 && (
      <EventMoreInfoBox noBorder>
        <EventMoreInfoBoxInner>
          <EventMoreInfoType>
            <Localize id="attachments" />
          </EventMoreInfoType>
          <DownloadButtonsWrapper>
            {event.attachments.map(({ file, pk }) => (
              <DownloadButton key={`task_attachment_${pk}`} url={file} />
            ))}
          </DownloadButtonsWrapper>
        </EventMoreInfoBoxInner>
      </EventMoreInfoBox>
    )}
  </EventMoreInfoWrapper>
);

TaskMoreInfoWindow.propTypes = {
  event: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  handleCloseMoreInfo: PropTypes.func.isRequired,
};
