import ServerApi from 'shared/lib/api/ServerApi';
import { format } from 'shared/lib/lib/dateTime';
import { getDefaultLanguage } from 'shared/lib/lib/languages';

export const EVENT = {
  CREATE: 'create_notification',
};

export const KIND = {
  CHAT_MESSAGE: 'CHAT_MESSAGE',
  CALENDAR_EVENT: 'CALENDAR_EVENT',
  INVITEE_CHANGED_STATUS: 'INVITEE_CHANGED_STATUS',
  CONTRACT_UPLOADED: 'CONTRACT_UPLOADED',
  APPLICANT_ENTRY_CREATED: 'APPLICANT_ENTRY_CREATED',
  APPLICANT_3_DAYS_DEADLINE: 'APPLICANT_3_DAYS_DEADLINE',
  APPLICANT_1_WEEK_DEADLINE: 'APPLICANT_1_WEEK_DEADLINE',
  TEACHER_ENTRY_COMPLETED: 'TEACHER_ENTRY_COMPLETED',
  TEACHER_DEADLINE_EXPIRED: 'TEACHER_DEADLINE_EXPIRED',
  APPLICANT_SUBMITTED_INTERNSHIP_REPORT:
    'APPLICANT_SUBMITTED_INTERNSHIP_REPORT',
  APPLICANT_SUBMITTED_OLT_REPORT: 'APPLICANT_SUBMITTED_OLT_REPORT',
  APPLICANT_SUBMITTED_ASSESSMENT: 'APPLICANT_SUBMITTED_ASSESSMENT',
  CHAT_MESSAGE_HAS_BEEN_READ: 'CHAT_MESSAGE_HAS_BEEN_READ',
  TEACHER_UNSUBMITTED_OLT_REPORT: 'TEACHER_UNSUBMITTED_OLT_REPORT',
  TEACHER_ASSESSMENT_UNSUBMITTED: 'TEACHER_ASSESSMENT_UNSUBMITTED',
  INTERNSHIP_REPORT_UNSUBMITTED: 'INTERNSHIP_REPORT_UNSUBMITTED',
  TEACHER_ADDED_OPERATIONAL_LEARNING_TASK:
    'TEACHER_ADDED_OPERATIONAL_LEARNING_TASK',
  INTERNSHIP_REQUEST_READY: 'INTERNSHIP_REQUEST_READY',
};

export const sort = (items) => [...items].sort((a, b) => {
  if (!b.read_at) {
    // unread at top
    return 1;
  }
  if (!a.read_at) {
    // unread at top
    return -1;
  }
  return new Date(a.read_at) > new Date(b.read_at) ? -1 : 1;
});

class NotificationApi extends ServerApi {
  getUserNotifications(limit) {
    return super.get(
      `/internship/notification/?limit=${limit}&order_by=-created&language=${getDefaultLanguage()}`,
    );
  }

  markNotificationAsRead(pk) {
    return super.patch(`/internship/notification/${pk}/`, {
      read_at: format(new Date()),
    });
  }
}

export default new NotificationApi();
