import styled from 'styled-components';
import { Button as _Button } from 'shared';
import { device } from 'styles/device';

export const EventInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  //max-width: 560px;
  padding: 40px;
  font-family: 'Open Sans', 'san-serif';
  background-color: ${({ theme }) => theme.grayF3};

  @media ${device.tablet} {
    padding: 24px 20px;
  }

  @media ${device.mobile} {
    max-width: 100%;
    padding: 24px 11px;
    padding-top: 18px;
  }
`;

export const EventInfoTop = styled.div`
  display: flex;
  position: relative;
  min-height: 142px;
`;

export const EventInfoTopLeft = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
`;

export const EventInfoTopRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-grow: 1;
  position: relative;
`;

export const EventInfoHeroDate = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${({ theme }) => theme.secondary};
  line-height: 1.2;
`;

export const EventInfoHeroDayNumber = styled.div`
  font-size: 76px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: 56px;
`;

export const EventInfoHeroDayMonth = styled.div`
  margin-top: 10px;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: 22px;
  }

  @media ${device.mobile} {
    font-size: 16px;
  }
`;

export const EventInfoBottom = styled.div`
  margin-top: 10px;

  @media ${device.tablet} {
    margin-top: 32px;
  }

  @media ${device.mobile} {
    margin-top: 13px;
  }
`;
export const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export const Button = styled(_Button)`
  width: auto;
  max-width: 215px;
  margin-top: 12px;
  padding: 0px 8px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  position: relative;

  &:first-child {
    margin-top: 0;
  }

  & .icon {
    max-width: 16px;
  }
`;
