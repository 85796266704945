import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';

import { addEvent } from 'actions/calendarActions';
import VisitNote from '../../../ApplicantProfile/VisitNotes/VisitNote';
import ActionResults from '../../ActionResults';
import { UnaffectedUsers } from '../../ManageStudentsStyled';

const SetOnSiteMeeting = ({
  selectedApplicants,
  selectedInternship,
  showActionResult,
  closeActionModel,
  addEvent,
}) => {
  const handleSubmit = (values, cb) => {
    const addOnSiteMeeting = selectedApplicants.map((applicant) => {
      const selectedApplicantInternship = selectedInternship[applicant.pk];
      if (selectedApplicantInternship && selectedApplicantInternship.value) {
        return addEvent({
          ...values,
          visibility: 'EVERYONE',
          kind: 'ONSITE_APPOINTMENT',
          internship: selectedApplicantInternship.value.api_url,
        })
          .then(() => ({
            result: 'success',
            user: applicant.esuser.user,
          }))
          .catch(() => ({
            result: 'failed',
            user: applicant.esuser.user,
          }));
      }
      return null;
    });
    return Promise.all(addOnSiteMeeting).then((values) => {
      showActionResult(
        <ActionResults results={values} onClose={closeActionModel} />,
      );
      cb();
    });
  };
  const unaffectedUsers = selectedApplicants.filter(
    (applicant) => !(
      selectedInternship[applicant.pk]
        && selectedInternship[applicant.pk].value
    ),
  );
  return (
    <div>
      <VisitNote
        submitManageStudents={handleSubmit}
        closeManageStudents={closeActionModel}
      />
      {unaffectedUsers.length > 0 && (
        <UnaffectedUsers>
          <Localize id="following-users-will-not-be-affected" />
          {unaffectedUsers.map(
            (applicant) => ` ${applicant.esuser.user.first_name} ${applicant.esuser.user.last_name},`,
          )}
        </UnaffectedUsers>
      )}
    </div>
  );
};

SetOnSiteMeeting.propTypes = {
  selectedApplicants: PropTypes.array.isRequired,
  selectedInternship: PropTypes.shape({}).isRequired,
  showActionResult: PropTypes.func.isRequired,
  closeActionModel: PropTypes.func.isRequired,
  addEvent: PropTypes.func.isRequired,
};

export default connect(
  undefined,
  {
    addEvent,
  },
)(SetOnSiteMeeting);
