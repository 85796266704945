import styled from 'styled-components';
import { Tooltip } from 'react-tippy';

export const AvatarWrapper = styled.div`
  position: relative;

  .edit-icon-container {
    position: absolute;
    bottom: 100%;
    right: 12px;
    margin-bottom: -8px;
  }
  .avatar-input {
    display: none;
  }
`;

export const Avatar = styled.div`
  width: 134px;
  height: 134px;
  margin-right: 26px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &.avatar-wrap {
    margin-top: 9px;
    color: #fff;
    position: relative;
  }
  & img {
    display: block;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
  & svg {
    width: 65% !important ;
    height: 65% !important ;
    display: block;
    object-fit: cover;
  }
`;

export const AvatarLetters = styled.span`
  background-color: ${({ theme }) => theme.secondaryLight};
  font-size: 55px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeightUpperBold};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TooltipStyled = styled(Tooltip)`
  display: block !important;
  width: 100%;
  height: 100%;
`;
