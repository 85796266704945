import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAdvisorsData } from 'actions/applicationActions';
import { JobAdvisor, Loader, Error } from 'shared';
import { H2 } from './JobAdvisorStyled';

function _JobAdvisor({
  advisorsData,
  getAdvisorsData,
  withTitle,
  title,
  error,
  loading,
}) {
  const stableGetAdvisorsData = useCallback(getAdvisorsData, [advisorsData]);
  useEffect(() => {
    if (advisorsData.length === 0) stableGetAdvisorsData();
  }, [stableGetAdvisorsData, advisorsData]);
  if (error) {
    return <Error />;
  }
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      {withTitle
        && (title || (
          <H2>
            Hast du Fragen zu deiner Bewerbung? Wir von Jobufo helfen dir gerne
            weiter!
          </H2>
        ))}
      {advisorsData && advisorsData.length > 0 && (
        <JobAdvisor data={advisorsData} />
      )}
    </>
  );
}

_JobAdvisor.propTypes = {
  getAdvisorsData: PropTypes.func.isRequired,
  advisorsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  withTitle: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};

_JobAdvisor.defaultProps = {
  error: null,
  withTitle: true,
  title: undefined,
};

const mapStateToProps = ({
  currentUser,
  application: { data, loading, error },
}) => ({
  user: currentUser,
  loading,
  error,
  advisorsData: data.advisorsData,
});

export default connect(
  mapStateToProps,
  { getAdvisorsData },
)(_JobAdvisor);
