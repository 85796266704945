'use strict';

exports.__esModule = true;
exports.DownloadUrlContent = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  display: flex;\n  justify-content: center;\n  padding-bottom: 6px;\n'], ['\n  display: flex;\n  justify-content: center;\n  padding-bottom: 6px;\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var DownloadUrlContent = exports.DownloadUrlContent = _styledComponents2.default.div(_templateObject);