import specialTaskApi from 'api/SpecialTaskApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getSpecialTasks(internshipId) {
  return createAction({
    type: types.GET_SPECIAL_TASKS,
    callAPI: () => specialTaskApi.get(internshipId),
  });
}

export function createSpecialTasks(task) {
  return createAction({
    type: types.CREATE_SPECIAL_TASKS,
    callAPI: () => specialTaskApi.create(task),
  });
}

export function updateSpecialTasks(pk, task) {
  return createAction({
    type: types.UPDATE_SPECIAL_TASKS,
    callAPI: () => specialTaskApi.update(pk, task),
  });
}

export function patchSpecialTasks(pk, task) {
  return createAction({
    type: types.UPDATE_SPECIAL_TASKS,
    callAPI: () => specialTaskApi.patch(pk, task),
  });
}

export function removeSpecialTasks(pk) {
  return (dispatch) => specialTaskApi.remove(pk).then(() => {
    dispatch({
      type: types.REMOVE_SPECIAL_TASKS,
      response: { pk },
    });
  });
}
