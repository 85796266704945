import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';
import initialState from 'store/initialState';

const changeItems = (questions, changedItems) => ({
  ...questions,
  group_list: questions.group_list.map((group) => ({
    ...group,
    item_list: group.item_list.map((item) => {
      const changedItem = changedItems.find((i) => i.pk === item.pk);
      return changedItem || item;
    }),
  })),
});

const dataReducer = (state = initialState.assessment.data, action) => {
  switch (action.type) {
    case successType(types.GET_ASSESSMENT):
      if (action.response) {
        return {
          ...state,
          questions: action.response,
        };
      }
      return state;
    case successType(types.GET_ASSESSMENT_ANSWER_CHOICES):
      if (action.response) {
        return {
          ...state,
          answerChoices: action.response,
        };
      }
      return state;
    case successType(types.CHANGE_ASSESSMENT_ITEMS):
      if (action.response) {
        const newState = {
          ...state,
          questions: changeItems(state.questions, action.response),
        };
        return newState;
      }
      return state;
    case successType(types.CHANGE_ASSESSMENT):
      if (action.response) {
        const newState = {
          ...state,
          questions: {
            ...action.response,
            group_list: state.questions.group_list,
          },
        };
        return newState;
      }
      return state;
    case types.LOGOUT:
      return null;
    default:
      return state;
  }
};

export default createReducer(
  [types.GET_ASSESSMENT, types.CHANGE_ASSESSMENT],
  dataReducer,
);
