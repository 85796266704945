'use strict';

exports.__esModule = true;
exports.CarouselContainer = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  max-width: 1140px;\n  margin: 0 auto;\n  width: 100%;\n  position: relative;\n  .slick-arrow {\n    color: #d8d8d8;\n    font-size: 24px;\n    &::before {\n      display: none;\n    }\n  }\n'], ['\n  max-width: 1140px;\n  margin: 0 auto;\n  width: 100%;\n  position: relative;\n  .slick-arrow {\n    color: #d8d8d8;\n    font-size: 24px;\n    &::before {\n      display: none;\n    }\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var CarouselContainer = exports.CarouselContainer = _styledComponents2.default.div(_templateObject);