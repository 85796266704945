'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _FileInputStyled = require('./FileInputStyled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var FileInput = function FileInput(_ref) {
  var accept = _ref.accept,
      onChange = _ref.onChange,
      className = _ref.className,
      children = _ref.children,
      multiple = _ref.multiple,
      props = _objectWithoutProperties(_ref, ['accept', 'onChange', 'className', 'children', 'multiple']);

  return _react2.default.createElement(
    _FileInputStyled.FileInputLabel,
    { className: className },
    children,
    _react2.default.createElement('input', _extends({
      type: 'file',
      multiple: multiple,
      accept: accept,
      onChange: onChange
    }, props))
  );
};

FileInput.propTypes = process.env.NODE_ENV !== "production" ? {
  className: _propTypes2.default.string,
  accept: _propTypes2.default.string,
  multiple: _propTypes2.default.bool,
  onChange: _propTypes2.default.func.isRequired,
  children: _propTypes2.default.node.isRequired
} : {};

FileInput.defaultProps = {
  className: undefined,
  accept: undefined,
  multiple: false
};

exports.default = FileInput;
module.exports = exports['default'];