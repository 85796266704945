import React from 'react';
import PropTypes from 'prop-types';

import { Thumbnail } from 'shared';
import { ReportDayAttachmentsContainer } from './ReportDayAttachmentsStyled';

export const ReportDayAttachments = ({
  attachments,
  onDeleteAttachment,
  disabled,
}) => {
  const onDelete = (pk) => onDeleteAttachment(pk);
  return (
    <ReportDayAttachmentsContainer>
      {attachments.map((attachment) => {
        if (attachment.toDelete) return null;
        return (
          <Thumbnail
            key={attachment.pk}
            data={attachment}
            onDelete={onDelete}
            className="education-entry-thumb"
            deleteButtonClass="delete-button"
            disabled={disabled}
          />
        );
      })}
    </ReportDayAttachmentsContainer>
  );
};

ReportDayAttachments.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      pk: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onDeleteAttachment: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ReportDayAttachments;
