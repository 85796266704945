import React from 'react';
import PropTypes from 'prop-types';

import { Collapse } from 'shared';
import Can from 'components/Can/Can';
import Paragraph from 'components/Paragraph';
import { actions } from 'components/Can';
import defaultAvatar from 'styles/images/avatar.png';
import { section } from 'utils/access-rules/rules/internshipTask';
import { PrimaryUserData } from '../ApplicantProfileStyled';
import {
  getStateText,
  getStateSubText,
  getChatText,
  getChatSubText,
  getCalendarText,
  getCalendarSubText,
  getDocumentsText,
  getDocumentsSubText,
  getAssessmentText,
  getAssessmentSubText,
  getOltText,
  getOltSubText,
} from '../applicantProfileUtils';

const LoadingInternshipPage = ({ withoutHeader, translate, userRole }) => (
  <div>
    {!withoutHeader && (
      <>
        <Paragraph withTopMargin title="000000 00000" />
        <PrimaryUserData
          noTitle
          avatar={defaultAvatar}
          userFirstName="000"
          userLastName="000"
          birthday="00.00.0000"
          phone="+00 000 0000000"
          email="0000000@000.00"
          location="000000000"
          updateUser={() => {}}
        />
      </>
    )}
    <Collapse
      headerText={translate(getStateText(userRole))}
      previewText={(
        <Can I={actions.see} a={section.previewText}>
          {translate(getStateSubText(userRole))}
        </Can>
)}
    />
    <Collapse
      headerText={translate(getChatText(userRole))}
      previewText={(
        <Can I={actions.see} a={section.previewText}>
          {translate(getChatSubText(userRole))}
        </Can>
)}
    />
    <Collapse
      headerText={translate(getCalendarText(userRole))}
      previewText={(
        <Can I={actions.see} a={section.previewText}>
          <>{translate(getCalendarSubText(userRole))}</>
        </Can>
)}
    />
    <Collapse
      headerText={translate(getDocumentsText(userRole))}
      previewText={(
        <Can I={actions.see} a={section.previewText}>
          {translate(getDocumentsSubText(userRole))}
        </Can>
)}
    />
    <Collapse
      headerText={translate(getAssessmentText(userRole))}
      previewText={(
        <Can I={actions.see} a={section.previewText}>
          {translate(getAssessmentSubText(userRole))}
        </Can>
)}
    />
    <Collapse
      headerText={translate(getOltText(userRole))}
      previewText={(
        <Can I={actions.see} a={section.previewText}>
          {translate(getOltSubText(userRole))}
        </Can>
)}
    />
  </div>
);

LoadingInternshipPage.propTypes = {
  withoutHeader: PropTypes.bool,
  userRole: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

LoadingInternshipPage.defaultProps = {
  withoutHeader: false,
};

export default LoadingInternshipPage;
