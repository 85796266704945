import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  EVENT_TYPES,
  EVENT_VISIBILITY,
  sortSpecialTasks,
} from 'utils/calendarHelpers';
import {
  addEvent,
  deleteEvent,
  editEvent,
  getEvents,
} from 'actions/calendarActions';
import { getSpecialTasks } from 'actions/specialTaskAction';
import Calendar from 'components/SchedulerCalendar';
import { Error, Loader } from 'shared';
import { filterByInternship } from 'reducers/reducersUtils';

const ScheduleCalendar = ({
  getEvents,
  getSpecialTasks,
  deleteEvent,
  internshipId,
  shouldFetchEvents,
  addEvent,
  editEvent,
  events: list,
  loading,
  error,
  notifications,
  specialTasks,
  shouldFetchSpecialTasks,
  shouldAddAbsence,
  isStudentProfile,
}) => {
  const [date, setDate] = useState(moment());
  const [selectedEvent, setSelectedEvent] = useState(null);

  const onGetEvents = () => {
    getEvents(internshipId, moment());
  };

  const stableOnGetEvents = useCallback(onGetEvents, [
    internshipId,
    shouldFetchEvents,
    shouldFetchSpecialTasks,
  ]);
  const stableGetSpecialTasks = useCallback(getSpecialTasks, [
    internshipId,
    shouldFetchEvents,
    shouldFetchSpecialTasks,
  ]);
  useEffect(() => {
    if (shouldFetchEvents) stableOnGetEvents();
    if (shouldFetchSpecialTasks) {
      stableGetSpecialTasks(internshipId);
    }
  }, [
    stableOnGetEvents,
    stableGetSpecialTasks,
    internshipId,
    shouldFetchEvents,
    shouldFetchSpecialTasks,
  ]);

  const closeForm = () => setSelectedEvent(null);

  const onChangeDate = (date) => {
    setDate(date);
    closeForm();
  };

  const onChangeNavDate = (date) => {
    getEvents(internshipId, date);
  };

  const onEventDelete = (e, event) => {
    if (e) e.stopPropagation();
    return deleteEvent(event);
  };

  const onEventAdd = async (eventType) => {
    await closeForm();
    const newEvent = {};

    newEvent.kind = EVENT_TYPES[eventType];
    newEvent.title = '';
    newEvent.description = '';
    newEvent.place = '';
    newEvent.start = '';
    newEvent.end = '';
    newEvent.visibility = EVENT_VISIBILITY.STUDENT_ONLY;
    newEvent.attendees = [];
    newEvent.isNew = true;

    await setSelectedEvent(newEvent);
  };

  const onSendEvent = (event) => {
    if (event.pk) {
      const eventId = event.pk;
      const editedEvent = {
        start: event.start,
        end: event.end,
        kind: event.kind,
        title: event.title,
        description: event.description,
        place: event.place,
        internship: event.internship,
        visibility: event.visibility,
        attendees: event.attendees,
      };
      return editEvent(eventId, editedEvent);
    }
    return addEvent(event);
  };

  const _onOpenForm = (event) => {
    setSelectedEvent(event);
  };

  const onEventEdit = (e, event) => {
    e.stopPropagation();
    _onOpenForm({
      pk: event.pk,
      start: event.start,
      end: event.end,
      kind: event.kind,
      title: event.title,
      description: event.description,
      place: event.place,
      internship: event.internship,
      visibility: event.visibility,
      attendees: event.assigned_to,
      invitees: event.invitees,
      medical_document_requested: event.medical_document_requested,
    });
  };

  const specialTasksObj = useMemo(
    () => sortSpecialTasks(specialTasks.filter((specialTask) => !specialTask.done)),
    [specialTasks],
  );

  const events = { ...list, ...specialTasksObj };

  if (error) {
    return <Error />;
  }
  return (
    <Loader loading={loading}>
      <Calendar
        onChangeNavDate={onChangeNavDate}
        events={events}
        date={date}
        internshipId={internshipId}
        closeForm={closeForm}
        selectedEvent={selectedEvent}
        onChangeDate={onChangeDate}
        onEventAdd={onEventAdd}
        onEventEdit={onEventEdit}
        onEventDelete={onEventDelete}
        onCancelForm={closeForm}
        onSendEvent={onSendEvent}
        notifications={notifications}
        shouldAddAbsence={shouldAddAbsence}
        isStudentProfile={isStudentProfile}
        onGetEvents={onGetEvents}
      />
    </Loader>
  );
};

ScheduleCalendar.propTypes = {
  shouldFetchEvents: PropTypes.bool,
  internshipId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  events: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.oneOf([PropTypes.null, PropTypes.bool, PropTypes.string]),
  notifications: PropTypes.arrayOf(PropTypes.object),
  specialTasks: PropTypes.array.isRequired,
  getEvents: PropTypes.func.isRequired,
  getSpecialTasks: PropTypes.func.isRequired,
  addEvent: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  editEvent: PropTypes.func.isRequired,
  shouldFetchSpecialTasks: PropTypes.bool,
  shouldAddAbsence: PropTypes.bool,
  isStudentProfile: PropTypes.bool,
};

ScheduleCalendar.defaultProps = {
  shouldFetchEvents: true,
  internshipId: null,
  error: null,
  notifications: null,
  shouldFetchSpecialTasks: true,
  shouldAddAbsence: true,
  isStudentProfile: true,
};

const mapStateToProps = (
  {
    currentUser,
    notifications,
    calendar: { data, loading, error },
    specialTasks,
  },
  { internshipId },
) => ({
  currentUser: currentUser.data,
  events: data.byInternship[internshipId || 'global'] || [],
  loading: loading || specialTasks.loading,
  error: error || specialTasks.error,
  notifications: notifications.data.calendarNotReadNotification,
  specialTasks: filterByInternship(internshipId, specialTasks.data),
});

export default connect(mapStateToProps, {
  getEvents,
  getSpecialTasks,
  deleteEvent,
  addEvent,
  editEvent,
})(ScheduleCalendar);
