import React from 'react';
import PropTypes from 'prop-types';
import {
  ParagraphContainer,
  ParagraphTitle,
  ParagraphIcon,
  ParagraphText,
  ParagraphRightTextContainer,
} from './ParagraphStyled';

const Paragraph = ({
  title,
  children,
  icon,
  className,
  onClick,
  withTopMargin,
  rightText,
}) => (
  <ParagraphContainer withTopMargin={withTopMargin} className={className}>
    <ParagraphTitle className="title" withRightText={!!rightText}>
      {title}
      {icon && <ParagraphIcon onClick={onClick}>{icon}</ParagraphIcon>}
      {rightText && rightText !== true && (
        <ParagraphRightTextContainer>{rightText}</ParagraphRightTextContainer>
      )}
    </ParagraphTitle>
    <ParagraphText>{children}</ParagraphText>
  </ParagraphContainer>
);

Paragraph.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  withTopMargin: PropTypes.bool,
  rightText: PropTypes.node,
};

Paragraph.defaultProps = {
  children: undefined,
  icon: undefined,
  onClick: undefined,
  className: undefined,
  withTopMargin: false,
  title: null,
  rightText: null,
};

export default Paragraph;
