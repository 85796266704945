import styled from 'styled-components';
import { device } from 'styles/device';

export const CalendarDay = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
`;

export const EventBadgesContainer = styled.div`
  position: absolute;
  top: 75%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const EventBadge = styled.div`
  width: 10px;
  height: 10px;
  border-radius: ${({ theme }) => theme.borderRadiusRound};
  background-color: ${({
    theme,
    meetEvent,
    missEvent,
    taskEvent,
    onsiteEvent,
  }) => {
    if (meetEvent) {
      return theme.secondary;
    }
    if (missEvent) {
      return theme.primary;
    }
    if (taskEvent) {
      return 'green';
    }
    if (onsiteEvent) {
      return 'orange';
    }
  }};

  @media ${device.tablet} {
    width: 4px;
    height: 4px;
  }
`;
