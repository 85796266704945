import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';

const changeInternshipRequest = (list, item) => list.map((listItem) => {
  if (listItem.pk === item.pk) return item;
  return listItem;
});

const setInternshipRequestAttachment = (list, attachment) => list.map((listItem) => {
  if (listItem.pk === attachment.internship_request_id) {
    return {
      ...listItem,
      attachments: [...listItem.attachments, attachment],
    };
  }
  return listItem;
});

const deleteInternshipRequestAttachment = (list, attachmentId) => list.map((listItem) => ({
  ...listItem,
  attachments: listItem.attachments.filter((a) => a.pk !== attachmentId),
}));

const dataReducer = (state = null, action) => {
  switch (action.type) {
    case successType(types.GET_INTERNSHIPS_REQUESTS_LIST):
      return action.response;
    case successType(types.CHANGE_INTERNSHIP_REQUEST):
      return changeInternshipRequest(state, action.response);
    case successType(types.UPLOAD_INTERNSHIP_REQUEST_ATTACHMENT):
      return setInternshipRequestAttachment(state, action.response);
    case successType(types.DELETE_INTERNSHIP_REQUEST_ATTACHMENT):
      return deleteInternshipRequestAttachment(state, action.fileId);
    case successType(types.ADD_INTERNSHIP_REQUEST):
      return [...state, { ...action.response, new_added: true }];
    case successType(types.DELETE_INTERNSHIP_REQUEST):
      return state.filter((i) => i.pk !== action.internshipRequestId);
    case successType(types.GET_INTERNSHIP):
      if (action.response) {
        return action.response.applicant.internship_requests;
      }
      return state;
    case types.LOGOUT:
      return null;
    default:
      return state;
  }
};

export default createReducer(
  [
    types.GET_INTERNSHIPS_REQUESTS_LIST,
    types.CHANGE_INTERNSHIP_REQUEST,
    types.UPLOAD_INTERNSHIP_REQUEST_ATTACHMENT,
    types.ADD_INTERNSHIP_REQUEST,
  ],
  dataReducer,
);
