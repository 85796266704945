import styled from 'styled-components';
import Select from 'components/Form/Select';
import { device } from 'styles/device';

export const DropdownContainer = styled.div`
  margin: 50px 50px 180px 50px;
  display: flex;
  justify-content: center;
  @media ${device.mobile} {
    margin: 50px 0px 180px 0px;
  }
`;

export const ActionSelect = styled(Select)`
  width: 400px;
  height: 100%;
  margin-right: 8px;
  & .select__menu-list {
    max-height: 100%;
  }
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const CompletePresentationWrapper = styled.div`
  position: relative;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background: #fff;
`;

export const ChatWrapper = styled.div`
  position: relative;
  background-color: #fff;
  padding: 25px;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`;

export const ChatTitle = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.secondary};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  flex-grow: 1;
  margin-bottom: 10px;
`;

export const InputFieldWrapper = styled.div`
  width: 100%;
`;

export const ButtonClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: transparent;
  font-size: 25px;
  border: none;
  top: 18px;
  right: 14px;
  cursor: pointer;
  color: ${({ theme }) => theme.secondary};
`;
