'use strict';

exports.__esModule = true;
exports.ModalContent = exports.PopupStyled = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  &-content {\n    @media ', '{\n      width: 100% !important;\n    }\n  }\n'], ['\n  &-content {\n    @media ', '{\n      width: 100% !important;\n    }\n  }\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  .close {\n    position: absolute;\n    top: 10px;\n    right: 15px;\n    font-size: 22px;\n    cursor: pointer;\n  }\n'], ['\n  .close {\n    position: absolute;\n    top: 10px;\n    right: 15px;\n    font-size: 22px;\n    cursor: pointer;\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactjsPopup = require('reactjs-popup');

var _reactjsPopup2 = _interopRequireDefault(_reactjsPopup);

var _device = require('../../styles/device');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var PopupStyled = exports.PopupStyled = (0, _styledComponents2.default)(_reactjsPopup2.default)(_templateObject, _device.device.mobile);

var ModalContent = exports.ModalContent = _styledComponents2.default.div(_templateObject2);