import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { AnswerCheckboxContainer } from './AnswerCheckboxStyled';

const AnswerCheckbox = ({
  answer,
  answers,
  userRole,
  isComparingMode,
  question,
  onChange: _onChange,
  pk,
  disabled,
  ...rest
}) => {
  const currentAnswer = question[userRole];
  const getAnswersAmount = (values, answer) => {
    const answerPropertyName = Object.keys(answers).find((answerKey) => {
      if (answers[answerKey] === answer) {
        return answerKey;
      }
      return null;
    });
    return values.filter((value) => value === answerPropertyName).length;
  };
  const onChange = () => {
    if (isComparingMode) return;
    if (disabled) return;
    if (answers[currentAnswer] === answer) {
      _onChange(pk, '');
      return;
    }
    _onChange(pk, answer);
  };
  const answersCount = getAnswersAmount(Object.values(question), answer);
  return (
    <AnswerCheckboxContainer
      single={answersCount === 1}
      disabled={disabled}
      isComparingMode={isComparingMode}
    >
      {isComparingMode ? (
        <>
          <input
            checked={
              answer === answers[question.applicant]
              || answer === answers[question.teacher]
              || answer === answers[question.company_employee]
              || answer === answers[question.education_provider]
            }
            type="checkbox"
            onChange={() => {}}
            {...rest}
          />
          <span className="styled-checkbox overview" onClick={onChange}>
            {answer === answers[question.applicant] && (
              <FontAwesomeIcon
                className="checkbox-icon applicant"
                icon={faCheck}
              />
            )}
            {answer === answers[question.teacher] && (
              <FontAwesomeIcon
                className="checkbox-icon teacher"
                icon={faCheck}
              />
            )}
            {answer === answers[question.company_employee] && (
              <FontAwesomeIcon
                className="checkbox-icon company"
                icon={faCheck}
              />
            )}
            {answer === answers[question.education_provider] && (
              <FontAwesomeIcon
                className="checkbox-icon provider"
                icon={faCheck}
              />
            )}
          </span>
        </>
      ) : (
        <>
          <input
            checked={answer === answers[currentAnswer]}
            type="checkbox"
            {...rest}
          />
          <span className="styled-checkbox" onClick={onChange}>
            <FontAwesomeIcon className="checkbox-icon" icon={faCheck} />
          </span>
        </>
      )}
    </AnswerCheckboxContainer>
  );
};

AnswerCheckbox.propTypes = {
  answer: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  currentAnswer: PropTypes.string,
  pk: PropTypes.number.isRequired,
  isComparingMode: PropTypes.bool,
  disabled: PropTypes.bool,
  question: PropTypes.shape({
    applicant: PropTypes.string,
    teacher: PropTypes.string,
    company_employee: PropTypes.string,
    education_provider: PropTypes.string,
  }).isRequired,
  answers: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
};

AnswerCheckbox.defaultProps = {
  isComparingMode: false,
  disabled: false,
  currentAnswer: null,
};

export default AnswerCheckbox;
