import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Localize from 'components/Localize';

import { updateInternshipLearningTask } from 'actions/internshipsActions';
import WriteTask from '../../../../components/TaskCard/WriteTask';
import ActionResults from '../../ActionResults';
import { UnaffectedUsers } from '../../ManageStudentsStyled';

const SetOlt = ({
  selectedApplicants,
  selectedInternship,
  showActionResult,
  closeActionModel,
  updateInternshipLearningTask,
}) => {
  const handleSubmit = (values) => {
    const setOlt = selectedApplicants.map((applicant) => {
      const selectedApplicantInternship = selectedInternship[applicant.pk];
      if (
        selectedApplicantInternship
        && selectedApplicantInternship.value
        && !selectedApplicantInternship.value.olt_submitted_at
        && !selectedApplicantInternship.value.olt_submited_and_company_commented
      ) {
        return updateInternshipLearningTask(
          selectedApplicantInternship.value.pk,
          {
            task: values.task,
            teacher_attachment: values.attachment || null,
          },
        )
          .then(() => ({
            result: 'success',
            user: applicant.esuser.user,
          }))
          .catch(() => ({
            result: 'failed',
            user: applicant.esuser.user,
          }));
      }
      return null;
    });
    return Promise.all(setOlt).then((values) => {
      showActionResult(
        <ActionResults results={values} onClose={closeActionModel} />,
      );
    });
  };
  const unaffectedUsers = selectedApplicants.filter(
    (applicant) => !(
      selectedInternship[applicant.pk]
        && selectedInternship[applicant.pk].value
        && !selectedInternship[applicant.pk].value.olt_submitted_at
        && !selectedInternship[applicant.pk].value
          .olt_submited_and_company_commented
    ),
  );
  return (
    <div>
      <WriteTask
        submitManageStudents={handleSubmit}
        closeManageStudents={closeActionModel}
      />
      {unaffectedUsers.length > 0 && (
        <UnaffectedUsers>
          <Localize id="following-users-will-not-be-affected" />
          {unaffectedUsers.map(
            (applicant) => ` ${applicant.esuser.user.first_name} ${applicant.esuser.user.last_name},`,
          )}
        </UnaffectedUsers>
      )}
    </div>
  );
};

SetOlt.propTypes = {
  selectedApplicants: PropTypes.array.isRequired,
  selectedInternship: PropTypes.shape({}).isRequired,
  showActionResult: PropTypes.func.isRequired,
  closeActionModel: PropTypes.func.isRequired,
  updateInternshipLearningTask: PropTypes.func.isRequired,
};

export default connect(
  undefined,
  {
    updateInternshipLearningTask,
  },
)(SetOlt);
