import * as types from 'actions/types';
import { createReducer, successType } from 'shared/lib/lib/callAPI';
import { combineReducers } from 'redux';

const byInternship = (state = {}, action) => {
  switch (action.type) {
    case successType(types.GET_USERS):
      return {
        ...state,
        [action.response.internshipId]: [...action.response.data],
      };
    case types.LOGOUT:
      return {};
    default:
      return state;
  }
};

const dataReducer = combineReducers({
  byInternship,
});

export default createReducer(types.GET_USERS, dataReducer);
