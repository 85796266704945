import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Localize from 'components/Localize';
import {
  Button,
  Error,
  FileList,
  Form,
  Info,
  InputField,
  Success,
  Prompt
} from 'shared';
import Textarea from 'components/Form/Textarea';
import {
  deleteLearningTaskAttachment,
  updateLearningTask
} from 'actions/applicantLearningTaskAction';
import checkIcon from 'shared/lib/styles/images/check.svg';
import { DownloadButton } from 'components/DownloadUrl';
import arrowRightIcon from 'styles/images/svg/arrow-right.svg';
import {
  TaskCardSection,
  TaskCardSectionContent,
  TaskCardSectionContentWithButton,
  TaskCardSectionTitle,
  TaskFooter
} from 'components/TaskCard/TaskCardStyled';

const Task = ({
  task,
  history,
  updateLearningTask,
  deleteLearningTaskAttachment,
  internshipId
}) => {
  const isReadyToFill = task ? !!task.task : false;
  if (!isReadyToFill) {
    return (
      <Info>
        <Localize id="task-has-not-been-provided-by-teacher-yet" />
      </Info>
    );
  }
  const handleSave = (values, form) => {
    let isSubmit = false;
    if (form) {
      isSubmit = true;
    }
    const attachments = values.attachments.map(({ pk, file, type, kind }) => ({
      pk,
      file: file.split(',').pop(),
      kind: kind || (type && type.includes('image') ? 'IMAGE' : 'DOCUMENT'),
      operational_learning: task.api_url
    }));
    const filesToDelete = task.applicant_attachment_list.filter(
      attachment => attachments.filter(a => a.pk === attachment.pk).length === 0
    );
    if (filesToDelete.length !== 0) {
      const promises = filesToDelete.map(file =>
        deleteLearningTaskAttachment(file.pk)
      );
      return Promise.all(promises)
        .then(() =>
          updateLearningTask(task.pk, {
            report: values.report,
            applicant_attachment_list: attachments,
            submitted: isSubmit === true
          })
        )
        .then(
          () =>
            isSubmit === true &&
            history.push(`/applicant/${internshipId || ''}`)
        );
    }
    return updateLearningTask(task.pk, {
      report: values.report,
      task: values.task,
      applicant_attachment_list: attachments,
      submitted: isSubmit === true
    }).then(
      () =>
        isSubmit === true && history.push(`/applicant/${internshipId || ''}`)
    );
  };
  return (
    <Form
      initialValues={{
        report: task.report,
        attachments: task.applicant_attachment_list.map(
          ({ pk, file, preview, kind }) => ({
            pk,
            file,
            preview,
            kind
          })
        )
      }}
      validateSchema={{
        report: { required: true }
      }}
      onSubmit={handleSave}
      enableReinitialize
    >
      {({ errors, isSubmitting, dirty, values, submitForm, ...rest }) => {
        const onSave = () => handleSave(values);
        return (
          <>
            {task.submitted && (
              <Success>
                <Localize id="operational-learning-task-submitted" />
              </Success>
            )}
            <TaskCardSection>
              <TaskCardSectionTitle>
                <Localize id="task" />
              </TaskCardSectionTitle>
              <TaskCardSectionContentWithButton>
                <div className="text">{task.task}</div>
                {task.teacher_attachment && (
                  <DownloadButton url={task.teacher_attachment.file}>
                    <Localize id="download-task-sheet" />
                  </DownloadButton>
                )}
              </TaskCardSectionContentWithButton>
            </TaskCardSection>
            <TaskCardSection>
              <TaskCardSectionTitle>
                <Localize id="due-date" />
              </TaskCardSectionTitle>
              <TaskCardSectionContentWithButton>
                <div className="text">{task.due_date}</div>
              </TaskCardSectionContentWithButton>
            </TaskCardSection>
            <TaskCardSection>
              <TaskCardSectionTitle>
                <Localize id="message" />
              </TaskCardSectionTitle>
              <Localize>
                {({ translate }) => (
                  <InputField
                    rows={2}
                    name="report"
                    placeholder={translate('no-text')}
                    component={Textarea}
                    disabled={task.submitted}
                  />
                )}
              </Localize>
            </TaskCardSection>
            <TaskCardSection>
              <TaskCardSectionTitle>
                <Localize id="files-photos-or-videos" />
              </TaskCardSectionTitle>
              <TaskCardSectionContent>
                <InputField
                  fileListOnTop
                  name="attachments"
                  component={FileList}
                  meta={rest}
                  disabled={task.submitted}
                />
              </TaskCardSectionContent>
            </TaskCardSection>
            {errors._error && <Error message={errors._error} />}
            {!task.submitted && (
              <TaskFooter>
                <Button
                  type="button"
                  className="btn-save"
                  disableBoxShadow
                  icon={checkIcon}
                  size="small"
                  color="secondary"
                  variant="outlined"
                  disabled={!dirty}
                  loading={isSubmitting}
                  onClick={onSave}
                >
                  <Localize id="save" />
                </Button>
                <Prompt
                  confirm={submitForm}
                  trigger={
                    <Button
                      icon={arrowRightIcon}
                      className="btn-submit"
                      disableBoxShadow
                      color="secondary"
                      size="small"
                      type="button"
                    >
                      <Localize id="submit-report" />
                    </Button>
                  }
                >
                  <Localize id="are-you-sure-want-submit-form" />
                </Prompt>
              </TaskFooter>
            )}
          </>
        );
      }}
    </Form>
  );
};

Task.propTypes = {
  internshipId: PropTypes.number.isRequired,
  task: PropTypes.shape({
    submitted: PropTypes.bool.isRequired,
    report: PropTypes.string,
    due_date: PropTypes.string,
    task: PropTypes.string,
    api_url: PropTypes.string,
    pk: PropTypes.number.isRequired,
    applicant_attachment_list: PropTypes.arrayOf,
    teacher_attachment: PropTypes.object
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  updateLearningTask: PropTypes.func.isRequired,
  deleteLearningTaskAttachment: PropTypes.func.isRequired
};

export default compose(
  withRouter,
  connect(undefined, {
    updateLearningTask,
    deleteLearningTaskAttachment
  })
)(Task);
