import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loader } from 'shared';
import {
  getAssessment,
  changeAssessment,
  getAssessmentAnswerChoices,
  changeAssessmentItems,
} from 'actions/assessmentActions';
import InternshipAssessmentTabs from '../InternshipAssessmentTabs';
import { getSubmittedProperty } from '../assessmentUtils';

const AssessmentContainer = ({
  loading,
  error,
  assessment,
  answerChoices,
  getAssessment: _getAssessment,
  changeAssessment: _changeAssessment,
  getAssessmentAnswerChoices: _getAssessmentAnswerChoices,
  changeAssessmentItems: _changeAssessmentItems,
  user,
  internshipId,
}) => {
  const [assessmentSaveError, setAssessmentSaveError] = useState(false);
  const [assessmentSaveSuccess, setAssessmentSaveSuccess] = useState(false);

  const stableGetAssessment = useCallback(_getAssessment, [
    answerChoices,
    internshipId,
  ]);
  const stableGetAssessmentAnswerChoices = useCallback(
    _getAssessmentAnswerChoices,
    [answerChoices, internshipId],
  );
  useEffect(() => {
    stableGetAssessment(internshipId);
    if (!answerChoices) stableGetAssessmentAnswerChoices();
  }, [
    stableGetAssessment,
    stableGetAssessmentAnswerChoices,
    answerChoices,
    internshipId,
  ]);
  const onSubmit = (items, assessmentData) => _changeAssessmentItems(items).then(() => _changeAssessment(internshipId, {
    ...assessmentData,
    ...getSubmittedProperty(assessment, user.role),
  }));
  const onSave = (items, assessmentData) => {
    _changeAssessmentItems(items)
      .then(() => {
        _changeAssessment(internshipId, assessmentData);
      })
      .then(() => {
        setAssessmentSaveSuccess(true);
        setAssessmentSaveError(false);
      })
      .catch(() => {
        setAssessmentSaveSuccess(false);
        setAssessmentSaveError(true);
      });
  };
  if (loading || !assessment || !answerChoices) {
    return <Loader />;
  }
  return (
    <InternshipAssessmentTabs
      data={assessment.group_list}
      onSubmit={onSubmit}
      onSave={onSave}
      isCompleted={assessment.completed}
      assessment={assessment}
      answers={answerChoices}
      userRole={user.role.toLowerCase()}
      error={error}
      saveError={assessmentSaveError}
      saveSuccess={assessmentSaveSuccess}
      hasButtons
    />
  );
};

AssessmentContainer.propTypes = {
  error: PropTypes.shape({
    detail: PropTypes.string,
  }),
  user: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  assessment: PropTypes.shape({
    group_list: PropTypes.arrayOf(PropTypes.object),
    pk: PropTypes.number.isRequired,
    completed: PropTypes.bool.isRequired,
  }),
  answerChoices: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  getAssessment: PropTypes.func.isRequired,
  changeAssessment: PropTypes.func.isRequired,
  getAssessmentAnswerChoices: PropTypes.func.isRequired,
  changeAssessmentItems: PropTypes.func.isRequired,
  internshipId: PropTypes.number.isRequired,
};

AssessmentContainer.defaultProps = {
  error: null,
  assessment: null,
  answerChoices: null,
};

const mapStateToProps = ({
  assessment: { data, loading, error },
  currentUser,
}) => ({
  user: currentUser && currentUser.data,
  loading,
  error,
  assessment: data && data.questions,
  answerChoices: data && data.answerChoices,
});

export default connect(mapStateToProps, {
  getAssessment,
  getAssessmentAnswerChoices,
  changeAssessment,
  changeAssessmentItems,
})(AssessmentContainer);
