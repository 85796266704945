'use strict';

exports.__esModule = true;
exports.default = undefined;

var _JobAdvisor = require('./JobAdvisor');

var _JobAdvisor2 = _interopRequireDefault(_JobAdvisor);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _JobAdvisor2.default;
module.exports = exports['default'];