import applicationApi from 'api/ApplicationApi';
import { createAction } from 'shared/lib/lib/callAPI';
import * as types from './types';

export function getAdvisorsData() {
  return createAction({
    type: types.SET_ADVISORS_DATA,
    callAPI: () => applicationApi.getAdvisorsData(),
  });
}

export function getMyApplications() {
  return createAction({
    type: types.GET_MY_APPLICATIONS,
    callAPI: () => applicationApi.getMyApplications(),
    shouldCallAPI: (state) => !state.myApplications.data,
  });
}

export function getApplicationStatuses(language) {
  return createAction({
    type: types.GET_APPLICATION_STATUSES,
    callAPI: () => applicationApi.getApplicationStatuses(language)
      .then((data) => ({
        language,
        items: Object.keys(data)
          .map((key) => ({
            key,
            value: data[key],
          })),
      })),
    shouldCallAPI: (state) => !state.applicationStatuses.data || !state.applicationStatuses.data[language],
  });
}
