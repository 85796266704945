import React from 'react';
import Localize from 'components/Localize';

const NoMatch = () => (
  <div>
    <Localize id="were-sorry-we-couldnt-find-page-you-requested" />
  </div>
);

export default NoMatch;
