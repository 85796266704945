import React, { useRef, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import BellIcon from './BellIcon/BellIcon';
import {
  BellNotificationContainer,
  BellNotificationList,
  BellNotificationButton,
} from './BellNotificationStyled';

function BellNotification({
  hasUnread,
  onClose,
  children,
  className,
  isDropdownOpen,
  onVisibilityChange,
}) {
  const notificationRef = useRef(null);

  function onCloseMenu() {
    onVisibilityChange(false);
    onClose();
  }

  function onCloseMenuByWindow(e) {
    if (isDropdownOpen && !notificationRef.current.contains(e.target)) {
      onCloseMenu();
    }
  }

  function onOpenMenu() {
    onVisibilityChange(true);
  }

  function onToggleMenu() {
    if (isDropdownOpen) {
      onCloseMenu();
    } else {
      onOpenMenu();
    }
  }

  const stableOnCloseMenuByWindow = useCallback(onCloseMenuByWindow, [
    notificationRef,
    isDropdownOpen,
  ]);
  useEffect(() => {
    if (notificationRef.current) {
      if (isDropdownOpen) {
        document.body.addEventListener('click', stableOnCloseMenuByWindow);
      } else {
        document.body.removeEventListener('click', stableOnCloseMenuByWindow);
      }
    }

    return () => {
      document.body.removeEventListener('click', stableOnCloseMenuByWindow);
    };
  }, [stableOnCloseMenuByWindow, notificationRef, isDropdownOpen]);

  return (
    <BellNotificationContainer className={className} ref={notificationRef}>
      <BellNotificationButton
        isMenuOpen={isDropdownOpen}
        onClick={onToggleMenu}
      >
        <BellIcon hasUnread={hasUnread} />
      </BellNotificationButton>
      <BellNotificationList isMenuOpen={isDropdownOpen}>
        {children(onToggleMenu)}
      </BellNotificationList>
    </BellNotificationContainer>
  );
}

BellNotification.propTypes = {
  hasUnread: propTypes.bool,
  onClose: propTypes.func,
  children: propTypes.func.isRequired,
  className: propTypes.string,
  isDropdownOpen: propTypes.bool.isRequired,
  onVisibilityChange: propTypes.func.isRequired,
};

BellNotification.defaultProps = {
  hasUnread: false,
  onClose: () => {},
  className: undefined,
};

export default BellNotification;
